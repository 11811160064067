import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("SpecialDayStore");

const urls = {
  specialDays: "/absence/special-day",
  carryOverDates: (year) => `/absence/special-day/carry-over-dates/${year}`,
};

const initState = () => {
  return {
    specialDaysFrom: null,
    specialDaysAll: null,
    specialDays: {},
    specialDay: null,
    selectedYear: new Date().getFullYear(),
    carryOverDates: null,
    normalDayNumber: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  getSpecialDaysFrom: async function ({ commit, dispatch }, fromDate) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.specialDays + "/all-from",
        {
          params: { fromDate: fromDate },
        },
        true,
        dispatch,
        "Fetching all special days from..."
      );
      commit("setSpecialDaysFrom", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getSpecialDaysAll: async function ({ commit, dispatch, state }, force) {
    try {
      let specialDays = await this.remoteRequest(
        "get",
        urls.specialDays + "/" + state.selectedYear + "/all",
        null,
        true,
        dispatch,
        "Év napjainak betöltése...",
        null,
        force
      );
      commit("setSpecialDaysAll", specialDays);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getSpecialDays: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      params.params.append("year", state.selectedYear);
      let data = await this.remoteRequest(
        "get",
        urls.specialDays,
        params,
        true,
        dispatch,
        "Különleges napok betöltése...",
        null,
        force
      );

      commit("setSpecialDays", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  setSelectedYear: async ({ commit }, selectedYear) => {
    await commit("setSelectedYear", selectedYear);
  },
  getSpecialDay: async function ({ commit, dispatch }, dayId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.specialDays + "/" + dayId,
        null,
        true,
        dispatch,
        "Különleges nap betöltése..."
      );
      commit("setSpecialDay", data);
    } catch (err) {
      logger.error(err);
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  initWeekends: async function ({ dispatch, state }) {
    try {
      await this.remoteRequest(
        "post",
        urls.specialDays + "/init?year=" + state.selectedYear,
        null,
        false,
        dispatch,
        "specialDay.initWeekends.flair|Hétvégék felvétele",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "specialDay.initWeekends.progress|Hétvégék felvétele folyamatban...",
          fail: "specialDay.initWeekends.fail|Hétvégék felvétele sikertelen!",
          success:
            "specialDay.initWeekends.success|Hétvégék felvétele sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  deleteSpecialDay: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.specialDays + `/${id}`,
        null,
        false,
        dispatch,
        "specialDay.delete.flair|Különleges nap törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "specialDay.delete.progress|Különleges nap törlése folyamatban...",
          fail: "specialDay.delete.fail|Különleges nap törlése sikertelen!",
          success: "specialDay.delete.success|Különleges nap törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateSpecialDay: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.specialDays + `/${request.id}`,
        {
          type: request.type,
          name: request.name,
          description: request.description,
          dayDate: request.dayDate,
        },
        false,
        dispatch,
        "specialDay.modify.flair|Különleges nap módosítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "specialDay.modify.progress|Különleges nap módosítása folyamatban...",
          fail: "specialDay.modify.fail|Különleges nap módosítása sikertelen!",
          success:
            "specialDay.modify.success|Különleges nap módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  createSpecialDay: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.specialDays,
        request,
        false,
        dispatch,
        "specialDay.create.flair|Különleges nap létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "specialDay.create.progress|Létrehozás folyamatban...",
          fail: "specialDay.create.fail|Különleges nap létrehozása sikertelen!",
          success:
            "specialDay.create.success|Különleges nap létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  fetchCarryOverDates: async function ({ dispatch, commit }, selectedYear) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.carryOverDates(selectedYear),
        null,
        true,
        dispatch,
        "Fetching carry over dates..."
      );
      commit("setCarryOverDates", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchNormalDayNumberBetween: async function ({ dispatch, commit }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.specialDays + "/normal",
        {
          params: {
            fromDate: params.fromDate,
            toDate: params.toDate,
          },
        },
        true,
        dispatch,
        "Fetching normal day number..."
      );
      commit("setNormalDayNumber", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setSpecialDays: (state, specialDays) => {
    for (let i in specialDays.items) {
      if (!specialDays.items.hasOwnProperty(i) || !specialDays.items[i])
        continue;
      switch (specialDays.items[i].type) {
        case "WEEKEND":
          specialDays.items[i].type = { enum: "WEEKEND", display: "Hétvége" };
          break;
        case "HOLIDAY":
          specialDays.items[i].type = { enum: "HOLIDAY", display: "Ünnepnap" };
          break;
        case "EXTRA_WORKDAY":
          specialDays.items[i].type = {
            enum: "EXTRA_WORKDAY",
            display: "Munkanap",
          };
          break;
      }
    }
    state.specialDays = specialDays;
  },
  setSpecialDaysAll: (state, specialDays) => {
    state.specialDaysAll = specialDays;
  },
  setSpecialDaysFrom: (state, specialDays) => {
    state.specialDaysFrom = specialDays;
  },
  setSpecialDay: (state, specialDay) => {
    switch (specialDay.type) {
      case "WEEKEND":
        specialDay.type = { enum: "WEEKEND", display: "Hétvége" };
        break;
      case "HOLIDAY":
        specialDay.type = { enum: "HOLIDAY", display: "Ünnepnap" };
        break;
      case "EXTRA_WORKDAY":
        specialDay.type = { enum: "EXTRA_WORKDAY", display: "Munkanap" };
        break;
    }
    state.specialDay = specialDay;
  },
  setSelectedYear: (state, year) => {
    state.selectedYear = year;
  },
  setCarryOverDates: (state, carryOverDates) => {
    state.carryOverDates = carryOverDates;
  },
  setNormalDayNumber: (state, normalDayNumber) => {
    state.normalDayNumber = normalDayNumber;
  },
};

const getters = {
  specialDay: (state) => {
    return state.specialDay;
  },
  specialDaysAll: (state) => {
    return state.specialDaysAll;
  },
  specialDaysFrom: (state) => {
    return state.specialDaysFrom;
  },
  specialDays: (state) => {
    return state.specialDays;
  },
  selectedYear: (state) => {
    return state.selectedYear;
  },
  carryOverDates: (state) => {
    return state.carryOverDates;
  },
  normalDayNumber: (state) => {
    return state.normalDayNumber;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
