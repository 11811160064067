"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ForecastRuleGet = /** @class */ (function () {
    function ForecastRuleGet(functionValue, key, value, index) {
        this.function = functionValue;
        this.key = key;
        this.value = value;
        this.index = index;
    }
    return ForecastRuleGet;
}());
exports.default = ForecastRuleGet;
