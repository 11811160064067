<template>
  <div>
    <div class="container" v-if="absenceRequest">
      <div class="mb-2 ml-2">
        <RouterLink to="../?force=true">
          <div class="is-inline-flex is-align-items-center">
            <b-icon icon="chevron-left" />
            <p>
              {{ $tf("handleAbsenceRequestForm.backToList|Szabadságkérelmek") }}
            </p>
          </div>
        </RouterLink>
      </div>
      <div class="columns">
        <div class="column is-6">
          <article class="panel is-info">
            <div class="panel-heading">
              {{
                $tf("handleAbsenceRequestForm.requestDetails|Kérelem adatai")
              }}
            </div>
            <div class="panel-block">
              <section style="width: 100%">
                <b-field
                  horizontal
                  :label="$tf('handleAbsenceRequestForm.date|Kérelem dátuma')"
                >
                  <b-input
                    :value="formatDate(absenceRequest.created)"
                    disabled
                  />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('handleAbsenceRequestForm.type|Típus')"
                >
                  <b-input :value="absenceRequest.type.name" disabled />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('handleAbsenceRequestForm.status|Státusz')"
                >
                  <b-input :value="absenceRequest.status.display" disabled />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('handleAbsenceRequestForm.description|Leírás')"
                >
                  <b-input :value="absenceRequest.description" disabled />
                </b-field>
                <b-field
                  horizontal
                  :label="$tf('handleAbsenceRequestForm.requestDate|Dátum')"
                >
                  <b-field grouped group-multiline>
                    <b-tooltip
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.carryOver.tooltip|Előző évről áthozott'
                        )
                      "
                      type="is-light"
                      class="mr-2 my-auto"
                      v-if="absenceRequest.carryOver"
                    >
                      <div class="is-flex is-align-items-center">
                        <b-icon size="is-small" icon="clock-rotate-left" />
                      </div>
                    </b-tooltip>
                    <b-field
                      v-for="(
                        actDate, index
                      ) in absenceRequest.absenceRequestDates"
                      :key="index"
                    >
                      <b-tag type="is-info">
                        {{ formatDate(actDate) }}
                      </b-tag>
                    </b-field>
                  </b-field>
                </b-field>

                <div class="panel-block" v-if="absenceRequestId">
                  <commented-approval
                    :absence-request-id="absenceRequestId"
                    :reload="commentedApprovalKey"
                  ></commented-approval>
                </div>

                <b-input
                  type="textarea"
                  maxlength="200"
                  :placeholder="
                    $tf('handleAbsenceRequestForm.approvalComment|Indoklás')
                  "
                  v-model="approvalComment"
                  :disabled="
                    absenceRequest.status.enum === 'REVOKED' ||
                    absenceRequest.status.enum === 'ACTIVE' ||
                    absenceRequest.status.enum === 'TAKEN' ||
                    isSubmitting
                  "
                ></b-input>

                <div class="panel-block" v-if="absenceRequestId">
                  <b-field grouped group-multiline>
                    <b-field v-if="canApproveRequests">
                      <b-button
                        type="is-success"
                        @click="approve('APPROVE')"
                        :disabled="
                          absenceRequest.status.enum === 'REVOKED' ||
                          absenceRequest.status.enum === 'ACTIVE' ||
                          absenceRequest.status.enum === 'TAKEN'
                        "
                        :loading="isSubmitting"
                      >
                        {{ $tf("handleAbsenceRequestForm.accept|Elfogadom") }}
                      </b-button>
                    </b-field>
                    <b-field>
                      <b-button
                        type="is-warning"
                        @click="approve('CLARIFY')"
                        :disabled="
                          absenceRequest.status.enum === 'REVOKED' ||
                          absenceRequest.status.enum === 'ACTIVE' ||
                          absenceRequest.status.enum === 'TAKEN'
                        "
                        :loading="isSubmitting"
                      >
                        {{
                          $tf(
                            "handleAbsenceRequestForm.clarify|Egyeztetés szükséges"
                          )
                        }}
                      </b-button>
                    </b-field>
                    <b-field v-if="canRevokeRequests">
                      <b-button
                        type="is-danger"
                        @click="approve('DENY')"
                        :disabled="
                          absenceRequest.status.enum === 'REVOKED' ||
                          absenceRequest.status.enum === 'ACTIVE' ||
                          absenceRequest.status.enum === 'TAKEN'
                        "
                        :loading="isSubmitting"
                      >
                        {{ $tf("handleAbsenceRequestForm.deny|Elutasítom") }}
                      </b-button>
                    </b-field>
                  </b-field>
                  <br />
                </div>
              </section>
            </div>
          </article>
        </div>

        <div class="column is-6">
          <article class="panel is-light">
            <div class="panel-heading">
              {{
                $tf("handleAbsenceRequestForm.userDetails|Felhasználó adatai")
              }}
            </div>
            <div class="panel-block">
              <section>
                <ul v-if="quota">
                  <li>
                    <strong>{{
                      $tf("handleAbsenceRequestForm.quota.all|Összes:")
                    }}</strong
                    >{{
                      $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                        days: quota.quota,
                      })
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $tf(
                        "handleAbsenceRequestForm.quota.remaining|Fennmaradó:"
                      )
                    }}</strong>
                    <span :class="{ 'has-text-red': quota.remaining <= 0 }"
                      >{{
                        $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                          days: quota.remaining,
                        })
                      }}
                    </span>
                  </li>
                  <li>
                    <strong>{{
                      $tf("handleAbsenceRequestForm.quota.reserved|Lefoglalt:")
                    }}</strong>
                    {{
                      $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                        days: quota.reserved,
                      })
                    }}
                  </li>
                  <li>
                    <strong>{{
                      $tf("handleAbsenceRequestForm.quota.taken|Felhasznált:")
                    }}</strong>
                    {{
                      $tf("handleAbsenceRequestForm.quota.days|{days} nap", {
                        days: quota.taken,
                      })
                    }}
                  </li>
                </ul>
              </section>
            </div>
            <div class="panel-block">
              <section>
                <b-collapse
                  aria-id="contentIdForA11y2"
                  animation="slide"
                  v-model="isWaitingRequestsOpen"
                >
                  <template v-slot:trigger>
                    <div role="button" aria-controls="contentIdForA11y2">
                      <strong
                        ><a>
                          {{
                            $tf(
                              "handleAbsenceRequestForm.pendingRequestsOfUser.title|@{user} döntésre váró kérelmei",
                              { user: absenceRequest.user }
                            )
                          }}
                        </a></strong
                      >
                    </div>
                  </template>

                  <b-table
                    :data="absenceRequestsWaiting.items"
                    ref="table"
                    striped
                    hoverable
                    mobile-cards
                    paginated
                    backend-pagination
                    :total="paginationWaiting.totalCount"
                    :per-page="paginationWaiting.size"
                    @page-change="onPageChangeWaiting"
                    backend-sorting
                    @sort="onSortWaiting"
                    default-sort-direction="desc"
                  >
                    <b-table-column
                      field="type"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pendingRequestsOfUser.table.type|Típus'
                        )
                      "
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.type.name }}
                    </b-table-column>

                    <b-table-column
                      field="status"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pendingRequestsOfUser.table.status|Státusz'
                        )
                      "
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.status ? props.row.status.display : "-" }}
                    </b-table-column>

                    <b-table-column
                      field="description"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pendingRequestsOfUser.table.description|Leírás'
                        )
                      "
                      centered
                      v-slot="props"
                    >
                      {{ props.row.description }}
                    </b-table-column>

                    <b-table-column
                      field="date"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pendingRequestsOfUser.table.requestDate|Dátum'
                        )
                      "
                      centered
                      width="450"
                      v-slot="props"
                    >
                      <div
                        class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
                      >
                        <b-tooltip
                          :label="
                            $tf(
                              'handleAbsenceRequestForm.pendingRequestsOfUser.table.carryOver.tooltip|Előző évről áthozott'
                            )
                          "
                          type="is-light"
                          class="mr-2"
                          v-if="props.row.carryOver"
                        >
                          <div class="is-flex is-align-items-center">
                            <b-icon size="is-small" icon="clock-rotate-left" />
                          </div>
                        </b-tooltip>
                        <b-tag
                          v-for="(actDate, index) in props.row
                            .absenceRequestDates"
                          :key="index"
                          :type="
                            getDateType(
                              props.row.status ? props.row.status.enum : null
                            )
                          "
                        >
                          {{ formatDate(actDate) }}
                        </b-tag>
                      </div>
                    </b-table-column>

                    <b-table-column
                      field="actions"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pendingRequestsOfUser.table.actions|Műveletek'
                        )
                      "
                      centered
                      v-slot="props"
                    >
                      <b-tooltip
                        :label="
                          $tf(
                            'handleAbsenceRequestForm.pendingRequestsOfUser.table.actions.open|Megnyitás'
                          )
                        "
                        type="is-light"
                      >
                        <clickable-icon
                          @click="openAbsenceRequestForm(props.row.id)"
                          icon="edit"
                          type="is-info"
                        />
                      </b-tooltip>
                    </b-table-column>

                    <template #empty>
                      <div class="has-text-centered">
                        {{
                          $tf(
                            "handleAbsenceRequestForm.pendingRequestsOfUser.table.empty|Jelenleg nincs több döntésre váró kérelem."
                          )
                        }}
                      </div>
                    </template>
                  </b-table>
                </b-collapse>

                <b-collapse
                  aria-id="contentIdForA11y2"
                  animation="slide"
                  v-model="isPastRequestOpen"
                >
                  <template v-slot:trigger>
                    <div role="button" aria-controls="contentIdForA11y2">
                      <strong
                        ><a>
                          {{
                            $tf(
                              "handleAbsenceRequestForm.pastRequestsOfUser.title|@{user} korábbi kérelmei",
                              { user: absenceRequest.user }
                            )
                          }}</a
                        ></strong
                      >
                    </div>
                  </template>

                  <b-table
                    :data="absenceRequestsPast.items"
                    ref="table"
                    striped
                    hoverable
                    mobile-cards
                    paginated
                    backend-pagination
                    :total="paginationPast.totalCount"
                    :per-page="paginationPast.size"
                    @page-change="onPageChangePast"
                    backend-sorting
                    @sort="onSortPast"
                    default-sort-direction="desc"
                  >
                    <b-table-column
                      field="type"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pastRequestsOfUser.table.type|Típus'
                        )
                      "
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.type.name }}
                    </b-table-column>

                    <b-table-column
                      field="status"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pastRequestsOfUser.table.status|Státusz'
                        )
                      "
                      sortable
                      v-slot="props"
                    >
                      {{ props.row.status ? props.row.status.display : "-" }}
                    </b-table-column>

                    <b-table-column
                      field="description"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pastRequestsOfUser.table.description|Leírás'
                        )
                      "
                      centered
                      v-slot="props"
                    >
                      {{ props.row.description }}
                    </b-table-column>

                    <b-table-column
                      field="date"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pastRequestsOfUser.table.requestDate|Dátum'
                        )
                      "
                      centered
                      width="450"
                      v-slot="props"
                    >
                      <div
                        class="is-flex is-flex-wrap-wrap has-gap-1 is-justify-content-center is-align-items-center"
                      >
                        <b-tooltip
                          :label="
                            $tf(
                              'handleAbsenceRequestForm.pastRequestsOfUser.table.carryOver.tolltip|Előző évről áthozott'
                            )
                          "
                          type="is-light"
                          class="mr-2"
                          v-if="props.row.carryOver"
                        >
                          <div class="is-flex is-align-items-center">
                            <b-icon size="is-small" icon="clock-rotate-left" />
                          </div>
                        </b-tooltip>
                        <b-tag
                          v-for="(actDate, index) in props.row
                            .absenceRequestDates"
                          :key="index"
                          :type="
                            getDateType(
                              props.row.status ? props.row.status.enum : null
                            )
                          "
                        >
                          {{ formatDate(actDate) }}
                        </b-tag>
                      </div>
                    </b-table-column>

                    <b-table-column
                      field="actions"
                      :label="
                        $tf(
                          'handleAbsenceRequestForm.pastRequestsOfUser.table.actions|Műveletek'
                        )
                      "
                      centered
                      v-slot="props"
                    >
                      <b-tooltip
                        :label="
                          $tf(
                            'handleAbsenceRequestForm.pastRequestsOfUser.table.actions.open|Kérelem megnyitása'
                          )
                        "
                        type="is-light"
                      >
                        <clickable-icon
                          @click="openAbsenceRequestForm(props.row.id)"
                          icon="edit"
                          type="is-info"
                        />
                      </b-tooltip>
                    </b-table-column>

                    <template #empty
                      ><div class="has-text-centered">
                        {{
                          $tf(
                            "handleAbsenceRequestForm.pastRequestsOfUser.table.empty|Nincs korábbi kérelme a felhasználónak."
                          )
                        }}
                      </div></template
                    >
                  </b-table>
                </b-collapse>
              </section>
            </div>
          </article>
        </div>
      </div>
    </div>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommentedApproval from "@/components/absence/admin/CommentedApproval";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { createUrlSearchParams, formatDate } from "@/utils/util";
import { PRIVILEGES } from "@/utils/const";

export default {
  name: "HandleAbsenceRequestForm",
  components: { CommentedApproval, ClickableIcon },
  async mounted() {
    this.absenceRequestId = parseInt(this.$route.params.id);
    await this.getAbsenceRequest();
    await this.getWaiting();
    await this.getPast();
    // let paramsPastRequests = new URLSearchParams();
    // paramsPastRequests.append("user", this.absenceRequest.user);
    // paramsPastRequests.append("status", "APPROVED");
    // paramsPastRequests.append("status", "ACTIVE");
    // paramsPastRequests.append("status", "TAKEN");
    // paramsPastRequests.append("status", "DENIED");
    // paramsPastRequests.append("status", "REVOKED");
    //
    // let paramsWaitingRequests = new URLSearchParams();
    // paramsWaitingRequests.append("user", this.absenceRequest.user);
    // paramsWaitingRequests.append("status", "REQUEST");
    //
    // await this.$store.dispatch("absence_request/getPmAbsenceRequests", {
    //   params: paramsWaitingRequests
    // });

    await this.getAbsenceQuota();
  },
  data() {
    return {
      formatDate,
      commentedApprovalKey: 0,
      approvalComment: null,
      absenceRequestId: null,
      isWaitingRequestsOpen: false,
      isPastRequestOpen: false,
      isLoading: false,
      isSubmitting: false,
      paginationPast: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sortPast: {
        field: "created",
        order: "desc",
      },
      paginationWaiting: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sortWaiting: {
        field: "created",
        order: "desc",
      },
    };
  },
  computed: {
    canApproveRequests() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ASSIGNED,
        PRIVILEGES.ABSENCE.REQUEST.APPROVE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN
      );
    },
    canRevokeRequests: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ABSENCE.REQUEST.REVOKE,
        PRIVILEGES.ABSENCE.REQUEST.REVOKE_ANY,
        PRIVILEGES.ABSENCE.REQUEST.ADMIN
      );
    },
    ...mapGetters({
      absenceRequestsWaiting: "absence_request/pmAbsenceRequestsWaiting",
      absenceRequestsPast: "absence_request/pmAbsenceRequestsPast",
      absenceRequest: "absence_request/absenceRequest",
      quota: "absence_request/userQuota",
    }),
  },
  methods: {
    async getAbsenceRequest(force) {
      await this.$store.dispatch("absence_request/getAbsenceRequest", {
        absenceId: this.absenceRequestId,
        force,
      });

      await this.getPast(force);
      await this.getWaiting(force);
    },

    async getPast(force) {
      await this.$store.dispatch("absence_request/getPmAbsenceRequestsPast", {
        user: this.absenceRequest.userId,
        pageable: {
          params: createUrlSearchParams(this.paginationPast, this.sortPast),
        },
        force,
      });
      this.paginationPast = this.absenceRequestsPast.pagination;
    },
    async getWaiting(force) {
      await this.$store.dispatch(
        "absence_request/getPmAbsenceRequestsWaiting",
        {
          user: this.absenceRequest.userId,
          pageable: {
            params: createUrlSearchParams(
              this.paginationWaiting,
              this.sortWaiting
            ),
          },
          force,
        }
      );
      this.paginationWaiting = this.absenceRequestsWaiting.pagination;
    },

    async getAbsenceQuota(force) {
      let params = {
        year: new Date().getFullYear(),
        username: this.absenceRequest.userId,
        force,
      };
      await this.$store.dispatch("absence_request/getUserQuota", params);
    },
    forceRerenderCommentedApprovalComponent() {
      this.commentedApprovalKey = this.commentedApprovalKey + 1;
    },
    async approve(decision) {
      this.isSubmitting = true;
      let payload = {
        comment: this.approvalComment,
        approval: decision,
        user: this.absenceRequest.userId,
        absenceRequestId: this.absenceRequest.id,
      };
      await this.$store
        .dispatch("absence_request/approve", payload)
        .then(async () => {
          await this.getAbsenceRequest(true);
          this.forceRerenderCommentedApprovalComponent();
          this.approvalComment = null;
          await this.getAbsenceQuota(true);
          //this.$router.push("/approval/absence");
        });
      this.isSubmitting = false;
    },
    async openAbsenceRequestForm(absenceId) {
      await this.$router.push("/approval/absence/handle-requests/" + absenceId);
      this.absenceRequestId = parseInt(this.$route.params.id);
      this.isLoading = true;
      await this.getAbsenceRequest(true);
      await this.forceRerenderCommentedApprovalComponent();
      this.isLoading = false;
    },

    onPageChangePast: function (page) {
      this.paginationPast.page = page - 1;
      this.getPast();
    },
    onSortPast: function (field, order) {
      this.sortPast.field = field;
      this.sortPast.order = order;
      this.getPast();
    },
    onPageChangeWaiting: function (page) {
      this.paginationWaiting.page = page - 1;
      this.getWaiting();
    },
    onSortWaiting: function (field, order) {
      this.sortWaiting.field = field;
      this.sortWaiting.order = order;
      this.getWaiting();
    },
    getDateType(status) {
      switch (status) {
        case "REQUEST":
          return "is-info";
        case "LINE_APPROVED":
          return "is-info";
        case "APPROVED":
          return "is-info";
        case "ACTIVE":
          return "is-success";
        case "TAKEN":
          return "is-light";
        case "DENIED":
          return "is-danger";
        case "REVOKED":
          return "is-light";
      }
    },
  },
};
</script>

<style scoped></style>
