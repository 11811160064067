<template>
  <b-table v-bind="$attrs">
    <b-table-column
      field="value"
      v-slot="props"
      :label="valueColumnLabel"
      :subheading="$tf('forecastDashboard.planTable.subheading|Total')"
    >
      {{ props.row.value }}</b-table-column
    >
    <b-table-column
      field="income.ytd"
      centered
      :label="$tf('forecastDashboard.planTable.ytd|ytd')"
    >
      <template #subheading>
        <p :class="{ ['is-danger']: totalData.ytd < 0 }">
          {{
            moneyify(
              totalData.ytd,
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              false,
              true,
              2
            )
          }}
        </p>
      </template>
      <template #default="props">
        <p :class="{ ['is-danger']: props.row.income.ytd < 0 }">
          {{
            moneyify(
              props.row.income.ytd,
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              false,
              true,
              2
            )
          }}
        </p>
      </template>
    </b-table-column>
    <b-table-column
      v-for="quarter in Object.keys(FORECAST_YEAR_QUARTER)"
      :key="'PLAN' + quarter"
      :label="quarter"
      centered
      :field="'income.' + quarter"
    >
      <template #subheading>
        <p :class="{ ['is-danger']: totalData[quarter] < 0 }">
          {{
            moneyify(
              totalData[quarter],
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              false,
              true,
              2
            )
          }}
        </p>
      </template>
      <template #default="props">
        <p :class="{ ['is-danger']: props.row.income[quarter] < 0 }">
          {{
            moneyify(
              props.row.income[quarter],
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              false,
              true,
              2
            )
          }}
        </p>
      </template>
    </b-table-column>
    <b-table-column
      field="income.total"
      centered
      :label="$tf('forecastDashboard.planTable.total|Total')"
    >
      <template #subheading>
        <strong :class="{ ['is-danger']: totalData.total < 0 }">
          {{
            moneyify(
              totalData.total,
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              false,
              true,
              2
            )
          }}
        </strong>
      </template>
      <template #default="props">
        <strong :class="{ ['is-danger']: props.row.income.total < 0 }">
          {{
            moneyify(
              props.row.income.total,
              CURRENCY_TIERS.LEVEL_TWO,
              true,
              false,
              true,
              2
            )
          }}
        </strong>
      </template>
    </b-table-column>
  </b-table>
</template>

<script>
import { moneyify } from "@/utils/util";
import { CURRENCY_TIERS, FORECAST_YEAR_QUARTER } from "@/utils/const";

export default {
  name: "ForecastDashboardTable",
  inheritAttrs: false,
  props: {
    totalData: {
      type: Object,
      required: true,
    },
    valueColumnLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      CURRENCY_TIERS,
      FORECAST_YEAR_QUARTER,
    };
  },
  methods: {
    moneyify,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.tier-create-button {
  margin-left: auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.link.is-danger {
  color: red;
}

.red {
  color: red;
}

.cost-label {
  color: $custom-grayer-blue;
}

.cost-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.cost-since {
  color: $custom-grayer-blue;
  font-size: 14px;
}

.tier-toggle {
  :deep(.has-text-link) {
    color: $custom-dark-blue !important;
  }
}

.users-without-tiers-container {
  h1.title {
    color: $red;
    font-size: 1.1rem;
  }
}

.fixed-price-margin {
  margin-bottom: 2rem;
}
</style>
