<template>
  <div class="mx-2 forecast-chart newForecastCard">
    <div
      class="is-flex is-justify-content-space-between has-gap-2 is-align-items-center"
    >
      <h3 class="title">
        {{ $tf("projectPage.forecastCombinedChart.readiness|Készültség:") }}
        {{ percentify(readiness / 100) }} -
        {{ $tf(progressToken[progressType]) }}
      </h3>
      <div
        class="is-flex is-align-items-center is-justify-content-center"
        style="margin-bottom: 8px"
        @click="showReadinessModal = true"
      >
        <b-tooltip
          class="m-0"
          :label="
            $tf(
              'projectPage.forecastCombinedChart.setReadiness|Készültség beállítása'
            )
          "
          :auto-close="true"
        >
          <b-tag class="gear-tag" type="is-info">
            <b-icon pack="fas" icon="gear" size="is-small" />
          </b-tag>
        </b-tooltip>
      </div>
    </div>
    <div class="triangle-charts-container">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <forecast-combined-chart-radial
          :readiness="readiness"
          :value="spentBudget"
          :title="
            $tf('projectPage.forecastCombinedChart.budgetChart.title|Büdzsé')
          "
        />
      </div>
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <forecast-combined-chart-radial
          :readiness="readiness"
          :value="spentTime"
          :title="$tf('projectPage.forecastCombinedChart.mwdChart.title|MWD')"
        />
      </div>
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <forecast-combined-chart-radial
          :readiness="readiness"
          :value="spentDays"
          :title="
            $tf('projectPage.forecastCombinedChart.spentChart.title|Átfutás')
          "
        />
      </div>
    </div>

    <b-modal v-model="showReadinessModal" @close="showReadinessModal = false">
      <div class="card">
        <div class="card-header">
          <h3 class="title m-5">
            {{ $tf("projectPage.readinessModal.title|Készültség beállítása") }}
          </h3>
        </div>
        <div class="card-content m-4">
          <forecast-readiness-chart
            :project-details="projectDetails"
          ></forecast-readiness-chart>
          <div class="my-4">
            <b-field grouped>
              <b-field
                :label="
                  $tf(
                    'projectPage.readinessModal.readinessType|Előrehaladás típusa'
                  )
                "
                label-position="on-border"
              >
                <b-select
                  v-model="progressEdit.progressType"
                  :placeholder="
                    $tf(
                      'projectPage.readinessModal.readinessType.placeholder|Típus'
                    )
                  "
                  icon="percentage"
                  class="has-blue-icon"
                  expanded
                >
                  <option
                    v-for="item in progressTypes"
                    :key="item"
                    :value="item"
                    :selected="item === projectDetails.progressType"
                  >
                    {{ $tf(progressToken[item]) }}
                  </option>
                </b-select>
              </b-field>
              <b-field>
                <b-button type="is-info" @click="saveProgress">
                  {{ $tf("projectPage.readinessModal.save|Mentés") }}
                </b-button>
              </b-field>
            </b-field>
          </div>

          <div
            class="my-4"
            v-if="progressEdit.progressType === progressTypes.MANUAL"
          >
            <b-field grouped>
              <b-field class="m-0">
                <money-input
                  :label="
                    $tf(
                      'projectPage.readinessModal.manualReadiness.percentage|Készültség %'
                    )
                  "
                  label-position="on-border"
                  percentage
                  v-model="progressEdit.progress"
                />
              </b-field>
              <b-field
                :label="
                  $tf(
                    'projectPage.readinessModal.manualReadiness.summary|Összegzés'
                  )
                "
                label-position="on-border"
                expanded
                style="margin-left: 5px"
              >
                <b-input
                  expanded
                  type="text"
                  :placeholder="
                    $tf(
                      'projectPage.readinessModal.manualReadiness.summary.placeholder|Rövid magyarázat'
                    )
                  "
                  clearable
                  v-model="progressEdit.summary"
                />
              </b-field>
            </b-field>
          </div>
          <hr />
          <div class="has-text-dark-blue">
            <div class="has-font-weight-500">
              {{
                $tf(
                  "projectPage.readinessModal.manualReadiness.past|Korábban megadott manuális készültségek"
                )
              }}
            </div>
            <ul v-if="projectData && projectData.progressHistory.length">
              <li
                v-for="(progressHistoryRecord, index) in sortedProgressHistory"
                :key="index"
              >
                <span>
                  {{ formatDateTime(progressHistoryRecord.timestamp) }} -
                </span>
                <span>
                  {{ getEmployeeNameById(progressHistoryRecord.userId) }} :
                </span>
                <span class="has-font-weight-500">
                  {{ percentify(progressHistoryRecord.progress) }}
                </span>
                <span class="is-italic">
                  {{ progressHistoryRecord.summary }}
                </span>
              </li>
            </ul>
            <div v-else class="is-italic">
              {{
                $tf(
                  "projectPage.readinessModal.manualReadiness.empty|Nem volt még beállítva manuális készültség"
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import StatusMixin from "../../../../../mixins/StatusMixin";
import { deepCopy, formatDateTime, percentify } from "@/utils/util";
import {
  PROJECT_PROGRESS_TYPE,
  PROJECT_PROGRESS_TYPE_TOKEN,
} from "@/utils/const";
import ForecastReadinessChart from "@/components/enterprise/project/module/forecast/ForecastReadinessChart.vue";
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import { mapGetters } from "vuex";
import ForecastCombinedChartRadial from "@/components/enterprise/project/module/forecast/ForecastCombinedChartRadial.vue";

export default {
  name: "ForecastCombinedChart",
  components: {
    ForecastCombinedChartRadial,
    MoneyInput,
    ForecastReadinessChart,
  },
  mixins: [StatusMixin],
  props: {
    readiness: {
      type: Number,
      required: true,
    },
    spentTime: {
      type: Number,
      required: true,
    },
    spentBudget: {
      type: Number,
      required: true,
    },
    spentDays: {
      type: Number,
      required: true,
    },
    progressType: {
      type: String,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // const ref = this.$refs.forecastReadinessRadial;
  },
  data() {
    return {
      progressToken: PROJECT_PROGRESS_TYPE_TOKEN,
      progressTypes: PROJECT_PROGRESS_TYPE,
      showReadinessModal: false,
      progressEdit: {
        progressType: this.projectDetails.progressType,
        progress: this.projectDetails.readiness * 100,
        summary: "",
      },
    };
  },
  computed: {
    tooltipTitles() {
      return [
        this.$tf(
          "projectPage.forecastCombinedChart.readinessChart.tooltip|A projekt jelenlegi készültsége, százalékban kimutatva."
        ),
        this.$tf(
          "projectPage.forecastCombinedChart.budgetChart.tooltip|A projekt jelenlegi költése, százalékban kimutatva."
        ),
        this.$tf(
          "projectPage.forecastCombinedChart.mwdChart.tooltip|A projektre fordított jelenlegi munkaerőnap költés, százalékban kimutatva."
        ),
        this.$tf(
          "projectPage.forecastCombinedChart.spentChart.tooltip|A projekt jelenlegi átfutása, százalékban kimutatva."
        ),
      ];
    },

    max() {
      return Math.max(
        this.readiness,
        this.spentTime,
        this.spentBudget,
        this.spentDays,
        100
      );
    },

    otherProgressTypes() {
      let types = [];
      for (let key in this.projectDetails.projectReadinessMap) {
        if (
          this.projectDetails.projectReadinessMap[key] &&
          key !== this.projectDetails.progressType
        ) {
          types.push({
            type: key,
            readiness: this.projectDetails.projectReadinessMap[key],
          });
        }
      }
      return types;
    },
    sortedProgressHistory() {
      let sorted = deepCopy(this.projectDetails.progressHistory);
      if (!sorted) return [];
      return sorted.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0
      );
    },
    ...mapGetters({
      allEmployees: "employee/employees",
    }),

    // series() {
    //   let readiness = Math.round(this.readiness);
    //   let spentBudget = Math.round(this.spentBudget);
    //   let spentTime = Math.round(this.spentTime);
    //   let spentDays = Math.round(this.spentDays);
    //   let blueSeries = [readiness];
    //   let greenSeries = [0];
    //   let redSeries = [0];
    //   let transparentSeries = [100 - readiness];
    //   if (readiness === spentBudget) {
    //     blueSeries.push(spentBudget);
    //     greenSeries.push(0);
    //     redSeries.push(0);
    //     transparentSeries.push(100 - spentBudget);
    //   }
    //   if (readiness > spentBudget) {
    //     blueSeries.push(spentBudget);
    //     greenSeries.push(readiness - spentBudget);
    //     redSeries.push(0);
    //     transparentSeries.push(100 - spentBudget - (readiness - spentBudget));
    //   }
    //   if (readiness < spentBudget) {
    //     blueSeries.push(readiness);
    //     greenSeries.push(0);
    //     redSeries.push(spentBudget - readiness);
    //     transparentSeries.push(100 - spentBudget);
    //   }
    //   if (readiness === spentTime) {
    //     blueSeries.push(spentTime);
    //     greenSeries.push(0);
    //     redSeries.push(0);
    //     transparentSeries.push(100 - spentTime);
    //   }
    //   if (readiness > spentTime) {
    //     blueSeries.push(spentTime);
    //     greenSeries.push(readiness - spentTime);
    //     redSeries.push(0);
    //     transparentSeries.push(100 - spentTime - (readiness - spentTime));
    //   }
    //   if (readiness < spentTime) {
    //     blueSeries.push(readiness);
    //     greenSeries.push(0);
    //     redSeries.push(spentTime - readiness);
    //     transparentSeries.push(100 - spentTime);
    //   }
    //   if (readiness === spentDays) {
    //     blueSeries.push(spentDays);
    //     greenSeries.push(0);
    //     redSeries.push(0);
    //     transparentSeries.push(100 - spentDays);
    //   }
    //   if (readiness > spentDays) {
    //     blueSeries.push(spentDays);
    //     greenSeries.push(readiness - spentDays);
    //     redSeries.push(0);
    //     transparentSeries.push(100 - spentDays - (readiness - spentDays));
    //   }
    //   if (readiness < spentDays) {
    //     blueSeries.push(readiness);
    //     greenSeries.push(0);
    //     redSeries.push(spentDays - readiness);
    //     transparentSeries.push(100 - spentDays);
    //   }
    //   return [
    //     {
    //       name: "Tényleges",
    //       data: blueSeries,
    //     },
    //     {
    //       name: "Alulköltés",
    //       data: greenSeries,
    //     },
    //     {
    //       name: "Túlköltés",
    //       data: redSeries,
    //     },
    //     // {
    //     //   name: "Hátralevő",
    //     //   data: transparentSeries,
    //     // },
    //   ];
    // },
    // chartOptions() {
    //   const self = this;
    //   return {
    //     chart: {
    //       type: "bar",
    //       stacked: true,
    //       stactType: "100%",
    //       toolbar: {
    //         show: false,
    //       },
    //     },
    //     colors: [
    //       "#0084ff",
    //       "#1aea47ff",
    //       "#ea1818",
    //       /*", #ccc"*/
    //     ],
    //     plotOptions: {
    //       bar: {
    //         horizontal: true,
    //         dataLabels: {
    //           total: {
    //             fontWeight: "700",
    //             fontFamily: "Roboto",
    //             fontColor: "darkGrey",
    //             offsetY: 20,
    //             fontSize: "14px",
    //             color: "#888",
    //           },
    //           value: {
    //             fontSize: "40px",
    //             fontWeight: "700",
    //             fontFamily: "Roboto",
    //             offsetY: -20,
    //             offsetX: 8,
    //           },
    //         },
    //       },
    //     },
    //     stroke: {
    //       width: 1,
    //       colors: ["#fff"],
    //     },
    //     legend: {
    //       show: false,
    //     },
    //     dataLabels: {
    //       formatter: function (val) {
    //         return val + "%";
    //       },
    //     },
    //     xaxis: {
    //       categories: [
    //         "Készültség",
    //         "Büdzsé Költés",
    //         "MWD Költés",
    //         "Átfutás Költés",
    //       ],
    //       max: this.max,
    //       labels: {
    //         formatter: function (val) {
    //           return Math.round(val) + "%";
    //         },
    //       },
    //     },
    //     tooltip: {
    //       x: {
    //         formatter: function (_, { dataPointIndex }) {
    //           return self.tooltipTitles[dataPointIndex];
    //         },
    //       },
    //       fixed: {
    //         enabled: true,
    //       },
    //     },
    //   };
    // },
  },
  methods: {
    formatDateTime,
    percentify,
    getType(val) {
      if (val < this.readiness) {
        return "is-success";
      }
      if (val > this.readiness) {
        return "is-danger";
      }
      return "is-primary";
    },
    getEmployeeNameById(employeeId) {
      return this.allEmployees.find((e) => e.id === employeeId)?.name;
    },
    async saveProgress() {
      let request = deepCopy(this.progressEdit);
      request.progress = request.progress / 100;
      await this.$store.dispatch("enterprise_projects/progressChange", {
        identifier: this.projectDetails.identifier,
        body: request,
      });
      await this.$store.dispatch("enterprise_core/fetchProjectDetails", {
        projectIdentifier: this.projectDetails.identifier,
        force: true,
      });
      this.showReadinessModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
td.is-danger {
  background-color: $red;
}
td.is-warning {
  background-color: $yellow;
}
td.is-success {
  background-color: $green;
}
.is-vertical-align-middle {
  vertical-align: middle !important;
}
.forecast-chart {
  min-width: 10rem;
  height: 100%;
}

.triangle-charts-container {
  position: relative;
  height: 100%;

  > div {
    position: absolute;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      top: 0;
      right: 0;
    }
    &:nth-child(3) {
      top: 30%;
      left: 0;
      right: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 250px;
    }
  }
}
</style>
