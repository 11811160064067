import { FORECAST_YEAR_QUARTER } from "@/utils/const";

export default {
  methods: {
    style(row) {
      if (this.isProject(row)) return this.$style.project;
      if (this.isChildPlan(row)) return this.$style.childPlan;
      return this.$style.plan;
    },
    isProject(row) {
      return !!row.project?.id;
    },
    isChildPlan(row) {
      return !!row.parent?.id;
    },
    isCurrentYear(row) {
      return row.year === this.year;
    },
    isPreviousYear(row) {
      return row.year === this.year - 1;
    },
    hasIncome(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return row.incomeQ1 !== null;
        case FORECAST_YEAR_QUARTER.Q2:
          return row.incomeQ2 !== null;
        case FORECAST_YEAR_QUARTER.Q3:
          return row.incomeQ3 !== null;
        case FORECAST_YEAR_QUARTER.Q4:
          return row.incomeQ4 !== null;
      }
    },
    hasIncomeNextYear(row, quarter) {
      switch (quarter) {
        case FORECAST_YEAR_QUARTER.Q1:
          return row.incomeNextYearQ1 !== null;
        case FORECAST_YEAR_QUARTER.Q2:
          return row.incomeNextYearQ2 !== null;
        case FORECAST_YEAR_QUARTER.Q3:
          return row.incomeNextYearQ3 !== null;
        case FORECAST_YEAR_QUARTER.Q4:
          return row.incomeNextYearQ4 !== null;
      }
    },
    getIncome(row, quarter) {
      if (this.isPreviousYear(row)) {
        switch (quarter) {
          case FORECAST_YEAR_QUARTER.Q1:
            return row.incomeNextYearQ1;
          case FORECAST_YEAR_QUARTER.Q2:
            return row.incomeNextYearQ2;
          case FORECAST_YEAR_QUARTER.Q3:
            return row.incomeNextYearQ3;
          case FORECAST_YEAR_QUARTER.Q4:
            return row.incomeNextYearQ4;
        }
      } else if (this.isCurrentYear(row)) {
        switch (quarter) {
          case FORECAST_YEAR_QUARTER.Q1:
            return row.incomeQ1;
          case FORECAST_YEAR_QUARTER.Q2:
            return row.incomeQ2;
          case FORECAST_YEAR_QUARTER.Q3:
            return row.incomeQ3;
          case FORECAST_YEAR_QUARTER.Q4:
            return row.incomeQ4;
        }
      }
    },
    getChance(row, quarter) {
      if (this.isProject(row)) return 100;
      if (this.isPreviousYear(row)) {
        switch (quarter) {
          case FORECAST_YEAR_QUARTER.Q1:
            return row.chanceCategoryNextYearQ1?.chance;
          case FORECAST_YEAR_QUARTER.Q2:
            return row.chanceCategoryNextYearQ2?.chance;
          case FORECAST_YEAR_QUARTER.Q3:
            return row.chanceCategoryNextYearQ3?.chance;
          case FORECAST_YEAR_QUARTER.Q4:
            return row.chanceCategoryNextYearQ4?.chance;
        }
      } else if (this.isCurrentYear(row)) {
        switch (quarter) {
          case FORECAST_YEAR_QUARTER.Q1:
            return row.chanceCategoryQ1?.chance;
          case FORECAST_YEAR_QUARTER.Q2:
            return row.chanceCategoryQ2?.chance;
          case FORECAST_YEAR_QUARTER.Q3:
            return row.chanceCategoryQ3?.chance;
          case FORECAST_YEAR_QUARTER.Q4:
            return row.chanceCategoryQ4?.chance;
        }
      }
    },
  },
};
