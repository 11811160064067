<template>
  <div class="invoices">
    <money-modal
      :label="$tf('projectpage.invoices.modal.editTitle|Számlák')"
      @close="closeEditModal()"
      :project-id="projectData.id"
      :show="editModal"
      :modify="modifyInvoice"
      :edit-data="editData"
      type="invoices"
      :data-type="$tf('projectpage.invoice|Számla')"
      :columns="invoiceColumns"
      :tigs="projectData.tigDatas"
    />
    <money-modal
      :label="$tf('projectpage.invoices.modal.newTitle|Számlák')"
      @close="closeCreateModal()"
      :project-id="projectData.id"
      :show="createModal"
      :create="createInvoice"
      :edit-data="null"
      type="invoices"
      :data-type="$tf('projectpage.invoice|Számla')"
      :columns="invoiceColumns"
      :tigs="projectData.tigDatas"
    />
    <b-modal v-model="sSummary" @close="closeSummaryModal()">
      <div class="card">
        <div class="card-content m-4">
          <p>{{ vSummary }}</p>
        </div>
      </div>
    </b-modal>
    <div class="is-flex is-justify-content-space-between">
      <div>
        <h3 class="title">{{ $tf("projectpage.invoices.title|Számlák") }}</h3>
      </div>
      <div>
        <b-button
          type="is-info"
          size="is-small"
          rounded
          @click="showCreateModal"
        >
          {{ $tf("projectpage.invoices.add|Számla hozzáadása") }}
        </b-button>
      </div>
    </div>
    <b-table
      :data="data || []"
      ref="expenseTable"
      paginated
      :per-page="10"
      detailed
      detail-key="identifier"
      :show-detail-icon="false"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
    >
      <template #empty>
        <div class="has-text-centered">
          {{ $tf("projectpage.invoices.table.empty|Nincsenek számlák") }}
        </div>
      </template>
      <b-table-column
        field="number"
        :label="$tf('projectpage.invoices.table.invoiceNumero|Számlaszám')"
        centered
        v-slot="props"
      >
        {{ props.row.number }}
      </b-table-column>
      <b-table-column
        field="status"
        :label="$tf('projectpage.invoices.table.status|Státusz')"
        centered
        v-slot="props"
      >
        <span
          class="tag"
          v-bind:class="{
            'is-success': props.row.status === 'PAID',
            'is-warning': props.row.status === 'CANCELLED',
            'is-info': props.row.status === 'DUE',
          }"
        >
          {{ resolveStatus(props.row.status) }}
        </span>
      </b-table-column>
      <b-table-column
        field="tigIdentifiers"
        :label="$tf('projectpage.invoices.table.tig|Mérföldkő')"
        centered
        v-slot="props"
      >
        {{ props.row.tigIdentifiers.join(", ") }}
      </b-table-column>
      <b-table-column
        field="summary"
        :label="$tf('projectpage.invoices.table.summary|Megjegyzés')"
        centered
        v-slot="props"
      >
        <b-tooltip
          :label="
            $tf(
              'projectpage.invoices.table.summary.show|Megjegyzés megjelenítése'
            )
          "
          type="is-light"
        >
          <clickable-icon
            @click="showSummaryModal(props.row.summary)"
            icon="eye"
            type="is-info"
          />
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="cost"
        :label="$tf('projectpage.invoices.table.cost|Összeg')"
        centered
        v-slot="props"
      >
        {{ moneyify(props.row.cost) }}
      </b-table-column>
      <b-table-column
        field="date"
        :label="$tf('projectpage.invoices.table.date|Keltezés dátuma')"
        centered
        v-slot="props"
      >
        {{ props.row.date }}
      </b-table-column>
      <b-table-column
        field="actions"
        :label="$tf('projectpage.invoices.table.actions|Műveletek')"
        centered
        v-slot="props"
      >
        <b-tooltip
          v-if="canEvaluateInvoice && props.row.status === 'DUE'"
          :label="
            $tf('projectpage.invoices.actions.markPaid|Fizetettnek jelölés')
          "
          type="is-light"
        >
          <clickable-icon
            icon="circle-check"
            type="is-success"
            @click="payInvoice(props.row.id)"
          />
        </b-tooltip>
        <b-tooltip
          v-if="canEvaluateInvoice && props.row.status === 'DUE'"
          :label="$tf('projectpage.invoices.table.actions.cancel|Visszavonás')"
          type="is-light"
        >
          <clickable-icon
            icon="circle-xmark"
            type="is-danger"
            @click="cancelInvoice(props.row.id)"
          />
        </b-tooltip>
        <b-tooltip
          v-if="props.row.status === 'DUE'"
          :label="$tf('projectpage.invoices.table.actions.edit|Szerkesztés')"
          type="is-light"
        >
          <clickable-icon
            icon="edit"
            type="is-info"
            @click="showEditModal(props.row)"
          />
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import MoneyModal from "@/components/enterprise/ProjectDetails/TabItems/MoneyModal";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { deepCopy, formatDate, moneyify, numberify } from "@/utils/util";
import { PRIVILEGES } from "@/utils/const";

export default {
  name: "InvoiceTabItem",
  components: {
    MoneyModal,
    ClickableIcon,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newData: {},
      modifiedData: {},
      name: "",
      editModal: false,
      createModal: false,
      sSummary: false,
      vSummary: "",
      editData: {},
      moneyify,
      formatDate,
      invoiceColumns: [
        {
          field: "number",
          label: this.$tf("projectpage.invoice.invoiceNumero|Számlaszám"),
        },
        {
          field: "tigIdentifiers",
          label: this.$tf("projectpage.invoice.tigs|Mérföldkövek"),
        },
        {
          field: "summary",
          label: this.$tf("projectpage.invoice.summary|Megjegyzés"),
        },
        {
          field: "cost",
          label: this.$tf("projectpage.invoice.cost|Összeg"),
        },
        {
          field: "date",
          label: this.$tf("projectpage.invoice.date|Kelt"),
        },
      ],
    };
  },
  computed: {
    canEvaluateInvoice() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.EVALUATE_INVOICE,
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.ADMIN
      );
    },
  },
  methods: {
    async fetchData(force) {
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: this.projectData.identifier,
        force,
      });
      this.$emit("fetch", force);
    },
    toggleDetails(props) {
      this.modifiedData = deepCopy(props.row);
      if (props.row.date) {
        this.modifiedData.date = new Date(props.row.date);
      }
      if (props.row.day) {
        this.modifiedData.day = new Date(props.row.day);
      }
      props.toggleDetails(props.row);
    },
    toggleOverrideDetail(props) {
      this.toggleDetails(props);
      this.name = props.row.displayName;
    },
    closeEditModal() {
      this.editData = {};
      this.editModal = false;
    },
    showEditModal(data) {
      this.editData = deepCopy(data);
      this.editModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
    },
    showCreateModal() {
      this.createModal = true;
    },
    closeSummaryModal() {
      this.sSummary = false;
      this.vSummary = "";
    },
    showSummaryModal(summary) {
      this.vSummary = summary;
      this.sSummary = true;
    },
    async payInvoice(id) {
      this.$buefy.dialog.prompt({
        title: this.$tf(
          "projectpage.invoice.markPaidModal.title|Számla fizetettnek jelölése"
        ),
        message: this.$tf(
          "projectpage.invoice.markPaidModal.message|Kifizetés dátuma"
        ),
        confirmText: this.$tf(
          "projectpage.invoice.markPaidModal.confirm|Jelölés"
        ),
        cancelText: this.$tf("projectpage.invoice.markPaidModal.cancel|Mégsem"),
        inputAttrs: {
          type: "date",
          value: formatDate(new Date()),
        },
        trapFocus: true,
        onConfirm: async (value) => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: "PAID",
              date: formatDate(value),
            },
          });
          await this.fetchData(true);
        },
      });
    },
    async cancelInvoice(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectpage.invoice.cancelModal.title|Számla visszavonása"
        ),
        message: this.$tf(
          "projectpage.invoice.cancelModal.message|Biztosan vissza szeretnéd vonni a számlát?"
        ),
        confirmText: this.$tf(
          "projectpage.invoice.cancelModal.confirm|Visszavonás"
        ),
        cancelText: this.$tf("projectpage.invoice.cancelModal.cancel|Mégse"),
        trapFocus: true,
        type: "is-danger",
        onConfirm: async (value) => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: "CANCELLED",
              date: formatDate(new Date()),
            },
          });
          await this.fetchData(true);
        },
      });
    },
    async createInvoice(invoice) {
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        projectId: this.projectData.id,
        number: invoice.number,
        status: "DUE",
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    async modifyInvoice(invoice) {
      invoice.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        id: invoice.id,
        projectId: this.projectData.id,
        number: invoice.number,
        status: invoice.status,
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    resolveStatus(enu) {
      switch (enu) {
        case "DUE":
          return this.$tf("projectpage.invoice.status.due|Fizetendő");
        case "PAID":
          return this.$tf("projectpage.invoice.status.paid|Fizetett");
        case "CANCELLED":
          return this.$tf("projectpage.invoice.status.cancelled|Visszavont");
        default:
          return this.$tf("projectpage.invoice.status.unknown|Ismeretlen");
      }
    },
  },
};
</script>

<style scoped></style>
