const initState = () => {
  return {
    isPageLoading: false,
  };
};

const mutations = {
  doStartLoadPage: (state) => {
    state.isPageLoading = true;
  },
  doFinishLoadPage: (state) => {
    state.isPageLoading = false;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
};
