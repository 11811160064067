<template>
  <div>
    <div v-for="n in dayArray.length / 7" v-bind:key="n" class="columns">
      <div
        v-for="elem in dayArray.slice((n - 1) * 7, n * 7)"
        v-bind:key="elem.date"
        class="column"
      >
        <day-time :data="elem" @open="onOpen" />
      </div>
    </div>
  </div>
</template>

<script>
import DayTime from "./tab/tile/DayTime";
import { formatDate, getTotalSecondsCss } from "@/utils/util";
import { SHORT_MONTH_NAMES } from "@/utils/const";

Date.prototype.substractDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

export default {
  name: "PortalTimesheet",
  components: { DayTime },
  props: [
    "year",
    "month",
    "hours",
    "specialDays",
    "absenceRequests",
    "expected",
    "workingLocations",
  ],
  data: function () {
    return {
      dayArray: [],
      holidaysDates: [],
      absenceRequestsDates: [],
      weekendDates: [],
    };
  },
  computed: {},
  methods: {
    calcDayArray: function () {
      let dayArray = [];
      let rollingDate = new Date(this.year, this.month, 1, 12);
      let sumOfWorkedHoursInWeek = 0;
      let sumOfExpectedWorkHoursInWeek = 0;
      let isWeekWorkCompleted = false;

      let block = 1;
      while (rollingDate.getDay() !== block) {
        rollingDate = rollingDate.substractDays(1);
      }

      while (
        rollingDate.getMonth() <= this.month ||
        rollingDate.getDay() !== block ||
        (rollingDate.getMonth() === 11 && this.month === 0) || // this happens in january if the week started in the prev year
        (rollingDate.getMonth() === 0 && this.month === 11)
      ) {
        if (
          rollingDate.getFullYear() > this.year &&
          rollingDate.getDay() === 1
        ) {
          break;
        }

        let dateStr = formatDate(rollingDate);
        let css = "is-disabled";

        let hour = this.hours[dateStr];
        if (rollingDate.getDay() === 0) {
          isWeekWorkCompleted =
            sumOfWorkedHoursInWeek >= sumOfExpectedWorkHoursInWeek;

          css = isWeekWorkCompleted ? "is-week-complete" : "is-week-incomplete";

          sumOfWorkedHoursInWeek = 0;
          sumOfExpectedWorkHoursInWeek = 0;
        } else {
          if (hour) {
            sumOfWorkedHoursInWeek =
              sumOfWorkedHoursInWeek + hour.dailyTotalSeconds / 3600;
          }
          if (this.expectedHoursOfDay(dateStr)) {
            sumOfExpectedWorkHoursInWeek =
              sumOfExpectedWorkHoursInWeek + this.expectedHoursOfDay(dateStr);
          }
        }

        if (!hour) {
          if (
            this.expectedHoursOfDay(dateStr) > 0 &&
            dateStr < formatDate(new Date())
          ) {
            css = "is-danger";
          }
        } else {
          css = getTotalSecondsCss(
            hour.dailyTotalSeconds,
            this.expectedHoursOfDay(dateStr)
          );
        }
        dayArray.push({
          date: dateStr,
          isCurrentMonth: this.month === rollingDate.getMonth(),
          monthDay: `${this.$tf(
            SHORT_MONTH_NAMES[rollingDate.getMonth()]
          )}. ${rollingDate.getDate()}`,
          isSunday: rollingDate.getDay() === 0,
          //isWeekend: rollingDate.getDay() === 0 || rollingDate.getDay() === 6,
          isWeekend: this.isWeekend(dateStr),
          isHoliday: this.isHoliday(dateStr),
          isAbsence:
            this.isAbsenceRequest(dateStr) ||
            this.expectedHoursOfDay(dateStr) === 0,
          isToday: formatDate(new Date()) === dateStr,
          isFuture: dateStr > formatDate(new Date()),
          isWeekWorkCompleted: isWeekWorkCompleted,
          logged: hour ? hour.dailyTotalSeconds : 0,
          unrecognized: hour ? hour.untracked : null,
          overtime: hour ? hour.dailyTotalOvertimeSeconds : 0,
          hasUntracked: hour ? hour.untracked > 0 : false,
          hasConflicted: hour ? hour.conflicted > 0 : false,
          hasCashOvertime: hour ? hour.hasCashOvertime : false,
          hasShiftOvertime: hour ? hour.hasShiftOvertime : false,
          expectedHoursOfDay: this.expectedHoursOfDay(dateStr),
          css: css,
        });

        rollingDate.setDate(rollingDate.getDate() + 1);
      }

      this.dayArray = dayArray;
    },
    calcHolidaysDates: function () {
      if (this.specialDays) {
        this.holidaysDates = this.specialDays
          .filter((sd) => sd.type === "HOLIDAY")
          .map((sd) => sd.dayDate);
      }
    },
    calcWeekendDates: function () {
      if (this.specialDays) {
        this.weekendDates = this.specialDays
          .filter((sd) => sd.type === "WEEKEND")
          .map((sd) => sd.dayDate);
      }
    },
    isHoliday(day) {
      return this.holidaysDates.includes(day);
    },
    isWeekend(day) {
      return this.weekendDates.includes(day);
    },
    calcAbsenceRequestsDates: function () {
      if (this.absenceRequests.items) {
        this.absenceRequestsDates = [];
        this.absenceRequests.items
          .filter((ar) =>
            [
              "ACTIVE",
              "APPROVED",
              "LINE_APPROVED",
              "REQUEST",
              "TAKEN",
            ].includes(ar.status.enum)
          )
          .forEach(
            (ar) =>
              (this.absenceRequestsDates = this.absenceRequestsDates.concat(
                ar.absenceRequestDates
              ))
          );
      }
    },
    isAbsenceRequest(day) {
      return this.absenceRequestsDates.includes(day);
    },
    expectedHoursOfDay(day) {
      return this.expected.filter((e) => e.day === day).map((e) => e.hours)[0];
    },
    onOpen(date) {
      this.$emit("open", date);
    },
  },
  mounted() {
    this.calcHolidaysDates();
    this.calcAbsenceRequestsDates();
    this.calcWeekendDates();
    this.calcDayArray();
  },
  watch: {
    year() {
      this.calcHolidaysDates();
      this.calcAbsenceRequestsDates();
      this.calcWeekendDates();
      this.calcDayArray();
    },
    month() {
      this.calcDayArray();
    },
    hours() {
      this.calcHolidaysDates();
      this.calcAbsenceRequestsDates();
      this.calcWeekendDates();
      this.calcDayArray();
    },
    specialDays() {
      this.calcHolidaysDates();
      this.calcWeekendDates();
    },
    absenceRequests() {
      this.calcAbsenceRequestsDates();
    },
  },
};
</script>

<style scoped>
.column {
  min-height: 9rem;
  min-width: 9rem;
}
</style>
