<template>
  <div class="onboarding-step-card-content">
    <h2 class="has-text-centered">
      <template v-if="ISSUE_TRACKING_SYSTEM.JIRA.enum === trackingSystem">
        {{
          $tf(
            "onboarding.jiraSyncStart.jiraKeyNeeded|Először is, szükségünk van egy Jira API kulcsra, mely adminisztrátori hozzáféréssel rendelkezik"
          )
        }}
      </template>
      <template
        v-else-if="ISSUE_TRACKING_SYSTEM.YOUTRACK.enum === trackingSystem"
      >
        {{
          $tf(
            "onboarding.jiraSyncStart.youTrackKeyNeeded|Először is, szükségünk van egy YouTrack API kulcsra, mely YouTrack és YouTrack Adminsitration hozzáféréssel rendelkezik"
          )
        }}
      </template>
    </h2>
    <p class="has-text-centered">
      {{
        $tf(
          "onboarding.ExternalIssueTrackerSyncStart.whyExternalIssueTrackerKeyNeeded|Ennek segítségével tudjuk elérni a rendszer működéséhez szükséges adatokat, mint például alkalmazottak, projektek, időlogolások."
        )
      }}
    </p>
    <div class="is-flex is-flex-direction-column has-gap-4 mb-4">
      <b-input
        v-model="externalIssueTrackingToken"
        class="onboarding-input"
        style="width: 425px"
        :placeholder="
          $tf(
            'onboarding.externalIssueTrackerSyncStart.externalIssueTrackingToken|'
          )
        "
        @keyup.enter="saveAndNext"
      >
      </b-input>
      <b-button class="onboarding-button" type="is-info" @click="saveAndNext">
        {{ $tf("onboarding.externalIssueTrackerSyncStart.continue|Tovább") }}
      </b-button>
    </div>

    <b-collapse :open="false" class="details-dropdown">
      <template #trigger="props">
        <div
          class="is-flex has-gap-2 is-justify-content-space-between is-align-items-center"
          style="width: 100%"
        >
          <span>
            <img src="@/assets/images/onboarding/polygon.svg" alt="polygon" />
          </span>
          <span class="has-font-weight-500">{{
            $tf(
              "onboarding.externalIssueTrackerSyncStart.help.title|Hol találom a {system} API kulcsom?",
              { system: trackingSystem }
            )
          }}</span>
          <span style="color: #98a2b3">
            <b-icon :icon="props.open ? 'minus-circle' : 'plus-circle'" />
          </span>
        </div>
      </template>
      <div class="details-container">
        <p>
          <template v-if="ISSUE_TRACKING_SYSTEM.JIRA.enum === trackingSystem">
            {{
              $tf(
                "onboarding.ExternalIssueTrackerSyncStart.help.generateMessage1|A {system} API kulcsot",
                { system: trackingSystem }
              )
            }}
          </template>
          <template
            v-else-if="ISSUE_TRACKING_SYSTEM.YOUTRACK.enum === trackingSystem"
          >
            {{
              $tf(
                "onboarding.ExternalIssueTrackerSyncStart.help.helpMessage1|A generáláshoz segítséget"
              )
            }}
          </template>
          <a
            class="has-font-weight-500"
            :href="
              trackingSystem
                ? ISSUE_TRACKING_SYSTEM[trackingSystem].generateUrl()
                : ''
            "
            target="_blank"
            >{{
              $tf("onboarding.ExternalIssueTrackerSyncStart.help.link|ide")
            }}</a
          >
          <template v-if="ISSUE_TRACKING_SYSTEM.JIRA.enum === trackingSystem">
            {{
              $tf(
                "onboarding.ExternalIssueTrackerSyncStart.generateMessage2|kattintva generálhatod. A kulcsot titkosítva tároljuk."
              )
            }}
          </template>
          <template
            v-else-if="ISSUE_TRACKING_SYSTEM.YOUTRACK.enum === trackingSystem"
          >
            {{
              $tf(
                "onboarding.ExternalIssueTrackerSyncStart.helpMessage2|kattintva találsz. A kulcsot titkosítva tároljuk."
              )
            }}
          </template>
        </p>
        <p class="has-text-centered">
          <img
            src="@/assets/images/onboarding/jira_api_token.png"
            style="max-height: 300px; border-radius: 8px"
            alt="sync_help"
          />
        </p>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { ISSUE_TRACKING_SYSTEM, ONBOARDING_PAGES } from "@/utils/const";
import { mapGetters } from "vuex";

export default {
  name: "OnboardingExternalIssueTrackerSyncStart",
  data() {
    return {
      ONBOARDING_PAGES,
      ISSUE_TRACKING_SYSTEM,
      externalIssueTrackingToken: "",
    };
  },
  computed: {
    ...mapGetters({
      trackingSystem: "onboarding/getTrackingSystem",
    }),
  },
  methods: {
    async saveAndNext() {
      await this.$store.dispatch(
        "onboarding/updateExternalIssueTrackingToken",
        this.externalIssueTrackingToken
      );
      this.$emit(
        "nextPage",
        ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_IN_PROGRESS
      );
    },
  },
};
</script>

<style scoped>
.details-dropdown {
  background: #f4f7ff;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
}

.details-container {
  margin-top: 12px;
  font-size: 12px;
  line-height: 150%;
  max-height: 250px;
  overflow-y: auto;
}
</style>
