import { correctWithTimezoneOffset, newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("AssociationStore");

const urls = {
  association: "/ralloc/association",
  user: "/ralloc/association/user",
};

const initState = () => {
  return {
    associations: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  getAssociations: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.association,
        null,
        true,
        dispatch,
        "association.get.flair|Asszociációk betöltése",
        undefined,
        force
      );
      commit("setAssociations", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getAssociationCsv: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.association + "/export",
        null,
        true,
        dispatch,
        "association.get.flair|Asszociációk letöltése"
      );
      commit("exportCsv", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  uploadFile: async function ({ dispatch }, formData) {
    await this.remoteRequest(
      "post",
      urls.association + "/upload",
      formData,
      false,
      dispatch,
      "association.import.flair|Asszociációk importálása"
    );
  }.bind(storeCore),
  deleteAssociation: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.association + `/${id}`,
        null,
        false,
        dispatch,
        "association.delete.flair|Asszociáció törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "association.delete.progress|Asszociáció törlése folyamatban...",
          fail: "association.delete.fail|Asszociáció törlése sikertelen!",
          success: "association.delete.success|Asszociáció törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateAssociation: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.association + `/${request.id}`,
        request,
        false,
        dispatch,
        "association.modify.flair|Asszociáció módosítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "association.modify.progress|Asszociáció módosítása folyamatban...",
          fail: "association.modify.fail|Asszociáció módosítása sikertelen!",
          success: "association.modify.success|Asszociáció módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  createAssociation: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.association,
        request,
        false,
        dispatch,
        "association.create.flair|Asszociáció létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "association.create.progress|Asszociáció létrehozása folyamatban...",
          fail: "association.create.fail|Asszociáció létrehozása sikertelen!",
          success:
            "association.create.success|Asszociáció létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  getMine: async function ({ commit, dispatch }, { from, to }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.user + `/my-associations?from=${from}&to=${to}`,
        null,
        true,
        dispatch,
        "association.get.flair|Asszociációk betöltése",
        undefined
      );
      commit("setAssociations", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getForUser: async function ({ commit, dispatch }, { employeeId, from, to }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.user + `/${employeeId}?from=${from}&to=${to}`,
        null,
        true,
        dispatch,
        "association.get.flair|Asszociációk betöltése",
        undefined
      );
      commit("setAssociations", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setAssociations: (state, associations) => {
    state.associations = associations;
  },
  exportCsv: (state, data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ralloc.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};

const getters = {
  associations: (state) => {
    return state.associations;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
