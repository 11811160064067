<template>
  <div class="">
    <div class="content mx-0">
      <b-modal
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        v-model="modalOpen"
      >
        <div class="card p-4">
          <task-form
            :project-id="details.id"
            :tigs="details.tigDetails"
            :employees="employees"
            project-type="BASIC"
            @task-submitted="handleTaskSubmit"
          ></task-form>
        </div>
      </b-modal>
      <b-collapse
        class="mb-3"
        animation="slide"
        aria-id="config-messages-collapse"
        :model-value="false"
      >
        <template #trigger="props">
          <div class="card-header" role="button" :aria-expanded="props.open">
            <p class="card-header-title m-0">
              {{
                props.open
                  ? "Jegy statisztikák"
                  : `Jegyek száma: ${filteredIssues.length} db, Elkészültek: ${doneIssueCount} db, Becslés nélküliek: ${issuesWithoutEstimate.length} db`
              }}
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
            </a>
          </div>
        </template>
        <div class="card-content">
          <div>
            <b-field>
              <b-checkbox v-model="includeBugs" type="is-info">
                {{
                  $tf(
                    "projectPage.tasks.includeBugs|Hiba típusú jegyek beszámítása"
                  )
                }}
              </b-checkbox>
            </b-field>
          </div>
          <div class="is-flex tig-bars-top-container mb-5 has-gap">
            <div class="is-flex-shrink-1 is-flex-grow-1">
              <div class="mb-4">
                <span class="tig-bars-header-text has-font-weight-500">
                  {{ $tf("projectPage.tasks.count|Jegyek száma:") }}
                  <strong>{{
                    $tf("projectPage.tasks.count.data|{count} db", {
                      count: filteredIssues.length,
                    })
                  }}</strong>
                </span>
              </div>
              <div class="tig-bars">
                <tig-bar-list :values="issueStatusTigBars" />
              </div>
            </div>
            <div class="mb-5 is-flex-shrink-1 is-flex-grow-1">
              <div class="mb-4">
                <span class="tig-bars-header-text has-font-weight-500">
                  {{
                    $tf(
                      "projectPage.tasks.unestimatedCount|Becslés nélküli jegyek száma:"
                    )
                  }}
                  <strong>{{
                    $tf("projectPage.tasks.unestimatedCount.data|{count} db", {
                      count: issuesWithoutEstimate.length,
                    })
                  }}</strong>
                  <b-tooltip
                    type="is-info"
                    :triggers="['click']"
                    :auto-close="['outside', 'escape']"
                  >
                    <template v-slot:content>
                      <div class="content tooltip">
                        <p>
                          {{
                            $tf(
                              "projectPage.tasks.unestimated.tooltip|Egy jegy akkor számolódik ide, ha becsléssel nem, de logolt idővel rendelkezik. Az ilyen jegyek rontják a kiszámolt adatok megbízhatóságát és relevanciáját, így létezésük kerülendő."
                            )
                          }}
                        </p>
                      </div>
                    </template>
                    <portal-icon icon="info-circle" />
                  </b-tooltip>
                </span>
              </div>
              <div class="tig-bars">
                <tig-bar-list :values="unEstimatedIssueStatusTigBars" />
              </div>
            </div>
          </div>

          <div class="tig-bars-bottom-container">
            <div class="mb-4">
              <span class="tig-bars-header-text has-font-weight-500">
                {{
                  $tf("projectPage.tasks.sumEstimateMWD|Összes becslés (MWD):")
                }}
                <strong>{{ workdayify(sumEstimateMd) }}</strong>
                <b-tooltip
                  type="is-info"
                  :triggers="['click']"
                  :auto-close="['outside', 'escape']"
                >
                  <template v-slot:content>
                    <div class="content tooltip">
                      <p>
                        {{
                          $tf(
                            "projectPage.tasks.sumEstimateMWD.tooltip|A jegyek létrehozásakor beírt becslés vevődik csak figyelembe!"
                          )
                        }}
                      </p>
                    </div>
                  </template>
                  <portal-icon icon="info-circle" />
                </b-tooltip>
              </span>
            </div>
            <div class="tig-bars">
              <tig-bar-list :values="issueCountTigBars" />
            </div>
          </div>
        </div>
      </b-collapse>
      <br />
      <b-button
        v-if="isSelfTrackingMode"
        :disabled="!details.tigDetails || details.tigDetails?.length === 0"
        type="is-primary"
        rounded
        @click="modalOpen = true"
        >{{
          $tf("projectPage.tasks.list.actions.create|Jegy létrehozása")
        }}</b-button
      >
      <issue-details
        :project-details="details"
        :employees="employees"
        :issues="issues"
      ></issue-details>
    </div>
  </div>
</template>

<script>
import IssueDetails from "@/components/enterprise/project/issue/IssueDetails";
import PortalIcon from "@/components/module/icon/PortalIcon";
import TigBarList from "@/components/enterprise/ProjectDetails/TigBarList";
import { mapGetters } from "vuex";
import { roundToTwoDecimals, workdayify } from "@/utils/util";
import TaskForm from "@/components/enterprise/project/form/TaskForm.vue";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";

export default {
  name: "TasksList",
  components: {
    TaskForm,
    TigBarList,
    IssueDetails,
    PortalIcon,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    issues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      workdayify,
      jegyTab: 0,
      includeBugs: false,
      modalOpen: false,
    };
  },
  computed: {
    isSelfTrackingMode() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),

    filteredIssues() {
      let filteredIssues = this.issues;
      if (!this.includeBugs)
        filteredIssues = filteredIssues.filter((issue) => issue.type !== "BUG");
      return filteredIssues;
    },

    sumEstimateMd() {
      return roundToTwoDecimals(
        this.filteredIssues.reduce(
          (acc, issue) => acc + issue.estimateSeconds,
          0
        ) /
          3600 /
          8
      );
    },

    waitingIssueCount() {
      return this.filteredIssues.filter((issue) => issue.status === "TODO")
        .length;
    },
    proceedingIssueCount() {
      return this.filteredIssues.filter(
        (issue) => issue.status === "IN_PROGRESS"
      ).length;
    },
    doneIssueCount() {
      return this.filteredIssues.filter((issue) => issue.status === "DONE")
        .length;
    },

    unEstimatedWaitingIssueCount() {
      return this.issuesWithoutEstimate.filter(
        (issue) => issue.status === "TODO"
      ).length;
    },
    unEstimatedProceedingIssueCount() {
      return this.issuesWithoutEstimate.filter(
        (issue) => issue.status === "IN_PROGRESS"
      ).length;
    },
    unEstimatedDoneIssueCount() {
      return this.issuesWithoutEstimate.filter(
        (issue) => issue.status === "DONE"
      ).length;
    },

    issuesWithoutEstimate() {
      return this.filteredIssues.filter(
        (issue) => issue.estimateSeconds === 0 || issue.estimateSeconds === null
      );
    },

    issueStatusTigBars() {
      const allCount =
        this.waitingIssueCount +
        this.proceedingIssueCount +
        this.doneIssueCount;
      const divider = allCount !== 0 ? allCount / 100 : 1;
      return [
        {
          header: "To Do",
          size: this.waitingIssueCount / divider,
          count: this.waitingIssueCount,
          type: "is-danger",
        },
        {
          header: "In-progress",
          size: this.proceedingIssueCount / divider,
          count: this.proceedingIssueCount,
          type: "is-warning",
        },
        {
          header: "Done",
          size: this.doneIssueCount / divider,
          count: this.doneIssueCount,
          type: "is-success",
        },
      ];
    },

    unEstimatedIssueStatusTigBars() {
      const allCount =
        this.unEstimatedWaitingIssueCount +
        this.unEstimatedProceedingIssueCount +
        this.unEstimatedDoneIssueCount;
      const divider = allCount !== 0 ? allCount / 100 : 1;
      return [
        {
          header: "To do",
          size: this.unEstimatedWaitingIssueCount / divider,
          count: this.unEstimatedWaitingIssueCount,
          type: "is-danger",
        },
        {
          header: "In progress",
          size: this.unEstimatedProceedingIssueCount / divider,
          count: this.unEstimatedProceedingIssueCount,
          type: "is-warning",
        },
        {
          header: "Done",
          size: this.unEstimatedDoneIssueCount / divider,
          count: this.unEstimatedDoneIssueCount,
          type: "is-success",
        },
      ];
    },

    devIssueCount() {
      return this.filteredIssues.filter((issue) => issue.type === "DEV").length;
    },
    generalIssueCount() {
      return this.filteredIssues.filter((issue) => issue.type === "GENERAL")
        .length;
    },
    bugIssueCount() {
      return this.filteredIssues.filter((issue) => issue.type === "BUG").length;
    },
    otherIssueCount() {
      return this.filteredIssues.filter((issue) => issue.type === "OTHER")
        .length;
    },

    issueCountTigBars() {
      const allCount =
        this.devIssueCount +
        this.generalIssueCount +
        this.bugIssueCount +
        this.otherIssueCount;
      const divider = allCount !== 0 ? allCount / 100 : 1;
      return [
        {
          header: "Development",
          size: this.devIssueCount / divider,
          count: this.devIssueCount,
          type: "is-dark-blue",
        },
        {
          header: "General assignment",
          size: this.generalIssueCount / divider,
          count: this.generalIssueCount,
          type: "is-blue",
        },
        {
          header: "Bug",
          size: this.bugIssueCount / divider,
          count: this.bugIssueCount,
          type: "is-light-blue",
        },
        {
          header: "Other",
          size: this.otherIssueCount / divider,
          count: this.otherIssueCount,
          type: "is-lighter-blue",
        },
      ];
    },
  },
  methods: {
    async handleTaskSubmit() {
      this.modalOpen = false;
      await this.$store.dispatch(
        "enterprise_core/fetchIssues",
        this.details.id
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.tig-bars-top-container {
  border-bottom: 1px solid $grey-lighter;
}

.tig-bars-header-text {
  font-size: 16px;
  color: $custom-dark-blue;

  span {
    font-weight: 400;
  }
}

.has-gap {
  gap: 32px;
}
</style>
