import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-Statistics");
const urls = {
  statistics: "/enterprise/statistics",
};

const initState = () => {
  return {
    statistics: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchStatistics: async function ({ commit, dispatch }, year) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.statistics + "?year=" + year,
        null,
        true,
        dispatch,
        "Statisztikák letöltése..."
      );
      commit("saveStatistics", { data, year });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveStatistics: (state, { data, year }) => {
    if (state.statistics[year]) {
      delete state.statistics[year];
    }
    state.statistics[year] = data;
  },
};

const getters = {
  statistics: (state) => (year) => {
    if (state.statistics[year] == null) return undefined;
    return state.statistics[year];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
