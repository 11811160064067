<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("riskManagement.title|Kockázatmenedzsment") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "riskManagement.subtitle.first|Az alábbi tabokon a pénzügyi kimutatásokban potenciálisan hibás adatokat okozó tényezők kivonatai tekinthetők meg."
          )
        }}
        <br />
        {{
          $tf(
            "riskManagement.subtitle.second|Az összesítések mindegyikében kizárólag jelenleg is aktív, kötelezően logolásra konfigurált kollegák jelennek meg."
          )
        }}
      </h2>
    </div>

    <div class="container">
      <ProjectCollapse
        v-if="canSeeIgnoredPrefixes"
        id="ignored-prefixes"
        :header="$tf('riskManagement.tab.ignoredPrefixes|Ignorált prefixek')"
      >
        <div class="card-content">
          <div
            v-for="(prefix, index) in prefixEdit"
            :key="prefix.id"
            class="columns"
          >
            <div class="column is-one-quarter">
              <b-field
                v-if="canEditIgnoredPrefixes"
                :label="$tf('riskManagement.ignoredPrefixes.prefix|Prefix')"
                label-position="on-border"
              >
                <b-input v-model="prefixEdit[index].prefix"> </b-input>
              </b-field>
              <b-tag v-else size="is-medium">{{
                prefixEdit[index].prefix
              }}</b-tag>
            </div>
            <div class="column">
              <b-field
                v-if="canEditIgnoredPrefixes"
                :label="
                  $tf('riskManagement.ignoredPrefixes.description|Megjegyzés')
                "
                label-position="on-border"
              >
                <b-input v-model="prefixEdit[index].description"> </b-input>
              </b-field>
              <span v-else>{{ prefixEdit[index].description }}</span>
            </div>
            <div v-if="canEditIgnoredPrefixes" class="column is-narrow">
              <div class="columns">
                <div class="column">
                  <b-button
                    type="is-info"
                    :disabled="changedLine(index)"
                    :title="
                      $tf('riskManagement.ignoredPrefixes.edit|Szerkesztés')
                    "
                    @click="updatePrefix(index)"
                    ><b-icon icon="floppy-disk"
                  /></b-button>
                </div>
                <div class="column">
                  <b-button
                    type="is-danger"
                    :title="$tf('riskManagement.ignoredPrefixes.delete|Törlés')"
                    @click="unignorePrefix(index)"
                    ><b-icon icon="trash"
                  /></b-button>
                </div>
              </div>
            </div>
          </div>
          <b-button
            v-if="canEditIgnoredPrefixes"
            type="is-info"
            icon-left="plus-circle"
            @click="openNewPrefixModal"
            @save-prefix="savePrefix"
            >{{
              $tf("riskManagement.ignoredPrefixes.new|Új ignorált prefix")
            }}</b-button
          >
        </div>
      </ProjectCollapse>

      <b-tabs v-model="activeTab" type="is-toggle">
        <b-tab-item
          :label="$tf('riskManagement.tab.missingSheets|Logolási hiányok')"
        >
          <RiskManagementMissingSheetsTable
            v-if="activeTab === 0"
          ></RiskManagementMissingSheetsTable>
        </b-tab-item>
        <b-tab-item
          :label="$tf('riskManagement.tab.unknownSheets|Ismeretlen logolások')"
        >
          <RiskManagementUnknownSheetsTable
            v-if="activeTab === 1"
          ></RiskManagementUnknownSheetsTable>
        </b-tab-item>
        <b-tab-item
          :label="$tf('riskManagement.tab.unknownIssues|Ismeretlen jegyek')"
        >
          <RiskManagementUnknownTable
            v-if="activeTab === 2"
          ></RiskManagementUnknownTable>
        </b-tab-item>
        <b-tab-item
          :label="$tf('riskManagement.tab.sharedIssues|Többszörözött jegyek')"
        >
          <RiskManagementSharedTable
            v-if="activeTab === 3"
          ></RiskManagementSharedTable>
        </b-tab-item>
        <b-tab-item
          :label="
            $tf('riskManagement.tab.loggingWillingness|Logolási hajlandóság')
          "
        >
          <LoggingWillingnessTable
            v-if="activeTab === 4"
          ></LoggingWillingnessTable>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import RiskManagementUnknownTable from "../../../components/enterprise/risk-management/RiskManagementUnkownTable";
import RiskManagementSharedTable from "../../../components/enterprise/risk-management/RiskManagementSharedTable";
import IgnoredPrefixModal from "@/components/enterprise/risk-management/IgnoredPrefixModal";
import { mapGetters } from "vuex";
import { deepCopy } from "@/utils/util";
import { PRIVILEGES } from "@/utils/const";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse.vue";
import RiskManagementMissingSheetsTable from "@/components/enterprise/risk-management/RiskManagementMissingTable.vue";
import RiskManagementUnknownSheetsTable from "@/components/enterprise/risk-management/RiskManagementUnknownSheetsTable.vue";
import LoggingWillingnessTable from "@/components/enterprise/risk-management/LoggingWillingnessTable.vue";

export default {
  name: "RiskManagement",
  components: {
    LoggingWillingnessTable,
    RiskManagementUnknownSheetsTable,
    RiskManagementMissingSheetsTable,
    ProjectCollapse,
    RiskManagementUnknownTable,
    RiskManagementSharedTable,
  },
  data() {
    return {
      activeTab: 0,
      prefixEdit: [],
      currentPrefixes: [],
      loading: true,
    };
  },
  computed: {
    canEditIgnoredPrefixes: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.EDIT,
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.ADMIN
      );
    },
    canSeeIgnoredPrefixes: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.ADMIN
      );
    },
    canSeeAllTeams: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.RISK_MANAGEMENT.ADMIN
      );
    },
    ...mapGetters({
      ignoredPrefixes: "risk_management/getIgnoredPrefixes",
      timesheets: "risk_management/getTimesheet",
    }),
  },
  methods: {
    changedLine(index) {
      return (
        this.prefixEdit[index].prefix === this.currentPrefixes[index].prefix &&
        this.prefixEdit[index].description ===
          this.currentPrefixes[index].description
      );
    },
    openNewPrefixModal() {
      this.$buefy.modal.open({
        parent: this,
        component: IgnoredPrefixModal,
        events: {
          "save-prefix": async (prefix) => {
            await this.savePrefix(prefix);
          },
        },
        hasModalCard: true,
        trapFocus: true,
        width: "100%",
      });
    },
    async savePrefix(prefix) {
      await this.$store.dispatch("risk_management/createIgnoredPrefix", prefix);
      await this.refreshData();
    },
    async updatePrefix(index) {
      await this.$store.dispatch(
        "risk_management/editIgnoredPrefix",
        this.prefixEdit[index]
      );
      await this.refreshData();
    },
    unignorePrefix(index) {
      this.$buefy.dialog.confirm({
        type: "is-danger",
        hasIcon: true,
        title: this.$tf(
          "riskManagement.ignoredPrefixes.deleteModal.title|Prefix ignorálásának törlése"
        ),
        message: this.$tf(
          "riskManagement.ignoredPrefixes.deleteModal.text|Biztosan törölni szeretnéd a prefixet a listáról?"
        ),
        cancelText: this.$tf(
          "riskManagement.ignoredPrefixes.deleteModal.cancel|Mégsem"
        ),
        confirmText: this.$tf(
          "riskManagement.ignoredPrefixes.deleteModal.delete|Törlés"
        ),
        onConfirm: async () => {
          await this.$store.dispatch(
            "risk_management/deleteIgnoredPrefix",
            this.prefixEdit[index].prefix
          );
          await this.refreshData();
        },
      });
    },
    async refreshData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/fetchIgnoredPrefixes", true);
      this.currentPrefixes = deepCopy(this.ignoredPrefixes);
      this.prefixEdit = deepCopy(this.ignoredPrefixes);
      this.loading = false;
    },
  },
  async created() {
    await this.$store.dispatch("risk_management/fetchIgnoredPrefixes");
    this.onlyMyTeam = !this.canSeeAllTeams;
    this.currentPrefixes = deepCopy(this.ignoredPrefixes);
    this.prefixEdit = deepCopy(this.ignoredPrefixes);
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors";

.container {
  :deep(.team-name) {
    color: $custom-dark-blue;
  }

  .user-name {
    color: $custom-dark-blue;
  }

  .has-error {
    color: $red;
    font-weight: 450;
  }

  .is-success {
    color: $turquoise;
    font-weight: 450;
  }
}

.ignored-prefix-container {
  margin-top: 16px;
  padding-top: 8px;
  padding-bottom: 16px;
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px rgba(48, 52, 62, 0.1);
}
</style>
