<template>
  <component
    :is="isPageLoading ? null : BField"
    v-bind="$attrs"
    v-on="$listeners"
    :animation="isPageLoading ? 'wave-new' : $attrs.animation"
    :height="isPageLoading ? $attrs.height ?? '40px' : $attrs.height"
    :width="isPageLoading ? $attrs.width ?? '200px' : $attrs.width"
    :rounded="isPageLoading ? true : $attrs.rounded"
    type="rect"
    :class="{ 'm-2': isPageLoading }"
  >
    <slot v-if="!hideComponent"></slot>
  </component>
</template>

<script>
import { BField } from "buefy/src/components/field";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "BFieldWithLoading",
  props: {
    hideComponent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  data() {
    return {
      BField,
    };
  },
  inheritAttrs: false,
};
</script>
