import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("SeatingArrangementStore");

const urls = {
  seatingArrangement: "/census/seating-arrangement",
  createSeatingArrangementWithSeat: "/census/seating-arrangement/set-seat",
  seatingArrangementWithSeat: "/census/seating-arrangement/ownSeats",
  seatDetails: "census/seating-arrangement/seatDetails",
  seatNames: "census/seating-arrangement/seatNames",
};

const initState = () => {
  return {
    requestInProgress: false,
    ownSeatingArrangements: null,
    ownSeatingArrangementsWithSeat: null,
    adminSeatingArrangements: null,
    seatDetails: [],
    seatNames: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  createSeatingArrangement: async function ({ dispatch, state }, params) {
    try {
      if (params.user) {
        await this.remoteRequest(
          "post",
          urls.seatingArrangement + "/" + params.user,
          params.request,
          false,
          dispatch,
          "Egyéni ülésrend mentése",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Ülésrend mentése folyamatban...",
            fail: "Ülésrend mentése sikertelen!",
            success: "Ülésrend mentése sikeres!",
          }
        );
      } else {
        await this.remoteRequest(
          "post",
          urls.seatingArrangement,
          params.request,
          false,
          dispatch,
          "Ülésrend mentése",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Ülésrend mentése folyamatban...",
            fail: "Ülésrend mentése sikertelen!",
            success: "Ülésrend mentése sikeres!",
          }
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  createSeatingArrangementWithSeat: async function (
    { dispatch, state },
    params
  ) {
    try {
      if (params.seat) {
        await this.remoteRequest(
          "post",
          urls.createSeatingArrangementWithSeat + "/" + params.seat,
          params.request,
          false,
          dispatch,
          "Ülésrend mentése ülőhelyhez",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Ülésrend mentése folyamatban...",
            fail: "Ülésrend mentése sikertelen!",
            success: "Ülésrend mentése sikeres!",
          }
        );
      } else {
        await this.remoteRequest(
          "post",
          urls.createSeatingArrangementWithSeat,
          params.request,
          false,
          dispatch,
          "Ülésrend mentése",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Ülésrend mentése folyamatban...",
            fail: "Ülésrend mentése sikertelen!",
            success: "Ülésrend mentése sikeres!",
          }
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  setForYear: async function ({ dispatch, state }, params) {
    try {
      if (params.user) {
        await this.remoteRequest(
          "post",
          urls.seatingArrangement + "/set-year/" + params.user,
          params.request,
          false,
          dispatch,
          "Éves ülésrend beállítása",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Éves ülésrend mentése folyamatban...",
            fail: "Éves ülésrend mentése sikertelen!",
            success: "Éves ülésrend mentése sikeres!",
          }
        );
      } else {
        await this.remoteRequest(
          "post",
          urls.seatingArrangement + "/set-year",
          params.request,
          false,
          dispatch,
          "Éves ülésrend beállítása",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Éves ülésrend mentése folyamatban...",
            fail: "Éves ülésrend mentése sikertelen!",
            success: "Éves ülésrend mentése sikeres!",
          }
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  setForYearWithSeat: async function ({ dispatch, state }, params) {
    try {
      if (params.seat) {
        await this.remoteRequest(
          "post",
          urls.seatingArrangement + "/set-year-seat/" + params.seat,
          params.request,
          false,
          dispatch,
          "Éves ülésrend mentése ülőhelyhez",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Éves ülésrend mentése folyamatban...",
            fail: "Éves ülésrend mentése sikertelen!",
            success: "Éves ülésrend mentése sikeres!",
          }
        );
      } else {
        await this.remoteRequest(
          "post",
          urls.seatingArrangement + "/set-year-seat",
          params.request,
          false,
          dispatch,
          "Éves ülésrend mentése",
          undefined,
          undefined,
          false,
          true,
          {
            progress: "Éves ülésrend mentése folyamatban...",
            fail: "Éves ülésrend mentése sikertelen!",
            success: "Éves ülésrend mentése sikeres!",
          }
        );
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  fetchSeatDetails: async function ({ dispatch, state, commit }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatDetails,
        params,
        true,
        dispatch,
        "Ülések betöltése..."
      );
      commit("saveSeatDetails", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeatDetailsBySiteId: async function (
    { dispatch, state, commit },
    params
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatDetails + "/" + params.siteId,
        params,
        true,
        dispatch,
        "Ülések betöltése..."
      );
      commit("saveSeatDetails", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeatNames: async function ({ dispatch, state, commit }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatNames,
        null,
        true,
        dispatch,
        "Ülések betöltése..."
      );
      commit("saveSeatNames", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeatNamesBySiteId: async function ({ dispatch, state, commit }, siteId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatNames + "/" + siteId,
        null,
        true,
        dispatch,
        "Ülések betöltése..."
      );
      commit("saveSeatNames", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getSeatingArrangements: async function ({ dispatch, state, commit }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatingArrangement,
        params,
        true,
        dispatch,
        "Ülésrendek betöltése..."
      );
      commit("setOwnSeatingArrangements", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getSeatingArrangementsWithSeat: async function (
    { dispatch, state, commit },
    params
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatingArrangementWithSeat,
        params,
        true,
        dispatch,
        "Ülésrendek betöltése..."
      );
      commit("setOwnSeatingArrangementsWithSeat", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getAdminSeatingArrangements: async function (
    { dispatch, state, commit },
    params
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatingArrangement + "/admin",
        params,
        true,
        dispatch,
        "Ülésrendek betöltése..."
      );
      commit("setAdminSeatingArrangements", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setOwnSeatingArrangements(state, seatingArrangements) {
    state.ownSeatingArrangements = seatingArrangements;
  },
  setAdminSeatingArrangements(state, seatingArrangements) {
    state.adminSeatingArrangements = seatingArrangements;
  },
  saveSeatDetails: (state, seatDetails) => {
    state.seatDetails = seatDetails;
  },
  saveSeatNames: (state, seatNames) => {
    state.seatNames = seatNames;
  },
  setOwnSeatingArrangementsWithSeat(state, seatingArrangementsWithSeat) {
    state.ownSeatingArrangementsWithSeat = seatingArrangementsWithSeat;
  },
};

const getters = {
  ownSeatingArrangements: (state) => {
    return state.ownSeatingArrangements;
  },
  requestInProgress: (state) => {
    return state.requestInProgress;
  },
  adminSeatingArrangements: (state) => {
    return state.adminSeatingArrangements;
  },
  seatDetails: (state) => {
    return state.seatDetails;
  },
  seatNames: (state) => {
    return state.seatNames;
  },
  ownSeatingArrangementsWithSeat: (state) => {
    return state.ownSeatingArrangementsWithSeat;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
