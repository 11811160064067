<template>
  <div
    class="is-flex is-flex-direction-column is-justify-content-flex-end"
    :style="{ height: `${maxHeight + 60}px` }"
  >
    <div
      :style="{ height: height, background: color, width: '32px' }"
      style="margin-bottom: 4px"
      class="timesheet-graph-bar"
    ></div>
    <div class="has-text-centered has-text-grey-dark">{{ day }}</div>
    <div class="has-text-centered has-text-grey" style="font-size: 11px">
      {{ date }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TimesheetBarGraph",
  props: {
    day: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    expected: {
      type: Number,
      required: true,
    },
    logged: {
      type: Number,
      required: true,
    },
    maxLogged: {
      type: Number,
      required: true,
    },
    maxHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    height() {
      if (this.expected > 0 && this.logged === 0) return "3px";
      // const height = (this.logged / 60 / 60) * 25;
      const height = this.logged * 25;
      return `${height}px`;
    },
    color() {
      // if (this.logged / 60 / 60 > this.expected) return "#7B61FF";
      if (this.logged > this.expected) return "#7B61FF";
      // else if (this.logged / 60 / 60 === this.expected) return "#399745";
      else if (this.logged === this.expected) return "#399745";
      else return "#F45252";
    },
  },
};
</script>

<style scoped>
.timesheet-graph-bar {
  border-radius: 8px 8px 0 0;
  opacity: 0.85;
}

.timesheet-graph-bar:hover {
  opacity: 1;
  box-shadow: 0 0 3px 0 grey;
}
</style>
