<template>
  <div>
    <loading-skeleton v-if="loading" />
    <div v-else>
      <b-loading
        :active="softLoading"
        v-if="softLoading"
        :is-full-page="false"
      ></b-loading>
      <h3 class="title">
        {{ $tf("associationForm.base.title|Asszociáció szerkesztése") }}
      </h3>

      <form @submit.prevent="saveAssociation">
        <div class="columns mt-5">
          <div class="column is-half">
            <b-field
              :label="$tf('associationForm.base.employee|Alkalmazott')"
              label-position="on-border"
            >
              <f-autocomplete
                v-model="associationEdit.employeeId"
                :placeholder="
                  $tf(
                    'associationForm.base.employeeId.placeholder|Alkalmazott neve'
                  )
                "
                icon="user"
                field="name"
                id-field="id"
                expanded
                :data="employees"
                append-to-body
                open-on-focus
                :disabled="!create"
              />
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field
              :label="$tf('associationForm.base.day|Dátum')"
              label-position="on-border"
            >
              <b-datepicker
                :placeholder="$tf('associationForm.base.day|Dátum')"
                icon="calendar-alt"
                v-model="associationEdit.day"
                trap-focus
                class="has-blue-icon"
                append-to-body
                :disabled="!create"
              ></b-datepicker>
            </b-field>
          </div>
        </div>

        <div class="mb-5">
          <b-field
            :label="$tf('associationForm.base.note.label|Megjegyzés')"
            label-position="on-border"
          >
            <b-input
              :placeholder="
                $tf('associationForm.base.note.placeholder|Megjegyzés...')
              "
              v-model="associationEdit.note"
              trap-focus
              class="has-blue-icon"
              type="textarea"
            ></b-input>
          </b-field>
        </div>

        <template v-if="create">
          <div
            v-for="(allocation, index) in associationEdit.allocations"
            :key="index"
          >
            <div class="columns mb-1">
              <div class="column is-half">
                <b-field
                  :label="$tf('associationForm.base.project|Projekt')"
                  label-position="on-border"
                >
                  <f-autocomplete
                    v-model="allocation.projectId"
                    :placeholder="
                      $tf(
                        'associationForm.base.project.placeholder|Projekt neve'
                      )
                    "
                    icon="swatchbook"
                    field="name"
                    id-field="id"
                    expanded
                    :data="projects"
                    append-to-body
                    open-on-focus
                  />
                </b-field>
              </div>
              <div class="column is-half is-flex has-gap-1">
                <div>
                  <b-field
                    :label="
                      $tf('associationForm.base.hours.placeholder|Munkaórák')
                    "
                    label-position="on-border"
                  >
                    <b-input
                      v-model="allocation.hours"
                      :placeholder="
                        $tf('associationForm.base.hours.placeholder|Munkaórák')
                      "
                      label-position="on-border"
                      expanded
                      type="number"
                      select-on-focus
                  /></b-field>
                </div>
                <div v-if="index > 0">
                  <b-button type="is-danger" @click="removeAllocation(index)">
                    <b-icon icon="minus" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <b-button type="is-primary" @click="addNewAllocation">
              <b-icon icon="plus" />
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="columns mb-1">
            <div class="column is-half">
              <b-field
                :label="$tf('associationForm.base.project|Projekt')"
                label-position="on-border"
              >
                <f-autocomplete
                  v-model="associationEdit.projectId"
                  :placeholder="
                    $tf('associationForm.base.project.placeholder|Projekt neve')
                  "
                  icon="swatchbook"
                  field="name"
                  id-field="id"
                  expanded
                  :data="projects"
                  append-to-body
                  open-on-focus
                />
              </b-field>
            </div>
            <div class="column is-half is-flex has-gap-1">
              <div>
                <b-field
                  :label="
                    $tf('associationForm.base.hours.placeholder|Munkaórák')
                  "
                  label-position="on-border"
                >
                  <b-input
                    v-model="associationEdit.hours"
                    :placeholder="
                      $tf('associationForm.base.hours.placeholder|Munkaórák')
                    "
                    label-position="on-border"
                    expanded
                    type="number"
                    select-on-focus
                /></b-field>
              </div>
            </div>
          </div>
        </template>

        <div class="is-flex is-justify-content-flex-end mb-0">
          <b-field class="mb-0">
            <b-button
              size="is-small"
              @click="cancel()"
              :loading="buttonsLoading"
            >
              {{ $tf("associationForm.cancel|Mégse") }}
            </b-button>
          </b-field>
          <b-field class="mb-0 ml-5">
            <b-button
              :loading="buttonsLoading"
              size="is-small"
              type="is-info"
              @click="saveAssociation()"
            >
              {{ $tf("associationForm.save|Mentés") }}
            </b-button>
          </b-field>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { correctWithTimezoneOffset, deepCopy, formatDate } from "@/utils/util";
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import { mapGetters } from "vuex";
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "AssociationForm",
  components: { FAutocomplete, LoadingSkeleton },
  emits: ["association-saved", "cancel"],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    associationData: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatDate,
      associationEdit: {
        allocations: [
          {
            hours: 8,
            projectId: undefined,
          },
        ],
        day: new Date(),
        note: undefined,
        employeeId: undefined,
      },
      buttonsLoading: false,
      softLoading: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      projects: "enterprise_core/projects",
    }),
  },
  methods: {
    addNewAllocation() {
      this.associationEdit.allocations.push({
        hours: undefined,
      });
    },
    removeAllocation(index) {
      this.associationEdit.allocations.splice(index, 1);
    },
    async saveAssociation() {
      this.buttonsLoading = true;
      if (
        (!this.create && !this.associationEdit.projectId) ||
        (this.create &&
          this.associationEdit.allocations.some((it) => !it.projectId)) ||
        !this.associationEdit.employeeId
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "associationForm.error.IdsMissing|Projekt és alkalmazott megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        this.buttonsLoading = false;
        return;
      }

      if (
        (!this.create && !this.associationEdit.hours) ||
        (this.create &&
          this.associationEdit.allocations.some((it) => !it.hours))
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "associationForm.error.hoursMissing|Óraszám megadása kötelező!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        this.buttonsLoading = false;
        return;
      }

      this.associationData.id
        ? await this.updateAssociation()
        : await this.createAssociation();
      this.associationEdit = {};
      this.buttonsLoading = false;
    },
    async updateAssociation() {
      const request = deepCopy(this.associationEdit);
      request.day = correctWithTimezoneOffset(new Date(request.day));
      await this.$store.dispatch("association/updateAssociation", request);
      this.$emit("association-saved");
    },
    async createAssociation() {
      const request = deepCopy(this.associationEdit);
      request.day = correctWithTimezoneOffset(new Date(request.day));
      await this.$store.dispatch("association/createAssociation", request);
      this.$emit("association-saved");
    },
    preloadData() {
      if (this.create) {
        this.associationEdit.day = new Date();
      } else {
        this.associationEdit = deepCopy(this.associationData);
        if (this.associationEdit.day) {
          this.associationEdit.day = new Date(this.associationEdit.day);
        } else this.associationEdit.day = new Date();
      }
    },
    cancel() {
      this.associationEdit = {};
      this.$emit("cancel");
    },
  },
  mounted() {
    this.preloadData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors";

button {
  border-radius: 8px !important;
}

.is-used-key {
  color: $red;
  font-weight: bold;
  text-decoration: line-through;
}
</style>
