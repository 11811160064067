<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="isCreate">
          {{ $tf("userIndicatorFrom.newTitle|Új attribútum létrehozása") }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf("userIndicatorFrom.editTitle|Attribútum módosítása") }}
        </h1>

        <form @submit.prevent="submit">
          <b-field
            :label="$tf('userIndicatorFrom.type|Típus')"
            class="mt-5"
            label-position="on-border"
          >
            <b-select
              v-model="editData.type"
              :placeholder="$tf('userIndicatorFrom.type.placeholder|Típus')"
              expanded
            >
              <option
                v-for="(key, value) in USER_INDICATOR_TYPE_LABEL"
                :key="value"
                :value="value"
              >
                {{ $tf(key) }}
              </option>
            </b-select>
          </b-field>

          <b-field class="mt-5">
            <vuelidated-input
              v-model="editData.name"
              :placeholder="$tf('userIndicatorFrom.name.placeholder|Név')"
              :label="$tf('userIndicatorFrom.name|Név')"
              label-position="on-border"
              :validation-rule="v$.editData.name"
            />
          </b-field>

          <b-field label="Szín kiválasztása">
            <b-colorpicker v-model="editData.color" append-to-body />
          </b-field>

          <b-field
            :label="$tf('userIndicatorFrom.parent|Szülő Terület')"
            class="mt-5"
            label-position="on-border"
            v-if="editData.type === USER_INDICATOR_TYPE_ENUM.ROLE"
          >
            <b-select
              v-model="editData.parent"
              :placeholder="
                $tf('userIndicatorFrom.parent.placeholder|Válassz...')
              "
              expanded
            >
              <option
                v-for="(department, index) in departments"
                :key="index"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </b-select>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("userIndicatorFrom.cancel|Mégse") }}
            </b-button>
            <b-button native-type="submit" type="is-info" class="ml-3">
              {{ $tf("userIndicatorFrom.save|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { useVuelidate } from "@vuelidate/core";
import {
  USER_INDICATOR_TYPE_LABEL,
  USER_INDICATOR_TYPE_ENUM,
} from "@/utils/const";
import { getCurrentInstance } from "vue";

export default {
  name: "UserIndicatorForm",
  components: { VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    indicator: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: Object,
      required: false,
      default: null,
    },
    indicators: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      name: "",
      USER_INDICATOR_TYPE_ENUM,
      USER_INDICATOR_TYPE_LABEL,
      editData: {
        name: "",
        type: USER_INDICATOR_TYPE_ENUM.DEPARTMENT,
      },
      isCreate: true,
    };
  },
  computed: {
    departments() {
      return this.indicators
        .filter((attr) => attr.type === USER_INDICATOR_TYPE_ENUM.DEPARTMENT)
        .map((attr) => {
          return { id: attr.id, name: attr.name };
        });
    },
  },
  mounted() {
    if (this.indicator) {
      this.isCreate = false;
      this.name = this.indicator.name;
      this.editData = JSON.parse(JSON.stringify(this.indicator));
    } else {
      this.editData.type = this.type;
      this.isCreate = true;
    }
  },
  validations: {
    editData: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$store.dispatch("user_indicator/fetch", true);
    },
    submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.editData.color = this.editData.color
        ? this.editData.color.toString("hex")
        : null;

      if (this.isCreate) {
        this.$store
          .dispatch("user_indicator/create", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("user_indicator/update", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
  },
};
</script>
