<template>
  <div class="scrollable-modal-card">
    <div class="container">
      <header class="modal-card-head">
        <h1 class="title" v-if="newUser">
          {{ $tf("userModal.newTitle|Új munkatárs felvétele") }}
        </h1>
        <h1 class="title" v-else>
          {{
            $tf("userModal.editTitle|{name} módosítása", {
              name: userData.name,
            })
          }}
        </h1>
      </header>
      <section class="modal-card-body">
        <user-form
          ref="userForm"
          :new-user="newUser"
          :user-data="userData"
        ></user-form>
      </section>
      <footer class="modal-card-footer">
        <b-field class="ml-auto">
          <b-button @click="cancel" class="mr-3">
            {{ $tf("userModal.cancel|Mégse") }}
          </b-button>
          <b-field>
            <b-button @click="submit" type="is-info">
              {{ $tf("userModal.save|Mentés") }}
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
import UserForm from "@/components/census/user/UserForm";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";
import { getCurrentInstance } from "vue";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "UserModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  components: { UserForm },
  props: {
    newUser: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
      default: null,
    },
    superadminCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async submit() {
      let userForm = this.$refs.userForm;
      userForm.v$.$touch();

      let phoneInput = userForm.$refs.phoneInput;
      phoneInput.v$.$touch();

      let contactInput = userForm.$refs.contactInput;
      contactInput.v$.$touch();

      let changeEntryComponents = userForm.$refs.entries;
      let entryValues = [];

      if (
        !userForm.v$.$invalid &&
        !phoneInput.v$.$invalid &&
        !contactInput.v$.$invalid
      ) {
        if (changeEntryComponents) {
          for (let index in changeEntryComponents) {
            let changeEntryComponent = changeEntryComponents[index];
            if (await changeEntryComponent.isInvalid()) {
              return;
            }
            entryValues.push(changeEntryComponent.getValues());
          }
        }

        if (userForm.userEdit.rolesEdit) {
          userForm.userEdit.roles = userForm.userEdit.rolesEdit
            .map((r) => r.value)
            .join(",");
        }

        if (userForm.userEdit.competenciesEdit) {
          userForm.userEdit.competencies =
            userForm.userEdit.competenciesEdit.map((c) => {
              return { id: c.value };
            });
        }

        if (userForm.userEdit.startDate) {
          userForm.userEdit.startDate = correctWithTimezoneOffset(
            userForm.userEdit.startDate
          );
        }

        if (userForm.userEdit.endDate) {
          userForm.userEdit.endDate = correctWithTimezoneOffset(
            userForm.userEdit.endDate
          );
        }

        userForm.userEdit.phone =
          "36" + userForm.userEdit.phone.replace(/\s/g, "");

        const formData = new FormData();
        if (userForm.userEdit.avatar) {
          formData.append("file", userForm.userEdit.avatar);
          formData.append("fileName", userForm.userEdit.avatar.name);
          formData.append("documentType", "USER_AVATAR");
        }

        if (userForm.newUser) {
          userForm.userEdit.tenantId = this.tenantId;

          if (this.superadminCreate) {
            await this.$store
              .dispatch("employee/superadminCreate", {
                request: userForm.userEdit,
                avatarForm: formData,
              })
              .then(async () => {
                await this.close();
              });
          } else {
            await this.$store
              .dispatch("employee/create", {
                createUserRequest: userForm.userEdit,
                createJiraUser: userForm.createsJiraUser,
                avatarForm: formData,
              })
              .then(async () => {
                await this.close();
              });
          }
        } else {
          userForm.userEdit.id = userForm.userId;
          let request = deepCopy(userForm.userEdit);
          if (request?.phone.startsWith("36")) {
            request.phone = request.phone.substring(2);
          }
          await this.$store
            .dispatch("employee/update", {
              request,
              avatarForm: formData,
            })
            .then(async () => {
              await this.close();
            });
        }
      }
    },
    async close() {
      this.$emit("fetch");
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.animation-content {
  .scrollable-modal-card {
    h3 {
      &:not(:first-child) {
        margin-top: 1rem;
      }
      margin-bottom: 1rem;
    }
  }
}
</style>
