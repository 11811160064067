<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="overflow-x: auto">
      <section class="modal-card-body">
        <h1 class="title" v-if="createMode">
          {{ $tf("forecastFact.edit.createTitle|Sor felvétele") }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf("forecastFact.edit.editTitle|Sor módosítása") }}
        </h1>
        <div v-show="loaded">
          <form>
            <b-field grouped class="mt-5">
              <b-field expanded>
                <vuelidated-input
                  :label="$tf('forecastFact.edit.nameLabel|Tervadat neve')"
                  :placeholder="
                    $tf('forecastFact.edit.namePlaceholder|Add meg a nevet')
                  "
                  v-model="editObject.name"
                  :validation-rule="v$.editObject.name"
                  :loading="projectLoading"
                ></vuelidated-input
              ></b-field>
              <vuelidated-input
                type="autocomplete"
                v-model="editObject.project"
                :label="
                  $tf('forecastFact.edit.projectLabel|Projekt kiválasztása')
                "
                :validation-rule="v$.editObject.project"
                :data="projects"
                open-on-focus
                append-to-body
                field="name"
                id-field="identifier"
              >
              </vuelidated-input>
              <vuelidated-input
                type="autocomplete"
                v-model="editObject.SALES_TYPE"
                :label="
                  $tf(
                    'forecastFact.edit.salesTypeLabel|Sales típus kiválasztása'
                  )
                "
                :validation-rule="v$.editObject.SALES_TYPE"
                :disabled="
                  filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE)
                    .length === 1
                "
                :data="filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE)"
                :field="
                  findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE).get
                    .value
                "
                :id-field="
                  findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE).get
                    .key
                "
                append-to-body
                open-on-focus
              />
            </b-field>

            <b-field grouped>
              <vuelidated-input
                type="autocomplete"
                v-model="editObject.ACCOUNT"
                :label="
                  $tf('forecastFact.edit.accountLabel|Ügyfél kiválasztása')
                "
                :validation-rule="v$.editObject.ACCOUNT"
                :disabled="!!parent || !!edit"
                :loading="projectLoading"
                :data="filterByRuleset('ACCOUNT')"
                field="name"
                id-field="id"
                open-on-focus
                append-to-body
              />
              <b-field
                :label="
                  $tf('forecastFact.edit.supervisorLabel|Felelős kiválasztás')
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastFact.edit.supervisorPlaceholder|Válassz a listából'
                    )
                  "
                  v-model="editObject.supervisor"
                  :data="filteredEmployees"
                  field="name"
                  id-field="id"
                  append-to-body
                  open-on-focus
                  clearable
                  :loading="projectLoading"
                  :disabled="!!parent || !!edit"
              /></b-field>
            </b-field>

            <b-field
              grouped
              v-for="(quarter, index) in Object.keys(FORECAST_YEAR_QUARTER)"
              :key="'QUARTER_' + index"
            >
              <b-field>
                <b-checkbox
                  :class="index === 0 ? 'mt-6' : 'mt-5'"
                  v-model="editObject['hasIncome' + quarter]"
                  :disabled="!!parent || !!edit"
                  >{{ quarter }}</b-checkbox
                >
              </b-field>
              <div class="is-flex is-align-items-end mr-2">
                <vuelidated-input
                  :label="
                    index === 0
                      ? $tf('forecastFact.edit.income|Negyedévek adatai')
                      : undefined
                  "
                  v-model="editObject['income' + quarter]"
                  :validation-rule="v$.editObject['income' + quarter]"
                  :disabled="
                    !!parent || !!edit || !editObject['hasIncome' + quarter]
                  "
                  :loading="projectLoading"
                />
                <div class="mb-1">
                  {{ currency }}
                </div>
              </div>
              <vuelidated-input
                :label="
                  index === 0
                    ? $tf(
                        'forecastFact.edit.chanceCategory|Valószínűség kiválasztása'
                      )
                    : undefined
                "
                v-model="editObject['chanceCategory' + quarter]"
                :validation-rule="v$.editObject['chanceCategory' + quarter]"
                :disabled="
                  !!parent || !!edit || !editObject['hasIncome' + quarter]
                "
                type="autocomplete"
                :loading="projectLoading"
                :data="chanceCategories"
                field="name"
                id-field="id"
                append-to-body
                open-on-focus
              />
            </b-field>

            <b-checkbox
              v-model="hasIncomeNextYear"
              :disabled="!!parent || !!edit"
            >
              {{
                $tf(
                  "forecastFact.edit.hasIncomeNextYear|A tervsor átnyúlik a következő évre"
                )
              }}</b-checkbox
            >

            <template v-if="hasIncomeNextYear">
              <h2 class="title is-6 my-2">
                {{ selectedYear + 1 }}
              </h2>
              <b-field
                grouped
                v-for="(quarter, index) in Object.keys(FORECAST_YEAR_QUARTER)"
                :key="'QUARTER_NEXT_' + index"
              >
                <b-field>
                  <b-checkbox
                    :class="index === 0 ? 'mt-6' : 'mt-5'"
                    v-model="editObject['hasIncomeNextYear' + quarter]"
                    :disabled="!!parent || !!edit"
                    >{{ quarter }}</b-checkbox
                  >
                </b-field>
                <div class="is-flex is-align-items-end mr-2">
                  <vuelidated-input
                    :label="
                      index === 0
                        ? $tf('forecastFact.edit.income|Negyedévek adatai')
                        : undefined
                    "
                    v-model="editObject['incomeNextYear' + quarter]"
                    :validation-rule="v$.editObject['incomeNextYear' + quarter]"
                    :disabled="
                      !!parent ||
                      !!edit ||
                      !editObject['hasIncomeNextYear' + quarter]
                    "
                    :loading="projectLoading"
                  />
                  <div class="mb-1">
                    {{ currency }}
                  </div>
                </div>
                <vuelidated-input
                  :label="
                    index === 0
                      ? $tf(
                          'forecastFact.edit.chanceCategory|Valószínűség kiválasztása'
                        )
                      : undefined
                  "
                  v-model="editObject['chanceCategoryNextYear' + quarter]"
                  :validation-rule="
                    v$.editObject['chanceCategoryNextYear' + quarter]
                  "
                  :disabled="
                    !!parent ||
                    !!edit ||
                    !editObject['hasIncomeNextYear' + quarter]
                  "
                  type="autocomplete"
                  :loading="projectLoading"
                  :data="chanceCategories"
                  field="name"
                  id-field="id"
                  append-to-body
                  open-on-focus
                />
              </b-field>
            </template>

            <h2 class="title is-5 mt-5">
              {{
                $tf(
                  "forecastFact.edit.optionalDataTitle|Opcionális adatok megadása"
                )
              }}
            </h2>
            <b-field grouped class="mt-2">
              <b-field
                :label="
                  $tf(
                    'forecastFact.edit.incomeTypeLabel|Árbevétel típus kiválasztás'
                  )
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastFact.edit.incomeTypePlaceholder|Válassz a listából'
                    )
                  "
                  v-model="editObject[FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE]"
                  :data="
                    filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE)
                  "
                  :field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE)
                      .get.value
                  "
                  :id-field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE)
                      .get.key
                  "
                  append-to-body
                  open-on-focus
                  clearable
              /></b-field>
              <b-field
                :label="
                  $tf('forecastFact.edit.segmentLabel|Szegmens kiválasztás')
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastFact.edit.segmentPlaceholder|Válassz a listából'
                    )
                  "
                  v-model="editObject.segment"
                  :data="segments"
                  field="name"
                  id-field="id"
                  append-to-body
                  open-on-focus
                  clearable
              /></b-field>
              <b-field
                :label="
                  $tf(
                    'forecastFact.edit.serviceTypeLabel|Szolgáltatás típus kiválasztás'
                  )
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastFact.edit.serviceTypePlaceholder|Válassz a listából'
                    )
                  "
                  v-model="
                    editObject[FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE]
                  "
                  :data="
                    filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE)
                  "
                  :field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE)
                      .get.value
                  "
                  :id-field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE)
                      .get.key
                  "
                  append-to-body
                  open-on-focus
                  clearable
              /></b-field>
            </b-field>
            <b-field class="mt-5 is-pulled-right">
              <b-button @click="close">
                {{ $tf("forecastFact.edit.cancel|Mégsem") }}
              </b-button>
              <b-button @click="save" type="is-info" class="ml-3">
                {{ $tf("forecastFact.edit.save|Mentés") }}
              </b-button>
            </b-field>
          </form>
        </div>
        <LoadingSkeleton v-if="!loaded" />
      </section>
    </div>
  </div>
</template>

<script>
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import ForecastEditMixin from "@/mixins/ForecastEditMixin";
import { mapGetters } from "vuex";
import { decimal, minValue, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { FORECAST_YEAR_QUARTER } from "@/utils/const";
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";
import { deepCopy, getQuarters } from "@/utils/util";

export default {
  name: "ForecastFactEditModal",
  mixins: [ForecastEditMixin],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FAutocomplete,
    LoadingSkeleton,
    VuelidatedInput,
  },
  async mounted() {
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
  },
  data() {
    return {
      projectLoading: false,
    };
  },
  validations: {
    editObject: {
      name: {
        required,
      },
      project: {
        required,
      },
      ACCOUNT: {
        required: requiredIf(function (editObject) {
          return !editObject.noClient;
        }),
      },
      SALES_TYPE: {
        required,
      },
      ...Object.keys(FORECAST_YEAR_QUARTER)
        .map((quarter) => {
          return {
            [`income${quarter}`]: {
              required: requiredIf(function (editObject) {
                return editObject[`hasIncome${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
            [`incomeNextYear${quarter}`]: {
              required: requiredIf(function (editObject) {
                return editObject[`hasIncomeNextYear${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
            [`chanceCategory${quarter}`]: {
              required: requiredIf(function (editObject) {
                return editObject[`hasIncome${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
            [`chanceCategoryNextYear${quarter}`]: {
              required: requiredIf(function (editObject) {
                return editObject[`hasIncomeNextYear${quarter}`];
              }),
              decimal,
              minValue: minValue(0),
            },
          };
        })
        .reduce((acc, val) => Object.assign(acc, val), {}),
    },
  },
  watch: {
    "editObject.project": {
      async handler(val) {
        if (typeof val === "string") {
          this.projectLoading = true;
          await this.$store.dispatch("enterprise_core/fetchProjectDetails", {
            projectIdentifier: val,
          });
          await this.$store.dispatch("enterprise_projects/fetchProjectData", {
            projectIdentifier: val,
          });
          this.projectLoading = false;
        }
      },
    },
    projectData(val) {
      this.editObject.ACCOUNT = val?.client;
      this.editObject.supervisor = val?.lead;
    },
    projectDetails(val) {
      this.setQuarters(val);
    },
  },
  computed: {
    ...mapGetters({
      projects: "enterprise_core/limitedProjects",
      projectDetailsGetter: "enterprise_core/projectDetails",
      projectDataGetter: "enterprise_projects/projectData",
    }),
    projectDetails() {
      return this.editObject?.project
        ? this.projectDetailsGetter(this.editObject.project)
        : undefined;
    },
    projectData() {
      return this.editObject?.project
        ? this.projectDataGetter(this.editObject.project)
        : undefined;
    },
  },
  methods: {
    setQuarters(projectDetails) {
      if (!projectDetails) return;
      const chanceCategory = this.chanceCategories.find(
        (it) => it.chance === 100
      )?.id;

      const quarters = projectDetails.tigDetails
        ?.map((tig) => {
          const quarters = getQuarters(
            this.selectedYear,
            tig.startDate,
            tig.dueDate
          );
          const thisYearQuarters = quarters.thisYearQuarters.map((quarter) => {
            return {
              quarter,
              income: Math.round(
                (tig.billableAmount || 0) / quarters.thisYearQuarters.length
              ),
            };
          });
          const nextYearQuarters = quarters.nextYearQuarters.map((quarter) => {
            return {
              quarter,
              income: Math.round(
                (tig.billableAmount || 0) / quarters.nextYearQuarters.length
              ),
            };
          });
          return {
            thisYearQuarters,
            nextYearQuarters,
          };
        })
        .reduce(
          (acc, val) => {
            val.thisYearQuarters.forEach((thisYearQuarter) => {
              const found = acc.thisYearQuarters.find(
                (it) => it.quarter === thisYearQuarter.quarter
              );
              if (found) {
                found.income += thisYearQuarter.income;
              } else {
                acc.thisYearQuarters.push(thisYearQuarter);
              }
            });
            val.nextYearQuarters.forEach((nextYearQuarter) => {
              const found = acc.nextYearQuarters.find(
                (it) => it.quarter === nextYearQuarter.quarter
              );
              if (found) {
                found.income += nextYearQuarter.income;
              } else {
                acc.nextYearQuarters.push(nextYearQuarter);
              }
            });
            return acc;
          },
          { thisYearQuarters: [], nextYearQuarters: [] }
        );
      Object.keys(FORECAST_YEAR_QUARTER).forEach((quarter) => {
        const foundThisYear = quarters.thisYearQuarters.find(
          (it) => it.quarter === quarter
        );
        const foundNextYear = quarters.nextYearQuarters.find(
          (it) => it.quarter === quarter
        );
        if (foundThisYear) {
          this.editObject[`hasIncome${quarter}`] = true;
          this.editObject[`income${quarter}`] = foundThisYear.income;
          this.editObject[`chanceCategory${quarter}`] = chanceCategory;
        }
        if (foundNextYear) {
          this.hasIncomeNextYear = true;
          this.editObject[`hasIncomeNextYear${quarter}`] = true;
          this.editObject[`incomeNextYear${quarter}`] = foundNextYear.income;
          this.editObject[`chanceCategoryNextYear${quarter}`] = chanceCategory;
        }
      });
    },

    async save() {
      this.v$.editObject.$touch();
      if (!this.v$.editObject.$invalid) {
        const requestObject = deepCopy(this.editObject);
        requestObject.year = this.selectedYear;
        requestObject.project = this.projects.find(
          (it) => it.identifier === requestObject.project
        )?.id;
        requestObject.parent = this.parent?.id;
        if (this.createMode) {
          await this.$store.dispatch("forecast_fact/createFact", requestObject);
        } else {
          await this.$store.dispatch("forecast_fact/updateFact", requestObject);
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
