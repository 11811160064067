<template>
  <div class="timeline">
    <!--    <h5>Átfutás</h5>-->
    <!--    <div class="columns">-->
    <!--      <div class="column is-one-fifth">-->
    <!--        <b-tooltip-->
    <!--          label="Készültségi arány vs. Átfutási időből eltelt napok aránya a projekt kezdetétől: (Mai Dátum - Kezdeti Dátum) / (Határidő - Kezdeti Dátum)"-->
    <!--        >-->
    <!--          <dashboard-card-->
    <!--            :image="'calendar-icon'"-->
    <!--            :project="{-->
    <!--              name: 'Készültség vs Átfutás',-->
    <!--              clientName: `${donePercent} vs ${daysLeftPercentage}%`,-->
    <!--              clientAvatar: require('@/assets/images/dashboard/calendar-icon.svg'),-->
    <!--              clickEmitter: true,-->
    <!--            }"-->
    <!--          />-->
    <!--        </b-tooltip>-->
    <!--      </div>-->
    <!--      <div class="column is-one-fifth">-->
    <!--        <b-tooltip-->
    <!--          label="Fennmaradó napok száma az eredeti vállalásból: (Vállalás - Tényleges Költés)"-->
    <!--        >-->
    <!--          <dashboard-card-->
    <!--            :image="'calendar-icon'"-->
    <!--            :project="{-->
    <!--              name: 'Betervezett hátralevő idő',-->
    <!--              clientName: `${projectDetails.remainingMd} MWD`,-->
    <!--              clientAvatar: require('@/assets/images/dashboard/calendar-icon.svg'),-->
    <!--              clickEmitter: true,-->
    <!--            }"-->
    <!--          />-->
    <!--        </b-tooltip>-->
    <!--      </div>-->
    <!--      <div class="column is-one-fifth">-->
    <!--        <b-tooltip-->
    <!--          label="Fennmaradó napok száma az eredeti vállalásból: (Vállalás - Tényleges Költés)"-->
    <!--        >-->
    <!--          <dashboard-card-->
    <!--            :image="'calendar-icon'"-->
    <!--            :project="{-->
    <!--              name: 'Fennmaradó vállalás',-->
    <!--              clientName: `${-->
    <!--                projectDetails.commitment - projectDetails.sumSpentMd-->
    <!--              } MWD`,-->
    <!--              clientAvatar: require('@/assets/images/dashboard/calendar-icon.svg'),-->
    <!--              clickEmitter: true,-->
    <!--            }"-->
    <!--          />-->
    <!--        </b-tooltip>-->
    <!--      </div>-->
    <!--      <div class="column is-one-fifth">-->
    <!--        <b-tooltip label="ForeCastolt hátralévő fejlesztési idő">-->
    <!--          <dashboard-card-->
    <!--            :image="'calendar-icon'"-->
    <!--            :project="{-->
    <!--              name: 'Hátralevő ForeCast',-->
    <!--              clientName: `${projectDetails.forecastRemainingMd} MWD`,-->
    <!--              clientAvatar: require('@/assets/images/dashboard/calendar-icon.svg'),-->
    <!--              clickEmitter: true,-->
    <!--            }"-->
    <!--          />-->
    <!--        </b-tooltip>-->
    <!--      </div>-->
    <!--      <div class="column is-one-fifth">-->
    <!--        <b-tooltip-->
    <!--          label="Fennmaradó munkanapok száma a határidőig: (Határidő - Mai Dátum)"-->
    <!--        >-->
    <!--          <dashboard-card-->
    <!--            :image="'calendar-icon'"-->
    <!--            :project="{-->
    <!--              name: 'Hátralevő naptári napok',-->
    <!--              clientName: `${projectDetails.remainingDays} nap`,-->
    <!--              clientAvatar: require('@/assets/images/dashboard/calendar-icon.svg'),-->
    <!--              clickEmitter: true,-->
    <!--            }"-->
    <!--          />-->
    <!--        </b-tooltip>-->
    <!--      </div>-->
    <!--    </div>-->
    <b-modal v-model="showSettingsModal" @close="showSettingsModal = false">
      <div class="card">
        <div class="card-header">
          <h3 class="title m-5">
            {{ $tf("projectPage.timeline.modal.title|Idővonal beállítása") }}
          </h3>
        </div>
        <div class="card-content m-4">
          <div class="mb-4">
            <b-field grouped>
              <b-field
                :label="
                  $tf(
                    'projectPage.timeline.modal.message|Idővonalon megjelenő mezők'
                  )
                "
              >
                <div class="mr-4">
                  <b-checkbox v-model="timelineSettings" native-value="today">
                    {{ $tf("projectPage.timeline.modal.today|Mai dátum") }}
                  </b-checkbox>
                </div>
                <div class="mr-4">
                  <b-checkbox v-model="timelineSettings" native-value="endDate">
                    {{
                      $tf("projectPage.timeline.modal.endDate|Lezárás dátuma")
                    }}
                  </b-checkbox>
                </div>
                <div class="mr-4">
                  <b-checkbox
                    v-model="timelineSettings"
                    native-value="fcTrendingEndDate"
                  >
                    {{
                      $tf(
                        "projectPage.timeline.modal.fcTrendingEndDate|Becsült befejezés (trendtartó)"
                      )
                    }}
                  </b-checkbox>
                </div>
                <div class="mr-4">
                  <b-checkbox
                    v-model="timelineSettings"
                    native-value="fcPlanningEndDate"
                  >
                    {{
                      $tf(
                        "projectPage.timeline.modal.fcPlanningEndDate|Becsült befejezés (tervtartó)"
                      )
                    }}
                  </b-checkbox>
                </div>
                <div class="mr-4">
                  <b-checkbox v-model="timelineSettings" native-value="dueDate">
                    {{ $tf("projectPage.timeline.modal.dueDate|Határidő") }}
                  </b-checkbox>
                </div>
              </b-field>
            </b-field>
          </div>
          <b-field>
            <b-button type="is-info" @click="saveTimelineSettings">
              {{ $tf("projectPage.timeline.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </div>
      </div>
    </b-modal>
    <div class="timeline-calendar">
      <!--      <div-->
      <!--        style="position: absolute; top: 1rem; cursor: pointer"-->
      <!--        @click="showSettingsModal = true"-->
      <!--      >-->
      <!--        <b-tooltip-->
      <!--          class="ml-0"-->
      <!--          :label="-->
      <!--            $tf('projectPage.timeline.openSettings.tooltip|Idővonal beállítása')-->
      <!--          "-->
      <!--          :auto-close="true"-->
      <!--        >-->
      <!--          <b-tag type="is-info" class="gear-tag">-->
      <!--            <b-icon pack="fas" icon="gear" size="is-small" />-->
      <!--          </b-tag>-->
      <!--        </b-tooltip>-->
      <!--      </div>-->
      <div class="timeline-line is-relative" :class="{ 'not-ready': !isReady }">
        <div class="timeline-line-holder" />
        <div class="timeline-line-start" :style="{ width: todayPercent }" />
        <div class="timeline-months is-flex">
          <div v-for="(day, index) in monthDays" :key="index" class="column">
            <span class="timeline-months-day">{{ day }}</span>
          </div>
        </div>
        <div
          v-if="!endDate && timelineSettings.includes('today')"
          class="timeline-line-today-text pop-out-on-hover"
          :style="{ left: todayPercent }"
        >
          <div class="is-absolute timeline-line-container">
            {{ $tf("projectPage.timeline.today|Mai dátum:") }}
            <span>{{ today }} ({{ todayPercentRounded }}%)</span>
          </div>
        </div>
        <div
          v-if="endDate && timelineSettings.includes('endDate')"
          class="timeline-line-today-text pop-out-on-hover"
          :style="{ left: endPercent }"
        >
          <div class="is-absolute timeline-line-container">
            {{ $tf("projectPage.timeline.endDate|Lezárás dátuma:") }}
            <span>{{ endDate }} ({{ endPercentRounded }}%)</span>
          </div>
        </div>
        <div
          v-if="
            projectDetails.forecastTrendingTime &&
            !endDate &&
            timelineSettings.includes('fcTrendingEndDate')
          "
          class="timeline-line-forecasted-end-date-text timeline-line-forecasted-end-date-text__trending pop-out-on-hover"
          :style="{ left: forecastedTrendingEndDayPercent }"
        >
          <div class="is-absolute timeline-line-container">
            {{
              $tf(
                "projectPage.timeline.fcTrendingEndDate|Becsült befejezés (trendtartó):"
              )
            }}
            <span
              :class="getTextClass(forecastedTrendingEndDate, 'commitment')"
              >{{ formatDate(forecastedTrendingEndDate) }}</span
            >
          </div>
        </div>
        <div
          v-if="
            projectDetails.forecastPlanningTime &&
            !endDate &&
            timelineSettings.includes('fcPlanningEndDate')
          "
          class="timeline-line-forecasted-end-date-text timeline-line-forecasted-end-date-text__planning pop-out-on-hover"
          :style="{ left: forecastedPlanningEndDayPercent }"
        >
          <div class="is-absolute timeline-line-container">
            {{
              $tf(
                "projectPage.timeline.fcPlanningEndDate|Becsült befejezés (tervtartó):"
              )
            }}
            <span
              :class="getTextClass(forecastedPlanningEndDate, 'commitment')"
              >{{ formatDate(forecastedPlanningEndDate) }}</span
            >
          </div>
        </div>
        <div
          v-if="timelineSettings.includes('dueDate')"
          class="timeline-line-end-text pop-out-on-hover"
          :style="{ left: dueDatePercent }"
        >
          <div class="is-absolute timeline-line-container">
            {{ $tf("projectPage.timeline.dueDate|Határidő:") }}
            <span :class="getTextClass(dueDate, 'commitment')">{{
              dueDate
            }}</span>
          </div>
        </div>
        <div
          class="timeline-line-end"
          :style="{
            left: dueDatePercent,
            width: `calc(100% - ${dueDatePercent} + 3%)`,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  correctWithTimezoneOffset,
  formatDate,
  formatDayWithMonthNameShort,
  secondToWorkDayFormat,
  localFetch,
  localStore,
} from "@/utils/util";
import { mapGetters } from "vuex";

export default {
  name: "ProjectTimeline",
  data() {
    return {
      today: formatDate(new Date()),
      endDate: this.data.endDate,
      dueDate: this.projectDetails.dueDate,
      formatDate,
      showSettingsModal: false,
      timelineSettings: localFetch("project_details_timeline_items") ?? [
        "today",
        "endDate",
        "fcTrendingEndDate",
        "fcPlanningEndDate",
        "dueDate",
      ],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isReady() {
      return this.today > this.data.startDate;
    },
    trueHighestDate() {
      const dueDate = new Date(this.data.dueDate);
      const endDate = new Date(this.data.endDate);
      const planningDueDate = this.endDate
        ? endDate
        : new Date(this.forecastedPlanningEndDate);
      const trendingDueDate = this.endDate
        ? endDate
        : new Date(this.forecastedTrendingEndDate);
      return new Date(
        Math.max(
          dueDate.valueOf(),
          trendingDueDate.valueOf(),
          planningDueDate.valueOf(),
          endDate.valueOf()
        )
      );
    },
    endPercent() {
      const startDate = new Date(this.data.startDate);
      const endDate = this.trueHighestDate;
      const today = new Date(this.data.endDate);
      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;

      if (today.getTime() < startDate.getTime()) {
        return "-2.5%";
      } else if (today.getTime() > endDate.getTime()) {
        return "103%";
      } else {
        return `${difference}%`;
      }
    },
    endPercentRounded() {
      return (
        Math.round(
          Number.parseFloat(
            this.endPercent.substring(0, this.endPercent.indexOf("%"))
          ) * 10
        ) / 10
      );
    },
    forecastedTrendingEndDate() {
      return new Date(this.data.startDate).addDaysSkippingWeekends(
        this.projectDetails.forecastTrendingTime
      );
    },
    forecastedPlanningEndDate() {
      return new Date(this.data.startDate).addDaysSkippingWeekends(
        this.projectDetails.forecastPlanningTime
      );
    },
    forecastedTrendingEndDayPercent() {
      const startDate = new Date(this.data.startDate);
      const endDate = this.trueHighestDate;
      const today = new Date(this.forecastedTrendingEndDate);

      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;

      if (today.getTime() < startDate.getTime()) {
        return "-2.5%";
      } else if (today.getTime() > endDate.getTime()) {
        return "103%";
      } else {
        return `${difference}%`;
      }
    },
    forecastedPlanningEndDayPercent() {
      const startDate = new Date(this.data.startDate);
      const endDate = this.trueHighestDate;
      const today = new Date(this.forecastedPlanningEndDate);

      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;

      if (today.getTime() < startDate.getTime()) {
        return "-2.5%";
      } else if (today.getTime() > endDate.getTime()) {
        return "103%";
      } else {
        return `${difference}%`;
      }
    },
    trueTodayPercent() {
      const startDate = new Date(this.data.startDate);
      const endDate = new Date(this.data.dueDate);
      const today = new Date();
      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;
      return `${difference}%`;
    },
    todayPercent() {
      const startDate = new Date(this.data.startDate);
      const endDate = this.trueHighestDate;
      const today = new Date();
      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;

      if (today.getTime() < startDate.getTime()) {
        return "-2.5%";
      } else if (today.getTime() > endDate.getTime()) {
        return "103%";
      } else {
        return `${difference}%`;
      }
    },
    dueDatePercent() {
      const startDate = new Date(this.data.startDate);
      const endDate = this.trueHighestDate;
      const today = new Date(this.data.dueDate);
      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;

      if (today.getTime() < startDate.getTime()) {
        return "-2.5%";
      } else if (today.getTime() > endDate.getTime()) {
        return "103%";
      } else {
        return `${difference}%`;
      }
    },
    todayPercentRounded() {
      return (
        Math.round(
          Number.parseFloat(
            this.trueTodayPercent.substring(
              0,
              this.trueTodayPercent.indexOf("%")
            )
          ) * 10
        ) / 10
      );
    },
    monthDays() {
      const startDate = new Date(this.data.startDate);
      const endDate = this.trueHighestDate;
      const monthDiff =
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear()) +
        1;

      const aDay = 24 * 60 * 60 * 1000;
      let ranges = [];
      ranges.push(startDate);

      for (let i = startDate.getTime(), end = endDate.getTime(); i <= end; ) {
        const first = correctWithTimezoneOffset(new Date(i));
        let last = correctWithTimezoneOffset(
          new Date(first.getFullYear(), first.getMonth() + 1, 0)
        );
        if (endDate.getTime() < last.getTime()) {
          last = endDate;
        }
        let middle;
        if (monthDiff < 5) {
          middle = correctWithTimezoneOffset(
            new Date((first.getTime() + last.getTime()) / 2)
          );
          if ((middle - first) / (1000 * 3600 * 24) > 8) {
            ranges.push(middle);
          }
          ranges.push(last);
        } else {
          ranges.push(last);
        }
        i = last.getTime() + aDay;
      }

      const timeLineDays = [];
      if (monthDiff > 12) {
        ranges.filter((e, i) => {
          if (i % 2 === 2 - 1) {
            timeLineDays.push(formatDayWithMonthNameShort(e));
          }
        });
      } else {
        ranges.forEach((item) => {
          timeLineDays.push(formatDayWithMonthNameShort(item));
        });
      }

      return timeLineDays;
    },
    daysLeft() {
      const dueDate = new Date(
        this.data.endDate ? this.data.endDate : this.data.dueDate
      );
      const now = new Date();
      return Math.ceil(
        (dueDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
      );
    },
    daysLeftPercentage() {
      const startDate = new Date(this.data.startDate);
      const dueDate = new Date(
        this.data.endDate ? this.data.endDate : this.data.dueDate
      );
      const now = new Date();
      const spentTime = now.getTime() - startDate.getTime();
      const allTime = dueDate.getTime() - startDate.getTime();
      return Math.round((spentTime / allTime) * 10000) / 100;
    },
  },
  methods: {
    getTextClass(value, type) {
      switch (type) {
        case "log":
          return value > 0
            ? value > 28800
              ? "is-danger"
              : "is-warning"
            : "is-success";
        case "commitment":
          const dueDate = new Date(this.data.dueDate);
          const valDate = new Date(value);
          return valDate.getTime() > dueDate.getTime()
            ? "is-danger"
            : "is-success";
        case "estimation":
          return value > 0 ? "is-danger" : "is-success";
      }
    },
    getAllLogsTime(time) {
      return secondToWorkDayFormat(time ? time : this.totalLogs);
    },
    saveTimelineSettings() {
      localStore("project_details_timeline_items", this.timelineSettings);
      this.showSettingsModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
.timeline {
  h5 {
    color: $custom-dark-blue;
    font-size: 1rem;
  }
  &-times {
    max-width: 460px;
  }
  &-dates {
    max-width: 340px;
    padding-top: 2.875rem;
    .column {
      &:first-child {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 1.25rem;
          bottom: calc(0.313rem + 1rem);
          border-right: 1px solid $grey-lightest;
        }
      }
    }
  }
  &-date {
    padding: 0.625rem;
    max-width: 160px;
    margin: 0 auto;

    strong {
      color: $custom-dark-blue;
    }
    span {
      color: $custom-grayer-blue;
    }
  }
  &-line {
    margin: 6rem 10% 0 0;
    font-size: 0.875rem;
    &.not-ready {
      margin-left: 5%;
      .timeline-line-start {
        border-radius: 0.5rem 0 0 0.5rem;
        background: $grey-lightest;
        opacity: 0.7;
        left: -6%;
      }
    }
    &-container {
      filter: drop-shadow(0.25rem 0.25rem 0.5rem $grey);
      border-radius: 20px;
      padding: 4px 8px 4px 8px;
      box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
      background: $white;
    }
    &-holder {
      height: 1rem;
      background-color: $grey-lightest;
    }
    &-start {
      background: linear-gradient(270deg, #7b61ff 14.55%, #0aa6fe 75.13%);
      border-radius: 0 0.5rem 0.5rem 0;
      height: 1rem;
      position: absolute;
      left: 0;
      top: 0;
    }
    &-end {
      background: repeating-linear-gradient(
        -45deg,
        $red,
        $red 6px,
        $white 6px,
        $white 12px
      );
      border-radius: 0 0.5rem 0.5rem 0;
      height: 1rem;
      position: absolute;
      top: 0;
    }
    &-end-text,
    &-today-text {
      background-color: $custom-light-blue;
      width: 2px;
      height: 2.5rem;
      margin-top: 0.813rem;
      position: absolute;
      top: -1.5rem;
      color: $custom-grayer-blue;
      span {
        color: $custom-light-blue;
        font-weight: 500;
        &.is-danger {
          color: $red;
        }
      }
    }
    &-today-text {
      top: -2.6rem;
      height: 3.6rem;
      z-index: 10;
    }
    &-forecasted-end-date-text {
      background-color: $custom-light-blue;
      width: 2px;
      margin-top: 0.813rem;
      position: absolute;
      z-index: 10;
      color: $custom-grayer-blue;
      &__trending {
        top: -0.8rem;
        height: 1.4rem;
      }
      &__planning {
        top: -4rem;
        height: 5rem;
      }
      span {
        color: $custom-light-blue;
        font-weight: 500;
        &.is-success {
          color: $green;
        }
        &.is-danger {
          color: $red;
        }
      }
    }
    &-end-text {
      left: 100%;
      margin-left: 0;
    }
    .is-absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      top: -1.5rem;
    }
  }
  &-months {
    font-size: 0.875rem;
    font-weight: 500;
    &-day {
      margin-left: -20px;
    }
    .column {
      position: relative;
      padding-top: 1.563rem;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: rgba($custom-grayer-blue, 0.5);
        width: 2px;
        height: 0.813rem;
        left: 0;
      }
      &:first-child {
      }
      &:last-child {
        position: absolute;
        left: 100%;
        white-space: nowrap;
      }
    }
  }
}
.is-danger {
  color: $red !important;
}
.is-success {
  color: $green !important;
}

.start-date-container {
  margin-left: 4rem;
}

.timeline-line-start-text {
  position: absolute;
  border-left: $primary 2px solid;
  height: 52px;
  bottom: 72px;
  font-size: 0.875rem;
}

.start-date-container-date-text {
  color: $primary;
  font-weight: 500;
}

.pop-out-on-hover {
  transition: all 0.2s ease-in-out;
  z-index: 9;
  &:hover {
    transform: scale(1.2);
    z-index: 10;
  }
}
</style>
<style lang="scss">
.gear-tag {
  span:first-child {
    height: 100%;
  }
}
</style>
