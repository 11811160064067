<template>
  <div>
    <table aria-label="since-start" class="whole-table">
      <thead>
        <tr>
          <th></th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.mwd|Munkaidő") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{
              $tf(
                "projectPage.forecastWholeStatus.table.budgetSpent|Büdzsé/Költés"
              )
            }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.time|Átfutási idő") }}
          </th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.dueDate|Határidő") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.income|Bevétel") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.profit|Profit") }}
          </th>
          <th>
            {{
              $tf(
                "projectPage.forecastWholeStatus.table.profitPercentage|Profit%"
              )
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="plan-row">
          <td class="has-font-weight-500">
            <b-tooltip
              position="is-right"
              :label="
                $tf(
                  'projectPage.forecastWholeStatus.table.plan.tooltip|A projekt létrehozásakor megadott paraméterek a projektre vonatkozóan.'
                )
              "
              >{{
                $tf("projectPage.forecastWholeStatus.table.plan|Terv")
              }}</b-tooltip
            >
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(commitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(budget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(time) }} nap</td>
          <td>
            {{ formatDate(dueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(income) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(profit) }}</td>
          <td>
            {{ validPercentage(profitPercent) }}
          </td>
        </tr>
        <tr class="blank-row-big">
          <td colspan="8"></td>
        </tr>
        <tr class="forecast-trend-row">
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.trendingForecast.tooltip|A projekt trendtartó előrejelzése szerint várható adatok. A trendtartó előrejelzés a már rögzített adatok alapjánszámolt trend alapján jelzi az adatokat."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.trendingForecast|Trendtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(trendingCommitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(trendingBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(trendingTime) }} nap</td>
          <td>
            {{ formatDate(trendingDueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(trendingIncome) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(trendingProfit) }}</td>
          <td>
            {{ validPercentage(trendingProfitPercent) }}
          </td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="8"></td>
        </tr>
        <tr>
          <td
            class="is-rounded bg-lightgray is-vertical-align-middle has-font-weight-500"
          >
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.planVsFc.tooltip|A projekt létrehozásakor megadott terv adatok, és a trendtartó előrejelzés közötti különbség. Amennyiben az adatok megfelelőek a felület zölddel jelzi. Nem optimális adatok esetén az érték pirossal kerül megjelölésre."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf("projectPage.forecastWholeStatus.table.planVsFc|Terv vs FC")
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentTrendingMd > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentTrendingMd > 0 ? 'circle-exclamation' : 'thumbs-up'
                "
                :icon-pack="diffPercentTrendingMd > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ workdayify(diffTrendingMd) }} ({{
              validPercentage(diffPercentTrendingMd)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentTrendingBudget > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentTrendingBudget > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentTrendingBudget > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffTrendingBudget) }} ({{
              validPercentage(diffPercentTrendingBudget)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentTrendingTime > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentTrendingTime > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentTrendingTime > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffTrendingTime) }} nap ({{
              validPercentage(diffPercentTrendingTime)
            }})
          </td>
          <td></td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentTrendingIncome < 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentTrendingIncome < 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentTrendingIncome < 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffTrendingIncome) }} ({{
              validPercentage(diffPercentTrendingIncome)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentTrendingProfit < 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentTrendingProfit < 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentTrendingProfit < 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffTrendingProfit) }} ({{
              validPercentage(diffPercentTrendingProfit)
            }})
          </td>
          <td>
            {{ validPercentage(diffTrendingProfitPercent) }}
          </td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="8"></td>
        </tr>
        <tr>
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.projectRiskKPI.tooltip|Az előrjelzés és a terv közötti arányszám. Amennnyiben az érték 1.00 alatt van, a projekt az elvártnál jobban teljesít, 1.00 érték felett pedig rosszabbul. Az optimális érték 1.00, illetve az alatti szám."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.projectRiskKPIWithLimits|Projekt Risk KPI"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskTrendingMd > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskTrendingMd > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskTrendingMd)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskTrendingMd) }}
            </b-tag>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="
                riskTrendingBudget > 1 ? 'circle-exclamation' : 'thumbs-up'
              "
              :icon-pack="riskTrendingBudget > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskTrendingBudget)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskTrendingBudget) }}
            </b-tag>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskTrendingTime > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskTrendingTime > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskTrendingTime)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskTrendingTime) }}
            </b-tag>
          </td>
        </tr>
        <tr class="blank-row-big">
          <td colspan="8"></td>
        </tr>
        <tr class="blank-row-big">
          <td colspan="8"></td>
        </tr>
        <tr class="forecast-plan-row">
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.planningForecast.tooltip|A projekt tervtartó előrejelzése szerint várható adatok. A tervtartó előrejelzés a már eltelt idő és az idő alatt rögzített ráfordítás alapján számolt trend alapján jelzi az adatokat."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.planningForecast|Tervtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(planningCommitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(planningBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(planningTime) }} nap</td>
          <td>
            {{ formatDate(planningDueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(planningIncome) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(planningProfit) }}</td>
          <td>
            {{ validPercentage(planningProfitPercent) }}
          </td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="8"></td>
        </tr>
        <tr>
          <td
            class="is-rounded bg-lightgray is-vertical-align-middle has-font-weight-500"
          >
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.planVsFc.tooltip|A projekt létrehozásakor megadott terv adatok, és a tervtartó előrejelzés közötti különbség. Amennyiben az adatok megfelelőek a felület zölddel jelzi. Nem optimális adatok esetén az érték pirossal kerül megjelölésre."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf("projectPage.forecastWholeStatus.table.planVsFc|Terv vs FC")
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentPlanningMd > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentPlanningMd > 0 ? 'circle-exclamation' : 'thumbs-up'
                "
                :icon-pack="diffPercentPlanningMd > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ workdayify(diffPlanningMd) }} ({{
              validPercentage(diffPercentPlanningMd)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentPlanningBudget > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentPlanningBudget > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentPlanningBudget > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffPlanningBudget) }} ({{
              validPercentage(diffPercentPlanningBudget)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentPlanningTime > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentPlanningTime > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentPlanningTime > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffPlanningTime) }} nap ({{
              validPercentage(diffPercentPlanningTime)
            }})
          </td>
          <td></td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentPlanningIncome < 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentPlanningIncome < 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentPlanningIncome < 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffPlanningIncome) }} ({{
              validPercentage(diffPercentPlanningIncome)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentPlanningProfit < 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentPlanningProfit < 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentPlanningProfit < 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffPlanningProfit) }} ({{
              validPercentage(diffPercentPlanningProfit)
            }})
          </td>
          <td>{{ validPercentage(diffPlanningProfitPercent) }}</td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="8"></td>
        </tr>
        <tr>
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.projectRiskKPI.tooltip|Az előrjelzés és a terv közötti arányszám. Amennnyiben az érték 1.00 alatt van, a projekt az elvártnál jobban teljesít, 1.00 érték felett pedig rosszabbul. Az optimális érték 1.00, illetve az alatti szám."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.projectRiskKPI|Projekt risk KPI"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskPlanningMd > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskPlanningMd > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskPlanningMd)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskPlanningMd) }}
            </b-tag>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="
                riskPlanningBudget > 1 ? 'circle-exclamation' : 'thumbs-up'
              "
              :icon-pack="riskPlanningBudget > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskPlanningBudget)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskPlanningBudget) }}
            </b-tag>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskPlanningTime > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskPlanningTime > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskPlanningTime)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskPlanningTime) }}
            </b-tag>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  formatDate,
  moneyify,
  numberify,
  percentify,
  roundToTwoDecimals,
  workdayify,
  getCurrentColorSet,
} from "@/utils/util";
import StatusMixin from "../../../../../mixins/StatusMixin";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import { mapGetters } from "vuex";

export default {
  name: "ForecastWholeTable",
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      riskKpiLimits: "uiConfigStore/getRiskKpiLimits",
    }),
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },
    currentColors() {
      return getCurrentColorSet();
    },
    commitment() {
      return this.projectDetails.commitment;
    },
    trendingCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingMd;
    },
    diffTrendingMd() {
      if (this.notTracked) return "-";
      return this.trendingCommitment - this.commitment;
    },
    diffPercentTrendingMd() {
      if (this.notTracked) return "-";
      return this.diffTrendingMd / this.commitment;
    },
    riskTrendingMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingCommitment / this.commitment);
    },
    planningCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningMd;
    },
    diffPlanningMd() {
      if (this.notTracked) return "-";
      return this.planningCommitment - this.commitment;
    },
    diffPercentPlanningMd() {
      if (this.notTracked) return "-";
      return this.diffPlanningMd / this.commitment;
    },
    riskPlanningMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningCommitment / this.commitment);
    },

    budget() {
      return this.projectDetails.budget;
    },
    trendingBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingBudget;
    },
    diffTrendingBudget() {
      if (this.notTracked) return "-";
      return this.trendingBudget - this.budget;
    },
    diffPercentTrendingBudget() {
      if (this.notTracked) return "-";
      return this.diffTrendingBudget / this.budget;
    },
    riskTrendingBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingBudget / this.budget);
    },
    planningBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningBudget;
    },
    diffPlanningBudget() {
      if (this.notTracked) return "-";
      return this.planningBudget - this.budget;
    },
    diffPercentPlanningBudget() {
      if (this.notTracked) return "-";
      return this.diffPlanningBudget / this.budget;
    },
    riskPlanningBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningBudget / this.budget);
    },

    time() {
      return this.projectDetails.daysBetweenDue;
    },
    trendingTime() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingTime;
    },
    diffTrendingTime() {
      if (this.notTracked) return "-";
      return this.trendingTime - this.time;
    },
    diffPercentTrendingTime() {
      if (this.notTracked) return "-";
      return this.diffTrendingTime / this.time;
    },
    riskTrendingTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingTime / this.time);
    },
    planningTime() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningTime;
    },
    diffPlanningTime() {
      if (this.notTracked) return "-";
      return this.planningTime - this.time;
    },
    diffPercentPlanningTime() {
      if (this.notTracked) return "-";
      return this.diffPlanningTime / this.time;
    },
    riskPlanningTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningTime / this.time);
    },

    dueDate() {
      return new Date(this.projectDetails.dueDate);
    },
    trendingDueDate() {
      if (this.notTracked) return "-";
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.trendingTime
      );
    },
    planningDueDate() {
      if (this.notTracked) return "-";
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.planningTime
      );
    },

    income() {
      return this.projectDetails.expectedIncome;
    },
    trendingIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffTrendingIncome() {
      if (this.notTracked) return "-";
      return this.trendingIncome - this.income;
    },
    diffPercentTrendingIncome() {
      if (this.notTracked) return "-";
      return this.diffTrendingIncome / this.income;
    },
    planningIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffPlanningIncome() {
      if (this.notTracked) return "-";
      return this.planningIncome - this.income;
    },
    diffPercentPlanningIncome() {
      if (this.notTracked) return "-";
      return this.diffPlanningIncome / this.income;
    },

    profit() {
      return this.income - this.budget;
    },
    trendingProfit() {
      if (this.notTracked) return "-";
      return this.trendingIncome - this.trendingBudget;
    },
    diffTrendingProfit() {
      if (this.notTracked) return "-";
      return this.trendingProfit - this.profit;
    },
    diffPercentTrendingProfit() {
      if (this.notTracked) return "-";
      return this.diffTrendingProfit / this.profit;
    },
    planningProfit() {
      if (this.notTracked) return "-";
      return this.planningIncome - this.planningBudget;
    },
    diffPlanningProfit() {
      if (this.notTracked) return "-";
      return this.planningProfit - this.profit;
    },
    diffPercentPlanningProfit() {
      if (this.notTracked) return "-";
      return this.diffPlanningProfit / this.profit;
    },

    profitPercent() {
      return this.profit / this.income;
    },
    trendingProfitPercent() {
      if (this.notTracked) return "-";
      return this.trendingProfit / this.trendingIncome;
    },
    diffTrendingProfitPercent() {
      if (this.notTracked) return "-";
      return this.trendingProfitPercent - this.profitPercent;
    },
    planningProfitPercent() {
      if (this.notTracked) return "-";
      return this.planningProfit / this.planningIncome;
    },
    diffPlanningProfitPercent() {
      if (this.notTracked) return "-";
      return this.planningProfitPercent - this.profitPercent;
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: {
    getRiskColor(value) {
      if (value > 10) return "is-fatal";
      if (value > roundToTwoDecimals(1 + this.riskKpiLimits[1]))
        return { "is-danger": true };
      if (value > roundToTwoDecimals(1 - this.riskKpiLimits[0]))
        return { "is-warning": true };
      return "is-success";
    },
    validPercentage(val) {
      if (isFinite(val)) {
        return percentify(val);
      }
      return "-";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.is-rounded {
  border-radius: 8px;
}

.bg-lightgray {
  background: $grey-lighter;
}

.blank-row-big {
  height: 24px !important;
}

.blank-row-big > td {
  padding: 0;
}

.blank-row-small {
  height: 6px !important;
  line-height: 0;
}

.blank-row-small > td {
  padding: 0;
}

.whole-table th,
.whole-table td {
  border: none !important;
  color: $custom-dark-blue;
}

.plan-row > td {
  background: $forecast-primary;
  color: $white !important;
}

.forecast-trend-row > td {
  background: $forecast-secondary;
  color: $white !important;
}

.forecast-plan-row > td {
  background: $forecast-tertiary;
  color: $white;
}

.plan-row > td:first-child,
.forecast-trend-row > td:first-child,
.forecast-plan-row > td:first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.plan-row > td:last-child,
.forecast-trend-row > td:last-child,
.forecast-plan-row > td:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.rpi-tag {
  width: 130px;
  font-weight: 500;
}

.icon-cell {
  width: 28px;
  padding: 0 !important;
  vertical-align: middle;
}

.diff-icon {
  background: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  margin: 2px;
  height: 2rem;
}
</style>
