<template>
  <div>
    <apex-chart width="250" :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { roundToTwoDecimals } from "@/utils/util";

const maxValue = 200;

export default {
  name: "ForecastCombinedChartRadial",
  props: {
    readiness: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      riskKpiLimits: "uiConfigStore/getRiskKpiLimits",
    }),
    valueColor() {
      const diff = this.value / this.readiness;
      if (diff > 100) {
        return "var(--black)";
      }
      if (
        this.value > roundToTwoDecimals(this.readiness + this.riskKpiLimits[1])
      ) {
        return "var(--red)";
      }
      if (
        this.value > roundToTwoDecimals(this.readiness - this.riskKpiLimits[0])
      ) {
        return "var(--yellow)";
      }
      return "var(--green)";
    },
    chartOptions() {
      const value = this.value;
      const readiness = this.readiness;
      return {
        chart: {
          type: "radialBar",
        },
        colors: [
          ...Array(Math.ceil(Math.min(this.value, maxValue) / 100)).fill(
            this.valueColor
          ),
          "var(--info)",
        ],
        labels: [
          ...Array(Math.ceil(Math.min(this.value, maxValue) / 100)).fill(
            this.title
          ),
          "Készültség",
        ],
        plotOptions: {
          radialBar: {
            track: {
              background: "var(--grey-light)",
            },
            hollow: {
              size: "50%",
            },
            dataLabels: {
              name: {
                fontWeight: "700",
                fontFamily: "Roboto",
                fontColor: "darkGrey",
                offsetY: 20,
                fontSize: "10px",
                color: "var(--grey)",
              },
              value: {
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Roboto",
                offsetY: -20,
                offsetX: 8,
                color: "var(--text)",
                formatter: function (val) {
                  return (
                    Math.round(
                      (parseFloat(val) === readiness ? readiness : value) * 10
                    ) /
                      10 +
                    "%"
                  );
                },
              },
              total: {
                show: true,
                label: this.title,
                color: "var(--grey)",
                fontSize: "12px",
                formatter: function () {
                  return Math.round(value * 10) / 10 + "%";
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
      };
    },
    chartSeries() {
      let valueSeries = [];
      let valueSerie = Math.min(this.value, maxValue);
      while (valueSerie > 0) {
        let valueToPush = valueSerie % 100 === 0 ? 100 : valueSerie % 100;
        valueSeries.push(valueToPush);
        valueSerie -= valueToPush;
      }
      return [...valueSeries, this.readiness];
    },
  },
};
</script>

<style scoped lang="scss"></style>
