import axios from "axios";
import { newLogger } from "./util";
import LoadingMixin from "@/mixins/LoadingMixin";

let logger = newLogger("Network");

let AUTH_HEADER = "X-Auth-Token";

let delay = (t) => {
  return new Promise((resolve) => {
    setTimeout(resolve, t);
  });
};

class Network {
  constructor() {
    this.router = null;
    this.store = null;
    this.http = null;
    this.excelNetwork = null;
    this.pdfNetwork = null;
    this.buefy = null;
  }

  /**
   * Called from app.vue on creation, sets the store reference and creates the axios instance
   */
  configure(store, router, buefy) {
    this.router = router;
    this.store = store;
    this.http = this.createAxiosInstance();
    this.excelNetwork = this.createExcelExportNetwork();
    this.pdfNetwork = this.createPdfExportNetwork();
    this.buefy = buefy;
  }

  getJwt() {
    return this.store.getters["session/jwt"];
  }

  createUnauthorizedAxiosInstance() {
    return axios.create({
      baseURL: "/api",
      timeout: 12000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // responseType: 'json',
    });
  }

  createAxiosInstance() {
    const http = axios.create({
      baseURL: "/api",
      timeout: 12000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // responseType: 'json',
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (!jwt || jwt === "") {
          throw "No JWT to use!";
        }
        config.headers[AUTH_HEADER] = jwt;
        // logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          // logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error!");
        logger.warn(error);
        if (!error.response) {
          logger.warn("No error.response in response, rejecting.");
          return Promise.reject(error);
        }
        if (error.response.status === 401) {
          // unauthorized
          // this.buefy.toast.open({
          //   duration: 5000,
          //   message: `<strong>(401)</strong> - Kérjük lépj be újra!`,
          //   position: "is-top",
          //   type: "is-danger",
          // });
          this.store.dispatch("session/boot");
          if (
            this.router.currentRoute.name !== "SignIn" &&
            this.router.currentRoute.name !== "SignInToTenant"
          )
            this.router.push({
              path: "/login",
              query: { returnUrl: this.router.currentRoute.fullPath },
            });
          LoadingMixin.methods.doFinishLoading().then(() => {});
        }
        throw error;
      }
    );
    return http;
  }

  createExcelExportNetwork() {
    const http = axios.create({
      baseURL: "/api",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (!jwt || jwt === "") {
          throw "No JWT to use!";
        }
        config.headers[AUTH_HEADER] = jwt;
        logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error response.");
        logger.warn(error);
        if (error.response.status === 401) {
          this.store.dispatch("session/boot");
          this.router.push("/login");
          LoadingMixin.methods.doFinishLoading().then(() => {});
        }
        if (error.response.status === 403) {
          this.router.push("/");
        }
        if (error.response.status === 503) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<b>(503)</b> - A kérést kiszolgáló modul jelenleg nem elérhető, próbáld később`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        return Promise.reject(error);
      }
    );
    return http;
  }

  createPdfExportNetwork() {
    const http = axios.create({
      baseURL: "/api",
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (!jwt || jwt === "") {
          throw "No JWT to use!";
        }
        config.headers[AUTH_HEADER] = jwt;
        logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error response.");
        logger.warn(error);
        if (error.response.status === 401) {
          this.store.dispatch("session/boot");
          this.store.dispatch("session/boot");
          this.router.push("/login");
          LoadingMixin.methods.doFinishLoading().then(() => {});
        }
        if (error.response.status === 403) {
          this.router.push("/");
        }
        if (error.response.status === 503) {
          this.buefy.toast.open({
            duration: 5000,
            message: `<b>(503)</b> - A kérést kiszolgáló modul jelenleg nem elérhető, próbáld később`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        return Promise.reject(error);
      }
    );
    return http;
  }

  async connection() {
    if (this.store.getters["session/isReady"]) return this.http;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.http;
  }

  async excelConnection() {
    if (this.store.getters["session/isReady"]) return this.excelNetwork;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.excelNetwork;
  }

  async pdfConnection() {
    if (this.store.getters["session/isReady"]) return this.pdfNetwork;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.pdfNetwork;
  }
}

// singleton
export default new Network();
