<template>
  <section class="section kontrolling">
    <div class="container" v-if="!loading">
      <h1 class="title">Új Projekt létrehozása</h1>
      <project-form :project-data="projectData"></project-form>
    </div>
    <LoadingSkeleton v-else />
  </section>
</template>

<script>
import ProjectForm from "@/components/enterprise/project/form/ProjectForm";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton";

export default {
  name: "Enterprise-Create",
  data() {
    return {
      loading: false,
      projectData: {
        lead: this.$store.getters["session/name"],
      },
    };
  },
  components: { LoadingSkeleton, ProjectForm },
  watch: {},
  computed: {},
  methods: {},
  created: async function () {},
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("employee/fetchWorkers");
    await this.$store.dispatch("enterprise_clients/fetchClients");
    await this.$store.dispatch("enterprise_projects/fetchKeys");
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.penzugy .progress-wrapper {
  margin-top: 5px;
  margin-bottom: 3px;
}

.button.is-money {
  background-color: $yellow;
  border-color: transparent;
  color: $white;
}
.button.is-percent {
  background-color: $grey-light;
  border-color: transparent;
  color: $grey-dark;
}
.button.is-time {
  background-color: $grey-light;
  border-color: transparent;
  color: $white;
}
.button.is-db {
  background-color: $grey-dark;
  border-color: transparent;
  color: $white;
}

.section.kontrolling {
  background: $grey-lightest;
}

.section.kontrolling hr {
  border: 1px inset;
  background: none;
  margin: 1rem 0;
}

.section.kontrolling .tab-content {
  background: $white;
}

.bunch {
  background: $white;
  padding: 12px 15px 8px 15px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.bunch .input,
.bunch select,
.bunch .textarea,
.bunch .taginput-container {
  background: $white !important;
}

.bunch [disabled].input {
  border-color: $grey-lighter !important;
  background: $grey-lightest !important;
}

.image-addon img {
  margin-top: 4px;
  margin-left: 4px;
}

.content.tooltip {
  width: 400px;
}
.content.tooltip p,
.content.tooltip li {
  word-break: normal;
  white-space: normal;
}

.employee-details .media {
  background: $white;
  padding: 10px;
  border-radius: 8px;
}
</style>
