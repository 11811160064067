import StoreCore from "../../StoreCore";

const urls = {
  enterpriseData: "/tenant/admin-config/enterprise",
  issueTrackingData: "/tenant/admin-config/issue-tracking",
  currencies: "tenant/admin-config/currencies",
};

const initState = () => {
  return {
    enterpriseData: null,
    issueTrackingData: null,
    currencies: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchEnterpriseData: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.enterpriseData,
        null,
        true,
        dispatch,
        "Enterprise beállítások betöltése...",
        null,
        force
      );
      commit("saveEnterpriseData", data);
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  fetchIssueTrackingData: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.issueTrackingData,
        null,
        true,
        dispatch,
        "Taskkezelő beállítások betöltése...",
        null,
        force
      );
      commit("saveIssueTrackingData", data);
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  fetchCurrencies: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.currencies,
        null,
        true,
        dispatch,
        "Pénznemek betöltése...",
        null,
        force
      );
      commit("saveCurrencies", data);
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  saveEnterpriseData: async function ({ commit, dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.enterpriseData,
        request,
        false,
        dispatch,
        "Enterprise beállítások mentése..."
      );
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  saveIssueTrackingData: async function ({ commit, dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.issueTrackingData,
        request,
        false,
        dispatch,
        "Tasskezelő beállítások mentése..."
      );
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
  sendPasswords: async function ({ dispatch }) {
    try {
      await this.remoteRequest(
        "post",
        "/bulwark/user/sudo-passwords",
        // "/census/flip",
        null,
        false,
        dispatch,
        "Jelszavak kiküldése..."
      );
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
};

const mutations = {
  saveEnterpriseData: (state, data) => {
    state.enterpriseData = data;
  },
  saveIssueTrackingData: (state, data) => {
    state.issueTrackingData = data;
  },
  saveCurrencies: (state, data) => {
    state.currencies = data;
  },
};

const getters = {
  getEnterpriseData: (state) => {
    return state.enterpriseData;
  },
  getIssueTrackingData: (state) => {
    return state.issueTrackingData;
  },
  getCurrencies: (state) => {
    return state.currencies;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
