<template>
  <main class="is-flex is-align-items-center">
    <div v-if="image" class="image">
      <img :class="{ 'is-rounded': imageRounded }" :src="image" alt="" />
    </div>
    <div
      v-else-if="imagePlaceholder"
      class="media is-flex is-align-items-center"
      style="width: 32px"
    >
      <div class="media-left">
        <p class="image is-32x32">
          <img class="is-rounded" :src="imagePlaceholder" alt="Kép" />
        </p>
      </div>
    </div>
    <div
      class="is-flex is-flex-direction-column is-justify-content-space-between"
    >
      <div>
        <p class="header" :class="size">{{ header }}</p>
      </div>
      <div>
        <p class="text" :class="size">{{ text }}</p>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "LabelBox",
  props: {
    image: {
      type: String,
      required: false,
    },
    imagePlaceholder: {
      type: String,
      required: false,
    },
    imageRounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    header: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: "is-large",
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
main {
  height: 40px;
  gap: 10px;
}
.header {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: "liga" off;
  color: $custom-grayer-blue;
}
.header.is-large {
  font-size: 16px;
  line-height: 21px;
}
.text {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "liga" off;
  color: $custom-dark-blue;
  vertical-align: bottom;
}
.text.is-large {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.image {
  width: 40px;
}
</style>
