import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import axios from "axios";
import app from "@/main.js";
import network from "@/utils/network";

let logger = newLogger("Census-User");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  users: "/census/users",
  timesheetUsers: "/census/timesheet/users",
  profile: "census/profile",
  me: "census/me",
  selectedForecastYear: (year) => `/census/me/forecast/${year}`,
  avatar: "/byte-barn/document",
  setAvatar: (userId) => `/bulwark/profile/${userId}/avatar`,
  setOwnAvatar: `/bulwark/profile/me/avatar`,
  import: `census/users/import`,
  export: `census/users/export`,
};

const initState = () => {
  return {
    teams: [],
    team: {},
    users: {},
    profile: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchProfile: async function ({ commit, dispatch }, { userId, force } = {}) {
    const url = userId ? urls.users + "/" + userId : urls.me;

    try {
      let data = await this.remoteRequest(
        "get",
        url,
        null,
        true,
        dispatch,
        "Profil betöltése...",
        null,
        force
      );
      commit("saveProfile", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  importPeople: async function ({ commit, dispatch }) {
    const url = urls.users + "/import";

    try {
      let data = await this.remoteRequest(
        "get",
        url,
        null,
        false,
        dispatch,
        "Profil betöltése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveProfile: async function (
    { dispatch },
    { profile, own = false, avatarForm }
  ) {
    const url = own ? urls.profile : urls.users + "/" + profile.id;
    const hasAvatar = profile.avatar;
    delete profile.avatar;

    try {
      let response = await this.remoteRequest(
        "put",
        url,
        profile,
        false,
        dispatch,
        "user.saveProfile.flair|Profil mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "user.saveProfile.progress|Profil mentése folyamatban...",
          fail: "user.saveProfile.fail|Profil mentése sikertelen!",
          success: "user.saveProfile.success|Profil mentése sikeres!",
        }
      );
      if (hasAvatar) {
        avatarForm.append("identifier", response.id);
        avatarForm.append("displayName", `USER_AVATAR_${response.id}`);
        const pathToImage = await this.remoteRequest(
          "post",
          `${urls.avatar}`,
          avatarForm,
          false,
          dispatch,
          "Avatar feltöltése..."
        );

        if (pathToImage) {
          dispatch("setAvatar", {
            userId: own ? undefined : response.id,
            pathToImage,
          });
        }
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  changePassword: async function ({ dispatch }, passwordChangeRequest) {
    try {
      await this.remoteRequest(
        "post",
        urls.users + "/password-change",
        passwordChangeRequest,
        false,
        dispatch,
        "user.changePassword.flair|Jelszóváltoztatás",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "user.changePassword.progress|Jelszó megváltoztatása folyamatban...",
          fail: "user.changePassword.fail|Jelszó megváltoztatása sikertelen!",
          success:
            "user.changePassword.success|Jelszó megváltoztatása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        if (
          err.response.data ===
          "Your new password cannot match your old password!"
        ) {
          app.config.globalProperties.$buefy.toast.open({
            duration: 5000,
            message: `Az új jelszavad nem egyezhet régi jelszóval!`,
            position: "is-bottom",
            type: "is-danger",
          });
        }
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  resetPassword: async function ({ dispatch }) {
    try {
      await this.remoteRequest(
        "post",
        urls.users + "/reset-password",
        null,
        false,
        dispatch,
        "user.resetPassword.flair|Jelszóbeállítás",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "user.resetPassword.progress|Jelszó beállítása folyamatban...",
          fail: "user.resetPassword.fail|Jelszó beállítása sikertelen!",
          success: "user.resetPassword.success|Jelszó beállítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  resetAdminPassword: async function ({ dispatch }, userId) {
    try {
      await this.remoteRequest(
        "post",
        urls.users + "/" + userId + "/reset-password",
        null,
        false,
        dispatch,
        "user.resetAdminPassword.flair|Jelszó-helyreállítás",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "user.resetAdminPassword.progress|Jelszó helyreállítása folyamatban...",
          fail: "user.resetAdminPassword.fail|Jelszó helyreállítása sikertelen!",
          success:
            "user.resetAdminPassword.success|Jelszó helyreállítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  setPassword: async function ({ dispatch }, passwordRequest) {
    try {
      let response = await axios.post(
        "/api/bulwark/auth/activate",
        passwordRequest.req,
        {
          headers: {
            "X-Tenant-Id": passwordRequest.tenant,
          },
        }
      );
      let token = response.headers["x-auth-token"];
      if (token) return token;
    } catch (e) {
      logger.warn("Error on password reset");
      logger.warn(e);
      throw e;
    }
  }.bind(storeCore),
  updateSelectedForecastYear: async function ({ dispatch }, year) {
    try {
      await this.remoteRequest(
        "put",
        urls.selectedForecastYear(year),
        undefined,
        false,
        dispatch,
        "user.setForecastYear.flair|Választott év frissítése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "user.setForecastYear.progress|Választott év beállítása folyamatban...",
          fail: "user.setForecastYear.fail|Választott év beállítása sikertelen!",
          success:
            "user.setForecastYear.success|Választott év beállítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  setAvatar: async function ({ dispatch }, { userId, pathToImage } = {}) {
    try {
      await this.remoteRequest(
        "put",
        userId ? urls.setAvatar(userId) : urls.setOwnAvatar,
        pathToImage,
        false,
        dispatch,
        "Avatar feltöltése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  importUsers: async function ({ dispatch }, fileForm) {
    try {
      return await this.remoteRequest(
        "post",
        urls.import,
        fileForm,
        false,
        dispatch,
        "Felhasználók importálása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  exportUsers: async function ({ dispatch }, request) {
    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "GET",
        url: urls.export,
        flair: "Felhasználók exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(urls.export, request);

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProfile: (state, profile) => {
    state.profile = profile;
  },
};

const getters = {
  profile: (state) => {
    return state.profile;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
