import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("QuotaStore");

const urls = {
  quotas: "/absence/absence-quota",
};

const initState = () => {
  return {
    quotas: null,
    remainings: null,
    actYearQuotas: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  getRemainings: async function ({ commit, dispatch }, { year, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.quotas + "/remaining-all/year/" + year,
        null,
        true,
        dispatch,
        "Fetching remaining days...",
        null,
        force
      );
      commit("setRemainings", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getRemainingForUsers: async function ({ commit, dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.quotas + "/remaining-all/users",
        params,
        true,
        dispatch,
        "Fetching remaining days..."
      );
      commit("setRemainings", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getActYearQuotas: async function ({ commit, dispatch }, selectedYear) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.quotas + "/year/" + selectedYear,
        null,
        true,
        dispatch,
        "Fetching quotas..."
      );
      commit("setActYearQuotas", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getQuotas: async function ({ commit, dispatch }, { params, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.quotas,
        params,
        true,
        dispatch,
        "Kvóták betöltése...",
        null,
        force
      );
      commit("setQuotas", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteQuota: async function ({ dispatch }, quotaId) {
    try {
      await this.remoteRequest(
        "delete",
        urls.quotas + `/${quotaId}`,
        null,
        false,
        dispatch,
        "quota.delete.flair|Kvóta törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "quota.delete.progress|Kvóta törlése folyamatban...",
          fail: "quota.delete.fail|Kvóta törlése sikertelen!",
          success: "quota.delete.success|Kvóta törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateQuota: async function ({ dispatch }, quotaRequest) {
    try {
      await this.remoteRequest(
        "put",
        urls.quotas + `/${quotaRequest.id}`,
        {
          quota: quotaRequest.quota,
          year: quotaRequest.year,
          userId: quotaRequest.user,
        },
        false,
        dispatch,
        "quota.modify.flair|Kvóta módosítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "quota.modify.progress|Kvóta módosítása folyamatban...",
          fail: "quota.modify.fail|Kvóta módosítása sikertelen!",
          success: "quota.modify.success|Kvóta módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  createQuota: async function ({ dispatch }, quotaRequest) {
    try {
      await this.remoteRequest(
        "post",
        urls.quotas,
        {
          quota: quotaRequest.quota,
          year: quotaRequest.year,
          userId: quotaRequest.user,
        },
        false,
        dispatch,
        "quota.create.flair|Kvóta létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "quota.create.progress|Kvóta létrehozása folyamatban...",
          fail: "quota.create.fail|Kvóta létrehozása sikertelen!",
          success: "quota.create.success|Kvóta létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setQuotas: (state, quotas) => {
    state.quotas = quotas;
  },
  setActYearQuotas: (state, quotas) => {
    state.actYearQuotas = quotas;
  },
  setRemainings: (state, remainings) => {
    state.remainings = remainings;
  },
};

const getters = {
  quotas: (state) => {
    return state.quotas;
  },
  actYearQuotas: (state) => {
    return state.actYearQuotas;
  },
  remainings: (state) => {
    return state.remainings;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
