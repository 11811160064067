<template>
  <div>
    <h1 class="title">Áttekintés</h1>
    <div class="is-flex is-align-items-center has-gap-6 mb-4">
      <div>
        <b-dropdown aria-role="list" v-model="selectedYear">
          <template #trigger="{ active }">
            <b-button
              :label="'Év: ' + selectedYear"
              type="is-info"
              :icon-right="active ? 'chevron-up' : 'chevron-down'"
            />
          </template>

          <b-dropdown-item
            v-for="year in years"
            :key="year"
            :value="year"
            aria-role="listitem"
            >{{ year }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <div style="overflow-x: auto; padding-bottom: 24px">
      <table class="outsource-list-table">
        <tr class="table-header-months-row">
          <th></th>
          <th></th>
          <th style="width: 16px"></th>
          <th class="table-header-month-cell" v-for="i in 12" :key="'a' + i">
            {{
              $tf(SHORT_MONTH_NAMES[i - 1])
                .charAt(0)
                .toUpperCase() + $tf(SHORT_MONTH_NAMES[i - 1]).slice(1)
            }}
          </th>
          <th class="has-text-centered">Összesítés</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td v-for="i in 12" :key="i">
            <div
              v-if="now.getMonth() + 1 > i"
              class="month-type-bubble bubble-green"
            >
              Tény
            </div>
            <div
              v-else-if="now.getMonth() + 1 < i"
              class="month-type-bubble bubble-blue"
            >
              FC
            </div>
            <div v-else class="month-type-bubble bubble-red">Folyamatban</div>
          </td>
          <td class="has-text-centered p-0">
            <div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <b-button
                    :label="$tf('outsourceProjectStatus.summary|Összesítés')"
                    type="is-info"
                    :icon-right="active ? 'chevron-up' : 'chevron-down'"
                  />
                </template>

                <b-dropdown-item aria-role="listitem" class="px-5">
                  <b-field>
                    <b-slider
                      v-model="sumRange"
                      :min="1"
                      :max="12"
                      :step="1"
                      ticks
                    >
                      <b-slider-tick :value="sumRange[0]">{{
                        $tf(SHORT_MONTH_NAMES[sumRange[0] - 1])
                      }}</b-slider-tick>
                      <b-slider-tick :value="sumRange[1]">{{
                        $tf(SHORT_MONTH_NAMES[sumRange[1] - 1])
                      }}</b-slider-tick>
                    </b-slider>
                  </b-field>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </td>
        </tr>
        <tr class="client-section-first-row border-bottom-lighter-grey">
          <td class="sum-cell" rowspan="5">Összesen</td>
          <td class="background-light-grey">Óraszám</td>
          <td></td>
          <td v-for="i in 12" :key="'d' + i">
            {{
              $filterSecondToShortWorkDayFormat(monthlyData(i)?.loggedSeconds)
            }}
          </td>
          <td>
            {{ $filterSecondToShortWorkDayFormat(sumForProject.loggedSeconds) }}
          </td>
        </tr>
        <tr class="client-section-third-row border-bottom-lighter-grey">
          <td class="background-light-grey">Bevétel</td>
          <td></td>
          <td v-for="i in 12" :key="'d' + i">
            {{ moneyify(monthlyData(i)?.income) }}
          </td>
          <td>{{ moneyify(sumForProject.income) }}</td>
        </tr>
        <tr class="client-section-third-row border-bottom-lighter-grey">
          <td class="background-light-grey">Költség</td>
          <td></td>
          <td v-for="i in 12" :key="'d' + i">
            {{ moneyify(monthlyData(i)?.cost) }}
          </td>
          <td>{{ moneyify(sumForProject.cost) }}</td>
        </tr>
        <tr class="client-section-second-row">
          <td rowspan="2" class="background-light-grey">Profit</td>
          <td></td>
          <td v-for="i in 12" :key="'e' + i">
            {{ moneyify(monthlyData(i)?.profit) }}
          </td>
          <td>{{ moneyify(sumForProject.profit) }}</td>
        </tr>
        <tr class="client-section-third-row">
          <td></td>
          <td v-for="i in 12" :key="'f' + i">
            {{ percentify(monthlyData(i)?.profit / monthlyData(i)?.income) }}
          </td>
          <td>
            {{ percentify(sumForProject.profitPercentage) }}
          </td>
        </tr>
        <template
          v-for="(contract, i) in projectDetails.activeContracts"
          :key="'contract' + i"
        >
          <tr class="separator-row">
            <td colspan="16"></td>
          </tr>
          <tr>
            <!-- Worker info section -->
            <td colspan="16">
              <div class="worker-details-row has-text-dark-blue">
                <h3 class="title">{{ contract.userData.name }}</h3>
                <div>
                  <b-tag
                    ><span class="has-font-weight-500">Munkakör:</span>
                    {{ contract.contractCompetence }}</b-tag
                  >
                </div>
                <div>
                  <b-tag
                    ><span class="has-font-weight-500">Szerződés lejár:</span>
                    {{ contract.contractExpiry }}</b-tag
                  >
                </div>
                <div>
                  <b-tag
                    ><span class="has-font-weight-500"
                      >Aktuális havi óraszám:</span
                    >
                    {{ contract.monthlyHours }}</b-tag
                  >
                </div>
                <div>
                  <b-tag
                    ><span class="has-font-weight-500">Aktuális óradíj:</span>
                    {{ moneyify(contract.hourlyFee) }}</b-tag
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr class="client-section-first-row border-bottom-lighter-grey">
            <td class="background-light-grey" rowspan="10">
              <div class="media pb-2 pt-2">
                <figure class="media-left mb-auto">
                  <p class="image is-64x64">
                    <img
                      class="is-rounded"
                      :src="
                        contract?.userData?.avatar
                          ? contract?.userData?.avatar
                          : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
                      "
                      alt="User avatar"
                    />
                  </p>
                </figure>
              </div>
            </td>
            <td class="background-light-grey">Szerződés</td>
            <td></td>
            <td v-for="i in 12" :key="'d' + i" class="has-text-centered">
              <b-icon
                v-if="contractFinancialOfMonth(contract, i)?.contractActive"
                style="color: green; font-weight: 700"
                icon="check"
              />
              <b-icon v-else style="color: red; font-weight: 700" icon="x" />
            </td>
            <td></td>
          </tr>
          <tr class="border-bottom-lighter-grey number-data-row">
            <td class="background-light-grey">Óraszám</td>
            <td></td>
            <td v-for="i in 12" :key="'e' + i">
              {{
                $filterSecondToShortWorkDayFormat(
                  (contractFinancialOfMonth(contract, i)?.hours ?? 0) * 3600
                )
              }}
            </td>
            <td>
              {{
                $filterSecondToShortWorkDayFormat(
                  sumForContract(contract).seconds
                )
              }}
            </td>
          </tr>
          <tr class="number-data-row">
            <td class="background-light-grey">Bevétel</td>
            <td></td>
            <td v-for="i in 12" :key="'e' + i">
              {{ moneyify(contractFinancialOfMonth(contract, i)?.income) }}
            </td>
            <td>{{ moneyify(sumForContract(contract).income) }}</td>
          </tr>
          <tr class="border-bottom-lighter-grey number-data-row">
            <td class="background-light-grey">Szerződés óradíj</td>
            <td></td>
            <td v-for="i in 12" :key="'gé' + i">
              {{ moneyify(contractFinancialOfMonth(contract, i)?.hourlyFee) }}
            </td>
            <td></td>
          </tr>
          <tr class="number-data-row">
            <td class="background-light-grey">Költség</td>
            <td></td>
            <td v-for="i in 12" :key="'e' + i">
              {{ moneyify(contractFinancialOfMonth(contract, i)?.cost) }}
            </td>
            <td>{{ moneyify(sumForContract(contract).cost) }}</td>
          </tr>
          <tr class="number-data-row">
            <td class="background-light-grey">Belső óradíj</td>
            <td></td>
            <td v-for="i in 12" :key="'gr' + i">
              {{
                moneyify(
                  contractFinancialOfMonth(contract, i)?.internalHourlyCost
                )
              }}
            </td>
            <td></td>
          </tr>
          <tr class="number-data-row">
            <td class="background-light-grey">Logolt idő</td>
            <td></td>
            <td v-for="i in 12" :key="'tr' + i">
              {{
                $filterSecondToShortWorkDayFormat(
                  contractFinancialOfMonth(contract, i)?.loggedSeconds ?? 0
                )
              }}
            </td>
            <td>
              {{
                $filterSecondToShortWorkDayFormat(
                  sumForContract(contract).loggedSeconds
                )
              }}
            </td>
          </tr>
          <tr class="border-bottom-lighter-grey number-data-row">
            <td class="background-light-grey">Eltérés</td>
            <td></td>
            <td v-for="i in 12" :key="'tq' + i">
              {{
                $filterSecondToShortWorkDayFormat(
                  contractFinancialOfMonth(contract, i)?.loggedSeconds -
                    contractFinancialOfMonth(contract, i)?.hours * 3600
                )
              }}
            </td>
            <td>
              {{
                $filterSecondToShortWorkDayFormat(
                  sumForContract(contract).loggedDifference
                )
              }}
            </td>
          </tr>
          <tr class="client-section-second-row">
            <td rowspan="2" class="background-light-grey">Profit</td>
            <td></td>
            <td v-for="i in 12" :key="'e' + i">
              {{ moneyify(contractFinancialOfMonth(contract, i)?.profit) }}
            </td>
            <td>{{ moneyify(sumForContract(contract).profit) }}</td>
          </tr>
          <tr class="client-section-third-row">
            <td></td>
            <td v-for="i in 12" :key="'f' + i">
              {{
                percentify(
                  contractFinancialOfMonth(contract, i)?.profit /
                    contractFinancialOfMonth(contract, i)?.income
                )
              }}
            </td>
            <td>
              {{ percentify(sumForContract(contract).profitPercentage) }}
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import {
  moneyify,
  percentify,
  secondToShortWorkDayFormat,
  workdayify,
} from "@/utils/util";
import { SHORT_MONTH_NAMES } from "@/utils/const";

export default {
  name: "OutsourceProjectStatus",
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
      selectedYear: new Date().getFullYear(),
      sumRange: [1, 12],
      SHORT_MONTH_NAMES,
    };
  },
  computed: {
    years() {
      let years = [];
      let year = new Date().getFullYear() + 1;
      while (year > 2019) {
        years.push(year);
        year--;
      }
      return years;
    },

    monthlyFinancials() {
      let monthlyData = [];
      for (let i = 1; i <= 12; i++) {
        monthlyData.push(
          this.projectDetails.financials?.months?.find(
            (f) => f.month === i
          ) || {
            loggedSeconds: null,
            income: null,
            profit: null,
            cost: null,
            month: i,
          }
        );
      }
      return monthlyData;
    },
    sumForProject() {
      let sumIncome = 0;
      let sumProfit = 0;
      let sumLoggedSeconds = 0;
      let sumCost = 0;
      this.monthlyFinancials.forEach((f) => {
        if (f.month >= this.sumRange[0] && f.month <= this.sumRange[1]) {
          sumIncome += f.income;
          sumProfit += f.profit;
          sumLoggedSeconds += f.loggedSeconds;
          sumCost += f.cost;
        }
      });
      return {
        income: sumIncome,
        profit: sumProfit,
        loggedSeconds: sumLoggedSeconds,
        profitPercentage: sumProfit / sumIncome,
        cost: sumCost,
      };
    },
  },
  watch: {
    selectedYear(val) {
      this.$emit("updateYear", val);
    },
  },
  methods: {
    secondToShortWorkDayFormat,
    percentify,
    moneyify,
    workdayify,
    monthlyData(month) {
      return this.$props.projectDetails.financials?.months?.find(
        (fin) => fin.month === month
      );
    },
    contractFinancialOfMonth(contract, month) {
      return contract.financials?.months?.find((f) => f.month === month);
    },
    monthlyFinancialsForContract(contract) {
      let monthlyData = [];
      for (let i = 1; i <= 12; i++) {
        monthlyData.push(
          contract.financials?.months?.find((f) => f.month === i) || {
            hours: null,
            loggedSeconds: null,
            income: null,
            profit: null,
            cost: null,
            month: i,
          }
        );
      }
      return monthlyData;
    },
    sumForContract(contract) {
      let sumIncome = 0;
      let sumProfit = 0;
      let sumSeconds = 0;
      let sumCost = 0;
      let sumLoggedSeconds = 0;
      this.monthlyFinancialsForContract(contract).forEach((f) => {
        if (f.month >= this.sumRange[0] && f.month <= this.sumRange[1]) {
          sumIncome += f.income;
          sumProfit += f.profit;
          sumSeconds += f.hours * 3600;
          sumLoggedSeconds += f.loggedSeconds;
          sumCost += f.cost;
        }
      });
      return {
        income: sumIncome,
        profit: sumProfit,
        seconds: sumSeconds,
        loggedSeconds: sumLoggedSeconds,
        profitPercentage: sumProfit / sumIncome,
        loggedDifference: sumLoggedSeconds - sumSeconds,
        cost: sumCost,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.summary-table td {
  border: none;
  vertical-align: middle;
  font-weight: 500;
  color: $custom-dark-blue;
}

.summary-table th {
  color: $custom-light-blue;
  font-weight: normal;
}

.month-type-bubble {
  border-radius: 8px;
  text-align: center;
  padding: 4px;
  width: 120px;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.bubble-green {
  background: #48c78e;
}

.bubble-red {
  background: #f14668;
}

.bubble-blue {
  background: #4e75d9;
}

.outsource-list-table {
  table-layout: fixed;
  border: none;
}

.outsource-list-table td,
.outsource-list-table th {
  border: none;
  vertical-align: middle;
}

.table-header-months-row .table-header-month-cell {
  background: $grey-light;
  color: $custom-dark-blue;
  text-align: center;
}

.table-header-months-row > td:not(:nth-child(3)),
.table-header-months-row > th:not(:nth-child(3)) {
  padding: 6px 4px;
  font-size: 0.9rem;
  width: 144px;
}

.table-header-months-row > th:nth-child(4) {
  border-radius: 8px 0 0 8px;
}

.table-header-months-row > th:nth-child(15) {
  border-radius: 0 8px 8px 0;
}

.client-section-first-row > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: center;
  font-weight: 500;
  border-right: 2px solid $grey-lighter;
}

.client-section-first-row > td:nth-child(2) {
  border-top-right-radius: 8px;
}

.client-section-second-row > td:first-child {
  border-bottom-right-radius: 8px;
}

.border-bottom-lighter-grey {
  border-bottom: 2px solid $grey-lighter;
}

.client-section-first-row > td:not(:nth-child(1)):not(:nth-child(2)),
.client-section-second-row > td:not(:nth-child(1)),
.client-section-third-row > td:not(:nth-child(1)) {
  text-align: right;
  font-weight: 500;
  color: $custom-dark-blue;
}

.client-section-second-row > td:not(:nth-child(1)) {
  padding-bottom: 0;
}

.separator-row > td {
  height: 16px;
}

.sum-cell {
  background: #2f2e6b !important;
  color: white;
}

.background-light-grey {
  background: $grey-light;
}

.worker-details-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 5fr;
}

.number-data-row td:not(:first-child) {
  text-align: right;
  color: $custom-dark-blue;
  font-weight: 500;
}
</style>
