<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 class="title">Túlóra kérvényezése</h1>

        <form @submit.prevent="submit">
          <b-field label="Jegy" class="mt-5" label-position="on-border">
            <b-input :value="issueKey" :disabled="true"></b-input>
          </b-field>

          <b-field label="Idő" class="mt-5" label-position="on-border">
            <b-input
              :value="$filterSecondToTimeFormat(timespent)"
              :disabled="true"
            ></b-input>
          </b-field>

          <b-field label="Típus" class="mt-5" label-position="on-border">
            <b-select v-model="editData.type" placeholder="Típus" expanded>
              <option
                v-for="(key, value) in OVERTIME_REQUEST_TYPE.LABEL"
                :key="value"
                :value="value"
              >
                {{ $tf(key) }}
              </option>
            </b-select>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close"> Mégse </b-button>
            <b-button native-type="submit" type="is-info" class="ml-3">
              Mentés
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { OVERTIME_REQUEST_TYPE } from "@/utils/const";
import { createTicketUrl } from "@/utils/util";
import eventBus, { EVENTS } from "../../../utils/eventBus";
import { getCurrentInstance } from "vue";

export default {
  name: "OvertimeRequestForm",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    issueKey: {
      type: String,
      required: false,
      default: null,
    },
    timespent: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      createTicketUrl,
      OVERTIME_REQUEST_TYPE,
      editData: {
        type: OVERTIME_REQUEST_TYPE.ENUM.SHIFT,
      },
    };
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async submit() {
      await this.$store
        .dispatch("overtime_requests/createOvertimeRequest", {
          timeRecordId: this.id,
          type: this.editData.type,
        })
        .then(() => {
          this.close();
          eventBus.$emit(EVENTS.OVERTIME_REQUESTED);
        });
    },
  },
};
</script>
