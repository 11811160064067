<template>
  <div class="is-flex tig-bar-main">
    <div
      :class="{ smaller: newLine, 'has-font-weight-500': newLine }"
      class="tig-bar-header is-dark-blue-text"
    >
      <p>{{ header }}</p>
    </div>
    <template v-if="newLine">
      <div class="tig-bar-new-line">
        <div :class="type" :style="getStyle" class="tig-bar-bar" />
        <div class="tig-bar-sub is-dark-blue-text has-font-weight-500">
          <p>
            {{ `${roundedSize}%` }} <span>{{ `(${count} db)` }}</span>
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="type" :style="getStyle" class="tig-bar-bar" />
      <div class="tig-bar-sub is-dark-blue-text has-font-weight-500">
        <p>
          {{ `${roundedSize}%` }} <span>{{ `(${count} db)` }}</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TigBar",
  props: {
    header: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      required: true,
    },
    count: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    newLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getStyle() {
      return `width: ${this.size}%;`;
    },
    roundedSize() {
      return Math.round((this.size + Number.EPSILON) * 10) / 10;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.tig-bar-main {
  gap: 12px;
}
.tig-bar-header {
  width: 100px;
  text-align: end;
  align-self: flex-start;

  p {
    width: 100px;
  }
}
.tig-bar-sub {
  width: 100px;
  white-space: nowrap;
}
.tig-bar-bar {
  height: 24px;
  border-radius: 4px;
}
.is-success {
  background: $green;
}
.is-warning {
  background: $yellow;
}
.is-danger {
  background: $red;
}

.is-dark-blue {
  background: $custom-dark-blue;
}

.is-dark-blue-text {
  color: $custom-dark-blue;

  span {
    color: $custom-grayer-blue;
    font-weight: 400;
  }
}

.is-blue {
  background: $blue;
}
.is-light-blue {
  background: $custom-light-blue;
}

.is-lighter-blue {
  background: $custom-grayer-blue;
}

.smaller {
  width: 50px;
  margin-right: 10px;
  color: $custom-dark-blue;
}
.tig-bar-new-line {
  width: 100%;
}
</style>
