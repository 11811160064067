<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4999_23737)">
      <circle cx="16" cy="16" r="16" fill="#EBE9FE" />
      <circle cx="9.22392" cy="9.22432" r="1.89092" fill="#6A38EF" />
      <circle cx="9.22392" cy="16" r="1.89092" fill="#6A38EF" />
      <circle cx="9.22392" cy="22.7756" r="1.89092" fill="#6A38EF" />
      <circle cx="15.9993" cy="9.22423" r="1.89092" fill="#6A38EF" />
      <circle cx="15.9993" cy="16" r="1.89092" fill="#F670C7" />
      <circle cx="15.9993" cy="22.7756" r="1.89092" fill="#6A38EF" />
      <circle cx="22.7757" cy="9.22423" r="1.89092" fill="#6A38EF" />
      <circle cx="22.7757" cy="16" r="1.89092" fill="#6A38EF" />
      <circle cx="22.7757" cy="22.7756" r="1.89092" fill="#6A38EF" />
    </g>
    <defs>
      <clipPath id="clip0_4999_23737">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "OnboardingMassSettingIcon",
};
</script>

<style scoped></style>
