<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 30rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="isCreate">
          {{ $tf("clients.modal.newTitle|Új ügyfél létrehozása") }}
        </h1>
        <h1 class="title" v-else>
          {{
            $tf("clients.modal.editTitle|{name} ügyfél módsíotása}", {
              name: name,
            })
          }}
        </h1>
        <form @submit.prevent="submit">
          <vuelidated-input
            :expanded="true"
            v-model="editData.identifier"
            :placeholder="$tf('clients.modal.identifier|Azonosító')"
            :label="$tf('clients.modal.identifier|Azonosító')"
            :validation-rule="v$.editData.identifier"
            label-position="on-border"
            :disabled="isSubmitting"
          />
          <vuelidated-input
            :expanded="true"
            v-model="editData.name"
            :placeholder="$tf('clients.modal.name|Név')"
            :label="$tf('clients.modal.name|Név')"
            :validation-rule="v$.editData.name"
            label-position="on-border"
            :disabled="isSubmitting"
          />

          <b-field class="file" :class="{ 'has-name': !!editData.avatar }">
            <b-upload
              v-model="editData.avatar"
              class="file-label"
              accept="image/*"
              rounded
              :disabled="isSubmitting"
            >
              <span class="file-cta avatar-upload">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">{{
                  $tf("clients.modal.logoUpload|Ügyfél logó feltöltése")
                }}</span>
              </span>
              <span class="file-name" v-if="editData.avatar">
                {{ editData.avatar.name }}
              </span>
            </b-upload>
          </b-field>

          <b-field class="mt-3">
            <b-field
              :label="$tf('clients.modal.description|Összefoglaló, leírás')"
              label-position="on-border"
              expanded
            >
              <b-input
                type="textarea"
                v-model="editData.description"
                :disabled="isSubmitting"
              ></b-input>
            </b-field>
          </b-field>

          <b-field class="save-group is-pulled-right" :disabled="isSubmitting">
            <b-button @click="close">
              {{ $tf("clients.modal.cancel|Mégse") }}
            </b-button>
            <b-button
              native-type="submit"
              type="is-info"
              class="ml-2"
              :loading="isSubmitting"
            >
              {{ $tf("clients.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";

export default {
  name: "ClientForm",
  components: { VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    client: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      editData: {
        identifier: "",
        name: "",
        avatar: "",
        description: "",
      },
      isCreate: true,
      projects: [],
      isSubmitting: false,
    };
  },
  mounted() {
    if (this.client) {
      this.isCreate = false;
      this.name = this.client.name;
      this.editData = JSON.parse(JSON.stringify(this.client));
    } else {
      this.isCreate = true;
    }
  },
  validations: {
    editData: {
      identifier: {
        required,
      },
      name: {
        required,
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.close();
      this.$store.dispatch("enterprise_clients/fetchClients", true);
    },
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.isSubmitting = true;
      const formData = new FormData();
      if (this.editData.avatar) {
        formData.append("file", this.editData.avatar);
        formData.append("fileName", this.editData.name);
        formData.append("documentType", "CLIENT_LOGO");
      }

      if (this.isCreate) {
        await this.$store
          .dispatch("enterprise_clients/createClient", {
            client: this.editData,
            logoForm: formData,
          })
          .then(async () => {
            this.afterSave();
          });
      } else {
        await this.$store
          .dispatch("enterprise_clients/modifyClient", {
            client: this.editData,
            logoForm: formData,
          })
          .then(async () => {
            this.afterSave();
          });
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style scoped>
.save-group {
  margin-top: 3rem;
}
</style>
