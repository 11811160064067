import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import axios from "axios";
import app from "@/main.js";
import network from "@/utils/network";

let logger = newLogger("Census-Liked-Projects");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  likedProjects: "/census/like/liked",
  changeLike: (id) => `/census/like/${id}/changeLike`,
};

const initState = () => {
  return {
    likedProjects: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchLikedProjects: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.likedProjects,
        null,
        true,
        dispatch,
        "Kedvelt projektek betöltése...",
        null,
        true
      );
      commit("saveLikedProjects", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  changeLike: async function ({ commit, dispatch }, { projectId, liked }) {
    try {
      let data = await this.remoteRequest(
        "PATCH",
        `${urls.changeLike(projectId)}`,
        null,
        false,
        dispatch,
        "user.chageLike.flair|Kedvelés",
        undefined,
        true,
        true,
        {
          progress: liked
            ? "user.chageLike.progress.remove|Kedvelés törlése folyamatban..."
            : "user.chageLike.progress.add|Kedvelés folyamatban...",
          fail: liked
            ? "user.chageLike.fail.remove|Kedveslés törlése sikertelen!"
            : "user.chageLike.fail.add|Kedvelés sikertelen!",
          success: liked
            ? "user.chageLike.success.remove|Kedvelés törlése sikeres!"
            : "user.chageLike.success.add|Kedvelés sikeres!",
        }
      );
      commit("saveLikedProjects", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        throw err;
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveLikedProjects: (state, projects) => {
    state.likedProjects = projects;
  },
};

const getters = {
  likedProjects: (state) => {
    return state.likedProjects;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
