<template>
  <section class="section">
    <div class="back-button-container">
      <b-button icon-left="chevron-left" label="Telephelyek" @click="cancel" />
    </div>

    <div class="container">
      <h1 class="title">
        {{
          originalSiteName
            ? `${originalSiteName} módosítása`
            : "Új telephely létrehozása"
        }}
      </h1>
      <h3 class="heading">Alapadatok</h3>

      <b-field grouped>
        <vuelidated-input
          label="Azonosító"
          placeholder="Azonosító"
          v-model="site.identifier"
          :validation-rule="v$.site.identifier"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>

        <vuelidated-input
          label="Telephely neve"
          placeholder="Telephely neve"
          v-model="site.name"
          :validation-rule="v$.site.name"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>
      </b-field>

      <b-field grouped>
        <vuelidated-input
          label="Telephely címe"
          placeholder="Telephely címe"
          v-model="site.address"
          :validation-rule="v$.site.address"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>

        <vuelidated-input
          label="Telephely leírása"
          placeholder="Telephely leírása"
          v-model="site.description"
          :validation-rule="v$.site.description"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>
      </b-field>
    </div>

    <div class="save-button-container">
      <div class="buttons-wrapper">
        <b-button @click="cancel"> Mégse </b-button>
        <b-button icon-left="save" type="is-info" @click="saveSite">
          Mentés
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import VuelidatedInput from "../../../components/module/input/VuelidatedInput";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import eventBus from "../../../utils/eventBus";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "SiteEdit",
  components: {
    VuelidatedInput,
  },
  mixins: [LoadingMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    site: {
      name: {
        required,
      },
      identifier: {
        required,
      },
      address: {
        required,
      },
      description: {
        required,
      },
    },
  },
  data() {
    return {
      originalSiteName: undefined,
      site: {
        identifier: "",
        name: "",
        address: "",
        description: "",
      },
    };
  },
  async mounted() {
    await this.doStartLoading();

    let id = this.$route.params.id;
    if (id) {
      await this.$store.dispatch("census_seating/fetchSite", id);
      this.site = this.$store.getters["census_seating/site"];
      this.originalSiteName = this.site.name;
    }
    await this.doFinishLoading();
  },
  methods: {
    async cancel(force) {
      await this.$store.dispatch("census_seating/fetchSites", force);
      await this.$router.push("/settings/seating");
    },
    async saveSite() {
      this.v$.site.$touch();
      if (!this.v$.site.$invalid) {
        await this.$store.dispatch("census_seating/saveSite", this.site);
        await this.cancel(true);
      }
    },
  },
};
</script>
