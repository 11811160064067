<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 class="title">Projekt részletek exportálása</h1>

        <div class="mt-5 mb-5 columns">
          <div class="column">
            <b-switch v-model="entireProject"> Teljes projekt </b-switch>
          </div>
          <div class="column">
            <b-field
              :label="$tf('projectExportForm.dates.from|Mettől')"
              label-position="on-border"
              class="datepicker-field has-blue-icon"
              expanded
            >
              <b-datepicker
                v-model="from"
                :placeholder="$tf('moneyModal.choose|Válassz')"
                icon="calendar-day"
                trap-focus
                :disabled="entireProject"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$tf('projectExportForm.dates.to|Meddig')"
              label-position="on-border"
              class="datepicker-field has-blue-icon"
              expanded
            >
              <b-datepicker
                v-model="to"
                :placeholder="$tf('moneyModal.choose|Válassz')"
                icon="calendar-day"
                trap-focus
                :disabled="entireProject"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>

        <b-button
          type="is-info"
          rounded
          @click="doExport"
          :loading="isExportInProgress"
        >
          Exportálás megkezdése
        </b-button>
      </section>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import { getCurrentInstance } from "vue";

export default {
  name: "ProjectExportModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entireProject: true,
      isExportInProgress: false,
      from: new Date(new Date().setDate(1)),
      to: new Date(),
    };
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async doExport() {
      this.isExportInProgress = true;
      try {
        let data = await this.$store.dispatch(
          "enterprise_overview/exportOverview",
          {
            from: this.entireProject ? null : this.from,
            to: this.entireProject ? null : this.to,
            projects: [this.projectIdentifier],
          }
        );

        FileSaver.saveAs(data, "project_export.xlsx");
      } finally {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
