<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.9512 48.8002H18.2512C17.9487 48.8002 17.7012 48.5527 17.7012 48.2502C17.7012 47.9477 17.9487 47.7002 18.2512 47.7002H36.9512C37.2537 47.7002 37.5012 47.9477 37.5012 48.2502C37.5012 48.5527 37.2537 48.8002 36.9512 48.8002Z"
      fill="var(--primary)"
    />
    <path
      d="M33.0989 44.9505C32.8261 44.9505 32.5665 44.8845 32.3245 44.7558L16.3096 36.2154L17.8628 33.3037L33.8777 41.8452C34.2671 42.052 34.5509 42.3985 34.6796 42.8209C34.8083 43.2422 34.7643 43.6888 34.5575 44.0782C34.2693 44.6161 33.7105 44.9505 33.0989 44.9505Z"
      fill="var(--lightestPrimary)"
    />
    <path
      d="M18.0894 34.0469L33.6192 42.3299C33.8788 42.4685 34.068 42.6984 34.1538 42.98C34.2396 43.2616 34.2099 43.5586 34.0713 43.8182C33.8799 44.1779 33.507 44.4001 33.0989 44.4001C32.9185 44.4001 32.7447 44.3561 32.583 44.2703L17.0532 35.9884L18.0894 34.0469ZM17.6362 32.5586L15.5649 36.4405L32.0649 45.2405C32.3949 45.4165 32.7491 45.5001 33.0978 45.5001C33.8843 45.5001 34.6455 45.0777 35.0415 44.3352C35.6135 43.2627 35.2076 41.9295 34.1362 41.3586L17.6362 32.5586Z"
      fill="var(--primary)"
    />
    <path
      d="M22.155 44.934C21.5434 44.934 20.9846 44.5996 20.6975 44.0606C20.4896 43.6712 20.4456 43.2257 20.5743 42.8033C20.703 42.3809 20.9868 42.0355 21.3762 41.8276L37.3911 33.2861L38.9443 36.1978L22.9294 44.7393C22.6907 44.8669 22.4223 44.934 22.155 44.934Z"
      fill="var(--lightestPrimary)"
    />
    <path
      d="M37.1644 34.0303L38.1995 35.9718L22.6708 44.2537C22.508 44.3406 22.3342 44.3835 22.1549 44.3835C21.7468 44.3835 21.3739 44.1602 21.1825 43.8005C21.0439 43.5409 21.0153 43.2439 21.1011 42.9623C21.1869 42.6807 21.3761 42.4508 21.6357 42.3122L37.1644 34.0303ZM37.6165 32.542L21.1165 41.342C20.044 41.914 19.6392 43.2461 20.2112 44.3186C20.6072 45.0611 21.3673 45.4835 22.1549 45.4835C22.5036 45.4835 22.8589 45.3999 23.1878 45.2239L39.6878 36.4239L37.6165 32.542Z"
      fill="var(--primary)"
    />
    <path
      d="M21.5511 31.7506V23.89L20.2773 26.4376C20.1178 26.7544 19.856 27.0162 19.5392 27.1757L12.9392 30.4757C12.7071 30.5923 12.4596 30.6506 12.2022 30.6506C11.5719 30.6506 11.0065 30.3008 10.7249 29.7376C10.3179 28.9236 10.649 27.9303 11.463 27.5233L17.5713 24.4697L20.6249 18.3614C20.7492 18.1117 20.9373 17.8972 21.1672 17.7399C21.5049 17.5089 23.4013 16.3506 27.6011 16.3506C31.8009 16.3506 33.6973 17.5089 34.035 17.741C34.2649 17.8994 34.4519 18.1139 34.5773 18.3636L37.6309 24.4708L43.7392 27.5244C44.5532 27.9314 44.8843 28.9247 44.4773 29.7387C44.1957 30.3019 43.6292 30.6517 43 30.6517C42.7426 30.6517 42.4951 30.5934 42.2641 30.4768L35.6641 27.1768C35.3473 27.0173 35.0855 26.7555 34.926 26.4387L33.6511 23.89V31.7506H21.5511Z"
      fill="var(--lightestPrimary)"
    />
    <path
      d="M27.6012 16.9008C31.7239 16.9008 33.5312 18.0613 33.7238 18.1944C33.8766 18.3 34.0021 18.443 34.0857 18.6091L37.0578 24.5535L37.2218 24.8813L37.5496 25.0452L43.494 28.0174C44.0363 28.2891 44.2574 28.9513 43.9868 29.4936C43.7976 29.8676 43.4202 30.1008 43 30.1008C42.8296 30.1008 42.6646 30.0612 42.5095 29.9842L35.9106 26.6842C35.6983 26.5775 35.5234 26.4026 35.4178 26.1914L35.1856 25.7272L33.1012 21.5615V26.2211V31.2008H22.1011V26.2211V21.5615L20.0177 25.7294L19.7867 26.1925C19.68 26.4048 19.5051 26.5797 19.294 26.6853L12.6939 29.9853C12.5388 30.0623 12.3738 30.1008 12.2022 30.1008C11.782 30.1008 11.4047 29.8676 11.2166 29.4925C10.946 28.9502 11.166 28.2869 11.7083 28.0163L17.6528 25.0441L17.9805 24.8802L18.1445 24.5524L21.1156 18.6091C21.1992 18.4419 21.3245 18.2989 21.4774 18.1933C21.671 18.0613 23.4783 16.9008 27.6012 16.9008ZM27.6012 15.8008C23.2408 15.8008 21.2168 17.0394 20.856 17.288C20.5491 17.4992 20.3005 17.7841 20.1333 18.1174L17.161 24.0618L11.2166 27.034C10.1298 27.5774 9.68985 28.8985 10.2321 29.9864C10.6193 30.7553 11.3959 31.2008 12.2022 31.2008C12.5333 31.2008 12.8688 31.126 13.1845 30.9687L19.7845 27.6687C20.2102 27.4553 20.5556 27.1099 20.769 26.6842L21.0012 26.2211V32.3008H34.2012V26.2211L34.4333 26.6853C34.6467 27.111 34.9921 27.4564 35.4178 27.6698L42.0177 30.9698C42.3335 31.1271 42.669 31.2008 43 31.2008C43.8064 31.2008 44.5841 30.7553 44.9701 29.9842C45.5135 28.8974 45.0725 27.5763 43.9856 27.0318L38.0413 24.0596L35.069 18.1152C34.9018 17.7819 34.6544 17.497 34.3463 17.2858C33.9855 17.0394 31.9615 15.8008 27.6012 15.8008Z"
      fill="var(--primary)"
    />
    <path
      d="M27.6013 7.55078C26.7261 7.55078 25.8867 7.89846 25.2678 8.51733C24.6489 9.1362 24.3013 9.97557 24.3013 10.8508C24.3013 11.726 24.6489 12.5654 25.2678 13.1842C25.8867 13.8031 26.7261 14.1508 27.6013 14.1508C28.4765 14.1508 29.3159 13.8031 29.9347 13.1842C30.5536 12.5654 30.9013 11.726 30.9013 10.8508C30.9013 9.97557 30.5536 9.1362 29.9347 8.51733C29.3159 7.89846 28.4765 7.55078 27.6013 7.55078Z"
      fill="var(--lightestPrimary)"
    />
    <path
      d="M27.6012 8.1C29.117 8.1 30.3512 9.3342 30.3512 10.85C30.3512 12.3658 29.117 13.6 27.6012 13.6C26.0854 13.6 24.8512 12.3658 24.8512 10.85C24.8512 9.3342 26.0854 8.1 27.6012 8.1ZM27.6012 7C25.4749 7 23.7512 8.7226 23.7512 10.85C23.7512 12.9752 25.4749 14.7 27.6012 14.7C29.7275 14.7 31.4512 12.9752 31.4512 10.85C31.4512 8.7226 29.7275 7 27.6012 7Z"
      fill="var(--primary)"
    />
    <path
      d="M15.9335 36.2012C15.2977 35.8844 14.9138 35.2288 14.9512 34.5292C14.9853 33.8846 15.3835 33.3269 16.2041 32.7736L21.5512 29.2987V23.89L20.2774 26.4376C20.2037 26.5861 20.1025 26.7225 19.9793 26.8435L17.7683 24.077L20.6261 18.3614C20.7504 18.1117 20.9385 17.8972 21.1684 17.7399C21.505 17.5089 23.4014 16.3506 27.6012 16.3506C31.801 16.3506 33.6974 17.5089 34.0351 17.741C34.2639 17.8994 34.452 18.1139 34.5774 18.3636L37.4352 24.0792L35.2242 26.8457C35.101 26.7247 34.9998 26.5883 34.9261 26.4398L33.6512 23.89V29.2965L38.9169 32.7769C39.5692 33.1553 40.216 33.8318 40.2523 34.5303C40.2897 35.2299 39.9058 35.8855 39.2744 36.2012L31.1069 40.3614L27.5407 38.5156L24.1098 40.3691L15.9335 36.2012Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M27.6012 16.9008C31.724 16.9008 33.5313 18.0613 33.7238 18.1944C33.8767 18.3 34.0021 18.443 34.0857 18.6091L36.7862 24.0101L35.2726 25.9032L35.1857 25.7294L33.1012 21.5615V26.2211V29.0008V29.5926L33.5951 29.9193L38.6144 33.2369L38.6419 33.2556L38.6705 33.2721C39.2469 33.6054 39.6814 34.1477 39.7034 34.5602C39.7287 35.0409 39.4647 35.4919 39.0203 35.713L31.7889 39.3958L31.1091 39.7434L28.0522 38.1616L27.5352 37.8943L27.0237 38.1704L24.1032 39.7489L23.4135 39.3958L16.1744 35.7097C15.7388 35.4908 15.4747 35.0398 15.5 34.5591C15.5155 34.2709 15.6365 33.8199 16.4967 33.2402L21.6017 29.9226L22.1012 29.5981V29.0008V26.2211V21.5615L20.0178 25.7294L19.9309 25.9032L18.4173 24.0101L21.1167 18.6102C21.2003 18.443 21.3257 18.3 21.4786 18.1944C21.6711 18.0613 23.4784 16.9008 27.6012 16.9008ZM27.6012 15.8008C23.2408 15.8008 21.2168 17.0394 20.856 17.288C20.5491 17.4992 20.3005 17.7841 20.1333 18.1174L17.1611 24.0618L17.0808 24.1014L19.8791 27.6016C20.2576 27.386 20.5732 27.0758 20.7691 26.6853L21.0012 26.2211V29.0008L15.8961 32.3184C15.0546 32.8849 14.4496 33.5823 14.4012 34.5008C14.3528 35.4193 14.8566 36.2795 15.6838 36.692L22.9141 40.3737L24.1175 40.9886L27.5462 39.1351L31.1047 40.9776L32.2883 40.3737L39.5186 36.692C40.3458 36.2795 40.8496 35.4193 40.8012 34.5008C40.7528 33.5823 39.9938 32.765 39.2194 32.3184L34.2012 29.0008V26.2211L34.4333 26.6853C34.6291 27.0758 34.9448 27.386 35.3232 27.6016L38.1215 24.1014L38.0413 24.0618L35.0691 18.1174C34.9019 17.7841 34.6544 17.4992 34.3464 17.288C33.9856 17.0394 31.9616 15.8008 27.6012 15.8008Z"
      fill="var(--primary)"
    />
    <path
      d="M20.7163 35.6006L31.3258 41.0929L31.812 40.533L32.5644 40.5209L23.2001 35.6006H20.7163Z"
      fill="var(--primary)"
    />
    <path
      d="M31.8823 35.6006L23.6323 40.0006H25.9698L34.2198 35.6006H31.8823Z"
      fill="var(--primary)"
    />
  </svg>
</template>

<script>
export default {
  name: "WeekendGuru",
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

svg {
  --lightestPrimary: color-mix(in srgb, var(--primary), #{$white} 85%);
  --lighterStrongerPrimary: color-mix(in srgb, var(--primary), #{$white} 65%);
}
</style>
