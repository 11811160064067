import { localFetch, localStore } from "@/utils/util";

const SaveableObject = class SaveableObject {
  constructor(key, defaultValue, useForRequest = true) {
    this.key = key;
    this.defaultValue = defaultValue;
    this._value = defaultValue;
    this.useForRequest = useForRequest;
  }

  set value(value) {
    this._value = value;
    this.save();
  }

  get value() {
    return this._value;
  }

  save() {
    localStore(this.key, this.value);
  }

  fetchOrDefault() {
    const value = localFetch(this.key) ?? this.defaultValue;
    this.value = value;
    return value;
  }

  resetToDefault() {
    this.value = this.defaultValue;
    this.save();
  }

  clear() {
    localStorage.removeItem(this.key);
  }
};

export default SaveableObject;
