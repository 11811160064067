"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ForecastRuleFetch = /** @class */ (function () {
    function ForecastRuleFetch(functionValue, params) {
        this.function = functionValue;
        this.params = params;
    }
    return ForecastRuleFetch;
}());
exports.default = ForecastRuleFetch;
