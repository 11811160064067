import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";
import axios from "axios";
import { ONBOARDING_PAGES } from "@/utils/const";
import router from "@/router";

let logger = newLogger("TenantStore");

const urls = {
  templates: `/api/tenant/onboarding/templates`,
  currencies: `/api/tenant/onboarding/currencies`,
  privileges: `/api/tenant/onboarding/privileges`,
  info: (token) => `/api/tenant/onboarding/${token}`,
  updateData: (token) => `/api/tenant/onboarding/step/${token}`,
};

/**
 * Onboarding store
 * Stores onboarding settings.
 * Save mutation modifies the state value,
 * Send action save modifications on the server.
 * Submit action closes the onboarding process.
 */

const initState = () => {
  return {
    currentPosition: ONBOARDING_PAGES.START,
    templates: [],
    projects: [],
    users: [],
    roles: [],
    competencies: [],
    privileges: [],
    tiers: [],
    availableCurrencies: [],
    currency: {},
    clients: [],
    trackingSystem: "",
    apiKey: "",
    finished: false,
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchInfo: async function ({ commit }) {
    try {
      let { data } = await axios.get(
        urls.info(router.currentRoute.params.onboardingToken),
        {}
      );
      commit("saveInfo", data);
    } catch (err) {
      logger.error(err);
      throw "No onboarding found with given token!";
    }
  }.bind(storeCore),
  fetchTemplates: async function ({ commit }) {
    try {
      let { data } = await axios.get(urls.templates, {});
      commit("saveTemplates", data);
    } catch (err) {
      logger.error(err);
    }
  }.bind(storeCore),
  fetchCurrencies: async function ({ commit }) {
    try {
      let { data } = await axios.get(urls.currencies, {});
      commit("saveCurrencies", data);
    } catch (err) {
      logger.error(err);
    }
  }.bind(storeCore),
  fetchPrivileges: async function ({ commit }) {
    try {
      let { data } = await axios.get(urls.privileges);
      commit("savePrivileges", data);
    } catch (err) {
      logger.error(err);
    }
  }.bind(storeCore),
  fetchRoles: async function ({ commit }) {
    try {
      let { data } = await axios.get(
        urls.roles(router.currentRoute.params.onboardingToken)
      );
      commit("saveRoles", data);
    } catch (err) {
      logger.error(err);
    }
  }.bind(storeCore),
  updateRoles: async function ({ commit }, roles) {
    commit("saveRoles", roles);
    try {
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { roles: roles }
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateCompetencies: async function ({ commit }, competencies) {
    commit("saveCompetencies", competencies);
    try {
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { competencies: competencies }
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateTiers: async function ({ commit }, tiers) {
    commit("saveTiers", tiers);
    try {
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { tiers: tiers }
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateCurrency: async function ({ commit }, currency) {
    commit("saveCurrency", currency);
    try {
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { currency: currency }
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updatePosition: async function ({ commit }, position) {
    try {
      commit("savePosition", position);
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { position: position },
        {}
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateExternalIssueTrackingSystem: async function ({ commit }, system) {
    try {
      commit("saveTrackingSystem", system);
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { externalIssueTrackingSystem: system },
        {}
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateExternalIssueTrackingToken: async function ({ commit }, apiKey) {
    try {
      commit("saveApiKey", apiKey);
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { externalIssueTrackingToken: apiKey },
        {}
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateProjects: async function ({ commit }, projects) {
    try {
      commit("saveProjects", projects);
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { projects: projects },
        {}
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateUsers: async function ({ commit }, users) {
    try {
      commit("saveUsers", users);
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { users: users },
        {}
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateClients: async function ({ commit }, clients) {
    try {
      commit("saveClients", clients);
      await axios.patch(
        urls.updateData(router.currentRoute.params.onboardingToken),
        { clients: clients },
        {}
      );
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveInfo: (state, data) => {
    if (data.position) {
      state.currentPosition = data.position;
    }
    if (data.roles) {
      state.roles = data.roles;
    }
    if (data.competencies) {
      state.competencies = data.competencies;
    }
    if (data.tiers) {
      state.tiers = data.tiers;
    }
    if (data.currency) {
      state.currency = data.currency;
    }
    if (data.projects) {
      state.projects = data.projects;
    }
    if (data.users) {
      state.users = data.users;
    }
    if (data.clients) {
      state.clients = data.clients;
    }
    if (data.externalIssueTrackingSystem) {
      state.trackingSystem = data.externalIssueTrackingSystem;
    }
    if (data.finished !== null) {
      state.finished = data.finished;
    }
  },
  savePosition: (state, newPosition) => {
    state.currentPosition = newPosition;
  },
  saveTemplates: (state, templates) => {
    state.templates = templates;
  },
  saveClients: (state, clients) => {
    state.clients = clients;
  },
  saveProjects: (state, projects) => {
    state.projects = projects;
  },
  saveUsers: (state, users) => {
    state.users = users;
  },
  saveRoles: (state, roles) => {
    state.roles = roles;
  },
  saveCompetencies: (state, competencies) => {
    state.competencies = competencies;
  },
  saveTiers: (state, tiers) => {
    state.tiers = tiers;
  },
  saveCurrencies: (state, currencies) => {
    state.availableCurrencies = currencies;
  },
  saveCurrency: (state, currency) => {
    state.currency = currency;
  },
  savePrivileges: (state, privileges) => {
    state.privileges = privileges;
  },
  saveTrackingSystem: (state, trackingSystem) => {
    state.trackingSystem = trackingSystem;
  },
  saveApiKey: (state, apiKey) => {
    state.apiKey = apiKey;
  },
};

const getters = {
  getCurrentPosition: (state) => {
    return state.currentPosition;
  },
  getTemplates: (state) => {
    return state.templates;
  },
  getProjects: (state) => {
    return state.projects;
  },
  getUsers: (state) => {
    return state.users;
  },
  getRoles: (state) => {
    return state.roles;
  },
  getCompetencies: (state) => {
    return state.competencies;
  },
  getTiers: (state) => {
    return state.tiers;
  },
  getCurrencies: (state) => {
    return state.availableCurrencies;
  },
  getCurrency: (state) => {
    return Object.keys(state.currency).length > 0
      ? state.currency
      : state.availableCurrencies[0];
  },
  getPrivileges: (state) => {
    return state.privileges;
  },
  getApiKey: (state) => {
    return state.apiKey;
  },
  getFinished: (state) => {
    return state.finished;
  },
  getDefaultTier: (state) => {
    return state.tiers.find((tier) => tier.standard);
  },
  getDefaultRole: (state) => {
    return state.roles.find((role) => role.default);
  },
  getUsedRoles: (state) => {
    return state.roles.filter((role) => !role.recommendation);
  },
  getClients: (state) => {
    return state.clients;
  },
  getClientIdentifiers: (state) => {
    return state.clients.map((cl) => cl.identifier);
  },
  getTrackingSystem: (state) => {
    return state.trackingSystem;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
