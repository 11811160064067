import session from "./session";
import loading from "./loading";
import worklog from "./worklog";
import employee from "./employee";
import specialday from "./absence/specialday";
import quotas from "./absence/quota";
import dashboard from "./dashboard";
import admin from "./absence/admin";
import absence_request from "./absence/absence_request";
import work_schedule from "./absence/work_schedule";
import enterprise_core from "./enterprise/core";
import enterprise_tiers from "./enterprise/tiers";
import enterprise_projects from "./enterprise/projects";
import support_projects from "./enterprise/support-projects";
import outsource_projects from "./enterprise/outsource-projects";
import enterprise_clients from "./enterprise/client";
import enterprise_journal from "./enterprise/journal";
import project_dimensions from "./enterprise/project-dimensions";
import project_base_data from "./enterprise/project-base-data";
import enterprise_overview from "./enterprise/overview";
import overtime_policies from "./enterprise/overtime-policy";
import overtime_requests from "./enterprise/overtime-request";
import enterprise_issue from "./enterprise/issues";
import absence_admin from "./absence/absence_admin";
import census_team from "./census/team";
import census_user from "./census/user";
import risk_management from "./enterprise/risk-management";
import enterprise_statistics from "./enterprise/statistics";
import user_indicator from "./census/user-indicator";
import user_permission from "./census/user-permission";
import census_seating from "./census/seating_arrangement/list";
import seating_arrangement from "./census/seating_arrangement/reservation";
import uiConfigStore from "./ui-config";
import tenant from "./tenant";
import adminConfig from "./admin-config";
import passwordPolicy from "./password-policy";
import forecast_year from "./enterprise/forecast/year";
import forecast_segment from "./enterprise/forecast/segment";
import forecast_chance_category from "./enterprise/forecast/chance-category";
import forecast_plan from "./enterprise/forecast/plan";
import forecast_fact from "./enterprise/forecast/fact";
import forecast_scenario from "./enterprise/forecast/scenario";
import onboarding from "./onboarding";
import project_risk from "./project-risk";
import role from "@/store/modules/bulwark/role";
import api_key from "@/store/modules/bulwark/api-key";
import loader from "@/store/modules/loader";
import association from "@/store/modules/ralloc/association";
import commitment from "@/store/modules/ralloc/commitment";
import project_like from "@/store/modules/census/project-like";

/**
 * Gathering all modules to export into the router
 */
export default {
  session,
  loading,
  worklog,
  employee,
  specialday,
  quotas,
  dashboard,
  absence_request,
  work_schedule,
  admin,
  enterprise_core,
  enterprise_tiers,
  enterprise_projects,
  support_projects,
  outsource_projects,
  enterprise_clients,
  enterprise_journal,
  enterprise_issue,
  absence_admin,
  census_team,
  census_user,
  project_dimensions,
  project_base_data,
  overtime_policies,
  overtime_requests,
  risk_management,
  enterprise_statistics,
  enterprise_overview,
  user_indicator,
  user_permission,
  census_seating,
  seating_arrangement,
  uiConfigStore,
  tenant,
  adminConfig,
  passwordPolicy,
  forecast_year,
  forecast_segment,
  forecast_chance_category,
  forecast_plan,
  forecast_fact,
  onboarding,
  project_risk,
  role,
  api_key,
  loader,
  forecast_scenario,
  association,
  commitment,
  project_like,
};
