<template>
  <employee-time my-time-sheet></employee-time>
</template>

<script>
import EmployeeTime from "../census/user/EmployeeTime";

export default {
  name: "MyTime",
  components: { EmployeeTime },
};
</script>

<style></style>
