<template>
  <div class="onboarding-step-card-content">
    <h2>
      {{
        $tf("onboarding.ExternalIssueTrackerSyncSuccess.title|Meg is vagyunk!")
      }}
    </h2>
    <div style="width: 192px; height: 192px">
      <OnboardingSyncSuccessIllustration />
    </div>
    <div class="has-text-centered mb-4">
      <div
        class="is-flex is-align-items-center is-justify-content-center has-gap-2 mb-2"
      >
        <b-icon icon="users" class="mr-2" />
        <span class="has-font-weight-500">{{ userCount }}</span>
        <span>{{
          $tf(
            "onboarding.ExternalIssueTrackerSyncSuccess.userCount|felhasználó"
          )
        }}</span>
      </div>
      <div
        class="is-flex is-align-items-center is-justify-content-center has-gap-2"
      >
        <b-icon icon="bookmark" class="mr-2" />
        <span class="has-font-weight-500">{{ projectCount }}</span>
        <span>{{
          $tf("onboarding.ExternalIssueTrackerSyncSuccess.projectCount|projekt")
        }}</span>
      </div>
    </div>
    <div>
      <b-button
        class="onboarding-button"
        type="is-info"
        @click="$emit('nextPage', ONBOARDING_PAGES.COMPANY_PROFILE)"
      >
        {{
          $tf(
            "onboarding.ExternalIssueTrackerSyncSuccess.countinue|Tovább a konfigurációhoz"
          )
        }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";
import { mapGetters } from "vuex";
import OnboardingSyncSuccessIllustration from "@/components/onboarding/OnboardingSyncSuccessIllustration.vue";

export default {
  name: "OnboardingExternalIssueTrackerSyncSuccess",
  components: { OnboardingSyncSuccessIllustration },
  data: function () {
    return {
      ONBOARDING_PAGES,
    };
  },
  computed: {
    ...mapGetters({
      projects: "onboarding/getProjects",
      users: "onboarding/getUsers",
    }),
    userCount() {
      return this.users.length;
    },
    projectCount() {
      return this.projects.length;
    },
  },
};
</script>

<style scoped></style>
