<template>
  <div>
    <MoneyTable
      v-if="type === 'expenses'"
      :columns="expenseTable"
      :data="projectData.costs"
      :create="createExpense"
      :modify="modifyExpense"
      :delete="deleteExpense"
      :type="type"
    />
    <MoneyTable
      v-else-if="type === 'overrides'"
      :project-id="projectData.id"
      :employees="employees"
      :columns="overrideTable"
      :data="projectData.overrides"
      :create="createOverride"
      :modify="modifyOverride"
      :delete="deleteOverride"
      :type="type"
    />
  </div>
</template>

<script>
import { numberify } from "@/utils/util";
import MoneyTable from "@/components/enterprise/ProjectDetails/TabItems/MoneyTable";

export default {
  name: "MiscTabItem",
  components: { MoneyTable },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    employees: {
      type: Array,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      expenseTable: [
        {
          field: "identifier",
          label: this.$tf("projectpage.expenseTable.identifier|Megnevezés"),
        },
        {
          field: "summary",
          label: this.$tf("projectpage.expenseTable.summary|Megjegyzés"),
        },
        {
          field: "cost",
          label: this.$tf("projectpage.expenseTable.cost|Összeg"),
        },
        {
          field: "date",
          label: this.$tf("projectpage.expenseTable.date|Dátum"),
        },
      ],
      overrideTable: [
        {
          field: "displayName",
          label: this.$tf("projectpage.overrideTable.emplyeeName|Dolgozó"),
        },
        {
          field: "userId",
          label: this.$tf("projectpage.overrideTable.userId|Azonosító"),
        },
        {
          field: "cost",
          label: this.$tf("projectpage.overrideTable.cost|Összeg"),
        },
      ],
    };
  },
  methods: {
    async fetchData(force) {
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: this.projectIdentifier,
        force,
      });
      this.$emit("fetch", force);
    },
    async createOverride(override) {
      await this.$store.dispatch("enterprise_projects/createOrEditOverride", {
        projectId: this.projectData.id,
        userId: override.userId,
        cost: numberify(override.cost),
      });
      await this.fetchData(true);
    },
    async createExpense(expense) {
      await this.$store.dispatch("enterprise_projects/createOrEditExpense", {
        projectId: this.projectData.id,
        tigIdentifier: expense.tigIdentifier,
        identifier: expense.identifier,
        summary: expense.summary,
        cost: numberify(expense.cost),
        date: expense.date,
      });
      await this.fetchData(true);
    },
    async modifyOverride(override) {
      override.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditOverride", {
        projectId: this.projectData.id,
        userId: override.userId,
        cost: numberify(override.cost),
      });
      await this.fetchData(true);
    },
    async modifyExpense(expense) {
      expense.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditExpense", {
        projectId: this.projectData.id,
        tigIdentifier: expense.tigIdentifier,
        identifier: expense.identifier,
        summary: expense.summary,
        cost: numberify(expense.cost),
        date: expense.date,
      });
      await this.fetchData(true);
    },
    async deleteOverride(id) {
      await this.$store.dispatch("enterprise_projects/deleteOverride", {
        projectId: this.projectData.id,
        userId: id,
      });
      await this.fetchData(true);
    },
    async deleteExpense(identifier) {
      await this.$store.dispatch("enterprise_projects/deleteExpense", {
        projectId: this.projectData.id,
        identifier: identifier,
      });
      await this.fetchData(true);
    },
  },
};
</script>

<style scoped></style>
