import { changeTheme, localFetch, newLogger } from "@/utils/util";
import axios from "axios";
import jwtDecode from "jwt-decode";
import network from "@/utils/network";
import store from "../../index";
import { UI_THEMES } from "@/utils/const";

const JWT_STORAGE_KEY = "internal_portal_jwt";

// Private variables
const endpoints = {
  login: "/api/bulwark/auth/login",
  signin: "api/bulwark/auth/sign-in",
  resetPassword: "/api/bulwark/auth/reset-password",
  secondFactor: "/api/bulwark/auth/second-factor",
  activate: "/api/bulwark/auth/activate",
  logout: "/bulwark/session/logout",
  data: (user) => `/bulwark/session/user/${user}`,
  myData: "/bulwark/session/mine",
  allData: "/bulwark/session/all",
};
const claims = {
  grantedAuthorities: "ga",
  refreshToken: "rfsht",
  refreshGeneration: "rfshg",
  expiry: "exp",
  loggingRequired: "lgreq",
  workScheduleMode: "wrksch",
};
let logger = newLogger("SessionControl");

// Private methods
const parseJwt = (token) => {
  // src: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
  if (!token) return null;
  let jwt = jwtDecode(token);
  jwt.authorities = !!jwt[[claims.grantedAuthorities]]
    ? jwt[claims.grantedAuthorities].split(",")
    : [];
  return jwt;
};

/**
 * The session is considered logged in (fully) if the JWT is present and not expired
 */
const isLoggedIn = (jsonJwt) => {
  let now = Date.now();
  return !!jsonJwt && now < jsonJwt[claims.expiry] * 1000;
};

/**
 * The session is "ready" and considered (roughly) to be loggedIn if the JWT is present and has a refresh-token
 * or if the JWT is not expired
 */
const isReady = (jsonJwt) => {
  return isLoggedIn(jsonJwt) || (!!jsonJwt && !!jsonJwt[claims.refreshToken]);
};

/**
 * Inits the session state from the JWT stored in LocalStorage
 * If the JWT cannot be considered "ready" it is not loaded
 */
const initState = () => {
  let savedJwt = localStorage.getItem(JWT_STORAGE_KEY);
  let jsonJwt = parseJwt(savedJwt);

  let ready = isReady(jsonJwt);
  if (!ready) {
    logger.debug("No usable JWT detected on token: " + savedJwt);
    jsonJwt = {};
    savedJwt = ""; // reset the savedJwt
  } else {
    logger.debug("Usable JWT detected, considering session ready!");
  }
  return {
    encodedJwt: savedJwt,
    jsonJwt: jsonJwt,
    loginRedirect: "/",
    isReady: ready,
    reloginRequired: false,
    sessionData: {},
    isCurrentSession: false,
    selectedForecastYear: undefined,
  };
};

const actions = {
  refreshJwt: async (context, jwt) => {
    if (jwt) {
      try {
        let jsonJwt = parseJwt(jwt);
        context.commit("login", { encodedJwt: jwt, jsonJwt: jsonJwt });
      } catch (error) {
        throw "Unable to parse given JWT!";
      }
    } else {
      throw "No JWT in Login response!";
    }
  },
  login: async ({ commit }, loginRequest) => {
    try {
      let response = await axios.post(endpoints.login, loginRequest, {
        headers: {
          "X-Tenant-Id": loginRequest.tenant,
        },
      });
      let token = response.headers["x-auth-token"];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return Promise.resolve(true);
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      throw e;
    }
  },
  signin: async ({ commit }, loginRequest) => {
    try {
      let response = await axios.post(endpoints.signin, loginRequest, {
        headers: {
          "X-Tenant-Id": loginRequest.tenant,
        },
      });
      let token = response.headers["x-auth-token"];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return Promise.resolve(true);
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      throw e;
    }
  },
  resetPassword: async ({ commit }, requestData) => {
    try {
      await axios.post(endpoints.resetPassword, requestData.user, {
        headers: {
          "X-Tenant-Id": requestData.tenant,
          "Content-Type": "text/plain",
        },
      });
    } catch (e) {
      logger.warn("Error on password reset");
      logger.warn(e);
      throw e;
    }
  },
  second: async ({ commit }, solution) => {
    try {
      let response = await axios.post(endpoints.secondFactor, solution);
      logger.log(response.headers);
      let token = response.headers["x-auth-token"];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return Promise.resolve(true);
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  activate: async ({ commit }, activation) => {
    try {
      let response = await axios.post(endpoints.activate, activation);
      let token = response.headers["x-auth-token"];

      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return Promise.resolve(true);
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  logout: async (context, ids) => {
    try {
      const axios = await network.connection();
      logger.debug(`Logging out for ${ids.toString()}...`);
      let response = await axios.post(endpoints.logout, ids);
      context.commit("logout", response.data);
    } catch (err) {
      logger.error(err);
    }
  },
  logMeOut: async ({ commit }) => {
    try {
      const axios = await network.connection();
      logger.debug(`Logging out...`);
      try {
        await axios.post("bulwark/session/me/logout/this");
      } catch (ex) {
        logger.error("Couldn't log out!", ex);
      }
      commit("logout", true);
    } catch (err) {
      commit("logout", true);
      logger.error(err);
    }
  },
  logMeOutEverywhere: async (context) => {
    try {
      const axios = await network.connection();
      logger.debug(`Logging out...`);
      try {
        await axios.post("bulwark/session/me/logout/all");
      } catch (ex) {
        logger.error("Couldn't log out!", ex);
      }
      context.commit("logout", true);
    } catch (err) {
      logger.error(err);
      commit("logout", true);
    }
  },
  boot: (context) => {
    context.commit("logout", true);
  },

  fetchSessionData: async ({ commit, dispatch }, userId = undefined) => {
    try {
      const axios = await network.connection();
      logger.debug(
        `Fetching active logins for ${userId ? userId : "logged in user"}...`
      );
      let response = await axios.get(
        userId ? endpoints.data(userId) : endpoints.myData
      );
      commit("saveSessionData", {
        key: userId ? userId : "CURRENT_USER",
        data: response.data,
      });
    } catch (err) {
      logger.error(err);
    }
  },
  fetchAllSessionData: async ({ commit }) => {
    try {
      const axios = await network.connection();
      logger.debug(`Fetching active logins for all users...`);
      let response = await axios.get(endpoints.allData);
      commit("saveAllSessionData", response.data);
    } catch (err) {
      logger.error(err);
    }
  },
  updateSelectedForecastYear: ({ state }, year) => {
    state.selectedForecastYear = year;
  },
};

const mutations = {
  login: (state, { encodedJwt, jsonJwt }) => {
    state.encodedJwt = encodedJwt;
    state.jsonJwt = jsonJwt;
    state.isReady = true;
    localStorage.setItem(JWT_STORAGE_KEY, encodedJwt);

    const theme = localFetch("saved_ui_theme");

    if (theme !== undefined) {
      changeTheme(theme, false);
    } else {
      changeTheme(UI_THEMES.SYSTEM);
    }
  },
  logout: (state, response) => {
    state.isCurrentSession = response;
    if (response) {
      state.encodedJwt = "";
      state.jsonJwt = {};
      state.isReady = false;

      localStorage.setItem(JWT_STORAGE_KEY, "");

      store.dispatch("absence_admin/clearCache");
      store.dispatch("absence_request/clearCache");
      store.dispatch("admin/clearCache");
      store.dispatch("quotas/clearCache");
      store.dispatch("specialday/clearCache");
      store.dispatch("work_schedule/clearCache");
      store.dispatch("broadcast_template/clearCache");
      store.dispatch("seating_arrangement/clearCache");
      store.dispatch("census_seating/clearCache");
      store.dispatch("census_team/clearCache");
      store.dispatch("census_user/clearCache");
      store.dispatch("user_indicator/clearCache");
      store.dispatch("user_permission/clearCache");
      store.dispatch("dashboard/clearCache");
      store.dispatch("employee/clearCache");
      store.dispatch("enterprise_clients/clearCache");
      store.dispatch("enterprise_core/clearCache");
      store.dispatch("enterprise_journal/clearCache");
      store.dispatch("overtime_policies/clearCache");
      store.dispatch("overtime_requests/clearCache");
      store.dispatch("enterprise_overview/clearCache");
      store.dispatch("project_dimensions/clearCache");
      store.dispatch("enterprise_projects/clearCache");
      store.dispatch("enterprise_tiers/clearCache");
      store.dispatch("risk_management/clearCache");
      store.dispatch("project/clearCache");
      store.dispatch("tenant/clearCache");
      store.dispatch("uiConfigStore/clearCache");
      store.dispatch("worklog/clearCache");
      store.dispatch("vacation_worklog/clearCache");
      store.dispatch("unknownIssues/clearCache");
      store.dispatch("adminConfig/clearCache");
      store.dispatch("forecast_chance_category/clearCache");
      store.dispatch("forecast_scenario/clearCache");
      store.dispatch("forecast_fact/clearCache");
      store.dispatch("forecast_plan/clearCache");
      store.dispatch("forecast_segment/clearCache");
      store.dispatch("forecast_year/clearCache");
      store.dispatch("project_risk/clearCache");
      store.dispatch("project_risk/clearCache");
      store.dispatch("role/clearCache");
      store.dispatch("association/clearCache");
      store.dispatch("outsource_projects/clearCache");
      store.dispatch("support_projects/clearCache");
    }
  },
  relogin: (state) => {
    state.reloginRequired = true;
  },
  liftRelogin: (state) => {
    state.reloginRequired = false;
  },
  redirect: (state, url) => {
    state.loginRedirect = url;
  },
  saveSessionData: (state, { key, data }) => {
    state.sessionData[key] = data;
  },
  saveAllSessionData: (state, data) => {
    state.sessionData = {};
    data.forEach((login) => {
      let current = state.sessionData[login.userId];
      if (!current) {
        current = [];
      }
      current.push(login);
      state.sessionData[login.userId] = current;
    });
  },
};

const getters = {
  axios: (state) => {
    return state.axios;
  },
  isLoggedIn: (state) => {
    return isLoggedIn(state.jsonJwt);
  },
  isReady: (state) => {
    return state.isReady;
  },
  jwt: (state) => {
    return state.encodedJwt;
  },
  userId: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.sub;
    } else {
      return "UNKNOWN";
    }
  },
  isRoot: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.aud === "MASTER";
    } else {
      return false;
    }
  },
  displayName: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.fname;
    } else {
      return "ISMERETLEN";
    }
  },
  loggingRequired: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.lgreq;
    } else {
      return false;
    }
  },
  workScheduleMode: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.wrksch;
    } else {
      return undefined;
    }
  },
  refreshToken: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.rfshg;
    } else {
      return undefined;
    }
  },
  avatar: (state) => {
    if (isReady(state.jsonJwt)) {
      if (state.jsonJwt.ava && state.jsonJwt.ava !== "null") {
        return state.jsonJwt.ava;
      }
    } else {
      return null;
    }
  },
  occupation: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.tit;
    } else {
      return null;
    }
  },
  partnerId: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.ptnid;
    } else {
      return 0;
    }
  },
  selectedForecastYear: (state) => {
    if (state.selectedForecastYear) return state.selectedForecastYear;
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.fcy;
    }
    return undefined;
  },
  authorities: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.authorities;
    } else {
      return [];
    }
  },
  hasAuthority: (state) => (auth) => {
    if (!auth) return true;
    let hasAuthority = false;
    if (state.jsonJwt.authorities) {
      hasAuthority = state.jsonJwt.authorities.includes(auth);
    }
    return hasAuthority;
  },
  hasAnyAuthority:
    (state) =>
    (...auths) => {
      let hasAuthority = false;
      if (state.jsonJwt.authorities) {
        hasAuthority = state.jsonJwt.authorities.some((role) =>
          auths.includes(role)
        );
      }
      return hasAuthority;
    },
  jwtHeader: (state) => {
    return { "X-Auth-Token": state.encodedJwt };
  },
  reloginRequired: (state) => {
    return state.reloginRequired;
  },
  sessionData: (state) => (user) => {
    return state.sessionData[user];
  },
  isCurrentSession: (state) => {
    return state.isCurrentSession;
  },
  tenantId: (state) => {
    return state.jsonJwt.aud;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
