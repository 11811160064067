<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="overflow-x: auto">
      <section class="modal-card-body">
        <h1 class="title" v-if="createMode">
          {{ $tf("forecastPlan.edit.createTitle|Tervadat felvétele") }}
        </h1>
        <h1 class="title" v-else-if="parent">
          {{
            $tf("forecastPlan.edit.prospectTitle|Szűkített tervsor felvétele")
          }}
        </h1>
        <h1 class="title" v-else-if="!readOnly">
          {{ $tf("forecastPlan.edit.editTitle|Tervadat módosítása") }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf("forecastPlan.edit.readOnlyTitle|Tervadat megtekintése") }}
        </h1>
        <div v-show="loaded">
          <form>
            <b-field grouped class="mt-5">
              <b-field expanded>
                <vuelidated-input
                  :label="$tf('forecastPlan.edit.nameLabel|Tervadat neve')"
                  :placeholder="
                    $tf('forecastPlan.edit.namePlaceholder|Add meg a nevet')
                  "
                  v-model="editObject.name"
                  :validation-rule="v$.editObject.name"
                  :disabled="readOnly"
                ></vuelidated-input
              ></b-field>
              <vuelidated-input
                type="autocomplete"
                v-model="editObject.SALES_TYPE"
                :label="
                  $tf(
                    'forecastPlan.edit.salesTypeLabel|Sales típus kiválasztása'
                  )
                "
                :validation-rule="v$.editObject.SALES_TYPE"
                :disabled="
                  readOnly ||
                  parent?.SALES_TYPE ||
                  filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE)
                    .length === 1
                "
                :data="filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE)"
                :field="
                  findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE).get
                    .value
                "
                :id-field="
                  findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE).get
                    .key
                "
                append-to-body
                open-on-focus
              />

              <vuelidated-input
                type="autocomplete"
                v-model="editObject.ACCOUNT"
                :label="
                  $tf('forecastPlan.edit.accountLabel|Ügyfél kiválasztása')
                "
                :validation-rule="v$.editObject.ACCOUNT"
                :disabled="
                  readOnly || editObject.noClient || !!parent?.account?.id
                "
                :data="filterByRuleset('ACCOUNT')"
                field="name"
                id-field="id"
                append-to-body
                open-on-focus
              />
              <b-field>
                <div class="is-flex is-align-items-center mt-6">
                  <b-checkbox
                    v-model="editObject.noClient"
                    :disabled="readOnly || !!parent?.account?.id"
                    >{{
                      $tf("forecastPlan.edit.noClient|Még nincs ügyfél")
                    }}</b-checkbox
                  >
                </div>
              </b-field>
            </b-field>

            <b-field
              grouped
              v-for="(quarter, index) in Object.keys(FORECAST_YEAR_QUARTER)"
              :key="'QUARTER_' + index"
            >
              <b-field>
                <b-checkbox
                  :class="index === 0 ? 'mt-6' : 'mt-5'"
                  v-model="editObject['hasIncome' + quarter]"
                  :disabled="
                    readOnly || (parent && !parent['income' + quarter])
                  "
                  >{{ quarter }}</b-checkbox
                >
              </b-field>
              <div class="is-flex is-align-items-end mr-2">
                <vuelidated-input
                  :label="
                    index === 0
                      ? $tf('forecastPlan.edit.income|Negyedévek adatai')
                      : undefined
                  "
                  v-model="editObject['income' + quarter]"
                  :validation-rule="v$.editObject['income' + quarter]"
                  :disabled="readOnly || !editObject['hasIncome' + quarter]"
                />
                <div class="mb-1">
                  {{ currency }}
                </div>
              </div>
              <vuelidated-input
                :label="
                  index === 0
                    ? $tf(
                        'forecastPlan.edit.chanceCategory|Valószínűség kiválasztása'
                      )
                    : undefined
                "
                v-model="editObject['chanceCategory' + quarter]"
                :validation-rule="v$.editObject['chanceCategory' + quarter]"
                :disabled="readOnly || !editObject['hasIncome' + quarter]"
                type="autocomplete"
                :data="chanceCategories"
                field="name"
                id-field="id"
                append-to-body
                open-on-focus
              />
            </b-field>

            <b-checkbox
              v-model="hasIncomeNextYear"
              :disabled="readOnly || !!parent"
            >
              {{
                $tf(
                  "forecastPlan.edit.hasIncomeNextYear|A tervsor átnyúlik a következő évre"
                )
              }}</b-checkbox
            >

            <template v-if="hasIncomeNextYear">
              <h2 class="title is-6 my-2">
                {{ selectedYear + 1 }}
              </h2>
              <b-field
                grouped
                v-for="(quarter, index) in Object.keys(FORECAST_YEAR_QUARTER)"
                :key="'QUARTER_NEXT_' + index"
              >
                <b-field>
                  <b-checkbox
                    :class="index === 0 ? 'mt-6' : 'mt-5'"
                    v-model="editObject['hasIncomeNextYear' + quarter]"
                    :disabled="
                      readOnly ||
                      (parent && !parent['incomeNextYear' + quarter])
                    "
                    >{{ quarter }}</b-checkbox
                  >
                </b-field>
                <div class="is-flex is-align-items-end mr-2">
                  <vuelidated-input
                    :label="
                      index === 0
                        ? $tf('forecastPlan.edit.income|Negyedévek adatai')
                        : undefined
                    "
                    v-model="editObject['incomeNextYear' + quarter]"
                    :validation-rule="v$.editObject['incomeNextYear' + quarter]"
                    :disabled="
                      readOnly || !editObject['hasIncomeNextYear' + quarter]
                    "
                  />
                  <div class="mb-1">
                    {{ currency }}
                  </div>
                </div>
                <vuelidated-input
                  :label="
                    index === 0
                      ? $tf(
                          'forecastPlan.edit.chanceCategory|Valószínűség kiválasztása'
                        )
                      : undefined
                  "
                  v-model="editObject['chanceCategoryNextYear' + quarter]"
                  :validation-rule="
                    v$.editObject['chanceCategoryNextYear' + quarter]
                  "
                  :disabled="
                    readOnly || !editObject['hasIncomeNextYear' + quarter]
                  "
                  type="autocomplete"
                  append-to-body
                  open-on-focus
                  :data="chanceCategories"
                  field="name"
                  id-field="id"
                />
              </b-field>
            </template>

            <h2 class="title is-5 mt-5">
              {{
                $tf(
                  "forecastPlan.edit.optionalDataTitle|Opcionális adatok megadása"
                )
              }}
            </h2>
            <b-field grouped class="mt-2">
              <b-field
                :label="
                  $tf(
                    'forecastPlan.edit.incomeTypeLabel|Árbevétel típus kiválasztás'
                  )
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastPlan.edit.incomeTypePlaceholder|Válassz a listából'
                    )
                  "
                  v-model="editObject[FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE]"
                  :data="
                    filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE)
                  "
                  :field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE)
                      .get.value
                  "
                  :id-field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE)
                      .get.key
                  "
                  append-to-body
                  open-on-focus
                  :clearable="!readOnly"
                  :disabled="
                    readOnly ||
                    (parent &&
                      parent[
                        camelCase(
                          FORECAST_BASE_DATA_TYPE.ENUM.INCOME_TYPE.toLowerCase()
                        )
                      ])
                  "
              /></b-field>
              <b-field
                :label="
                  $tf('forecastPlan.edit.supervisorLabel|Felelős kiválasztás')
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastPlan.edit.supervisorPlaceholder|Válassz a listából'
                    )
                  "
                  :value="selectedSupervisor?.name"
                  :data="filteredEmployees"
                  field="name"
                  append-to-body
                  open-on-focus
                  :clearable="!readOnly"
                  @select="(option) => (editObject.supervisor = option?.id)"
                  :disabled="readOnly || parent?.userId"
              /></b-field>
              <b-field
                :label="
                  $tf('forecastPlan.edit.segmentLabel|Szegmens kiválasztás')
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastPlan.edit.segmentPlaceholder|Válassz a listából'
                    )
                  "
                  v-model="editObject.segment"
                  :data="segments"
                  field="name"
                  id-field="id"
                  append-to-body
                  open-on-focus
                  :clearable="!readOnly"
                  :disabled="readOnly || parent?.segment?.id"
              /></b-field>
              <b-field
                :label="
                  $tf(
                    'forecastPlan.edit.serviceTypeLabel|Szolgáltatás típus kiválasztás'
                  )
                "
              >
                <f-autocomplete
                  :placeholder="
                    $tf(
                      'forecastPlan.edit.serviceTypePlaceholder|Válassz a listából'
                    )
                  "
                  v-model="
                    editObject[FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE]
                  "
                  :data="
                    filterByRuleset(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE)
                  "
                  :field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE)
                      .get.value
                  "
                  :id-field="
                    findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE)
                      .get.key
                  "
                  append-to-body
                  open-on-focus
                  :clearable="!readOnly"
                  :disabled="
                    readOnly ||
                    (parent &&
                      parent[
                        camelCase(
                          FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE.toLowerCase()
                        )
                      ])
                  "
              /></b-field>
            </b-field>
            <b-field class="mt-5 is-pulled-right">
              <b-button @click="close">
                <template v-if="readOnly">
                  {{ $tf("forecastPlan.edit.close|Bezárás") }}
                </template>
                <template v-else>
                  {{ $tf("forecastPlan.edit.cancel|Mégsem") }}
                </template>
              </b-button>
              <b-button
                v-if="!readOnly"
                @click="save"
                type="is-info"
                class="ml-3"
              >
                {{ $tf("forecastPlan.edit.save|Mentés") }}
              </b-button>
            </b-field>
          </form>
        </div>
        <LoadingSkeleton v-if="!loaded" />
      </section>
    </div>
  </div>
</template>

<script>
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import LoadingSkeleton from "../../module/loading/LoadingSkeleton.vue";
import ForecastEditMixin from "@/mixins/ForecastEditMixin";
import { camelCase } from "lodash";
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "ForecastPlanEditModal",
  setup: () => ({ v$: useVuelidate() }),
  mixins: [ForecastEditMixin],
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FAutocomplete,
    LoadingSkeleton,
    VuelidatedInput,
  },
  methods: {
    camelCase,
    async save() {
      this.v$.editObject.$touch();
      if (!this.v$.editObject.$invalid) {
        this.editObject.parent = this.parent?.id;
        delete this.editObject.id;
        if (this.createMode || this.parent) {
          await this.$store.dispatch(
            "forecast_plan/createPlan",
            this.editObject
          );
        } else {
          await this.$store.dispatch(
            "forecast_plan/updatePlan",
            this.editObject
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
