<template>
  <div class="container">
    <h3 class="heading">
      {{ $tf("execDashboard.doneProjects.title|Kész projektek") }}
    </h3>
    <b-table-with-loading
      :data="doneProjects"
      hoverable
      default-sort="endDate"
      default-sort-direction="desc"
      scrollable
    >
      <b-table-column
        field="name"
        :label="$tf('execDashboard.doneProjects.table.projectName|Projekt név')"
        v-slot="props"
      >
        <router-link :to="`/projects/project/${props.row.identifier}`">
          {{ props.row.name }}
        </router-link>
      </b-table-column>

      <b-table-column
        field="income"
        :label="$tf('execDashboard.doneProjects.table.income|Bevétel')"
        sortable
        v-slot="props"
        >{{ moneyify(props.row.income) }}</b-table-column
      >

      <b-table-column
        field="budget"
        :label="$tf('execDashboard.doneProjects.table.budget|Büdzsé')"
        sortable
        v-slot="props"
        >{{ moneyify(props.row.budget) }}</b-table-column
      >

      <b-table-column
        field="spent"
        :label="$tf('execDashboard.doneProjects.table.spent|Költés')"
        sortable
        v-slot="props"
        >{{ moneyify(props.row.spent) }}</b-table-column
      >

      <b-table-column
        field="spentPercentage"
        :label="$tf('execDashboard.doneProjects.table.spentPercentage|Költés%')"
        sortable
        v-slot="props"
        >{{ percentify(props.row.spentPercentage) }}</b-table-column
      >

      <b-table-column
        field="overspending"
        :label="$tf('execDashboard.doneProjects.table.overspending|Túlköltés')"
        sortable
        v-slot="props"
        >{{ moneyify(props.row.overspending) }}</b-table-column
      >

      <b-table-column field="profit" label="Profit" sortable v-slot="props">{{
        moneyify(props.row.profit)
      }}</b-table-column>

      <b-table-column
        field="profitPercentage"
        :label="
          $tf('execDashboard.doneProjects.table.profitPercentage|Profit%')
        "
        sortable
        v-slot="props"
        >{{ percentify(props.row.profitPercentage) }}</b-table-column
      >

      <b-table-column
        field="dueDate"
        :label="$tf('execDashboard.doneProjects.table.dueDate|Határidő')"
        sortable
        v-slot="props"
        >{{ formatDate(props.row.dueDate) }}</b-table-column
      >

      <b-table-column
        field="endDate"
        :label="$tf('execDashboard.doneProjects.table.endDate|Befejezés')"
        sortable
        v-slot="props"
        >{{ formatDate(props.row.endDate) }}</b-table-column
      >

      <b-table-column
        field="delay"
        :label="$tf('execDashboard.doneProjects.table.delay|Csúszás')"
        sortable
        v-slot="props"
        >{{ props.row.delay }}</b-table-column
      >

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="frown" size="is-large"></b-icon></p>
            <p>
              {{
                $tf(
                  "execDashboard.doneProjects.empty|Nincs a megadott szűrési feltételeknek megfelelő kész projekt."
                )
              }}
            </p>
          </div>
        </section>
      </template>
    </b-table-with-loading>
  </div>
</template>

<script>
import { formatDate, moneyify, percentify } from "@/utils/util";
import { defineAsyncComponent } from "vue";

export default {
  name: "ExecutiveDashboardDoneProjects",
  components: {
    BTableWithLoading: defineAsyncComponent(() =>
      import("@/components/loading/BTableWithLoading.vue")
    ),
  },
  methods: { formatDate, percentify, moneyify },
  props: {
    doneProjects: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
