import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("RoleStore");

const urls = {
  list: "/bulwark/role",
  withId: (id) => `/bulwark/role/${id}`,
};

/**
 * Role store module
 */

const initState = () => {
  return {
    roles: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  list: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.list,
        null,
        true,
        dispatch,
        "Loading roles...",
        null,
        force
      );
      commit("saveList", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ dispatch }, role) {
    await this.remoteRequest(
      "post",
      urls.list,
      role,
      false,
      dispatch,
      "Creating role...",
      null,
      false
    );
  }.bind(storeCore),
  update: async function ({ dispatch }, role) {
    await this.remoteRequest(
      "put",
      urls.withId(role.id),
      role,
      false,
      dispatch,
      "Updating role...",
      null,
      false
    );
  }.bind(storeCore),
  delete: async function ({ dispatch }, role) {
    await this.remoteRequest(
      "delete",
      urls.withId(role.id),
      role,
      false,
      dispatch,
      "Deleting role...",
      null,
      false
    );
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveList: (state, roles) => {
    state.roles = roles;
  },
};

const getters = {
  list: (state) => {
    return state.roles;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
