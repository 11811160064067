import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("Census-Site");

const urls = {
  sites: "/census/seating-arrangement/sites",
  rooms: "/census/seating-arrangement/rooms",
  seats: "/census/seating-arrangement/seats",
  site: "/census/seating-arrangement/site",
  room: "/census/seating-arrangement/room",
  seat: "/census/seating-arrangement/seat",
  siteWithId: (id) => `/census/seating-arrangement/site/${id}`,
  roomWithId: (id) => `/census/seating-arrangement/room/${id}`,
  roomsById: (id) => `/census/seating-arrangement/rooms/${id}`,
  seatsById: (id) => `/census/seating-arrangement/seats/${id}`,
  seatsBySiteId: (id) => `/census/seating-arrangement/seats/site/${id}`,
  seatWithId: (id) => `/census/seating-arrangement/seat/${id}`,
};

const initState = () => {
  return {
    sites: [],
    rooms: [],
    seats: [],
    site: {},
    room: {},
    seat: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchSites: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.sites,
        null,
        true,
        dispatch,
        "Telephelyek betöltése...",
        null,
        force
      );
      commit("saveSites", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchRooms: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.rooms,
        null,
        true,
        dispatch,
        "Termek betöltése..."
      );
      commit("saveRooms", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchRoomsById: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.roomsById(id),
        null,
        true,
        dispatch,
        "Telephely termeinek betöltése..."
      );
      commit("saveRooms", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeats: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seats,
        null,
        true,
        dispatch,
        "Ülőhelyek betöltése..."
      );
      commit("saveSeats", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeatsById: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatsById(id),
        null,
        true,
        dispatch,
        "Terem ülőhelyeinek betöltése..."
      );
      commit("saveSeats", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeatsBySiteId: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatsBySiteId(id),
        null,
        true,
        dispatch,
        "Telephely ülőhelyeinek betöltése..."
      );
      commit("saveSeats", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSite: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.siteWithId(id),
        null,
        true,
        dispatch,
        "Telephely betöltése..."
      );
      commit("saveSite", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchRoom: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.roomWithId(id),
        null,
        true,
        dispatch,
        "Terem betöltése..."
      );
      commit("saveRoom", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSeat: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.seatWithId(id),
        null,
        true,
        dispatch,
        "Ülőhely betöltése..."
      );
      commit("saveSeat", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveSite: async function ({ dispatch }, site) {
    try {
      await this.remoteRequest(
        "put",
        urls.site,
        site,
        false,
        dispatch,
        "Telephely mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Telephely mentése folyamatban...",
          fail: "Telephely mentése sikertelen!",
          success: "Telephely mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveRoom: async function ({ dispatch }, room) {
    try {
      await this.remoteRequest(
        "put",
        urls.room,
        room,
        false,
        dispatch,
        "Terem mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Terem mentése folyamatban...",
          fail: "Terem mentése sikertelen!",
          success: "Terem mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveSeat: async function ({ dispatch }, seat) {
    try {
      await this.remoteRequest(
        "put",
        urls.seat,
        seat,
        false,
        dispatch,
        "Ülőhely mentése...",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Ülőhely mentése folyamatban...",
          fail: "Ülőhely mentése sikertelen!",
          success: "Ülőhely mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteSite: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.siteWithId(id),
        null,
        false,
        dispatch,
        "Telephely törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Telephely törlése folyamatban...",
          fail: "Telephely törlése sikertelen!",
          success: "Telephely törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteRoom: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.roomWithId(id),
        null,
        false,
        dispatch,
        "Terem törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Terem törlése folyamatban...",
          fail: "Terem törlése sikertelen!",
          success: "Terem törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteSeat: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.seatWithId(id),
        null,
        false,
        dispatch,
        "Ülőhely törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Ülőhely törlése folyamatban...",
          fail: "Ülőhely törlése sikertelen!",
          success: "Ülőhely törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveSites: (state, sites) => {
    state.sites = sites;
  },
  saveRooms: (state, rooms) => {
    state.rooms = rooms;
  },
  saveSeats: (state, seats) => {
    state.seats = seats;
  },
  saveSite: (state, site) => {
    state.site = site;
  },
  saveRoom: (state, rooms) => {
    state.room = rooms;
  },
  saveSeat: (state, seat) => {
    state.seat = seat;
  },
};

const getters = {
  sites: (state) => {
    return state.sites;
  },
  rooms: (state) => {
    return state.rooms;
  },
  seats: (state) => {
    return state.seats;
  },
  site: (state) => {
    return state.site;
  },
  room: (state) => {
    return state.room;
  },
  seat: (state) => {
    return state.seat;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
