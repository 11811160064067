<template>
  <div>
    <h3 class="title" v-if="!edit">
      {{ $tf("activityFeeForm.base.title|Új havidíj felvétele") }}
    </h3>
    <h3 class="title" v-if="edit">
      {{ $tf("activityFeeForm.base.editing_title|Havidíj módosítása") }}
    </h3>
    <div class="columns mt-5">
      <div class="column">
        <b-field
          :label="$tf('activityFeeForm.base.validFrom|Érvényesség kezdete')"
          label-position="on-border"
        >
          <b-datepicker
            v-model="feeEdit.validFrom"
            :placeholder="$tf('moneyModal.choose|Válassz')"
            icon="calendar-day"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <money-input
          v-model="feeEdit.monthlyFee"
          label-position="on-border"
          :label="$tf('activityFeeForm.base.monthlyFee|Havidíj')"
        />
      </div>
      <div v-if="activityType === 'LOCKUP'" class="column is-half">
        <money-input
          v-model="feeEdit.monthlyHours"
          label-position="on-border"
          :label="$tf('activityFeeForm.base.lockupHours|Lekötendő órák')"
          hour
        />
      </div>
      <div v-if="activityType === 'FIX'" class="column is-half">
        <money-input
          v-model="feeEdit.budget"
          label-position="on-border"
          :label="$tf('activityFeeForm.base.budget|Büdzsé')"
        />
      </div>
    </div>
    <div class="columns" v-if="activityType === 'FIX'">
      <div class="column">
        <div class="money-subtext">
          {{
            $tf("activityFeeForm.calculatedProfitRate|Kalkulált profit ráta: ")
          }}
          <span>{{ percentify(profitRate) }}</span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button type="is-info" :expanded="true" @click="submitFee">{{
          $tf("activityFeeForm.submit|Mentés")
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import {
  correctWithTimezoneOffset,
  deepCopy,
  numberify,
  percentify,
} from "@/utils/util";

export default {
  name: "ActivityFeeForm",
  components: { MoneyInput },
  emits: ["fee-submitted"],
  props: {
    activity: {
      type: Object,
      required: true,
    },
    activityType: {
      type: String,
      required: true,
    },
    feeData: {
      type: Object,
      required: false,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      feeEdit: {},
      profitRate: 0,
    };
  },
  mounted() {
    if (this.feeData) {
      this.feeEdit = deepCopy(this.feeData);
      this.feeEdit.validFrom = new Date(this.feeEdit.validFrom);
    }
  },
  methods: {
    percentify,
    async submitFee() {
      const request = deepCopy(this.feeEdit);
      request.activityId = this.activity.id;
      request.activityType = this.activityType;
      request.validFrom = correctWithTimezoneOffset(
        new Date(request.validFrom)
      );
      if (this.feeData) {
        await this.$store.dispatch("support_projects/updateFee", request);
      } else {
        await this.$store.dispatch("support_projects/createFee", request);
      }
      this.$emit("fee-submitted");
    },
  },
  watch: {
    "feeEdit.budget": function (val) {
      val = numberify(val);
      if (this.feeEdit.monthlyFee) {
        this.profitRate = 1 + (val / this.feeEdit.monthlyFee) * -1;
      } else {
        this.profitRate = 0;
      }
    },
    "feeEdit.monthlyFee": function (val) {
      if (!val) {
        this.profitRate = 0;
        return;
      }
      val = numberify(val);
      if (this.feeEdit.budget) {
        this.profitRate = 1 + (this.feeEdit.budget / val) * -1;
      } else {
        this.profitRate = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.money-subtext {
  color: $turquoise;
  font-weight: 400;
  span {
    color: $custom-dark-blue;
    font-weight: 500;
  }
}
</style>
