<template>
  <div>
    <div v-if="displayMode === 'minimal'">
      <b-tooltip
        :position="`is-${tooltipPosition}`"
        multilined
        :active="active"
        always
      >
        <figure class="image is-48x48 mx-1">
          <img
            :src="
              user?.avatar ??
              'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
            "
            class="is-rounded"
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
          />
        </figure>
        <template v-slot:content>
          <div
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
            @click.stop
          >
            {{ user?.name }}<br />
            <!-- {{ user.userId }}<br /> -->
            {{ user?.email }}<br />
            {{ $filterPhone(user?.phone) }}<br />
          </div>
        </template>
      </b-tooltip>
    </div>
    <div v-else-if="displayMode === 'long'" class="media is-align-items-center">
      <b-tooltip
        class="mx-2"
        :position="`is-${tooltipPosition}`"
        multilined
        :active="active"
        always
      >
        <figure class="image is-48x48 mx-0 is-block">
          <img
            :src="
              user?.avatar ??
              'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
            "
            class="is-rounded"
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
          />
        </figure>
        <template v-slot:content>
          <div
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
            @click.stop
          >
            {{ user?.email }}<br />
            {{ $filterPhone(user?.phone) }}<br />
          </div>
        </template>
      </b-tooltip>
      <div class="media-content">
        <p class="title is-5">{{ user?.name }}</p>
      </div>
    </div>
    <div v-else-if="displayMode === 'full'">
      <div class="media pb-2 pt-2">
        <figure class="media-left mb-auto">
          <p class="image is-64x64">
            <img
              :src="
                user?.avatar ??
                'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
              "
              class="is-rounded"
              alt="User avatar"
            />
          </p>
        </figure>
        <div
          class="media-content is-flex is-flex-direction-column is-justify-content-center"
        >
          <strong>{{ user?.name }}</strong>
          <small>{{ user?.email }} </small>
          <small>
            {{ $filterPhone(user?.phone) }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({
        userId: "",
        name: "",
        avatar: "",
        email: "",
        phone: "",
      }),
    },
    displayMode: {
      type: String,
      required: true,
      validator: (value) => ["minimal", "long", "full"].includes(value),
    },
    tooltipPosition: {
      type: String,
      validator: function (value) {
        return ["top", "right", "bottom", "left"].includes(value);
      },
    },
  },
  methods: {
    showTooltip() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = null;
      }
      this.active = true;
    },
    delayedHideTooltip() {
      this.hideTimeout = setTimeout(() => {
        this.active = false;
      }, 10);
    },
  },
};
</script>

<style scoped></style>
