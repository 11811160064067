<template>
  <section class="section">
    <div class="container is-flex is-justify-content-space-between">
      <div>
        <h1 class="title">Törzsadatok</h1>
        <h2 class="subtitle">Törzsadatok beállítása</h2>
      </div>
    </div>

    <div class="container">
      <b-tabs v-model="activeTab" type="is-toggle" class="toggle-only" expanded>
        <b-tab-item
          v-for="type in FORECAST_BASE_DATA_TYPE.ENUM"
          :key="type"
          :value="type"
          :label="$tf(FORECAST_BASE_DATA_TYPE.LABEL.TAB_ITEM[type])"
        />
      </b-tabs>

      <div v-for="type in FORECAST_BASE_DATA_TYPE.ENUM" :key="type">
        <ProjectBaseDataTab
          v-if="activeTab === type"
          :ref="type"
          :type="type"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { FORECAST_BASE_DATA_TYPE, PRIVILEGES } from "@/utils/const";
import { mapGetters } from "vuex";
import LoadingMixin from "@/mixins/LoadingMixin";
import ProjectBaseDataTab from "@/components/enterprise/base-data/ProjectBaseDataTab.vue";

export default {
  name: "ProjectBaseData",
  data() {
    return {
      FORECAST_BASE_DATA_TYPE,
      activeTab: FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE,
      carouselPage: 0,
      carouselDetailsOpen: false,
    };
  },
  components: {
    ProjectBaseDataTab,
  },
};
</script>
