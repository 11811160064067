<template>
  <section class="section has-text-centered">
    <h1 class="title">404 - Not found</h1>
    <h2 class="subtitle">
      There is nothing here. Please Check the URL and try again.
      <br />
      Hibás címre érkeztél. Kérjük ellenőrizd, hogy jól írtad-e be!
    </h2>
    <span class="icon is-large">
      <i class="fas fa-3x fa-drafting-compass"></i>
    </span>
    <br />
  </section>
</template>

<script>
export default {
  name: "PortalOther",
};
</script>
