<template>
  <div>
    <template v-if="isLockingDay">
      <div>
        <h4 class="subtitle">
          {{ $tf("logging.counter.endDay.subtitle|Nap összesítő") }}
        </h4>
        <div style="display: grid; grid-template-columns: 1fr 1fr 2fr">
          <div>
            <h4 class="subtitle">
              {{ $tf("logging.counter.endDay.projectTask|Projekt jegy") }}
            </h4>
          </div>
          <div>
            <h4 class="subtitle">
              {{ $tf("logging.counter.endDay.duration|Időtartam") }}
            </h4>
          </div>
          <div>
            <h4 class="subtitle">
              {{ $tf("logging.counter.endDay.comment|Megjegyzés") }}
            </h4>
          </div>
          <div class="has-font-weight-700 end-day-cell">FORCA-1</div>
          <div class="end-day-cell is-inline-flex">
            <b-button icon-left="minus"></b-button>
            <b-input value="1h 11m"></b-input>
            <b-button icon-left="plus"></b-button>
          </div>
          <div class="end-day-cell">
            <b-input expanded></b-input>
          </div>
          <div class="has-font-weight-700 p-2">
            {{ $tf("logging.counter.endDay.sum|Összesen") }}
          </div>
          <div class="p-2 has-text-centered">1h 11m</div>
        </div>

        <div class="mt-4">
          <p class="has-font-weight-700">
            {{
              $tf(
                "logging.counter.endDay.p1|A nap addig nem zárható le, amíg az összes rögzített sor időtartama nem kerekíthető negyed órás intervallumra!"
              )
            }}
          </p>
          <p>
            {{
              $tf(
                "logging.counter.endDay.p2|Kérlek használd a plusz/minusz gombokat a legközelebbi negyed órára kerekítéshez, utána pedig 15 perc hozzáadásához/levonásához"
              )
            }}
          </p>
        </div>
        <div class="is-inline-flex mt-4">
          <b-button
            icon-left="lock"
            type="is-primary"
            :disabled="true"
            class="mr-2"
            >{{ $tf("logging.counter.closeDay|Nap zárása") }}</b-button
          >
          <b-button @click="isLockingDay = false">{{
            $tf("logging.counter.cancel|Mégsem")
          }}</b-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <vue-cal
          hide-view-selector
          hide-title-bar
          active-view="day"
          :time-from="7 * 60"
          :time-to="19 * 60"
          :drag-to-create-threshold="15"
          :selected-date="now"
          :locale="storedLocale"
          :editable-events="{
            title: false,
            drag: true,
            resize: true,
            delete: true,
            create: true,
          }"
        >
          <template #no-event>
            <span></span>
          </template>
        </vue-cal>
      </div>
      <div class="mt-4">
        <h4 class="subtitle">
          {{ $tf("logging.counter.tasks.subtitle|Projekt jegyek") }}
        </h4>
        <div class="is-inline-flex" style="width: 100%">
          <div class="is-inline-flex">
            <b-field class="mr-2">
              <b-checkbox-button v-model="starredFilter" native-value="starred">
                <b-icon icon="star" pack="fas" class="mx-auto"></b-icon>
              </b-checkbox-button>
            </b-field>
            <b-field class="mr-2">
              <b-checkbox-button v-model="recentFilter" native-value="recent">
                <b-icon
                  icon="clock-rotate-left"
                  pack="fas"
                  class="mx-auto"
                ></b-icon>
              </b-checkbox-button>
            </b-field>
            <b-field>
              <b-input
                v-model="projectSearch"
                icon-right="search"
                :placeholder="
                  $tf('logging.counter.placeholder.search|Keresés...')
                "
              ></b-input>
            </b-field>
          </div>
          <div class="ml-auto">
            <b-button icon-left="plus" class="mr-2" type="is-primary">{{
              $tf("logging.counter.addLine|Sor hozzáadása")
            }}</b-button>
            <b-button
              icon-left="lock"
              type="is-primary"
              @click="isLockingDay = true"
              >{{ $tf("logging.counter.closeDay|Nap zárása") }}</b-button
            >
          </div>
        </div>
        <div>
          <b-dropdown
            aria-role="list"
            inline
            :disabled="tasks.length === 0"
            expanded
          >
            <template v-if="tasks.length === 0">
              <b-dropdown-item>{{
                $tf("logging.counter.tasks.empty|Nincsenek jegyek")
              }}</b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item
                :v-for="task in tasks"
                :key="task.id"
                aria-role="listitem"
                >{{ `${task.identifier} - ${task.name}` }}</b-dropdown-item
              >
            </template>
          </b-dropdown>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { formatDate, localFetch } from "@/utils/util";

export default {
  name: "LoggingCounter",
  components: { VueCal },
  data() {
    return {
      formatDate,
      now: new Date(),
      events: [],
      storedLocale: localFetch("stored_locale") ?? "hu",
      starredFilter: [],
      recentFilter: [],
      projectSearch: null,
      tasks: [],
      isLockingDay: false,
    };
  },
};
</script>

<style lang="scss">
.end-day-cell {
  border-bottom: 2px rgb(128, 128, 128) solid;
  padding: 0.5rem;
}
</style>
