<template>
  <section class="section">
    <div class="back-button-container">
      <b-button
        icon-left="chevron-left"
        :label="$tf('teamEdit.backToList|Csapatok')"
        @click="cancel"
      />
    </div>

    <b-modal
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      v-model="isModalOpen"
      v-on:close="isModalOpen = false"
    >
      <team-member-selector
        :is-lead="isLead"
        :members="members"
        v-on:save="(selected, role) => onSave(selected, role)"
      />
    </b-modal>

    <div class="container">
      <h1 class="title">
        {{
          originalTeamName
            ? $tf("teamEdit.editTitle|{name} csapat módosítása", {
                name: originalTeamName,
              })
            : $tf("teamEdit.newTitle|Új csapat létrehozása")
        }}
      </h1>
      <h3 class="heading">{{ $tf("teamEdit.baseData|Alapadatok") }}</h3>

      <b-field grouped>
        <b-field
          :label="$tf('teamEdit.identifier|Azonosító')"
          label-position="on-border"
          class="is-long"
        >
          <b-input
            type="text"
            :disabled="originalTeamName"
            v-model="team.identifier"
            :placeholder="$tf('teamEdit.identifier.placeholder|Azonosító')"
          ></b-input>
        </b-field>

        <vuelidated-input
          :label="$tf('teamEdit.teamName|Csapat neve')"
          :placeholder="$tf('teamEdit.teamName.placeholder|Csapat neve')"
          v-model="team.name"
          :validation-rule="v$.team.name"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>
      </b-field>
    </div>

    <div class="container">
      <h1 class="title">
        {{ $tf("teamEdit.assembleTeam|Csapat összeállítása") }}
      </h1>

      <h3 class="heading mb-2">
        <portal-icon icon="lead" type="is-title" class="mr-1" />
        {{ $tf("teamEdit.leadSelect.heading|Vezetők kiválasztása") }}
      </h3>

      <h2 class="subtitle">
        {{ $tf("teamEdit.leadSelect.info|Válaszd ki a csapat vezetőit.") }}
      </h2>

      <b-button type="is-info" @click="addLead"
        ><portal-icon icon="plus-circle" class="mr-2" />
        {{ $tf("teamEdit.leadSelect.add|Új vezető") }}
      </b-button>

      <team-member-viewer
        :members="team.leads"
        class="mt-5"
        @delete="onLeadDelete"
      ></team-member-viewer>

      <hr />

      <h3 class="heading mb-2">
        <portal-icon icon="team-members" type="is-title" class="mr-2" />
        {{ $tf("teamEdit.memberSelect.heading|Csapattagok kiválasztása") }}
      </h3>

      <h2 class="subtitle">
        {{ $tf("teamEdit.memberSelect.info|Válaszd ki a csapat tagjait.") }}
      </h2>

      <b-button type="is-info" @click="addTeamMember"
        ><portal-icon icon="plus-circle" class="mr-2" />{{
          $tf("teamEdit.memberSelect.add|Új csapattag")
        }}
      </b-button>

      <team-member-viewer
        :members="team.employees"
        class="mt-5"
        @delete="onEmployeeDelete"
      ></team-member-viewer>
    </div>

    <div class="save-button-container">
      <div class="buttons-wrapper">
        <b-button @click="cancel">
          {{ $tf("teamEdit.cancel|Mégse") }}
        </b-button>
        <b-button icon-left="save" type="is-info" @click="saveTeam">
          {{ $tf("teamEdit.save|Mentés") }}
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import VuelidatedInput from "../../../components/module/input/VuelidatedInput";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import PortalIcon from "../../../components/module/icon/PortalIcon";
import TeamMemberViewer from "../../../components/census/team/TeamMemberViewer";
import TeamMemberSelector from "../../../components/census/team/TeamMemberSelector";
import { openDeleteConfirm } from "@/utils/util";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "TeamEdit",
  components: {
    TeamMemberSelector,
    TeamMemberViewer,
    PortalIcon,
    VuelidatedInput,
  },
  mixins: [LoadingMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    team: {
      name: {
        required,
      },
    },
  },
  data() {
    return {
      originalTeamName: undefined,
      team: {
        identifier: "",
        name: "",
        leads: [],
        employees: [],
      },
      isNew: true,
      isModalOpen: false,
      isLead: true,
    };
  },
  async mounted() {
    await this.doStartLoading();
    await this.$store.dispatch("employee/fetchColleagues");
    await this.$store.dispatch("user_indicator/fetch");

    let id = this.$route.params.id;
    if (id) {
      await this.$store.dispatch("census_team/fetchTeam", id);
      this.team = this.$store.getters["census_team/team"];
      this.originalTeamName = this.team.name;
      this.isNew = false;
    }
    await this.doFinishLoading();
  },
  computed: {
    members() {
      return this.isLead ? this.team.leads : this.team.employees;
    },
  },
  methods: {
    async cancel(force) {
      await this.$store.dispatch("census_team/fetchTeams", force);
      await this.$router.push({
        name: "Teams",
      });
    },
    async saveTeam() {
      this.v$.team.$touch();
      if (!this.v$.team.$invalid) {
        await this.$store.dispatch("census_team/saveTeam", this.team);
        await this.cancel(true);
      }
    },
    addLead() {
      this.isLead = true;
      this.isModalOpen = true;
    },
    addTeamMember() {
      this.isLead = false;
      this.isModalOpen = true;
    },
    onSave(selected, role) {
      if (this.isLead) {
        selected.forEach((employee) => {
          this.team.leads.push({
            employeeId: employee.id,
            role: role,
          });
        });
      } else {
        selected.forEach((employee) => {
          this.team.employees.push({
            employeeId: employee.id,
          });
        });
      }
      this.isModalOpen = false;
    },
    onEmployeeDelete(member) {
      openDeleteConfirm(this.$buefy, () => {
        this.team.employees = this.team.employees.filter(
          (employee) => employee.employeeId !== member.id
        );
      });
    },
    onLeadDelete(member) {
      openDeleteConfirm(this.$buefy, () => {
        let toDelete = this.team.leads
          .filter(
            (lead) => lead.employeeId === member.id && lead.role === member.role
          )
          .shift();
        this.team.leads = this.team.leads.filter((lead) => lead !== toDelete);
      });
    },
  },
};
</script>
