import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Census-UserPermission");

const urls = {
  permissionWithId: (id) => `/census/users/permission/${id}`,
  permission: "/census/users/permission",
  permissions: "/census/users/permissions",
  tenantPermissions: (id) => `/tenant/cross/census/users/permissions/${id}`,
  toApprove: "/census/users/permits/to-approve",
  permits: "/census/users/permits",
  permitWithId: (id) => `/census/users/permit/${id}`,
  deactivate: (userId) => `/census/users/permits/deactivate/${userId}`,
  userPermits: (userId) => `/census/users/permits/user/${userId}`,
  mine: "/census/users/permits/mine",
};

const initState = () => {
  return {
    permissions: [],
    toApproveRequests: {
      items: [],
    },
    permits: {},
    userPermits: [],
    permit: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.permissions,
        null,
        true,
        dispatch,
        "Hozzáférések letöltése...",
        null,
        force
      );
      commit("savePermissions", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchForTenant: async function ({ commit, dispatch }, tenantId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tenantPermissions(tenantId),
        null,
        true,
        dispatch,
        "Hozzáférések letöltése...",
        null,
        true
      );
      commit("savePermissions", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ dispatch }, permission) {
    try {
      await this.remoteRequest(
        "put",
        urls.permissionWithId(permission.id),
        permission,
        false,
        dispatch,
        "Hozzáférések frissítése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Hozzáférések frissítése folyamatban...",
          fail: "Hozzáférések frissítése sikertelen!",
          success: "Hozzáférések frissítése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.permission,
        request,
        false,
        dispatch,
        "Hozzáférések létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Hozzáférések létrehozása folyamatban...",
          fail: "Hozzáférések létrehozása sikertelen!",
          success: "Hozzáférések létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.permissionWithId(id),
        null,
        false,
        dispatch,
        "Hozzáférések törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Hozzáférések törlése folyamatban...",
          fail: "Hozzáférések törlése sikertelen!",
          success: "Hozzáférések törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchPermits: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.permits,
        params,
        true,
        dispatch,
        "Hozzáférések betöltése...",
        null,
        force
      );
      commit("setPermits", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchUserPermits: async function ({ commit, dispatch, state }, userId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.userPermits(userId),
        null,
        true,
        dispatch,
        "Hozzáférések betöltése..."
      );
      commit("setUserPermits", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMyPermits: async function ({ commit, dispatch, state }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.mine,
        null,
        true,
        dispatch,
        "Hozzáférések betöltése..."
      );
      commit("setUserPermits", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getPermissionRequestToApprove: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.toApprove,
        params,
        true,
        dispatch,
        "Hozzáférésváltoztatási kérelmek betöltése...",
        null,
        force
      );
      commit("setToApproveRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getPermit: async function ({ commit, dispatch, state }, permitId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.permitWithId(permitId),
        null,
        true,
        dispatch,
        "Hozzáférés betöltése..."
      );
      commit("setPermit", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deactivateUser: async function ({ dispatch }, userId) {
    try {
      await this.remoteRequest(
        "patch",
        urls.deactivate(userId),
        null,
        false,
        dispatch,
        "Deaktiválás",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            userId + " azonosítójú felhasználó dekativálása folyamatban...",
          fail: userId + " azonosítójú felhasználó deaktiválása sikertelen!",
          success: userId + " azonosítójú felhasználó deaktiválva!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  approve: async function ({ dispatch }, permitId) {
    try {
      await this.remoteRequest(
        "patch",
        urls.permitWithId(permitId) + "/approve",
        null,
        true,
        dispatch,
        "Kérelem elfogadása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Kérelem elfogadása folyamatban...",
          fail: "Kérelem elfogadása sikertelen!",
          success: "Kérelem elfogadása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deny: async function ({ dispatch }, permitId) {
    try {
      await this.remoteRequest(
        "patch",
        urls.permitWithId(permitId) + "/deny",
        null,
        true,
        dispatch,
        "Kérelem elutasítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Kérelem elutasítása folyamatban...",
          fail: "Kérelem elutasítása sikertelen!",
          success: "Kérelem elutasítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  savePermissions: (state, permissions) => {
    state.permissions = permissions;
  },
  setPermits: (state, data) => {
    state.permits = data;
  },
  setUserPermits: (state, data) => {
    state.userPermits = data;
  },
  setToApproveRequests: (state, data) => {
    state.toApproveRequests = data;
  },
  setPermit: (state, data) => {
    state.permit = data;
  },
};

const getters = {
  permissions: (state) => {
    return state.permissions;
  },
  permits: (state) => {
    return state.permits;
  },
  userPermits: (state) => {
    return state.userPermits;
  },
  toApproveRequests: (state) => {
    return state.toApproveRequests;
  },
  permit: (state) => {
    return state.permit;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
