<template>
  <section class="section">
    <div class="back-button-container">
      <b-button icon-left="chevron-left" label="Ülések" @click="cancel" />
    </div>

    <div class="container">
      <h1 class="title">
        {{
          originalSeatName
            ? `${originalSeatName} módosítása`
            : "Új ülés létrehozása"
        }}
      </h1>
      <h3 class="heading">Alapadatok</h3>

      <b-field grouped>
        <vuelidated-input
          label="Azonosító"
          placeholder="Azonosító"
          v-model="seat.identifier"
          :validation-rule="v$.seat.identifier"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>

        <vuelidated-input
          label="Ülés leírása"
          placeholder="Ülés leírása"
          v-model="seat.description"
          :validation-rule="v$.seat.description"
          label-position="on-border"
          class="is-long"
        ></vuelidated-input>
      </b-field>

      <b-field grouped>
        <vuelidated-input
          label="Terem neve"
          placeholder="Terem neve"
          v-model="roomIdentifier"
          :validation-rule="v$.seat.description"
          label-position="on-border"
          class="is-long"
          disabled
        ></vuelidated-input>
      </b-field>
    </div>

    <div class="save-button-container">
      <div class="buttons-wrapper">
        <b-button @click="cancel"> Mégse </b-button>
        <b-button icon-left="save" type="is-info" @click="saveSeat">
          Mentés
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import VuelidatedInput from "../../../components/module/input/VuelidatedInput";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import eventBus from "../../../utils/eventBus";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "SeatEdit",
  components: {
    VuelidatedInput,
  },
  mixins: [LoadingMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    seat: {
      description: {
        required,
      },
      identifier: {
        required,
      },
    },
  },
  data() {
    return {
      originalSeatName: undefined,
      roomIdentifier: "",
      seat: {
        identifier: "",
        description: "",
        roomId: 0,
      },
      props: {
        room: {
          type: String,
        },
      },
    };
  },
  async mounted() {
    await this.doStartLoading();

    let id = this.$route.params.id;
    let room = this.$route.params.room;

    if (id) {
      await this.$store.dispatch("census_seating/fetchSeat", id);
      this.seat = this.$store.getters["census_seating/seat"];
      this.originalSeatName = this.seat.identifier;
    }

    if (room) {
      this.seat.roomId = room.id;
      this.roomIdentifier = room.name;
    } else {
      await this.cancel();
    }

    await this.doFinishLoading();
  },
  methods: {
    async cancel(force) {
      await this.$store.dispatch("census_seating/fetchSites", force);
      await this.$router.push("/settings/seating");
    },
    async saveSeat() {
      this.v$.seat.$touch();
      if (!this.v$.seat.$invalid) {
        await this.$store.dispatch("census_seating/saveSeat", this.seat);
        await this.cancel(true);
      }
    },
  },
};
</script>
