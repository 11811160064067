<template>
  <div class="main">
    <div v-if="header" class="top is-flex is-justify-content-center">
      <div>
        <p>{{ header }}</p>
      </div>
    </div>
    <div class="bottom has-font-weight-500 is-flex is-justify-content-center">
      <div>
        <template v-if="link">
          <a
            :href="createTicketUrl(text)"
            target="_blank"
            :title="text"
            style="font-size: 12px"
            >{{ text }}</a
          >
        </template>
        <p v-else v-html="text.replaceAll('\n', '<br>')" />
      </div>
    </div>
  </div>
</template>

<script>
import { createTicketUrl } from "@/utils/util";

export default {
  name: "TigDetailBox",
  methods: { createTicketUrl },
  props: {
    header: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

p {
  font-size: 12px;
}
.main {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $white;
}
.top {
  color: $custom-grayer-blue;
}
.bottom {
  color: $custom-dark-blue;
}
</style>
