import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("Enterprise-ForecastYearStore");

const urls = {
  years: "/enterprise/forecast/year",
  closePlanning: (year) => `/enterprise/forecast/year/planning/close/${year}`,
  openPlanning: (year) => `/enterprise/forecast/year/planning/open/${year}`,
  closeQuarter: (year, quarter) =>
    `/enterprise/forecast/year/fact/close-quarter/${year}/${quarter}`,
  openQuarter: (year, quarter) =>
    `/enterprise/forecast/year/fact/open-quarter/${year}/${quarter}`,
};

const initState = () => {
  return {
    years: [],
    closedYears: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchYears: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.years,
        undefined,
        true,
        dispatch,
        "Évek betöltése",
        null,
        force
      );
      commit("saveYears", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchClosedYears: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.years + "/closed",
        undefined,
        true,
        dispatch,
        "Évek betöltése",
        null,
        force
      );
      commit("saveClosedYears", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  closePlanning: async function ({ dispatch }, year) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.closePlanning(year ?? "null"),
        cacheable: false,
        dispatch,
        flair: "Tervezés lezárása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  openPlanning: async function ({ dispatch }, year) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.openPlanning(year ?? "null"),
        cacheable: false,
        dispatch,
        flair: "Tervezés megnyitása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  closeQuarter: async function ({ dispatch }, { year, quarter }) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.closeQuarter(year ?? "null", quarter),
        cacheable: false,
        dispatch,
        flair: "Negyedév lezárása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  openQuarter: async function ({ dispatch }, { year, quarter }) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.openQuarter(year ?? "null", quarter),
        cacheable: false,
        dispatch,
        flair: "Negyedév újranyitása...",
        toast: true,
      });
      dispatch("clearCache");
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveYears: (state, data) => {
    state.years = data;
  },
  saveClosedYears: (state, data) => {
    state.closedYears = data;
  },
};

const getters = {
  years: (state) => {
    return state.years;
  },
  closedYears: (state) => {
    return state.closedYears;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
