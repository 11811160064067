<template>
  <div>
    <span class="uploadedFile m-1 mb-2" v-for="file in v" :key="file.name"
      >{{ file.name
      }}<a @click="removeSelect(file)"
        ><b-icon icon="xmark" size="is-small" class="xmark"></b-icon
      ></a>
    </span>
    <b-field class="file">
      <b-upload v-model="v" class="file-label" multiple>
        <span class="file-cta avatar-upload">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">{{
            $tf("multifileUpload.uploadDocuments|Dokumentumok feltöltése")
          }}</span>
        </span>
      </b-upload>
      <b-button
        v-if="v.length !== 0"
        class="ml-2"
        type="is-danger"
        @click="removeAll"
      >
        {{ $tf("multifileUpload.removeAll|Dokumentumok visszavonása") }}
      </b-button>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "MultifileUpload",
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
  },
  watch: {
    selectedOptions(val) {
      this.$emit("update:modelValue", val);
    },
  },
  data() {
    return {
      v: this.$props["modelValue"],
    };
  },
  methods: {
    removeSelect(option) {
      this.v = this.v.filter((element) => {
        return element !== option;
      });
    },
    removeAll() {
      this.v = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.uploadedFile {
  background-color: $grey-lightest;
  color: $custom-grayer-blue;
  border-radius: 1rem;
  padding: 0.5rem;
  position: relative;
  display: inline-block;
  width: auto;
}
.xmark {
  vertical-align: middle;
  padding-bottom: 0.2rem;
  color: $custom-grayer-blue;
}
</style>
