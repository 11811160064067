<template>
  <b-tab-item
    :label="$tf('employees.tabs.employees|Kollégák')"
    icon="user-alt"
    type="is-primary"
  >
    <b-table
      :data="filterByExEmployees"
      ref="table"
      paginated
      per-page="30"
      detailed
      detail-key="email"
      :show-detail-icon="true"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
      :row-class="employeeClass"
    >
      <b-table-column field="avatar" label="Avatar" v-slot="props">
        <!-- v-if="props.row.avatar"> -->
        <figure class="media-left">
          <p class="image is-96x96">
            <img
              class="is-rounded"
              :src="
                props.row.avatar
                  ? props.row.avatar
                  : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
              "
              alt="User Avatar"
            />
          </p>
        </figure>
      </b-table-column>

      <b-table-column
        field="name"
        :label="$tf('employees.table.name|Név')"
        v-slot="props"
        sortable
        :custom-sort="
          (a, b, isAsc) =>
            isAsc
              ? Intl.Collator('hu').compare(a.name, b.name)
              : Intl.Collator('hu').compare(b.name, a.name)
        "
        searchable
      >
        {{ props.row.name }} <br />
      </b-table-column>

      <!--              <b-table-column field="handle" label="Kulcs" sortable v-slot="props">-->
      <!--                {{ props.row.handle }}-->
      <!--              </b-table-column>-->

      <b-table-column
        field="contact"
        :label="$tf('employees.table.contact|Elérhetőség')"
        v-slot="props"
        sortable
        searchable
      >
        <p>
          {{ thisOrDefault(props.row.email) }}<br />
          {{
            $filterPhone(
              !!props.row.contact ? props.row.contact : props.row.phone
            )
          }}
        </p>
      </b-table-column>

      <b-table-column
        field="title"
        :label="$tf('employees.table.title|Titulus/Terület')"
        searchable
        v-slot="props"
      >
        <p>
          <strong> {{ props.row.title }}</strong> <br />
          {{ props.row.department }}
        </p>
      </b-table-column>

      <template #detail="props">
        <div class="ml-1 mr-1 container content">
          <p
            class="has-text-centered is-italic subtitle is-5"
            v-if="props.row.flair"
          >
            <sub><b-icon icon="quote-left" size="is-small" /> </sub>
            {{ props.row.flair }}
            <sup><b-icon icon="quote-right" size="is-small" /></sup>
          </p>
          <img
            v-if="props.row.portraitLink"
            :src="props.row.portraitLink"
            alt="Portrait Picture"
          />
          <template v-if="props.row.competencies">
            <h3 class="heading">
              {{ $tf("employees.details.competencies|Kompetenciák") }}
            </h3>
            <b-field expanded>
              <b-taglist>
                <b-tag
                  v-for="(competency, index) in props.row.competencies"
                  :key="index"
                  icon="book"
                  type="is-light"
                >
                  {{ competency.name }}
                </b-tag>
              </b-taglist>
            </b-field>
          </template>
          <div
            class="is-flex is-align-items-center mb-5"
            style="gap: 8px"
            v-if="props.row.parentCompany"
          >
            <b-tag type="is-warning" size="is-medium">{{
              $tf("employees.details.external|Külsős")
            }}</b-tag>
            {{ props.row.parentCompany }}
          </div>

          <b-table :data="[props.row]" class="colleagues-table">
            <b-table-column
              field="seniority"
              :label="$tf('employees.table.seniority|Szenioritás')"
              header-class="colleagues-table-header"
              cell-class="colleagues-table-cell"
              v-slot="propz"
              >{{ thisOrDefault(propz.row.seniority) }}</b-table-column
            >
            <b-table-column
              field="startDate"
              :label="$tf('employees.table.start|Munkaviszony kezdete')"
              header-class="colleagues-table-header"
              cell-class="colleagues-table-cell"
              v-slot="propz"
              >{{ thisOrDefault(propz.row.startDate) }}</b-table-column
            >
            <b-table-column
              field="endDate"
              :label="$tf('employees.table.end|Munkaviszony vége')"
              header-class="colleagues-table-header"
              cell-class="colleagues-table-cell"
              :visible="!!props.row.endDate"
              v-slot="propz"
              >{{ thisOrDefault(propz.row.endDate) }}</b-table-column
            >
            <b-table-column
              field="birthDate"
              :label="$tf('employees.table.birthday|Születésnap')"
              header-class="colleagues-table-header"
              cell-class="colleagues-table-cell"
              v-slot="propz"
              >{{ thisOrDefault(propz.row.birthDate) }}</b-table-column
            >
          </b-table>
        </div>
      </template>
    </b-table>
    <b-switch v-model="isShowingExEmployees">{{
      $tf("employees.showExes|Ex-kollégák megjelenítése")
    }}</b-switch>
  </b-tab-item>
</template>

<script>
import { and, maxLength, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import FilteredExEmployeesMixin from "../../../mixins/FilteredExEmployeesMixin";
import { PRIVILEGES } from "@/utils/const";

export default {
  name: "EmployeeListEmployeeTab",
  components: {},
  mixins: [FilteredExEmployeesMixin],
  props: {
    employees: {
      type: Array,
      required: true,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      name: "",
      userData: {
        phoneNumber: null,
        privatePhoneNumber: null,
      },
      masks: {
        phoneNumber: {
          delimiter: "-",
          blocks: [3, 2, 3, 4],
        },
        privatePhoneNumber: {
          delimiter: "-",
          blocks: [3, 2, 3, 4],
        },
      },
      masked: {},
      isShowingExEmployees: false,
    };
  },
  validations: {
    userData: {
      phoneNumber: {
        length: and(minLength(12), maxLength(12)),
      },
      privatePhoneNumber: {
        length: and(minLength(12), maxLength(12)),
      },
    },
  },
  methods: {
    save(userId) {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let employee = this.employees.filter((emp) => {
          return emp.id === userId;
        });
        employee[0]["active"] = true;
        this.$store.dispatch("employee/update", employee[0]).then(() => {
          this.showModify = false;
        });
      }
    },
    thisOrDefault(self) {
      return self !== undefined && self !== null && self !== ""
        ? self
        : "Ismeretlen";
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.employee-ex {
  td {
    font-style: italic !important;
    color: $grey-lighter;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/colors.scss";
.colleagues-table {
  border-radius: 8px 8px 0 0 !important;
}
.colleagues-table-header {
  &:first-of-type {
    border-top-left-radius: 8px !important;
  }
  &:last-of-type {
    border-top-right-radius: 8px !important;
  }
  background: $blue !important;
  .th-wrap {
    span {
      color: $white !important;
    }
  }
}
.colleagues-table-cell {
  &:first-of-type {
    border-bottom-left-radius: 8px !important;
  }
  &:last-of-type {
    border-bottom-right-radius: 8px !important;
  }
  height: 52px !important;
  color: $black !important;
  background: $grey-lightest;
}
</style>
