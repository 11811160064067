<template>
  <section class="section">
    <div class="container">
      <div class="is-flex">
        <h1 class="title">{{ $tf("projectList.title|Projektek") }}</h1>
        <b-button
          type="is-info"
          class="ml-auto"
          icon-left="plus-circle"
          @click="goToCreate(activeTab)"
          >{{ $tf("projectList.createProject|Projekt létrehozása") }}</b-button
        >
      </div>

      <b-tabs type="is-toggle" v-model="activeTab" class="toggle-only">
        <b-tab-item
          :value="PROJECT_TABS.ALL"
          :label="$tf('projectList.tab.all|Összesített')"
          icon="stream"
          icon-pack="fas"
        />
        <b-tab-item
          :value="PROJECT_TABS.SIMPLE"
          :label="$tf('projectList.tab.simple|Hagyományos')"
          icon="long-arrow-alt-right"
          icon-pack="fas"
        />
        <b-tab-item
          :value="PROJECT_TABS.SUPPORT"
          :label="$tf('projectList.tab.support|Folyamatos teljesítésű')"
          icon="headset"
          icon-pack="fas"
        />
        <b-tab-item
          :value="PROJECT_TABS.OUTSOURCE"
          :label="$tf('projectList.tab.outsource|Outsource')"
          icon="handshake"
          icon-pack="fas"
        />
      </b-tabs>
      <div class="is-flex is-flex-direction-column has-gap-3">
        <div
          class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
          style="row-gap: 12px"
        >
          <div>
            <b-checkbox-button
              :modelValue="!saveObjects.showOnlyLiked.value"
              class="p-0 checkbox-button"
              @update:modelValue="
                saveObjects.showOnlyLiked.value =
                  !saveObjects.showOnlyLiked.value
              "
            >
              <b-icon pack="far" icon="heart" size="is-large" class="m-0 p-0" />
            </b-checkbox-button>
          </div>

          <div>
            <b-checkbox-button
              :modelValue="!saveObjects.showOnlyStarred.value"
              class="p-0 checkbox-button"
              @update:modelValue="
                saveObjects.showOnlyStarred.value =
                  !saveObjects.showOnlyStarred.value
              "
            >
              <b-icon pack="far" icon="star" size="is-large" class="m-0 p-0" />
            </b-checkbox-button>
          </div>

          <div>
            <b-field
              :label="$tf('projectList.filter.status|Projekt státusz')"
              label-position="on-border"
            >
              <multiselect-dropdown
                :items="projectStatuses"
                v-model="saveObjects.selectedProjectStatuses.value"
                has-select-all-option
                :none-selected-text="$tf('projectList.filter.all|Mind')"
              ></multiselect-dropdown>
            </b-field>
          </div>

          <div>
            <b-field
              :label="$tf('projectList.filter.type|Projekt típus')"
              label-position="on-border"
            >
              <multiselect-dropdown
                :items="projectTypes"
                v-model="saveObjects.selectedProjectTypes.value"
                has-select-all-option
                :none-selected-text="$tf('projectList.filter.all|Mind')"
              ></multiselect-dropdown>
            </b-field>
          </div>

          <div>
            <b-field label="Projekt szín" label-position="on-border">
              <multiselect-dropdown
                :items="projectColors"
                nameField="colorName"
                identifierField="id"
                v-model="saveObjects.selectedProjectColors.value"
                has-select-all-option
                :none-selected-text="$tf('projectList.filter.all|Mind')"
              ></multiselect-dropdown>
            </b-field>
          </div>

          <div v-for="(dimension, index) in projectDimensions" :key="index">
            <b-field
              :label="dimension.dimensionName"
              label-position="on-border"
            >
              <multiselect-dropdown
                :items="dimension.values"
                identifier-field="id"
                has-select-all-option
                :none-selected-text="$tf('projectList.filter.all|Mind')"
                :modelValue="getDimensionValues(dimension.dimensionName)"
                @update:modelValue="
                  (val) => saveDimensionValues(dimension.dimensionName, val)
                "
              ></multiselect-dropdown>
            </b-field>
          </div>

          <div>
            <b-field
              :label="$tf('projectList.filter.mixed|Vegyes keresés')"
              label-position="on-border"
            >
              <b-input
                v-model="saveObjects.search.value"
                icon="magnifying-glass"
                :placeholder="
                  $tf('projectList.filter.mixed.placeholder|Keresés...')
                "
                style="width: 292px"
              ></b-input>
            </b-field>
          </div>

          <div>
            <b-field>
              <b-switch
                size="is-small"
                v-if="
                  activeTab !== PROJECT_TABS.AGGREGATED && canSeeAllProjects
                "
                v-model="saveObjects.showOnlyMine.value"
              >
                {{
                  $tf(
                    "projectList.filter.onlyMine|Csak saját projektek mutatása"
                  )
                }}
              </b-switch>
            </b-field>
          </div>
        </div>
        <div>
          <b-collapse
            :open="saveObjects.showDetailedFilters.value"
            position="is-bottom"
            aria-id="showDetailedFilters"
            @open="saveObjects.showDetailedFilters.value = true"
            @close="saveObjects.showDetailedFilters.value = false"
          >
            <template #trigger="props">
              <a
                aria-controls="showDetailedFilters"
                :aria-expanded="props.open"
              >
                <div class="is-flex has-gap-2 is-align-items-center mt-2">
                  <template v-if="!props.open">
                    <p class="m-0">
                      {{
                        $tf(
                          "projectList.filter.showDetailedFilters|Részletes szűrő mutatása"
                        )
                      }}
                    </p>
                    <b-icon icon="chevron-down" size="is-small"></b-icon>
                  </template>
                  <template v-else>
                    <p class="m-0">
                      {{
                        $tf(
                          "projectList.filter.hideDetailedFilters|Részletes szűrő elrejtése"
                        )
                      }}
                    </p>
                    <b-icon icon="chevron-up" size="is-small"></b-icon>
                  </template>
                </div>
              </a>
            </template>

            <div
              class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap"
              style="row-gap: 12px"
            >
              <div>
                <b-field
                  :label="$tf('projectList.filter.projectKPI|Projekt KPI')"
                  label-position="on-border"
                  class="field-has-label-mb-0"
                >
                  <multiselect-dropdown
                    :value="saveObjects.selectedRiskKPIs.value"
                    :items="projectRisks"
                    identifier-field="id"
                    :none-selected-text="$tf('projectList.filter.all|Mind')"
                    :disabled="activeTab === PROJECT_TABS.SUPPORT"
                  >
                    <div v-for="(item, id) in projectRisks" :key="id">
                      <b-checkbox
                        v-model="saveObjects.selectedRiskKPIs.value"
                        :native-value="item.id"
                      >
                        <div class="is-flex is-align-items-center has-gap-1">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <circle cx="5" cy="5" r="5" :fill="item.color" />
                            </svg>
                          </div>
                          <div>
                            <div>
                              {{ item.name }}
                            </div>
                            <div>({{ item.intervalText }})</div>
                          </div>
                        </div>
                      </b-checkbox>
                    </div>
                  </multiselect-dropdown>
                </b-field>
              </div>
              <div>
                <b-field
                  :label="$tf('projectList.filter.fromDate|Kezdeti dátum')"
                  label-position="on-border"
                  class="field-has-label-mb-0"
                  style="width: 200px"
                >
                  <b-datepicker
                    icon="calendar-day"
                    :modelValue="fromDateObject"
                    @update:modelValue="saveObjects.fromDate.value = $event"
                    trap-focus
                    :icon-right="
                      saveObjects.fromDate.value ? 'close-circle' : ''
                    "
                    icon-right-clickable
                    @icon-right-click="saveObjects.fromDate.value = null"
                    :placeholder="datePattern"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div>
                <b-field
                  :label="$tf('projectList.filter.toDate|Befejezési dátum')"
                  label-position="on-border"
                  class="field-has-label-mb-0"
                  style="width: 200px"
                >
                  <b-datepicker
                    icon="calendar-day"
                    :modelValue="toDateObject"
                    @update:modelValue="saveObjects.toDate.value = $event"
                    trap-focus
                    :icon-right="saveObjects.toDate.value ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="saveObjects.toDate.value = null"
                    :placeholder="datePattern"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div>
                <b-field
                  :label="$tf('projectList.filter.projectName|Projekt név')"
                  label-position="on-border"
                  class="field-has-label-mb-0"
                  style="width: 200px"
                >
                  <b-input
                    v-model="saveObjects.projectName.value"
                    icon="magnifying-glass"
                    :placeholder="
                      $tf('projectList.filter.search.placeholder|Keresés...')
                    "
                  ></b-input>
                </b-field>
              </div>
              <div>
                <b-field
                  :label="
                    $tf(
                      'projectList.filter.projectIdentifier|Projekt azonosító'
                    )
                  "
                  label-position="on-border"
                  class="field-has-label-mb-0"
                  style="width: 200px"
                >
                  <b-input
                    v-model="saveObjects.projectIdentifier.value"
                    icon="magnifying-glass"
                    :placeholder="
                      $tf('projectList.filter.search.placeholder|Keresés...')
                    "
                  ></b-input>
                </b-field>
              </div>
              <div>
                <b-field
                  :label="$tf('projectList.filter.client|Ügyfél')"
                  label-position="on-border"
                  class="field-has-label-mb-0"
                  style="width: 200px"
                >
                  <b-input
                    v-model="saveObjects.clientName.value"
                    icon="magnifying-glass"
                    :placeholder="
                      $tf('projectList.filter.search.placeholder|Keresés...')
                    "
                  ></b-input>
                </b-field>
              </div>
              <div>
                <b-field
                  :label="$tf('projectList.filter.leadName|Felelős')"
                  label-position="on-border"
                  class="field-has-label-mb-0"
                  style="width: 200px"
                >
                  <b-input
                    v-model="saveObjects.leadName.value"
                    icon="magnifying-glass"
                    :placeholder="
                      $tf('projectList.filter.search.placeholder|Keresés...')
                    "
                  ></b-input>
                </b-field>
              </div>
              <div>
                <b-field>
                  <b-switch
                    size="is-small"
                    v-model="saveObjects.showArchived.value"
                    label-position="on-border"
                    class="field-has-label-mb-0"
                  >
                    {{
                      $tf(
                        "projectList.filter.showArchived|Archivált projektek mutatása"
                      )
                    }}
                  </b-switch>
                </b-field>
              </div>
              <div>
                <b-field>
                  <b-switch
                    size="is-small"
                    v-model="saveObjects.showInternal.value"
                    label-position="on-border"
                    class="field-has-label-mb-0"
                  >
                    {{
                      $tf(
                        "projectList.filter.showInternal|Belső projektek mutatása"
                      )
                    }}
                  </b-switch>
                </b-field>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>

    <div class="container">
      <template v-if="activeTab === PROJECT_TABS.ALL">
        <all-projects-list
          :filters="saveObjects"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        >
        </all-projects-list>
      </template>
      <template v-if="activeTab === PROJECT_TABS.SIMPLE">
        <normal-project-list
          :filters="saveObjects"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        >
        </normal-project-list>
      </template>

      <template v-else-if="activeTab === PROJECT_TABS.SUPPORT">
        <support-project-list
          :filters="saveObjects"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        />
      </template>

      <template v-else-if="activeTab === PROJECT_TABS.OUTSOURCE">
        <outsource-project-list
          :filters="saveObjects"
          @reset-filters="resetAllSaveObjects(saveObjects)"
        />
      </template>
    </div>
  </section>
</template>

<script>
import NormalProjectList from "@/components/enterprise/project/list/NormalProjectList";
import ProjectListMixin from "../../../mixins/ProjectListMixin";
import { mapGetters } from "vuex";
import { PRIVILEGES, PROJECT_STATUS_ENUM, PROJECT_TABS } from "@/utils/const";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import SaveableObject from "@/objects/SaveableObject";
import SaveableObjectContainer from "@/objects/SaveableObjectContainer";
import SupportProjectList from "@/components/enterprise/project/list/SupportProjectList.vue";
import OutsourceProjectList from "@/components/enterprise/project/list/OutsourceProjectList.vue";
import AllProjectsList from "@/components/enterprise/project/list/AllProjectsList.vue";

const LOCAL_KEY_PREFIX = "project_list_filter_";

export default {
  name: "Enterprise-List",
  mixins: [ProjectListMixin, LocalStoreMixin],
  components: {
    AllProjectsList,
    OutsourceProjectList,
    SupportProjectList,
    MultiselectDropdown,
    NormalProjectList,
  },
  async mounted() {
    await this.$store.dispatch("project_risk/list");
    await this.$store.dispatch(
      "project_dimensions/fetchActiveProjectDimensionsWithValues"
    );
    await this.$store.dispatch("project_dimensions/fetchProjectColorsCompact");
    await this.fetchAllSaveObjects(this.saveObjects);

    //remove items from localstorage if the dimension is deleted in the settings
    this.saveObjects.dimensionFilters.value =
      this.saveObjects.dimensionFilters.value.filter((item) =>
        this.projectDimensions.some((dim) => dim.dimensionName === item.name)
      );
    this.saveObjects.selectedProjectColors.value =
      this.saveObjects.selectedProjectColors.value.filter((selectedColor) =>
        this.projectColors?.some((color) => {
          return color.id === selectedColor;
        })
      );
  },
  data() {
    return {
      PROJECT_TABS,
      projectTypes: [
        { identifier: "BASIC", name: "Fejlesztési projekt" },
        { identifier: "OUTSOURCE", name: "Outsource projekt" },
        { identifier: "SUPPORT", name: "Support projekt" },
      ],
      saveObjects: new SaveableObjectContainer({
        showOnlyMine: new SaveableObject(
          LOCAL_KEY_PREFIX + "showOnlyMine",
          true,
          false
        ),
        showInternal: new SaveableObject(
          LOCAL_KEY_PREFIX + "showInternal",
          false,
          true
        ),
        showClosed: new SaveableObject(
          LOCAL_KEY_PREFIX + "showClosed",
          true,
          false
        ),
        showArchived: new SaveableObject(
          LOCAL_KEY_PREFIX + "showArchived",
          false,
          true
        ),
        fromDate: new SaveableObject("fromDate", null, false),
        toDate: new SaveableObject("toDate", null, false),
        search: new SaveableObject(LOCAL_KEY_PREFIX + "search", ""),
        showOnlyStarred: new SaveableObject(
          LOCAL_KEY_PREFIX + "showOnlyStarred",
          false
        ),
        showOnlyLiked: new SaveableObject(
          LOCAL_KEY_PREFIX + "showOnlyLiked",
          false
        ),
        dimensionFilters: new SaveableObject(
          LOCAL_KEY_PREFIX + "dimensionFilters",
          []
        ),
        showDetailedFilters: new SaveableObject(
          LOCAL_KEY_PREFIX + "detailedFilters",
          false
        ),
        selectedProjectColors: new SaveableObject(
          LOCAL_KEY_PREFIX + "selectedProjectColors",
          []
        ),
        selectedProjectTypes: new SaveableObject(
          LOCAL_KEY_PREFIX + "selectedProjectTypes",
          []
        ),
        selectedProjectStatuses: new SaveableObject(
          "selectedProjectStatuses",
          []
        ),
        selectedRiskKPIs: new SaveableObject(
          LOCAL_KEY_PREFIX + "selectedRiskKpis",
          []
        ),
        projectName: new SaveableObject(LOCAL_KEY_PREFIX + "projectName", ""),
        projectIdentifier: new SaveableObject(
          LOCAL_KEY_PREFIX + "projectIdentifier",
          ""
        ),
        clientName: new SaveableObject(LOCAL_KEY_PREFIX + "clientName", ""),
        leadName: new SaveableObject(LOCAL_KEY_PREFIX + "leadName", ""),
      }),
    };
  },
  methods: {
    getDimensionValues(dimensionName) {
      const foundObject = this.saveObjects.dimensionFilters.value.find(
        function (obj) {
          return obj.name === dimensionName;
        }
      );

      return foundObject?.values ? foundObject.values : [];
    },
    saveDimensionValues(dimensionName, values) {
      const foundObject = this.saveObjects.dimensionFilters.value.find(
        function (obj) {
          return obj.name === dimensionName;
        }
      );
      if (foundObject) {
        foundObject.values = values;
      } else {
        this.saveObjects.dimensionFilters.value.push({
          name: dimensionName,
          values: values,
        });
      }

      this.saveObjects.dimensionFilters.save();
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    canSeeAllProjects: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN
      );
    },
    projectStatuses() {
      return Object.keys(PROJECT_STATUS_ENUM).map((key) => ({
        identifier: key,
        name: this.resolveStatus(key),
      }));
    },
    fromDateObject() {
      return this.saveObjects.fromDate.value
        ? new Date(this.saveObjects.fromDate.value)
        : null;
    },
    toDateObject() {
      return this.saveObjects.toDate.value
        ? new Date(this.saveObjects.toDate.value)
        : null;
    },
    datePattern() {
      const formatter = new Intl.DateTimeFormat().formatToParts();
      return formatter
        .map((part) => {
          switch (part.type) {
            case "day":
              return "DD";
            case "month":
              return "MM";
            case "year":
              return "YYYY";
            default:
              return part.value;
          }
        })
        .join("");
    },
    activeTab: {
      get() {
        switch (this.$router.currentRoute.value.hash) {
          case PROJECT_TABS.HASH.SIMPLE:
            return PROJECT_TABS.SIMPLE;
          case PROJECT_TABS.HASH.SUPPORT:
            return PROJECT_TABS.SUPPORT;
          case PROJECT_TABS.HASH.OUTSOURCE:
            return PROJECT_TABS.OUTSOURCE;
          default:
            return PROJECT_TABS.ALL;
        }
      },
      set(val) {
        switch (val) {
          case PROJECT_TABS.SIMPLE:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.SIMPLE,
            });
            break;
          case PROJECT_TABS.SUPPORT:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.SUPPORT,
            });
            break;
          case PROJECT_TABS.OUTSOURCE:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.OUTSOURCE,
            });
            break;
          default:
            this.$router.push({
              name: "ControllingProjects",
              hash: PROJECT_TABS.HASH.ALL,
            });
            break;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.right-border {
  border-right: 1px solid $grey-lighter;
}

.checkbox-column {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
  }
}

.category-list {
  max-width: 40rem;
  overflow: auto;
}
</style>

<style lang="scss">
.column-selector-modal {
  .modal-background {
    background-color: rgba(0, 0, 0, 0);
  }

  .animation-content {
    position: absolute;
    left: 300px;
  }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
