<template>
  <div
    :class="!flatten ? 'container' : ''"
    class="is-flex card-shadowed"
    :style="`margin-right: ${marginRight ? '1rem' : '0'}`"
  >
    <div v-if="project">
      <img
        class="project-img"
        :src="project.clientAvatar"
        alt="Kliens avatárja"
      />
    </div>
    <div v-else-if="image">
      <img alt="Kép" :src="folder" />
    </div>
    <div class="dashboard-card-text project project-data" v-if="project">
      <div>
        <a
          class="project-text"
          @click="
            project.clickEmitter ? $emit('click') : gotoProjectSite(project.key)
          "
          >{{ project.name }}</a
        >
      </div>
      <div>
        <p class="project-text lower-text" :class="lowerTextClass">
          {{ project.clientName }}
        </p>
      </div>
    </div>
    <div class="dashboard-card-text" :class="project ? 'project' : ''">
      <div>
        <p class="subtitle">{{ textUpper }}</p>
      </div>
      <div>
        <p class="lower-text" :class="lowerTextClass" v-html="textLower"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    project: {
      type: Object,
      required: false,
    },
    textUpper: {
      type: String,
      required: false,
    },
    textLower: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    imageFolder: {
      type: String,
      required: false,
    },
    lowerTextClass: {
      type: String,
      required: false,
    },
    flatten: Boolean,
    marginRight: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    folder() {
      return require("@/assets/images/" +
        (this.imageFolder ? this.imageFolder : "dashboard/") +
        this.image +
        ".svg");
    },
  },
  methods: {
    async gotoProjectSite(identifier) {
      await this.$router.push(`../projects/project/${identifier}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
.container {
  padding: 10px;
  margin-left: 0;
  box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
  border-radius: 8px;
}
img {
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin-right: 10px;
  margin-top: 10px;
}
.dashboard-card-text {
  padding-top: 5px;
}
.project-data {
  margin-right: 10px;
}
.project {
  .project-text {
    font-size: 16px;
    white-space: nowrap;
  }
}
.lower-text {
  font-weight: bold;
}
.is-danger {
  color: $red;
}
.is-warning {
  color: $yellow;
}
.is-success {
  color: $green;
}

.subtitle {
  font-size: 18px;
  color: $custom-grayer-blue;
}
p {
  color: $custom-dark-blue;
}
</style>
