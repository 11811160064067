import VueI18n from "vue-i18n";
// import network from "@/utils/network";

import { localStore } from "@/utils/util";
import axios from "axios";
import { createI18n } from "vue-i18n";

const messages = {};
const i18n = createI18n({
  locale: "hu",
  fallbackLocale: "en",
  messages,
  allowComposition: true,
});

const loadedLanguages = []; // our default language that is preloaded

function setI18nLanguage(lang) {
  localStore("stored_locale", lang);
  i18n.locale = lang;
  i18n.global.locale = lang;
  return lang;
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return setI18nLanguage(lang);
  }
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang);
  }
  // If the language hasn't been loaded yet
  let messages = {};
  try {
    const newLangMessages = await axios.get(`/api/tokens/public/${lang}`);
    messages = newLangMessages.data.reduce((acc, obj) => {
      acc[obj.token] = obj.label;
      return acc;
    }, {});

    i18n.global.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
  } catch (ex) {
    console.log("Failed fetching language tokens");
  }

  return setI18nLanguage(lang);
}

export default i18n;
