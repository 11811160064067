"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ForecastRule = /** @class */ (function () {
    function ForecastRule(enumValue, label, selectedLabel, fetch, get, icon) {
        this.enum = enumValue;
        this.label = label;
        this.selectedLabel = selectedLabel;
        this.fetch = fetch;
        this.get = get;
        this.icon = icon;
    }
    return ForecastRule;
}());
exports.default = ForecastRule;
