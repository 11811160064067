import Emitter from "tiny-emitter";

const emitter = new Emitter();

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};

export const EVENTS = {
  OVERTIME_REQUESTED: "overtime-requested",
  PROJECT_FILTER_CHANGE: "project-filter-change",
};
