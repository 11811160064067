<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Ülésrend</h1>
      <h2 class="subtitle">Saját ülésrend megtekintése</h2>
      <br />
    </div>
    <div class="container">
      <div class="columns has-text-centered">
        <div class="column is-2">
          <b-button
            size="is-medium"
            icon-left="chevron-left"
            @click="prevMonth"
          >
            Előző hónap
          </b-button>
        </div>
        <div class="column">
          <p class="title">
            {{ actDate.getFullYear() }} {{ getMonthName(actDate) }}
          </p>
          <b-button @click="resetActMonth" type="is-dark is-outlined"
            >Ugrás az aktuális hónapra</b-button
          >
          <br />
        </div>
        <div class="column is-2">
          <b-button
            size="is-medium"
            icon-right="chevron-right"
            @click="nextMonth"
          >
            Következő hónap
          </b-button>
        </div>
      </div>

      <div
        class="content"
        v-if="ownSeatingArrangements && ownSeatingArrangements.length > 0"
      >
        <div
          class="weekComponent"
          v-for="(week, weekIndex) of ownSeatingArrangements"
          :key="weekIndex"
        >
          <p class="title">{{ week.week }}. hét</p>
          <p class="subtitle is-5">
            {{ getFromDate(week.week) }} - {{ getToDate(week.week) }}
          </p>
          <div class="columns is-vcentered">
            <div
              class="column"
              v-for="(day, hoursIndex) of week.seats"
              :key="hoursIndex"
            >
              <b-field :label="DAY_NAME_LABEL[day.dayOfWeek]"> </b-field>
              <b-field>
                <b-input
                  :value="
                    ownSeatingArrangements[weekIndex].seats[hoursIndex].seat
                  "
                  disabled
                  placeholder="Hely"
                />
              </b-field>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="content" v-else>
        <div class="column has-text-centered">
          <p class="title has-text-red">
            Nincs megadva ülésrend erre a hónapra
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, getMonthName } from "@/utils/util";
import { DAY_NAME_LABEL, PLACE } from "@/utils/const";
import uiConfigMixin from "../../../mixins/uiConfigMixin";

export default {
  name: "MySeatingArrangement",
  mixins: [uiConfigMixin],
  async mounted() {
    if (!this.isSeatingFeatureEnabled) {
      await this.$router.push("/profile");
      return;
    }
    await this.getSeatingArrangements();
  },
  watch: {
    async actDate() {
      await this.getSeatingArrangements();
    },
  },
  data() {
    return {
      getMonthName,
      DAY_NAME_LABEL,
      activeElement: null,
      actDate: new Date(),
      PLACE,
    };
  },
  computed: {
    ...mapGetters({
      ownSeatingArrangements: "seating_arrangement/ownSeatingArrangements",
      userId: "session/userId",
    }),
    actMonthFirstDay() {
      return new Date(this.actDate.getFullYear(), this.actDate.getMonth(), 1);
    },
    actMonthLastDay() {
      return new Date(
        this.actDate.getFullYear(),
        this.actDate.getMonth() + 1,
        0
      );
    },
    isSeatingFeatureEnabled() {
      return this.$store.getters["uiConfigStore/getFlags"]
        .seatingFeatureEnabled;
    },
  },
  methods: {
    async getSeatingArrangements() {
      let weekFrom = this.dateToISOWeek(this.actMonthFirstDay);
      let weekTo = this.dateToISOWeek(this.actMonthLastDay);
      if (weekFrom > weekTo) {
        weekFrom = 1;
      }

      let queryParams = {
        year: this.actDate.getFullYear(),
        weekFrom: weekFrom,
        weekTo: weekTo,
        user: this.userId,
      };
      await this.$store.dispatch("seating_arrangement/getSeatingArrangements", {
        params: queryParams,
      });
    },
    editSeatingArrangement() {
      this.$router.push({
        path: "/census/my-seating/" + new Date().getFullYear(),
      });
    },
    nextMonth() {
      this.actDate = new Date(
        this.actDate.getFullYear(),
        this.actDate.getMonth() + 1,
        this.actDate.getDate()
      );
    },
    prevMonth() {
      this.actDate = new Date(
        this.actDate.getFullYear(),
        this.actDate.getMonth() - 1,
        this.actDate.getDate()
      );
    },
    resetActMonth() {
      this.actDate = new Date();
    },
    getWeekNumber(index) {
      let actWeek = this.dateToISOWeek(this.actDate);
      return actWeek + index;
    },
    getFromDate(weekNumber) {
      return formatDate(
        this.isoWeekToDateStart(weekNumber, this.actDate.getFullYear())
      );
    },
    getToDate(weekNumber) {
      let fromDate = this.isoWeekToDateStart(
        weekNumber,
        this.actDate.getFullYear()
      );
      let toDate = new Date(fromDate);
      return formatDate(toDate.setDate(fromDate.getDate() + 6));
    },
    dateToISOWeek(dt) {
      let tdt = new Date(dt.valueOf());
      let dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      let firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
    isoWeekToDateStart(w, y) {
      let simple = new Date(y, 0, 1 + (w - 1) * 7);
      let dow = simple.getDay();
      let ISOweekStart = simple;
      if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOweekStart;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.userListTile {
  padding: 10px;
}

.userListContent {
  padding: 10px;
  height: 768px;
  overflow-x: hidden;
  overflow-y: auto;
}
.seatingArrangementContent {
  padding: 10px;
}
.userSpan {
  font-size: large;
}
.activeElement {
  transform: scale(1.1) translateX(10px);

  background-color: $primary;
  color: $white;
  border-radius: 5px;
}
.userSpan:not(.activeElement):hover {
  transform: scale(1.1) translateX(10px);

  background-color: $grey-lightest;
  border-radius: 5px;
}

.weekStatus {
  padding-top: 50px;
}
</style>
