<template>
  <div>
    <div class="is-flex is-justify-content-end pr-4 has-font-weight-500">
      <b-tabs
        :modelValue="$i18n.locale"
        @update:modelValue="loadLanguageAsync"
        type="is-toggle"
        class="toggle-only"
      >
        <b-tab-item key="hu" value="hu" label="HU"> </b-tab-item>
        <b-tab-item value="en" key="en" label="EN"> </b-tab-item>
      </b-tabs>
    </div>
    <div class="is-flex is-justify-content-center header-container">
      <div class="usp-element">
        <b-icon icon-pack="fa" icon="check-double" class="mr-2"></b-icon>
        <span
          ><label>{{ $tf("signIn.usp.first|Manage Resources") }}</label></span
        >
      </div>
      <div class="usp-element">
        <b-icon icon-pack="fa" icon="check-double" class="mr-2"></b-icon>
        <span
          ><label>{{
            $tf("signIn.usp.second|Professional Services Automation")
          }}</label></span
        >
      </div>
      <div class="usp-element">
        <b-icon icon-pack="fa" icon="check-double" class="mr-2"></b-icon>
        <span
          ><label>{{
            $tf("signIn.usp.third|Assess Profitability")
          }}</label></span
        >
      </div>
    </div>

    <div
      v-if="tenantIdFromPath"
      class="is-flex is-justify-content-center sign-in-container"
    >
      <section class="section">
        <!--        <div id="google-sign-in">-->
        <!--          <div id="portal-google-signin"></div>-->
        <!--        </div>-->

        <div class="classic-sign-in">
          <img class="erp-logo" :src="logo" alt="Forecastify logo" />

          <div class="login-form" @submit.prevent="doLogin">
            <b-field
              :label="$tf('signIn.username.label|Felhasználónév')"
              label-position="on-border"
              expanded
              class="mb-5"
            >
              <b-input
                type="text"
                v-model="user"
                icon="user"
                :placeholder="$tf('signIn.username.placeholder|felhasználónév')"
                autocomplete="username"
                @keydown.enter="doLogin"
                @input="errors.credentials = false"
              />
            </b-field>

            <b-field
              :label="$tf('signIn.password.label|Jelszó')"
              label-position="on-border"
              expanded
              class="mb-6"
            >
              <b-input
                type="password"
                v-model="pwd"
                icon="lock"
                :placeholder="$tf('signIn.password.placeholder|****')"
                autocomplete="password"
                @keydown.enter="doLogin"
                @input="errors.credentials = false"
              />
            </b-field>

            <b-message v-if="errors.credentials" type="is-danger" has-icon>
              {{
                $tf(
                  "signIn.errors.credentials.text|Hibás felhasználónév vagy jelszó!"
                )
              }}
            </b-message>

            <b-message v-if="errors.other" type="is-danger" has-icon>
              {{ errors.other }}
            </b-message>

            <b-field>
              <b-button
                tag="input"
                type="is-primary"
                expanded
                :disabled="isLoading"
                @click="doLogin"
                :value="$tf('signIn.login.button|Bejelentkezés')"
              >
              </b-button>
            </b-field>
          </div>
          <div class="mt-1 has-text-centered">
            <router-link :to="'/forgot-password'">{{
              $tf(
                "signIn.having.trouble|Probléma a bejelentkezéssel? Kattints ide!"
              )
            }}</router-link>
          </div>
        </div>
      </section>
    </div>

    <div v-else class="is-flex is-justify-content-center sign-in-container">
      <div
        class="classic-sign-in has-text-centered has-font-weight-700"
        style="width: 300px"
      >
        {{
          $tf(
            "signIn.tenant.error|Nem sikerült beazonosítani a keresett portált. Kérjük ellenőrizd hogy megfelelő címet írtál-e be!"
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import {
  getCurrentTheme,
  getSessionData,
  tenantIdFromPath,
} from "@/utils/util";
import { UI_THEMES } from "@/utils/const";
import { loadLanguageAsync } from "@/plugins/i18n";
// import uiConfigMixin from "../mixins/uiConfigMixin";

export default {
  name: "SignIn",
  // mixins: [uiConfigMixin],
  data() {
    return {
      user: "",
      pwd: "",
      errors: {
        credentials: false,
        other: null,
      },
      isLoading: false,
    };
  },
  computed: {
    tenantIdFromPath,
    logo() {
      if (getCurrentTheme() === UI_THEMES.LIGHT) {
        return process.env.VUE_APP_LOGIN_LOGO;
      } else {
        return process.env.VUE_APP_LOGIN_LOGO_DARK;
      }
    },
  },
  methods: {
    loadLanguageAsync,
    async doLogin() {
      try {
        this.isLoading = true;
        this.errors.other = null;
        let sessionData = await getSessionData();
        await this.$store.dispatch("session/login", {
          user: this.user,
          password: this.pwd,
          tenant: this.tenantIdFromPath,
          sessionData: sessionData,
        });
        // await this.fetchIsSeatingFeatureEnabled();

        await this.$store.dispatch("census_user/fetchProfile");
        const fetchedLocale = this.$store.getters["census_user/profile"]?.lang;
        if (fetchedLocale) {
          await loadLanguageAsync(fetchedLocale);
        }

        if (this.$route.query?.returnUrl) {
          await this.$router.push(this.$route.query.returnUrl);
        } else {
          await this.$router.replace("/");
        }
      } catch (ex) {
        console.error(ex);
        if (ex.response.data.error === "credentials") {
          this.errors.credentials = true;
        } else {
          this.errors.other =
            ex.response.data.errorMessage ?? ex.response.data.error;
        }
      } finally {
        this.isLoading = false;
      }
    },
    // called by the gapi if the login is successful
    onSuccess: async function (googleUser) {
      let profile = googleUser.getBasicProfile();
      let googleData = {
        token: googleUser.getAuthResponse().id_token,
        name: profile.getName(),
        email: profile.getEmail(),
        avatar: profile.getImageUrl(),
        tenant: this.tenantIdFromPath,
      };
      await this.$store.dispatch("session/signin", googleData);
      if (this.$route.query?.returnUrl) {
        await this.$router.push(this.$route.query.returnUrl);
      } else {
        await this.$router.replace("/");
      }
    },
    onFailure: function (error) {
      console.error(error);
    },
    // logout functionality, combines gapi with internal jwt destruction
    // signOut: function () {
    //   let auth2 = window.gapi.auth2.getAuthInstance();
    //   auth2.signOut().then(() => {
    //     this.$store.dispatch("session/logout");
    //   });
    // },
  },
  mounted() {
    window.gapi.signin2.render("portal-google-signin", {
      scope: "profile email openid",
      width: 460,
      height: 56,
      longtitle: true,
      theme: "dark",
      onsuccess: this.onSuccess,
      onfailure: this.onFailure,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.sign-in-container {
  width: 100%;
}

.header-container {
  text-shadow: 1px 1px 2px black;
  color: white;

  :deep(svg) {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
  }
}

.classic-sign-in {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: $white;
  border-radius: 16px;
  padding: 32px;
  min-width: 460px;
  margin-top: 3rem;
  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;

  .erp-logo {
    align-self: center;
    max-height: 8rem;
    max-width: 20rem;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
  }
}

.usp-element {
  display: flex;
  align-items: center;
  padding: 10px;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
}
</style>
