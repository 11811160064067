<template>
  <nav class="container">
    <div class="last-week">
      <loading-component class="progress-bar" is-full-width>
        <b-progress
          :value="
            totalPerExpected(
              totalTimespentLastWeek,
              expectedTimespentLastWeek
            ) * 100
          "
          size="is-medium"
          :type="
            getProgressType(
              totalPerExpected(
                totalTimespentLastWeek,
                expectedTimespentLastWeek
              )
            )
          "
          show-value
        >
          <span>
            {{
              $tf("timesheetSummary.pastWeek|Múlt hét {hours}%", {
                hours:
                  Math.round(
                    totalPerExpected(
                      totalTimespentLastWeek,
                      expectedTimespentLastWeek
                    ) * 1000
                  ) / 10,
              })
            }}</span
          >
        </b-progress>
      </loading-component>

      <div
        class="summary-items mt-5 is-flex is-flex-direction-column has-gap-1"
      >
        <loading-component>
          <portal-icon icon="sum" type="is-title" />
          <label class="ml-1 mr-1">{{
            $tf("timesheetSummary.balance|Egyenleg:")
          }}</label>
          <label
            class="has-font-weight-500"
            :class="{ 'has-text-red': summedTimeLastWeek < 0 }"
            >{{ $filterSecondToTimeFormat(summedTimeLastWeek) }}</label
          >
        </loading-component>

        <div>
          <loading-component>
            <portal-icon icon="wrench" type="is-title" />
            <label class="ml-1 mr-1">{{
              $tf("timesheetSummary.daysWorked|Nap munkával töltve:")
            }}</label>
            <label class="has-font-weight-500">{{
              $tf(
                "timesheetSummary.daysWorked.display|{worked} / {expected} nap",
                { worked: workedDaysLastWeek, expected: expectedDaysLastWeek }
              )
            }}</label>
          </loading-component>

          <loading-component>
            <portal-icon icon="time-arrow" type="is-title" />
            <label class="ml-1 mr-1">{{
              $tf("timesheetSummary.loggedTime|Logolt idő:")
            }}</label>
            <label class="has-font-weight-500">
              {{
                $tf(
                  "timesheetSummary.loggedTime.display|{logged} / {expected}",
                  {
                    logged: secondsToHoursAndMinutes(totalTimespentLastWeek),
                    expected: secondsToHoursAndMinutes(
                      expectedTimespentLastWeek
                    ),
                  }
                )
              }}</label
            >
          </loading-component>
        </div>
      </div>
    </div>

    <div class="this-week ml-2">
      <loading-component class="progress-bar" is-full-width>
        <b-progress
          :value="
            totalPerExpected(totalTimespentActWeek, expectedTimespentActWeek) *
            100
          "
          size="is-medium"
          :type="
            getProgressType(
              totalPerExpected(totalTimespentActWeek, expectedTimespentActWeek)
            )
          "
          show-value
        >
          <span>
            {{
              $tf("timesheetSummary.thisWeek|Jelenlegi hét {hours}%", {
                hours:
                  Math.round(
                    totalPerExpected(
                      totalTimespentActWeek,
                      expectedTimespentActWeek
                    ) * 1000
                  ) / 10,
              })
            }}</span
          >
        </b-progress>
      </loading-component>

      <loading-component class="days is-flex mt-3">
        <template #loader>
          <div-with-loading
            v-for="elem in 5"
            :key="elem"
            loading-height="100px"
            loading-width="100px"
            class="day-element mr-2"
          ></div-with-loading>
        </template>
        <template>
          <div
            v-for="elem in dayArray"
            v-bind:key="elem.date"
            class="day-element mr-2"
          >
            <day-time :data="elem" :dashboardView="true" />
          </div>
        </template>
      </loading-component>
    </div>
  </nav>
</template>

<script>
import PortalIcon from "@/components/module/icon/PortalIcon";
import DayTime from "@/components/timesheet/tab/tile/DayTime";
import { mapGetters, mapState } from "vuex";
import {
  formatDate,
  getTotalSecondsCss,
  secondsToHoursAndMinutes,
} from "@/utils/util";
import { SHORT_MONTH_NAMES } from "@/utils/const";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "TimeSheetSummary",
  components: { DayTime, PortalIcon, DivWithLoading, LoadingComponent },
  props: {
    userId: {
      type: String,
      required: false,
    },
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchWorkLogs();
    await this.fetchAbsenceRequests();
    await this.fetchSpecialDays();
    await this.fetchProfile();
    this.calcAbsenceRequestsDates();
    this.calcHolidaysDates();
    this.hours = this.calcTimesheetHours();
    this.calcDayArray();
    await this.doFinishLoading();
  },
  computed: {
    ...mapGetters({
      workScheduleMode: "session/workScheduleMode",
    }),
    actWeekStart() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1; //+1 because week starts with monday
      return formatDate(new Date(curr.setDate(first)));
    },
    actWeekEnds() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1; //+1 because week starts with monday
      return formatDate(new Date(curr.setDate(first + 6)));
    },
    lastWeekStart() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1 - 7; //+1 because week starts with monday
      return formatDate(new Date(curr.setDate(first)));
    },
    lastWeekEnds() {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay() + 1 - 7; //+1 because week starts with monday
      return formatDate(new Date(curr.setDate(first + 6)));
    },
    timelogActWeek() {
      return this.worklog(this.actWeekStart);
    },
    timelogLastWeek() {
      return this.worklog(this.lastWeekStart);
    },
    expectedActWeek() {
      return this.required(this.actWeekStart);
    },
    expectedLastWeek() {
      return this.required(this.lastWeekStart);
    },
    expectedTimespentActWeek() {
      return this.expectedActWeek ? this.expectedActWeek.total * 3600 : 0;
    },
    expectedTimespentLastWeek() {
      return this.expectedLastWeek ? this.expectedLastWeek.total * 3600 : 0;
    },
    expectedDaysLastWeek() {
      return this.expectedLastWeek ? this.expectedLastWeek.days : 0;
    },
    summedTimeLastWeek() {
      return this.totalTimespentLastWeek - this.expectedTimespentLastWeek;
    },
    totalTimespentActWeek() {
      return this.timelogActWeek ? this.timelogActWeek.totalSeconds : 0;
    },
    totalTimespentLastWeek() {
      return this.timelogLastWeek ? this.timelogLastWeek.totalSeconds : 0;
    },
    workedDaysLastWeek() {
      return this.timelogLastWeek ? this.timelogLastWeek.days.length : 0;
    },
    ...mapGetters({
      specialDays: "specialday/specialDaysAll",
    }),
    ...mapState({
      absenceRequests(state, getters) {
        return getters[
          this.userId
            ? "absence_request/userAbsenceRequests"
            : "absence_request/mineAbsenceRequests"
        ];
      },
      worklog(state, getters) {
        return getters[
          this.userId ? "worklog/worklogOfWeek" : "worklog/myWorklogOfWeek"
        ];
      },
      required(state, getters) {
        return getters[
          this.userId ? "worklog/expectedOfWeek" : "worklog/myExpectedOfWeek"
        ];
      },
    }),
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
      dayArray: [],
      holidaysDates: [],
      absenceRequestsDates: [],
      hours: [],
      userData: null,
      secondsToHoursAndMinutes,
    };
  },
  methods: {
    async fetchSpecialDays() {
      await this.$store.dispatch("specialday/setSelectedYear", this.year);
      await this.$store.dispatch("specialday/getSpecialDaysAll");
    },
    async fetchProfile() {
      await this.$store.dispatch("census_user/fetchProfile");
      this.userData = this.$store.getters["census_user/profile"];
    },
    async fetchAbsenceRequests() {
      const searchParams = new URLSearchParams();
      searchParams.append("unpaged", "true");
      const requestParams = {
        params: searchParams,
      };
      if (this.userId) {
        await this.$store.dispatch("absence_request/getUserAbsenceRequests", {
          user: this.userId,
          params: requestParams,
        });
      } else {
        await this.$store.dispatch("absence_request/getMine", {
          params: requestParams,
        });
      }
    },
    async fetchWorkLogs() {
      let lastWeek = {
        user: this.userId,
        year: this.year,
        from: this.lastWeekStart,
        to: this.lastWeekEnds,
      };
      let actWeek = {
        user: this.userId,
        year: this.year,
        from: this.actWeekStart,
        to: this.actWeekEnds,
      };

      await this.$store.dispatch(
        this.userId ? "worklog/fetchWeek" : "worklog/fetchMyWeek",
        lastWeek
      );
      await this.$store.dispatch(
        this.userId ? "worklog/fetchWeek" : "worklog/fetchMyWeek",
        actWeek
      );
      await this.$store.dispatch(
        this.userId
          ? "worklog/fetchExpectedWeek"
          : "worklog/fetchMyExpectedWeek",
        lastWeek
      );
      await this.$store.dispatch(
        this.userId
          ? "worklog/fetchExpectedWeek"
          : "worklog/fetchMyExpectedWeek",
        actWeek
      );
    },
    calcTimesheetHours: function () {
      if (this.timelogActWeek) {
        let dayArray = this.timelogActWeek.days;
        let hours = {};
        for (let d in dayArray) {
          let day = dayArray[d];
          hours[day.day] = {};

          let hour = hours[day.day];
          hour.dailyTotalSeconds = day.dailyTotalSeconds;
          hour.dailyTotalOvertimeSeconds = day.dailyTotalOvertimeSeconds;
          hour.tracked = day.dailyTimespent;
          hour.untracked = day.dailyUntrackedTimespent;
          hour.conflicted = day.dailyConflictedTimespent;
          hour.hasCashOvertime = day.dailyCashOvertime > 0;
          hour.hasShiftOvertime = day.dailyShiftOvertime > 0;
        }
        return hours;
      }
      return {};
    },
    calcDayArray: function () {
      let dayArray = [];
      let rollingDate = new Date(this.year, this.month, this.day, 0);
      let sumOfWorkedHoursInWeek = 0;
      let sumOfExpectedWorkHoursInWeek = 0;
      let isWeekWorkCompleted = false;

      let block = 1;
      while (rollingDate.getDay() !== block) {
        rollingDate = rollingDate.substractDays(1);
      }

      while (
        rollingDate.getTime() <= new Date().getTime() ||
        (rollingDate.getMonth() === 11 && this.month === 0) || // this happens in january if the week started in the prev year
        (rollingDate.getMonth() === 0 && this.month === 11)
      ) {
        if (
          rollingDate.getFullYear() > this.year &&
          rollingDate.getDay() === 1
        ) {
          break;
        }

        let dateStr = formatDate(rollingDate);
        let css = "is-disabled";

        let hour = this.hours[dateStr];
        if (rollingDate.getDay() === 0) {
          isWeekWorkCompleted =
            sumOfWorkedHoursInWeek >= sumOfExpectedWorkHoursInWeek;

          css = isWeekWorkCompleted ? "is-week-complete" : "is-week-incomplete";

          sumOfWorkedHoursInWeek = 0;
          sumOfExpectedWorkHoursInWeek = 0;
        } else {
          if (hour) {
            sumOfWorkedHoursInWeek =
              sumOfWorkedHoursInWeek + hour.dailyTotalSeconds / 3600;
          }
          if (this.expectedHoursOfDay(dateStr)) {
            sumOfExpectedWorkHoursInWeek =
              sumOfExpectedWorkHoursInWeek + this.expectedHoursOfDay(dateStr);
          }
        }

        if (!hour) {
          if (
            this.expectedHoursOfDay(dateStr) > 0 &&
            dateStr < formatDate(new Date())
          ) {
            css = "is-danger";
          }
        } else {
          css = getTotalSecondsCss(hour.dailyTotalSeconds);
        }
        dayArray.push({
          date: dateStr,
          isCurrentMonth: this.month === rollingDate.getMonth(),
          monthDay: `${this.$tf(
            SHORT_MONTH_NAMES[rollingDate.getMonth()]
          )}. ${rollingDate.getDate()}`,
          isSunday: rollingDate.getDay() === 0,
          isHoliday: this.isHoliday(dateStr),
          isAbsence:
            this.isAbsenceRequest(dateStr) ||
            this.expectedHoursOfDay(dateStr) === 0,
          isToday: formatDate(new Date()) === dateStr,
          isFuture: dateStr > formatDate(new Date()),
          isWeekWorkCompleted: isWeekWorkCompleted,
          logged: hour ? hour.dailyTotalSeconds : 0,
          unrecognized: hour ? hour.untracked : null,
          overtime: hour ? hour.dailyTotalOvertimeSeconds : 0,
          hasUntracked: hour ? hour.untracked > 0 : false,
          hasConflicted: hour ? hour.conflicted > 0 : false,
          hasCashOvertime: hour ? hour.hasCashOvertime : false,
          hasShiftOvertime: hour ? hour.hasShiftOvertime : false,
          css: css,
        });

        rollingDate.setDate(rollingDate.getDate() + 1);
      }

      this.dayArray = dayArray;
    },
    calcHolidaysDates: function () {
      if (this.specialDays) {
        this.holidaysDates = this.specialDays
          .filter((sd) => sd.type === "HOLIDAY")
          .map((sd) => sd.dayDate);
      }
    },
    isHoliday(day) {
      return this.holidaysDates.includes(day);
    },
    calcAbsenceRequestsDates: function () {
      if (this.absenceRequests.items) {
        this.absenceRequestsDates = [];
        this.absenceRequests.items
          .filter((ar) =>
            [
              "ACTIVE",
              "APPROVED",
              "LINE_APPROVED",
              "REQUEST",
              "TAKEN",
            ].includes(ar.status.enum)
          )
          .forEach(
            (ar) =>
              (this.absenceRequestsDates = this.absenceRequestsDates.concat(
                ar.absenceRequestDates
              ))
          );
      }
    },
    isAbsenceRequest(day) {
      return this.absenceRequestsDates.includes(day);
    },
    expectedHoursOfDay(day) {
      return this.expectedActWeek
        ?.filter((e) => e.day === day)
        .map((e) => e.hours)[0];
    },

    getProgressType(totalPerExpected) {
      if (totalPerExpected < 1) {
        return "is-danger";
      }
      if (totalPerExpected === 1) {
        return "is-success";
      }
      if (totalPerExpected > 1) {
        return "is-primary";
      }
    },
    totalPerExpected(totalTimeSpent, expectedTimeSpent) {
      return expectedTimeSpent === 0 ? 1 : totalTimeSpent / expectedTimeSpent;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.container > .last-week {
  flex: 0 0 25%;
}

.container > .this-week {
  flex: 0 0 75%;
}

.days > div {
  flex: 1 1 0;
  width: 0;
  max-width: 14%;
}

.day-element {
  min-height: 6.5rem;
}
</style>
