<template>
  <div>
    <div class="is-flex is-justify-content-center header-container">
      <div class="usp-element">
        <b-icon icon-pack="fa" icon="check-double" class="mr-2"></b-icon>
        <span
          ><label>{{ $tf("signIn.usp.first|Manage Resources") }}</label></span
        >
      </div>
      <div class="usp-element">
        <b-icon icon-pack="fa" icon="check-double" class="mr-2"></b-icon>
        <span
          ><label>{{
            $tf("signIn.usp.second|Professional Services Automation")
          }}</label></span
        >
      </div>
      <div class="usp-element">
        <b-icon icon-pack="fa" icon="check-double" class="mr-2"></b-icon>
        <span
          ><label>{{
            $tf("signIn.usp.third|Assess Profitability")
          }}</label></span
        >
      </div>
    </div>

    <div
      v-if="tenantIdFromPath"
      class="is-flex is-justify-content-center sign-in-container"
    >
      <section class="section">
        <div class="classic-sign-in">
          <p v-if="!passwordSet" class="title has-text-centered">
            {{ $tf("signIn.resetPassword.title|Jelszó helyreállítása") }}
          </p>
          <div v-if="!passwordSet" class="is-flex is-justify-content-center">
            <form class="login-form" @submit.prevent="setPassword">
              <b-field label="Token" label-position="on-border" class="pb-3">
                <b-input icon="key" disabled :value="token"></b-input>
              </b-field>

              <vuelidated-input
                :label="$tf('resetPassword.newPassword.label|Új jelszó')"
                type="password"
                :placeholder="
                  $tf('resetPassword.newPassword.placeholder|Új jelszó')
                "
                v-model="pwd"
                :validation-rule="v$.pwd"
                label-position="on-border"
                icon="lock"
              ></vuelidated-input>

              <div class="content set-password">
                <ul>
                  <li
                    v-if="policy.length"
                    :class="{ 'password--correct': isLengthy }"
                  >
                    {{
                      $tf(
                        "resetPassword.policy.length|min. {length} karakter",
                        { length: policy.length }
                      )
                    }}
                    <b-icon
                      v-if="isLengthy"
                      icon-pack="fa"
                      icon="check"
                      type="is-success"
                      size="is-small"
                    ></b-icon>
                  </li>
                  <li
                    v-if="policy.upperLower"
                    :class="{ 'password--correct': hasUpperLower }"
                  >
                    {{
                      $tf(
                        "resetPassword.policy.lowerUpper|min. 1 kisbetű és 1 nagybetű"
                      )
                    }}
                    <b-icon
                      v-if="hasUpperLower"
                      icon-pack="fa"
                      icon="check"
                      type="is-success"
                      size="is-small"
                    ></b-icon>
                  </li>
                  <li
                    v-if="policy.number"
                    :class="{ 'password--correct': hasDigit }"
                  >
                    {{ $tf("resetPassword.policy.number|min. 1 szám") }}
                    <b-icon
                      v-if="hasDigit"
                      icon-pack="fa"
                      icon="check"
                      type="is-success"
                      size="is-small"
                    ></b-icon>
                  </li>
                  <li
                    v-if="policy.specialCharacter"
                    :class="{ 'password--correct': hasSpecial }"
                  >
                    {{
                      $tf(
                        "resetPassword.policy.special|min. 1 különleges karakter"
                      )
                    }}
                    <b-icon
                      v-if="hasSpecial"
                      icon-pack="fa"
                      icon="check"
                      type="is-success"
                      size="is-small"
                    ></b-icon>
                  </li>
                </ul>
              </div>

              <b-field>
                <b-button
                  :disabled="isLoading"
                  @click="setPassword"
                  tag="input"
                  native-type="submit"
                  type="is-primary"
                  expanded
                  :value="$tf('resetPassword.button.submit|Mentés')"
                ></b-button>
              </b-field>
            </form>
          </div>
          <div v-else class="has-text-centered">
            <div class="m-auto pb-4">
              <b-icon icon="check-circle" size="is-large" type="is-success" />
            </div>
            <p class="subtitle has-text-weight-medium">
              {{
                $tf(
                  "resetPassword.afterSubmission.successful|Új jelszó beállítása sikeres. Bejelentkezés {count} másodperc múlva...",
                  { count: countInSec }
                )
              }}
            </p>
          </div>
        </div>
      </section>
    </div>

    <div v-else class="is-flex is-justify-content-center sign-in-container">
      <div
        class="classic-sign-in has-text-centered has-font-weight-700"
        style="width: 300px"
      >
        {{
          $tf(
            "signIn.tenant.error|Nem sikerült beazonosítani a keresett portált. Kérjük ellenőrizd hogy megfelelő címet írtál-e be!"
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { passwordStrength } from "check-password-strength";
import { tenantIdFromPath } from "@/utils/util";

export default {
  name: "ResetPassword",
  components: { VuelidatedInput },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    pwd: { required },
  },
  mounted() {
    this.token = this.$route.query.token;
  },
  async created() {
    await this.$store.dispatch("passwordPolicy/fetch", this.tenantIdFromPath);
    this.policy = this.$store.getters["passwordPolicy/get"];
  },
  data() {
    return {
      isLoading: false,
      pwd: "",
      token: "",
      policy: {
        upperLower: true,
        number: true,
        specialCharacter: false,
        length: 8,
      },
      timer: null,
      countInSec: 3,
      passwordSet: null,
    };
  },
  computed: {
    tenantIdFromPath,
    result() {
      return passwordStrength(this.pwd);
    },
    hasUpperLower() {
      return (
        this.result.contains.includes("lowercase") &&
        this.result.contains.includes("uppercase")
      );
    },
    hasDigit() {
      return this.result.contains.includes("number");
    },
    hasSpecial() {
      return this.result.contains.includes("symbol");
    },
    isLengthy() {
      return this.result.length >= this.policy.length;
    },
    passwordPolicyOk() {
      if (this.policy.upperLower && !this.hasUpperLower) return false;
      if (this.policy.number && !this.hasDigit) return false;
      if (this.policy.specialCharacter && !this.hasSpecial) return false;
      if (!this.isLengthy) return false;

      return true;
    },
  },
  methods: {
    async setPassword() {
      this.v$.$touch();
      if (!this.v$.$invalid && this.passwordPolicyOk) {
        try {
          this.isLoading = true;
          this.isError = false;
          let token = await this.$store.dispatch("census_user/setPassword", {
            req: {
              token: this.token,
              password: this.pwd,
            },
            tenant: this.tenantIdFromPath,
          });
          if (token) {
            await this.startCountDown(token);
          }
        } catch (ex) {
          if (ex?.response?.data?.error === "history") {
            this.$buefy.toast.open({
              duration: 4000,
              message: `Korábban haszált jelszó beállítása nem lehetséges.`,
              type: "is-danger",
              pauseOnHover: true,
            });
          } else {
            this.$buefy.toast.open({
              duration: 4000,
              message: `Váratlan hiba történt!`,
              type: "is-danger",
              pauseOnHover: true,
            });
          }
          this.isError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async startCountDown(token) {
      this.passwordSet = true;
      this.timer = setInterval(async () => {
        this.countInSec--;
        if (this.countInSec < 0) {
          clearInterval(this.timer);
          await this.$store.dispatch("session/refreshJwt", token);
          await this.$router.push("/");
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

#reset-password-form {
  width: 450px;
  margin: 0 auto;
  padding: 45px;
}

.icon.is-right.has-text-primary.is-clickable {
  color: $blue !important;
}
</style>
<style lang="scss" scoped>
.password-submitted-message {
  width: 75%;
  margin: auto;
}
.sign-in-container {
  width: 100%;
}

.header-container {
  margin-top: 1rem;
  text-shadow: 1px 1px 2px black;

  :deep(svg) {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
  }
}

.classic-sign-in {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  padding: 32px;
  min-width: 460px;
  margin-top: 3rem;
  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;

  .erp-logo {
    max-height: 6rem;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
  }
}

.usp-element {
  padding: 10px;
}

.password {
  &--correct {
    color: #00b67f !important;

    span {
      color: inherit !important;
    }
  }
}

.content.set-password {
  font-size: 0.9em;
}

.content.set-password ul {
  li {
    list-style: disc outside;
    color: #677d99;
    font-size: 0.75rem;
    font-weight: 500;
  }
  margin-left: 2em !important;
  margin-top: 1em !important;
}
</style>

<style scoped lang="scss">
.login-layout-wrapper {
  background-image: url("~@/assets/images/login_background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
