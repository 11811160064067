<template>
  <section class="section">
    <div class="container">
      <FullCalendar
        v-if="!loading"
        :options="calendarOptions"
        ref="fullCalendar"
      />
      <section class="section">
        <div class="container">
          <h1 class="title">{{ $tf("specialDays.title|Különleges napok") }}</h1>
          <h2 class="subtitle">
            {{
              $tf(
                "specialDays.subtitle|Ünnepnapok, hétvégék, ledolgozandók, egyéb különleges napok megadása."
              )
            }}
          </h2>
        </div>
        <hr />
        <div class="columns">
          <div
            class="column is-2 is-offset-5 card is-centered has-text-centered"
          >
            <b-field
              :label="$tf('specialDays.year|Év')"
              label-position="on-border"
            >
              <b-select
                v-model="selectedYear"
                @update:modelValue="setSelectedYear(selectedYear)"
                :placeholder="$tf('specialDays.year.placeholder|Cél év')"
                icon="calendar-alt"
                expanded
              >
                <option
                  v-for="year in years"
                  v-bind:key="year"
                  :modelValue="year"
                >
                  {{ year }}
                </option>
              </b-select>
            </b-field>
            <b-button type="is-info" @click="initWeekends">
              {{ $tf("specialDays.initWeekends|Hétvégék felvétele") }}
            </b-button>
          </div>
        </div>

        <div class="column is-4 is-offset-4 newSpecialDay">
          <form @submit.prevent="submit">
            <b-collapse class="panel" animation="fade" v-model="isCollapseOpen">
              <template v-slot:trigger>
                <div class="panel-heading text-center">
                  <strong>{{
                    $tf("specialDays.addSpecialDay|Új nap felvitele")
                  }}</strong>
                </div>
              </template>
              <div class="column collapseBlock">
                <b-field :expanded="true">
                  <vuelidated-form :validations="v$.formData.type">
                    <template v-slot="{ errors, invalid }">
                      <b-field
                        :expanded="true"
                        :type="{ 'is-danger': invalid }"
                        :message="v$.formData.type.$error ? errors[0] : null"
                        label-for="type"
                      >
                        <template #label>
                          {{ $tf("specialDays.type|Típus") }}
                          <span class="has-text-danger">*</span>
                        </template>
                        <b-autocomplete
                          :expanded="true"
                          v-model="typeSelected"
                          :data="filteredTypeArray"
                          :placeholder="
                            $tf('specialDays.type.placeholder|Nap típusa')
                          "
                          clearable
                          open-on-focus
                          field="display"
                          @select="(option) => (formData.type = option)"
                        >
                          <template #empty>{{
                            $tf("specialDays.type.empty|Nincs találat")
                          }}</template>
                        </b-autocomplete>
                      </b-field>
                    </template>
                  </vuelidated-form>
                </b-field>

                <b-field :expanded="true">
                  <vuelidated-input
                    :expanded="true"
                    v-model="formData.name"
                    :placeholder="$tf('specialDays.name.placeholder|Nap neve')"
                    :label="$tf('specialDays.name|Név')"
                    :ref-value="'name'"
                    :validation-rule="v$.formData.name"
                  />
                </b-field>

                <b-field :expanded="true">
                  <vuelidated-input
                    :expanded="true"
                    v-model="formData.description"
                    :placeholder="
                      $tf('specialDays.description.placeholder|Leírás')
                    "
                    :label="$tf('specialDays.description|Leírás')"
                    :ref-value="'description'"
                    :validation-rule="v$.formData.description"
                  />
                </b-field>

                <b-field expanded>
                  <vuelidated-form :validations="v$.formData.dayDate">
                    <template v-slot="{ errors, invalid }">
                      <b-field
                        :expanded="true"
                        :type="{ 'is-danger': invalid }"
                        :message="v$.formData.dayDate.$error ? errors[0] : null"
                        label-for="dayDate"
                      >
                        <template #label>
                          {{ $tf("specialDays.date|Dátum") }}
                          <span class="has-text-danger">*</span>
                        </template>
                        <b-datepicker
                          :expanded="true"
                          :events="specDaysForDatePicker"
                          indicators="bars"
                          ref="dayDate"
                          name="dayDate"
                          id="dayDate"
                          position="is-top-right"
                          :placeholder="
                            $tf('specialDays.date.placeholder|Nap kiválasztása')
                          "
                          v-model="formData.dayDate"
                          icon="calendar-alt"
                          trap-focus
                          :month-names="getMonthNames"
                          :day-names="getDayNames"
                          :first-day-of-week="getFirstDayOfWeek"
                        />
                      </b-field>
                    </template>
                  </vuelidated-form>
                </b-field>

                <b-field>
                  <b-button native-type="submit" type="is-info">
                    {{ $tf("specialDays.submit|Küldés") }}
                  </b-button>
                </b-field>
              </div>
            </b-collapse>
          </form>
        </div>
        <div class="container">
          <b-table
            :data="specialDays.items"
            ref="table"
            striped
            hoverable
            mobile-cards
            paginated
            backend-pagination
            :total="pagination.totalCount"
            :per-page="pagination.size"
            @page-change="onPageChange"
            backend-sorting
            @sort="onSort"
            default-sort-direction="asc"
            :loading="loading"
          >
            <b-table-column
              field="type"
              :label="$tf('specialDays.table.type|Típus')"
              width="160"
              sortable
              v-slot="props"
            >
              {{ props.row.type.display }}
            </b-table-column>

            <b-table-column
              field="name"
              :label="$tf('specialDays.table.name|Név')"
              width="160"
              sortable
              v-slot="props"
            >
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              field="dayDate"
              :label="$tf('specialDays.table.date|Dátum')"
              width="80"
              sortable
              v-slot="props"
            >
              <span
                v-if="props.row.type.enum === 'WEEKEND'"
                class="tag is-light"
              >
                {{ formatDate(props.row.dayDate) }}
              </span>
              <span
                v-else-if="props.row.type.enum === 'EXTRA_WORKDAY'"
                class="tag is-success"
              >
                {{ formatDate(props.row.dayDate) }}
              </span>
              <span
                v-else-if="props.row.type.enum === 'HOLIDAY'"
                class="tag is-danger"
              >
                {{ formatDate(props.row.dayDate) }}
              </span>
            </b-table-column>

            <b-table-column
              field="desc"
              :label="$tf('specialDays.table.description|Leírás')"
              width="160"
              v-slot="props"
            >
              {{ props.row.description }}
            </b-table-column>

            <b-table-column
              field="actions"
              :label="$tf('specialDays.table.actions|Műveletek')"
              centered
              width="80"
              v-slot="props"
            >
              <b-tooltip
                :label="$tf('specialDays.table.actions.delete|Törlés')"
                type="is-light"
              >
                <clickable-icon
                  type="is-info"
                  @click="deleteDay(props.row.id)"
                  icon="trash-alt"
                />
              </b-tooltip>

              <b-tooltip
                :label="$tf('specialDays.table.actions.edit|Módosítás')"
                type="is-light"
              >
                <clickable-icon
                  type="is-info"
                  @click="launchEditModal(props.row.id)"
                  icon="edit"
                />
              </b-tooltip>
            </b-table-column>

            <template #bottom-left>
              <div class="is-flex has-gap-1 is-align-items-center">
                <div>{{ $tf("specialDays.table.pageSize|Oldal mérete") }}</div>
                <div id="size-selector" class="ml-2 mr-2">
                  <b-select
                    v-model="pagination.size"
                    @update:modelValue="getSpecialDays"
                    size="is-small"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                  </b-select>
                </div>
                <div>
                  {{ $tf("specialDays.table.sum|Összesen:") }}
                  <strong>{{ pagination.totalCount }}</strong>
                </div>
              </div>
            </template>

            <template #empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p><b-icon icon="frown" size="is-large"></b-icon></p>
                  <p>
                    {{
                      $tf(
                        "specialDays.table.empty|Erre az évre nincs megadva különleges nap."
                      )
                    }}
                  </p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </section>
      <b-modal
        v-model="isEditSpecialDayActive"
        scroll="clip"
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        @close="getSpecialDays()"
      >
        <special-day-modal
          :special-day-id="specialDayIdToEdit"
          :spec-days="specDaysForDatePicker"
        />
      </b-modal>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/util";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import SpecialDayModal from "@/components/absence/specialday/SpecialDayModal";
import FullCalendar from "@fullcalendar/vue3";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import loading from "../../store/modules/loading";

export default {
  name: "SpecialDay",
  components: {
    SpecialDayModal,
    ClickableIcon,
    VuelidatedInput,
    VuelidatedForm,
    FullCalendar,
  },
  setup: () => ({ v$: useVuelidate() }),

  async mounted() {
    await this.getSpecialDays();
    this.calendarApi = this.$refs.fullCalendar.getApi();
  },

  data: function () {
    return {
      calendarApi: null,
      calendarOptions: {
        plugins: [interactionPlugin, multiMonthPlugin],
        initialView: "multiMonthYear",
        initialDate: new Date(new Date().getFullYear(), 0, 1),
        dayCellDidMount: this.highlightDays,
        dateClick: this.toggleDate,
        firstDay: 1,
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "",
        },
      },
      formData: {
        type: null,
        name: null,
        description: null,
        dayDate: null,
      },
      formatDate,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "dayDate",
        order: "asc",
      },
      selectedYear: new Date().getFullYear(),
      specDayTypes: [
        {
          enum: "WEEKEND",
          display: this.$tf("specialDays.type.weekend|Hétvége"),
        },
        {
          enum: "HOLIDAY",
          display: this.$tf("specialDays.type.holiday|Ünnepnap"),
        },
        {
          enum: "EXTRA_WORKDAY",
          display: this.$tf("specialDays.type.extraWorkday|Munkanap"),
        },
      ],
      isCollapseOpen: false,
      specDayTypeName: "",
      typeSelected: null,
      dayDate: null,
      specialDayIdToEdit: null,
      isEditSpecialDayActive: false,
      loading: true,
    };
  },

  validations: {
    formData: {
      type: {
        required,
      },
      name: {
        required,
      },
      description: {
        maxLength: maxLength(255),
      },
      dayDate: {
        required,
      },
    },
  },

  computed: {
    years() {
      let years = [];
      let curYear = new Date().getFullYear() + 1;
      while (curYear >= 2020) {
        years.push(curYear);
        curYear--;
      }
      return years;
    },
    ...mapGetters({
      specialDays: "specialday/specialDays",
      specialDaysAll: "specialday/specialDaysAll",
    }),
    specDaysForDatePicker() {
      let specDaysForDatePicker = [];
      if (this.specialDaysAll && this.specialDaysAll.length > 0) {
        for (let s in this.specialDaysAll) {
          if (this.specialDaysAll[s].type === "EXTRA_WORKDAY") {
            let specDay = {
              date: new Date(this.specialDaysAll[s].dayDate),
              type: "is-success",
            };
            specDaysForDatePicker.push(specDay);
          } else if (this.specialDaysAll[s].type === "HOLIDAY") {
            let specDay = {
              date: new Date(this.specialDaysAll[s].dayDate),
              type: "is-danger",
            };
            specDaysForDatePicker.push(specDay);
          } else if (this.specialDaysAll[s].type === "WEEKEND") {
            let specDay = {
              date: new Date(this.specialDaysAll[s].dayDate),
              type: "is-light",
            };
            specDaysForDatePicker.push(specDay);
          }
        }
      }
      return specDaysForDatePicker;
    },
    specDaysForCalendar() {
      let specDaysForCalendar = [];
      for (let s in this.specialDaysAll) {
        let specDay = null;
        if (this.specialDaysAll[s].type === "EXTRA_WORKDAY") {
          specDay = {
            id: this.specialDaysAll[s].id,
            date: this.specialDaysAll[s].dayDate,
            className: "extraWorkday",
          };
        } else if (this.specialDaysAll[s].type === "HOLIDAY") {
          specDay = {
            id: this.specialDaysAll[s].id,
            date: this.specialDaysAll[s].dayDate,
            className: "holiday",
          };
        } else if (this.specialDaysAll[s].type === "WEEKEND") {
          specDay = {
            id: this.specialDaysAll[s].id,
            date: this.specialDaysAll[s].dayDate,
            className: "weekend",
          };
        }
        specDaysForCalendar.push(specDay);
      }
      return specDaysForCalendar;
    },
    filteredTypeArray() {
      return this.specDayTypes.filter((option) => {
        return (
          option.display
            .toString()
            .toLowerCase()
            .indexOf(this.specDayTypeName.toLowerCase()) >= 0
        );
      });
    },
  },

  watch: {
    specDaysForCalendar: {
      immediate: false,
      handler() {
        if (this.calendarApi) {
          const newDate = new Date(this.selectedYear);
          this.calendarOptions.initialDate = newDate;
          this.calendarApi.gotoDate(newDate);
        }
      },
    },
  },

  methods: {
    highlightDays(info) {
      const date = info.date.toLocaleDateString("en-CA"); // Helyi időzóna szerint formázott dátum (YYYY-MM-DD)
      const dayData = this.specDaysForCalendar.find((day) => {
        const specDayDate = new Date(day.date).toLocaleDateString("en-CA");
        return specDayDate === date;
      });

      if (dayData) {
        info.el.classList.add(dayData.className);
      }
    },
    async getSpecialDays(force) {
      this.loading = true;
      await this.$store.dispatch("specialday/getSpecialDaysAll", force);
      const { page, size } = this.pagination;
      const sort =
        this.sort.field !== null
          ? `${this.sort.field},${this.sort.order}`
          : null;
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("page", page);
      urlSearchParams.append("size", size);
      urlSearchParams.append("sort", sort);
      await this.$store.dispatch("specialday/getSpecialDays", {
        params: { params: urlSearchParams },
        force,
      });
      if (this.specialDays) {
        this.pagination = this.specialDays.pagination;
      }
      this.loading = false;
    },
    initWeekends() {
      this.$store.dispatch("specialday/initWeekends").then(() => {
        this.getSpecialDays(true);
      });
    },
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let request = {
          type: this.formData.type.enum,
          name: this.formData.name,
          description: this.formData.description,
          dayDate: formatDate(this.formData.dayDate),
        };
        this.$store
          .dispatch("specialday/createSpecialDay", request)
          .then(() => {
            this.isCollapseOpen = false;
            this.getSpecialDays(true);
          });
      }
    },
    deleteDay(dayId) {
      this.$buefy.dialog.confirm({
        title: this.$tf("specialDays.deleteModal.title|Különleges nap törlése"),
        message: this.$tf(
          "specialDays.deleteModal.message|Biztos vagy benne, hogy törölni szeretnéd a napot?"
        ),
        confirmText: this.$tf("specialDays.deleteModal.confirm|Nap törlése"),
        cancelText: this.$tf("specialDays.deleteModal.cancel|Mégse"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          await this.$store
            .dispatch("specialday/deleteSpecialDay", dayId)
            .then(() => {
              this.getSpecialDays(true);
              this.$buefy.toast.open(
                this.$tf(
                  "specialDays.deleteModal.success|Különleges nap törölve!"
                )
              );
            });
        },
      });
    },
    async setSelectedYear(selectedYear) {
      this.isCollapseOpen = false;
      await this.$store.dispatch("specialday/setSelectedYear", selectedYear);
      await this.getSpecialDays();
    },

    launchEditModal(dayId) {
      this.specialDayIdToEdit = dayId;
      this.isEditSpecialDayActive = true;
    },
    toggleDate(dateInfo) {
      for (const element of this.specDaysForCalendar) {
        if (element.date === dateInfo.dateStr) {
          this.launchEditModal(element.id);
          return;
        }
      }
      const el = this.$el.getElementsByClassName("newSpecialDay")[0];
      if (el) {
        this.isCollapseOpen = true;
        this.formData.dayDate = new Date(dateInfo.dateStr);
        el.scrollIntoView();
      }
    },

    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getSpecialDays();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getSpecialDays();
    },

    getMonthNames() {
      return [
        this.$tf("month.january|Január"),
        this.$tf("month.february|Február"),
        this.$tf("month.march|Március"),
        this.$tf("month.april|Április"),
        this.$tf("month.may|Május"),
        this.$tf("month.june|Június"),
        this.$tf("month.july|Július"),
        this.$tf("month.august|Augusztus"),
        this.$tf("month.september|Szeptember"),
        this.$tf("month.october|Október"),
        this.$tf("month.november|November"),
        this.$tf("month.december|December"),
      ];
    },
    getDayNames() {
      return [
        this.$tf("day.short.monday|Hé"),
        this.$tf("day.short.tuesday|Ke"),
        this.$tf("day.short.wednesday|Sze"),
        this.$tf("day.short.thursday|Cs"),
        this.$tf("day.short.friday|Pé"),
        this.$tf("day.short.saturday|Szo"),
        this.$tf("day.short.sunday|Va"),
      ];
    },
    getFirstDayOfWeek() {
      return 1;
    },
  },
};
</script>

<style lang="stylus">

.container {
  .weekend {
    background-color: silver;
  }
  .holiday {
    background-color: lightcoral;
  }
  .extra_workday {
    background-color: lightgreen;
  }

  .fc-daygrid-day-number, .fc-col-header-cell-cushion {
    color: #363636;
  }

  .fc-daygrid-day {
    cursor: pointer;
  }

  .fc-daygrid-day:hover {
    background-color: #f0f0f0;
  }
}

.collapseBlock{
  border: 1px $grey-light solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
