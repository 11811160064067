import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import network from "@/utils/network";

let logger = newLogger("Enterprise-TierStore");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  tierCosts: "/enterprise/tiers/costs",
  userTiers: "/enterprise/tiers/users",
  standardCosts: "/enterprise/tiers/standards",
  tier: "/enterprise/tier",
  tiers: "/enterprise/tiers",
  tenantTiers: (id) => `/tenant/cross/enterprise/tiers/${id}`,
  tierWithId: (id) => `/enterprise/tier/${id}`,
  tierCost: "/enterprise/tiers/cost",
  userTier: "/enterprise/tiers/user",
  usersWithoutTier: "/enterprise/tiers/users-without-tier",
  standardCost: "/enterprise/tiers/standard",
  fixedPriceTier: "/enterprise/fixed-price-tier",
  fixedPriceTiers: "/enterprise/fixed-price-tiers",
  fixedPriceTierWithId: (id) => `/enterprise/fixed-price-tier/${id}`,
  export: "/enterprise/tiers/export",
  import: (validFrom) => `/enterprise/tiers/import?validFrom=${validFrom}`,
};

export const FIXED_PRICE_TIER_NAME = "PX";

const initState = () => {
  return {
    tierCosts: [],
    userTiers: [],
    usersWithoutTier: [],
    standardCosts: [],
    tiers: [],
    fixedPriceTiers: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchTierCosts: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tierCosts,
        null,
        true,
        dispatch,
        "Szint Ráták letöltése...",
        null,
        force
      );
      commit("saveTierCosts", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchUserTiers: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.userTiers,
        null,
        true,
        dispatch,
        "Dolgozók szintjeinek betöltése...",
        null,
        force
      );
      commit("saveUserTiers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchUsersWithoutTier: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.usersWithoutTier,
        null,
        true,
        dispatch,
        "Szint nélküli dolgozók letöltése..."
      );
      commit("saveUsersWithoutTier", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchStandardCosts: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.standardCosts,
        null,
        true,
        dispatch,
        "Általános árszintek letöltése...",
        null,
        force
      );
      commit("saveStandardCosts", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTiers: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tiers,
        null,
        true,
        dispatch,
        "Szintek letöltése...",
        null,
        force
      );
      commit("saveTiers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTiersForTenant: async function ({ commit, dispatch }, tenantId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tenantTiers(tenantId),
        null,
        true,
        dispatch,
        "Szintek letöltése...",
        null,
        true
      );
      commit("saveTiers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchFixedPriceTiers: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.fixedPriceTiers,
        null,
        true,
        dispatch,
        "Fixed áras szintek letöltése...",
        null,
        force
      );
      commit("saveFixedPriceTiers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createTierCost: async function ({ dispatch }, tierCost) {
    try {
      await this.remoteRequest(
        "post",
        urls.tierCost,
        tierCost,
        false,
        dispatch,
        "Árszint létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  modifyTierCost: async function ({ dispatch }, tierCost) {
    try {
      await this.remoteRequest(
        "put",
        urls.tierCost,
        tierCost,
        false,
        dispatch,
        "Árszint módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createUserTier: async function ({ dispatch }, userTier) {
    try {
      await this.remoteRequest(
        "post",
        urls.userTier,
        userTier,
        false,
        dispatch,
        "Szint-Dolgozó összerendelés létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  modifyUserTier: async function ({ dispatch }, userTier) {
    try {
      await this.remoteRequest(
        "put",
        urls.userTier,
        userTier,
        false,
        dispatch,
        "Szint-Dolgozó összerendelés módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createStandardCost: async function ({ dispatch }, standardCost) {
    try {
      await this.remoteRequest(
        "post",
        urls.standardCost,
        standardCost,
        false,
        dispatch,
        "Általános árszint létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  modifyStandardCost: async function ({ dispatch }, standardCost) {
    try {
      await this.remoteRequest(
        "put",
        urls.standardCost,
        standardCost,
        false,
        dispatch,
        "Általános árszint módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createTier: async function ({ dispatch }, tier) {
    try {
      await this.remoteRequest(
        "post",
        urls.tier,
        tier,
        false,
        dispatch,
        "Szint létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  modifyTier: async function ({ dispatch }, tier) {
    try {
      await this.remoteRequest(
        "put",
        urls.tierWithId(tier.id),
        tier,
        false,
        dispatch,
        "Szint módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createFixedPriceTier: async function ({ dispatch }, tier) {
    try {
      await this.remoteRequest(
        "post",
        urls.fixedPriceTier,
        tier,
        false,
        dispatch,
        "Fixed áras szint létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  modifyFixedPriceTier: async function ({ dispatch }, tier) {
    try {
      await this.remoteRequest(
        "put",
        urls.fixedPriceTierWithId(tier.id),
        tier,
        false,
        dispatch,
        "Fix áras szint módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteTierCost: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.tierCost}/${id}`,
        null,
        false,
        dispatch,
        "Árszint törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteUserTier: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.userTier}/${id}`,
        null,
        false,
        dispatch,
        "Összerendelés törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteStandardCost: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.standardCost}/${id}`,
        null,
        false,
        dispatch,
        "Általános Árszint törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteTier: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.tierWithId(id),
        null,
        false,
        dispatch,
        "Szint törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteFixedPriceTier: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.fixedPriceTierWithId(id),
        null,
        false,
        dispatch,
        "Fix áras szint törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  importTiers: async function ({ dispatch }, { formData, validFrom }) {
    try {
      await this.remoteRequest(
        "post",
        urls.import(validFrom),
        formData,
        false,
        dispatch,
        "Szintek importálása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  exportTiers: async function ({ dispatch }, request) {
    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "GET",
        url: urls.export,
        flair: "Szintek exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(urls.export, request);

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveTierCosts: (state, tierCosts) => {
    for (let tc in tierCosts) {
      tierCosts[tc].date = new Date(tierCosts[tc].since);
    }
    state.tierCosts = tierCosts;
  },
  saveUserTiers: (state, userTiers) => {
    for (let ut in userTiers) {
      userTiers[ut].date = new Date(userTiers[ut].since);
    }
    state.userTiers = userTiers;
  },
  saveUsersWithoutTier: (state, usersWithoutTier) => {
    state.usersWithoutTier = usersWithoutTier;
  },
  saveStandardCosts: (state, standardCosts) => {
    for (let sc in standardCosts) {
      standardCosts[sc].date = new Date(standardCosts[sc].since);
    }
    state.standardCosts = standardCosts;
  },
  saveTiers: (state, tiers) => {
    state.tiers = tiers;
  },
  saveFixedPriceTiers: (state, fixedPriceTiers) => {
    state.fixedPriceTiers = fixedPriceTiers;
  },
};

const getters = {
  userTiers: (state) => {
    return state.userTiers;
  },
  usersWithoutTier: (state) => {
    return state.usersWithoutTier;
  },
  tierCosts: (state) => {
    return state.tierCosts;
  },
  standardCosts: (state) => {
    return state.standardCosts;
  },
  tiers: (state) => {
    return state.tiers;
  },
  fixedPriceTiers: (state) => {
    return state.fixedPriceTiers;
  },
  currentTierCosts: (state) => {
    let currents = [];
    let chunks = {};
    for (let i in state.tierCosts) {
      let ut = state.tierCosts[i];
      if (chunks[ut.tier.id]) {
        chunks[ut.tier.id].push(ut);
      } else {
        chunks[ut.tier.id] = [ut];
      }
    }
    for (let c in chunks) {
      let ch = chunks[c];
      let i = 1;
      let candidate = ch[0];
      while (i < ch.length && new Date() > ch[i].date) {
        candidate = ch[i];
        i++;
      }
      currents.push(candidate);
    }
    currents.push({
      tier: {
        id: -1,
        tier: FIXED_PRICE_TIER_NAME,
      },
    });
    return currents;
  },
  currentUserTiers: (state) => {
    let currents = [];
    let chunks = {};
    for (let i in state.userTiers) {
      let ut = state.userTiers[i];
      if (chunks[ut.userId]) {
        chunks[ut.userId].push(ut);
      } else {
        chunks[ut.userId] = [ut];
      }
    }
    for (let i in state.fixedPriceTiers) {
      let fpt = state.fixedPriceTiers[i];
      fpt.tier = FIXED_PRICE_TIER_NAME;
      if (chunks[fpt.userId]) {
        chunks[fpt.userId].push(fpt);
      } else {
        chunks[fpt.userId] = [fpt];
      }
    }
    for (let c in chunks) {
      let ch = chunks[c];
      ch.sort((a, b) => a.since.localeCompare(b.since));
      let i = 1;
      let candidate = ch[0];
      while (i < ch.length && new Date() > new Date(ch[i].since)) {
        candidate = ch[i];
        i++;
      }
      currents.push(candidate);
    }

    return currents;
  },
  currentStandardCost: (state) => {
    if (!state.standardCosts.length) {
      return {};
    }
    let i = 1;
    let candidate = state.standardCosts[0];
    while (
      i < state.standardCosts.length &&
      new Date() > new Date(state.standardCosts[i].since)
    ) {
      candidate = state.standardCosts[i];
      i++;
    }
    return candidate;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
