<template>
  <work-schedule-form :user-id="userId"></work-schedule-form>
</template>

<script>
import WorkScheduleForm from "./WorkScheduleForm";
import { mapGetters } from "vuex";

export default {
  name: "MyWorkScheduleForm",
  components: { WorkScheduleForm },

  computed: {
    ...mapGetters({
      userId: "session/userId",
    }),
  },
};
</script>
