<template>
  <div>
    <h3 class="title">
      <template v-if="editedActivity">
        {{
          $tf("activityForm.base.editTitle|{name} szerkesztése", {
            name: editedActivity.name,
          })
        }}
      </template>
      <template v-else>
        {{ $tf("activityForm.base.newTitle|Új aktivitás felvétele") }}
      </template>
    </h3>
    <div class="columns mt-5">
      <div class="column">
        <b-field
          :label="$tf('activityForm.base.name|Név')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.name" :expanded="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('activityForm.base.identifier|Azonosító')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.identifier" :expanded="true"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('activityForm.base.description|Leírás')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.description" type="textarea"></b-input>
        </b-field>
      </div>
    </div>
    <div v-if="!isSelfTrackingMode" class="mb-4">
      <external-issue-targeting-form
        :prefix="prefix"
        :project-type="PROJECT_TYPES.SUPPORT"
        :project-identifier="projectIdentifier"
        :value="activityEdit.externalIssueTargeting"
        v-model="activityEdit.externalIssueTargeting"
      />
    </div>
    <div class="columns">
      <div class="column">
        <b-button type="is-info" :expanded="true" @click="createActivity"
          >Mentés</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ExternalIssueTargetingForm from "@/components/enterprise/targeting/ExternalIssueTargetingForm.vue";
import {
  ISSUE_TRACKING_SYSTEM,
  PROJECT_TYPES,
  SUPPORT_ACTIVITY_STATUS,
} from "@/utils/const";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";
import { mapGetters } from "vuex";

export default {
  name: "SupportActivityForm",
  components: { ExternalIssueTargetingForm },
  emits: ["activity-submitted"],
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    activityType: {
      type: String,
      required: true,
    },
    editedActivity: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isSelfTrackingMode() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  data() {
    return {
      activityEdit: {
        status: "ONGOING",
        externalIssueTargeting: {},
      },
      PROJECT_TYPES,
      SUPPORT_ACTIVITY_STATUS,
    };
  },
  mounted() {
    if (this.editedActivity) {
      this.activityEdit = deepCopy(this.editedActivity);
      if (this.activityEdit.invoiceDate) {
        this.activityEdit.invoiceDate = new Date(this.activityEdit.invoiceDate);
      }
    }
  },
  methods: {
    async createActivity() {
      if (this.activityType === "BILLABLE" && !this.activityEdit.invoiceDate) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Elszámolás dátum megadása <strong>KÖTELEZŐ</strong>!`,
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      this.activityEdit.type = this.activityType;
      const request = deepCopy(this.activityEdit);
      if (request.invoiceDate) {
        request.invoiceDate = correctWithTimezoneOffset(
          new Date(request.invoiceDate)
        );
      }
      if (this.isSelfTrackingMode) {
        delete request.externalIssueTargeting;
      }
      if (this.editedActivity) {
        await this.$store.dispatch("support_projects/updateActivity", request);
      } else {
        await this.$store.dispatch("support_projects/createActivity", {
          identifier: this.projectIdentifier,
          activity: request,
        });
      }
      this.$emit("activity-submitted");
    },
  },
};
</script>

<style scoped lang="scss"></style>
