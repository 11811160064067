export default {
  computed: {
    filterByExEmployees() {
      return this.employees.filter(
        (employee) =>
          !employee.endDate ||
          new Date(employee.endDate) > new Date() ||
          this.isShowingExEmployees
      );
    },
  },
  methods: {
    employeeClass(item) {
      if (item) {
        if (this.isBeforeEndDate(item)) return "employee-current";
        else return "employee-ex";
      }
      return null;
    },
    isBeforeEndDate(employee) {
      return !employee.endDate || new Date(employee.endDate) > new Date();
    },
  },
};
