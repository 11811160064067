<template>
  <div class="whole-chart-container">
    <b-tooltip v-if="tooltip">
      <template v-slot:content>
        <div class="content tooltip" v-html="tooltip"></div>
      </template>
      <h3 class="title">{{ title }}</h3>
    </b-tooltip>
    <h3 class="title" v-else>{{ title }}</h3>
    <div class="y-axis-and-chart-container">
      <div class="y-axis">
        <div class="axis-label" v-for="(dat, index) in data" :key="index">
          <div style="font-weight: bold">
            {{ dat.label }}
          </div>
        </div>
      </div>
      <div class="chart">
        <div class="bar-row" v-for="(dat, index) in data" :key="index">
          <div
            class="bar"
            :style="{
              width: valueWidth(Math.min(dat.value, firstValue)),
              background: dat.color + 'ff',
              borderTopRightRadius:
                dat.value > firstValue ||
                (dat.value < firstValue && isOverheadGood)
                  ? '0px'
                  : '8px',
              borderBottomRightRadius:
                dat.value > firstValue ||
                (dat.value < firstValue && isOverheadGood)
                  ? '0px'
                  : '8px',
            }"
          >
            <div
              v-if="
                (dat.value >= maxValue * 0.5 && !hasOverhead(dat)) ||
                (firstValue >= maxValue * 0.5 && dat.value > firstValue)
              "
              class="big-bar-value"
            >
              {{ barText(dat, false) }}
            </div>
            <b-tooltip
              v-else-if="hasOverhead(dat)"
              :label="barText(dat, false)"
              style="width: 100%; margin-left: 0"
            >
              <b-button class="tooltip-btn" />
            </b-tooltip>
          </div>
          <div
            v-if="
              dat.value > firstValue ||
              (dat.value < firstValue && isOverheadGood)
            "
            class="bar-overhead"
            :style="{
              width: valueWidth(
                dat.value > firstValue
                  ? dat.value - firstValue
                  : firstValue - dat.value
              ),
              background: dat.color + '33',
              border: '2px solid ' + dat.color,
              color:
                isOverheadGood && dat.value > firstValue
                  ? currentColors.green
                  : currentColors.red,
            }"
          >
            <div
              v-if="
                (dat.value - firstValue > maxValue * 0.5 &&
                  dat.value > firstValue) ||
                firstValue - dat.value > maxValue * 0.5
              "
              style="margin-left: 0.5rem"
            >
              {{ barText(dat, true) }}
            </div>
            <b-tooltip
              v-else
              :label="barText(dat, true)"
              style="width: 100%; margin-left: 0"
              :type="
                isOverheadGood && dat.value > firstValue
                  ? 'is-success'
                  : 'is-danger'
              "
            >
              <b-button class="tooltip-btn" />
            </b-tooltip>
          </div>
          <div
            v-if="dat.value < maxValue * 0.5 && !hasOverhead(dat)"
            class="small-bar-value"
          >
            {{ barText(dat, false) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentColorSet, moneyify, workdayify } from "@/utils/util";
import { CURRENCY_TIERS } from "@/utils/const";
export default {
  name: "ForecastHorizontalOverheadedOfFirstBar",
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
    isOverheadGood: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      moneyify,
      workdayify,
    };
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    maxValue() {
      return this.data.reduce((prev, current) =>
        prev.value > current.value ? prev : current
      ).value;
    },
    firstValue() {
      return this.data[0].value;
    },
  },
  methods: {
    valueWidth(value) {
      return (value / this.maxValue) * 100 + "%";
    },
    barText(dat, isOverhead) {
      let val = isOverhead ? dat.value - this.firstValue : dat.value;
      if (dat.kind === "ft") {
        return (
          (val > 0 && isOverhead ? "+" : "") +
          moneyify.call(this, val, CURRENCY_TIERS.LEVEL_ONE)
        );
      }
      if (dat.kind === "mwd") {
        return (val > 0 && isOverhead ? "+" : "") + workdayify(val);
      }
      return String(dat.value);
    },
    hasOverhead(dat) {
      return (
        dat.value > this.firstValue ||
        (dat.value < this.firstValue && this.isOverheadGood)
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.whole-chart-container {
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.y-axis-and-chart-container {
  width: 100%;
  display: flex;
  gap: 0;
}

.y-axis {
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chart {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bar {
  height: 40px;
  flex-shrink: 0;
}

.axis-label {
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 12px;
  color: $custom-dark-blue;
  font-weight: 500;
}

.bar-label {
  position: absolute;
  top: 7px;
  left: 20px;
}

.bar-row {
  position: relative;
  display: flex;
}

.bar-overhead {
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: 500;
  min-width: 1.5rem;
}

.big-bar-value {
  position: absolute;
  color: $white;
  margin-left: 0.5rem;
  top: 25%;
}
.small-bar-value {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $custom-dark-blue;
  margin-left: 0.5rem;
  font-weight: bold;
  vertical-align: center;
  height: 100%;
}
.tooltip-btn {
  position: relative;
  opacity: 0;
  padding: 0;
  width: 100%;
  height: 40px;
  transition: 0.3s;
  border: transparent;
  border-radius: inherit;
}
.tooltip-btn:hover {
  position: relative;
  opacity: 0.3;
  padding: 0;
  width: 100%;
  height: 40px;
}
</style>
