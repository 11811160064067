<template>
  <div>
    <project-journal
      :project-id="projectData.id"
      :project-identifier="projectIdentifier"
      :tig-identifiers="tigIdentifiers()"
      :adding-new="addingNew"
      @save="save()"
      @close="addingNew = false"
    ></project-journal>

    <progress-history :project-details="projectDetails"></progress-history>
  </div>
</template>

<script>
import ProjectJournal from "../../project/journal/ProjectJournal";
import { mapGetters } from "vuex";
import ProgressHistory from "../../project/journal/ProgressHistory.vue";

export default {
  name: "JournalTabItem",
  components: {
    ProgressHistory,
    ProjectJournal,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    // await this.$store.dispatch(
    //     "enterprise_journal/fetchEntries",
    //     this.projectData.id
    // )
  },
  data() {
    return {
      loading: false,
      addingNew: false,
    };
  },
  computed: {
    journalEntries() {
      return this.journalEntriesGetter(this.projectData.id);
    },
    ...mapGetters({
      journalEntriesGetter: "enterprise_journal/entries",
    }),
  },
  methods: {
    tigIdentifiers() {
      let tigIdentifiers = [];
      for (let t in this.projectData.tigDatas) {
        tigIdentifiers.push({
          identifier: this.projectData.tigDatas[t].identifier,
          name: this.projectData.tigDatas[t].name,
        });
      }
      return tigIdentifiers;
    },
    save() {
      this.addingNew = false;
    },
  },
};
</script>

<style scoped></style>
