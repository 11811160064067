<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="createMode">
          {{ $tf("tiers.tier.modal.newTitle|Új szint felvétele") }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf("tiers.tier.modal.editTitle|Szint módosítása") }}
        </h1>

        <form v-if="loaded">
          <vuelidated-input
            :label="$tf('tiers.tier.modal.tierName|Szint neve')"
            :placeholder="$tf('tiers.tier.modal.tierName|Szint neve')"
            v-model="editTier.tier"
            :validation-rule="v$.editTier.tier"
            label-position="on-border"
            class="mb-5"
          ></vuelidated-input>

          <vuelidated-input
            :label="$tf('tiers.tier.modal.description|Leírás')"
            :placeholder="$tf('tiers.tier.modal.description|Leírás')"
            v-model="editTier.description"
            :validation-rule="v$.editTier.description"
            label-position="on-border"
            class="mb-5"
          ></vuelidated-input>

          <template v-if="createMode">
            <vuelidated-input
              :label="$tf('tiers.tier.modal.cost|Összeg')"
              :placeholder="$tf('tiers.tier.modal.cost|Összeg')"
              v-model="editTier.cost"
              :validation-rule="v$.editTier.cost"
              label-position="on-border"
              class="mb-5"
            ></vuelidated-input>

            <b-field
              :label="$tf('tiers.tier.modal.validFrom|Érvényesség kezdete')"
              label-position="on-border"
            >
              <vuelidated-form :validations="v$.editTier.date">
                <template v-slot="{ errors, invalid }">
                  <b-field
                    :expanded="true"
                    :type="{ 'is-danger': invalid }"
                    :message="v$.editTier.date.$error ? errors[0] : null"
                    label-for="dayDate"
                  >
                    <template #label>
                      {{
                        $tf("tiers.tier.modal.validFrom|Érvényesség kezdete")
                      }}
                      <span class="has-text-danger">*</span>
                    </template>
                    <b-datepicker
                      :placeholder="
                        $tf(
                          'tiers.tier.modal.validFrom.placeholder|Kezdeti dátum'
                        )
                      "
                      icon="calendar-alt"
                      v-model="editTier.date"
                      trap-focus
                      append-to-body
                    >
                    </b-datepicker>
                  </b-field>
                </template>
              </vuelidated-form>
            </b-field>
          </template>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("tiers.tier.modal.cancel|Mégse") }}
            </b-button>
            <b-button @click="save" type="is-info" class="ml-3">
              {{ $tf("tiers.tier.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { deepCopy, formatDate } from "@/utils/util";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";
import VuelidatedForm from "../../../../components/module/input/VuelidatedForm";
import { getCurrentInstance } from "vue";

export default {
  name: "TiersEditModal",
  components: { LoadingSkeleton, VuelidatedInput, VuelidatedForm },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    tier: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.tier;

    if (this.tier) {
      this.editTier = deepCopy(this.tier);
    }

    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editTier: {
        tier: undefined,
        description: undefined,
        cost: undefined,
        date: undefined,
      },
    };
  },
  validations: {
    editTier: {
      tier: {
        required,
      },
      description: {
        required,
      },
      cost: {
        required: requiredIf(function () {
          return this.createMode;
        }),
      },
      date: {
        required: requiredIf(function () {
          return this.createMode;
        }),
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.v$.editTier.$touch();
      if (!this.v$.editTier.$invalid) {
        let request = deepCopy(this.editTier);

        if (this.createMode) {
          request.since = formatDate(this.editTier.date);
          await this.$store.dispatch("enterprise_tiers/createTier", request);
        } else {
          await this.$store.dispatch("enterprise_tiers/modifyTier", request);
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
