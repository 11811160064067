import { deepCopy, newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-CoreStore");

const urls = {
  limitedProjects: "/enterprise/projects/limited",
  simpleProjects: "/enterprise/projects/simple",
  projectsForClient: (clientId) => `/enterprise/projects/client/${clientId}`,
  projectDetails: "/enterprise/details/project",
  projectData: "/enterprise/project",
  issues: "/enterprise/issues",
  activeIssues: "/enterprise/issues/active",
  projectIssues: (id) => `/enterprise/project/${id}/issues`,
  notDoneIssues: (identifier) =>
    `/enterprise/project/${identifier}/not-done-issues`,
  refresh: (status, sheets, unknownProjects, projectDetails, jiraDemoData) =>
    `/enterprise/projects/refresh?issuestatus=${status}&timesheets=${sheets}&unknownProjects=${unknownProjects}&projectDetails=${projectDetails}&jiraDemoData=${jiraDemoData}`,
  projectEmployees: (identifier, type, year = new Date().getFullYear()) =>
    `enterprise/project/employees/${identifier}?type=${type}&year=${year}`,
};

const initState = () => {
  return {
    projects: [],
    simpleProjects: [],
    limitedProjects: [],
    projectDetails: {},
    projectData: {},
    issues: {},
    specialIssues: {},
    activeIssues: [],
    fetching: {},
    projectEmployees: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  // use this for project search everywhere, it includes all types
  fetchLimitedProjects: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.limitedProjects,
        null,
        true,
        dispatch,
        "Projektek letöltése..."
      );
      commit("saveLimitedProjects", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchProjectsForClient: async function (
    { commit, dispatch, state },
    clientId
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectsForClient(clientId),
        null,
        true,
        dispatch,
        "Projektek letöltése..."
      );
      commit("saveLimitedProjects", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSimpleProjects: async function ({ commit, dispatch, state }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.simpleProjects,
        params,
        true,
        dispatch,
        "Projektek letöltése...",
        undefined,
        true
      );
      commit("saveSimpleProjects", data);
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchProjectDetails: async function (
    { commit, dispatch, state },
    { projectIdentifier, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.projectDetails}/${projectIdentifier}`,
        null,
        true,
        dispatch,
        "Projekt részleteinek betöltése...",
        null,
        force
      );
      commit("saveProjectDetails", {
        identifier: projectIdentifier,
        data: data,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        throw err;
      }
    }
  }.bind(storeCore),
  fetchIssues: async function ({ commit, dispatch, state }, projectId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectIssues(projectId),
        null,
        true,
        dispatch,
        "Projekt jegyadatainak letöltése...",
        null,
        true
      );
      commit("saveIssues", { key: projectId, data: data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchActiveIssues: async function ({ commit, dispatch, state }, projectId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.activeIssues,
        null,
        true,
        dispatch,
        "Jegyadatok letöltése...",
        null,
        true
      );
      commit("saveActiveIssues", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchNotDoneIssues: async function ({ commit, dispatch, state }, projectId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.notDoneIssues(projectId),
        null,
        true,
        dispatch,
        "Projekt jegyadatainak letöltése..."
      );
      commit("saveIssues", { key: projectId, data: data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchFilteredIssues: async function ({ commit, dispatch, state }, prompt) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.issues + "?prompt=" + prompt,
        null,
        true,
        dispatch,
        "Jegyadatok letöltése..."
      );
      commit("saveIssues", { key: "FILTERED_" + prompt, data: data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchRecommendedIssues: async function ({ commit, dispatch, state }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.issues + "/recommended",
        null,
        true,
        dispatch,
        "Jegyadatok letöltése..."
      );
      commit("saveSpecialIssues", { key: "recommended", data: data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  searchIssues: async function ({ commit, dispatch }, prompt) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.issues}/search?prompt=${prompt}`,
        null,
        true,
        dispatch,
        "Jegyadatok letöltése..."
      );
      commit("saveSpecialIssues", { key: `SEARCH_${prompt}`, data: data });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createIssue: async function ({ commit, dispatch }, issue) {
    try {
      await this.remoteRequest(
        "post",
        urls.issues,
        issue,
        true,
        dispatch,
        "Jegy létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  editIssue: async function ({ commit, dispatch }, issue) {
    try {
      await this.remoteRequest(
        "put",
        urls.issues,
        issue,
        true,
        dispatch,
        "Jegy szerkesztése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteIssue: async function ({ commit, dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.issues}/${id}`,
        null,
        true,
        dispatch,
        "Jegy törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateIssueStatus: async function ({ commit, dispatch }, changes) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.issues}/status`,
        changes,
        true,
        dispatch,
        "Státusz frissítése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateIssue: async function ({ commit, dispatch, state }, issue) {
    try {
      await this.remoteRequest(
        "put",
        `/enterprise/issues?key=${issue.key}`,
        issue,
        false,
        dispatch,
        "Jegy frissítése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fullRefresh: async function (
    { dispatch },
    { status, sheets, unknownProjects, projectDetails, jiraDemoData }
  ) {
    try {
      await this.remoteRequest(
        "get",
        urls.refresh(
          status,
          sheets,
          unknownProjects,
          projectDetails,
          jiraDemoData
        ),
        null,
        false,
        dispatch,
        "Teljes frissítés..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  monthRefresh: async function ({ dispatch }, { year, month }) {
    try {
      await this.remoteRequest(
        "get",
        `/enterprise/timesheets/refresh?year=${year}&month=${month}`,
        null,
        false,
        dispatch,
        "A hónap befrissítése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  jiraImport: async function ({ dispatch }) {
    try {
      await this.remoteRequest(
        "get",
        `/enterprise/projects/import`,
        null,
        false,
        dispatch,
        "Importálás..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  jiraExport: async function ({ dispatch }) {
    try {
      await this.remoteRequest(
        "post",
        `/enterprise/demo/export`,
        null,
        false,
        dispatch,
        "Exportálás..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchProjectEmployees: async function (
    { dispatch, commit },
    { identifier, type, year }
  ) {
    try {
      const data = await this.remoteRequest(
        "get",
        urls.projectEmployees(identifier, type, year),
        null,
        false,
        dispatch,
        "Projekt dolgozóinak letöltése..."
      );
      commit("saveProjectEmployees", { identifier, data, year });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProjects: (state, projects) => {
    state.projects = projects;
  },
  saveSimpleProjects: (state, projects) => {
    state.simpleProjects = projects;
  },
  saveLimitedProjects: (state, projects) => {
    state.limitedProjects = projects;
  },
  saveProjectDetails: (state, { identifier, data }) => {
    state.projectDetails[identifier] = data;
  },
  saveIssues: (state, { key, data }) => {
    state.issues[key] = data;
  },
  saveActiveIssues: (state, data) => {
    state.activeIssues = data;
  },
  saveSpecialIssues: (state, { key, data }) => {
    state.specialIssues[key] = data;
  },
  saveProjectEmployees: (state, { identifier, data, year }) => {
    if (year) {
      if (!state[identifier]) {
        state.projectEmployees[identifier] = {};
      }
      state.projectEmployees[identifier][year] = data;
    } else {
      state.projectEmployees[identifier] = data;
    }
  },
};

const getters = {
  projects: (state) => {
    return state.projects;
  },
  simpleProjects: (state) => {
    return state.simpleProjects;
  },
  limitedProjects: (state) => {
    return state.limitedProjects;
  },
  projectDetails: (state) => (identifier) => {
    return state.projectDetails[identifier];
  },
  issues: (state) => (id) => {
    return state.issues[id];
  },
  activeIssues: (state) => {
    return state.activeIssues;
  },
  tigIssues: (state) => (tigIdentifier) => {
    const array = deepCopy(...Object.values(state.issues));
    return array.filter((issue) => issue.tigIdentifier === tigIdentifier);
  },
  recommendedIssues: (state) => {
    return state.specialIssues.recommended;
  },
  filteredIssues: (state) => (prompt) => {
    return state.specialIssues[`SEARCH_${prompt}`];
  },
  projectEmployees: (state) => (identifier) => {
    return state.projectEmployees[identifier];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
