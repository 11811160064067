<template>
  <b-table
    :data="items"
    ref="table"
    striped
    hoverable
    mobile-cards
    :checkbox-position="'left'"
    paginated
    backend-pagination
    :total="pagination.totalCount"
    :per-page="pagination.size"
    @page-change="onPageChange"
    backend-sorting
    @sort="onSort"
    default-sort-direction="asc"
    :loading="loading"
  >
    <b-table-column
      field="created"
      :label="$tf('overtimeRequest.table.date|Kérelem dátuma')"
      width="150"
      sortable
      v-slot="props"
    >
      {{ formatDate(props.row.created) }}
    </b-table-column>

    <b-table-column
      field="userId"
      :label="$tf('overtimeRequest.table.name|Név')"
      sortable
      v-slot="props"
      :custom-sort="
        (a, b, isAsc) =>
          isAsc
            ? Intl.Collator('hu').compare(a.user, b.user)
            : Intl.Collator('hu').compare(b.user, a.user)
      "
    >
      {{ getDisplayName(props.row.user) }}
    </b-table-column>

    <b-table-column
      field="status"
      :label="$tf('overtimeRequest.table.status|Státusz')"
      sortable
      v-slot="props"
    >
      {{ $tf(OVERTIME_REQUEST_STATUS.LABEL[props.row.status]) }}
    </b-table-column>

    <b-table-column
      field="type"
      :label="$tf('overtimeRequest.table.type|Típus')"
      sortable
      v-slot="props"
    >
      {{ $tf(OVERTIME_REQUEST_TYPE.LABEL[props.row.type]) }}
    </b-table-column>

    <b-table-column
      field="timeRecord.day"
      :label="$tf('overtimeRequest.table.overtimeDate|Túlóra napja')"
      centered
      v-slot="props"
      sortable
    >
      {{ formatDate(props.row.timeRecordDay) }}
    </b-table-column>

    <b-table-column
      field="timeRecord.seconds"
      :label="$tf('overtimeRequest.table.timeSpent|Ráfodított idő')"
      centered
      v-slot="props"
      sortable
    >
      {{ roundToTwoDecimals(props.row.seconds / 60 / 60) }}h
    </b-table-column>

    <b-table-column
      field="timeRecordDay.issueKey"
      :label="$tf('overtimeRequest.table.issue|Jegy')"
      centered
      v-slot="props"
      sortable
    >
      <a :href="createTicketUrl(props.row.issueKey)" target="_blank">{{
        props.row.issueKey
      }}</a>
    </b-table-column>

    <b-table-column
      field="actions"
      :label="$tf('overtimeRequest.table.actions|Műveletek')"
      centered
      v-slot="props"
      v-if="actions && canEvaluateRequests"
      width="80"
    >
      <b-tooltip
        :label="
          $tf('overtimeRequest.actions.handle.tooltip|Kérelem elbírálása')
        "
        type="is-light"
      >
        <clickable-icon
          @click="openRequestForm(props.row.id)"
          icon="edit"
          type="is-info"
        />
      </b-tooltip>
    </b-table-column>

    <template #empty>
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p><b-icon icon="smile-beam" size="is-large"></b-icon></p>
          <p>{{ emptyMessage }}</p>
        </div>
      </section>
    </template>

    <template #bottom-left>
      <p>
        {{
          $tf("overtimeRequest.table.sum|Összesen: {sum}", {
            sum: pagination.totalCount,
          })
        }}
      </p>
    </template>
  </b-table>
</template>

<script>
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import {
  createTicketUrl,
  getDisplayName,
  formatDate,
  roundToTwoDecimals,
} from "@/utils/util";
import { mapGetters } from "vuex";
import {
  OVERTIME_REQUEST_STATUS,
  OVERTIME_REQUEST_TYPE,
  PRIVILEGES,
} from "@/utils/const";

export default {
  components: { ClickableIcon },
  name: "OvertimeRequestTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    sort: {
      type: Object,
      required: true,
    },
    onSort: {
      type: Function,
      required: true,
    },
    onPageChange: {
      type: Function,
      required: true,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
    actions: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formatDate,
      OVERTIME_REQUEST_STATUS,
      OVERTIME_REQUEST_TYPE,
      createTicketUrl,
    };
  },
  computed: {
    canEvaluateRequests: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.OVERTIME.APPROVE_REQUEST,
        PRIVILEGES.ENTERPRISE.OVERTIME.ADMIN
      );
    },
    ...mapGetters({
      employees: "employee/employees",
    }),
  },
  methods: {
    roundToTwoDecimals,
    async openRequestForm(id) {
      await this.$router.push("/approval/overtime/handle-requests/" + id);
    },
    getDisplayName(user) {
      return getDisplayName(this.employees, user);
    },
  },
  async mounted() {
    await this.$store.dispatch("employee/fetchColleagues");
  },
};
</script>

<style scoped></style>
