import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("loader", {
      isPageLoading: "isPageLoading",
    }),
  },
  methods: {
    ...mapMutations({
      doStartLoading: "loader/doStartLoadPage",
      doFinishLoading: "loader/doFinishLoadPage",
    }),
  },
};
