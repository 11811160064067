<template>
  <div>
    <enterprise-normal-project-details></enterprise-normal-project-details>
  </div>
</template>

<script>
import EnterpriseNormalProjectDetails from "../../../components/enterprise/ProjectDetails/NormalProjectDetails";

export default {
  name: "Enterprise-Details",
  components: {
    EnterpriseNormalProjectDetails,
  },
  methods: {
    preloadData: async function () {
      this.projectIdentifier = this.$route.params.id;
      await this.fetchData();
    },
    fetchData: async function () {
      let projectData = await this.$store.dispatch(
        "enterprise_projects/fetchProjectData",
        { projectIdentifier: this.projectIdentifier }
      );
      this.projectType = projectData.type;
    },
  },
  async created() {
    await this.preloadData();
  },
};
</script>

<style lang="scss"></style>
