import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-ClientStore");

const urls = {
  client: "/enterprise/client",
  clients: "/enterprise/clients",
  logo: "/byte-barn/document",
  setLogo: (id) => `/enterprise/client/${id}/logo`,
};

const initState = () => {
  return {
    clients: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchClients: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.clients,
        null,
        true,
        dispatch,
        "Ügyfelek letöltése...",
        null,
        force
      );
      commit("saveClients", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createClient: async function ({ dispatch }, { client, logoForm }) {
    const hasAvatar = client.avatar;
    delete client.avatar;
    try {
      let response = await this.remoteRequest(
        "post",
        urls.client,
        client,
        false,
        dispatch,
        "Ügyfél létrehozása..."
      );
      if (hasAvatar) {
        logoForm.append("identifier", response.id);
        logoForm.append("displayName", `CLIENT_LOGO_${response.id}`);
        const pathToImage = await this.remoteRequest(
          "post",
          `${urls.logo}`,
          logoForm,
          false,
          dispatch,
          "Logo feltöltése..."
        );
        if (pathToImage) {
          dispatch("setLogo", { clientId: response.id, pathToImage });
        }
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  modifyClient: async function ({ dispatch }, { client, logoForm }) {
    const hasAvatar = client.avatar;
    delete client.avatar;
    try {
      let response = await this.remoteRequest(
        "put",
        urls.client,
        client,
        false,
        dispatch,
        "Ügyfél módosítása..."
      );
      if (hasAvatar) {
        logoForm.append("identifier", response.id);
        logoForm.append("displayName", `CLIENT_LOGO_${response.id}`);
        const pathToImage = await this.remoteRequest(
          "post",
          `${urls.logo}`,
          logoForm,
          false,
          dispatch,
          "Logo feltöltése..."
        );
        if (pathToImage) {
          dispatch("setLogo", { clientId: response.id, pathToImage });
        }
      }
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  setLogo: async function ({ dispatch }, { clientId, pathToImage } = {}) {
    try {
      await this.remoteRequest(
        "put",
        urls.setLogo(clientId),
        pathToImage,
        false,
        dispatch,
        "Ügyfél logo feltöltése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteClient: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.client}/${id}`,
        null,
        false,
        dispatch,
        "Ügyfél törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveClients: (state, clients) => {
    for (let c in clients) {
      clients[c].date = new Date(clients[c].since);
    }
    state.clients = clients;
  },
};

const getters = {
  clients: (state) => {
    return state.clients;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
