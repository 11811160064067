import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("Enterprise-FactFactStore");

const urls = {
  fact: "/enterprise/forecast/fact",
  factWithId: (id) => `/enterprise/forecast/fact/${id}`,
  duplicate: (id) => `/enterprise/forecast/fact/duplicate/${id}`,
};

const initState = () => {
  return {
    facts: [],
    fact: {},
    income: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchFacts: async function ({ commit, dispatch }, { force, params } = {}) {
    try {
      let fact = await this.remoteRequest(
        "get",
        urls.fact,
        params,
        true,
        dispatch,
        "Tételek betöltése",
        null,
        force
      );
      commit("saveFacts", fact);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getIncome: async function ({ commit, dispatch }, { force, params } = {}) {
    try {
      let fact = await this.remoteRequest(
        "get",
        urls.fact + "/income",
        params,
        true,
        dispatch,
        "Bevétel betöltése",
        null,
        force
      );
      commit("saveIncome", fact);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchFact: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let fact = await this.remoteRequest(
        "get",
        urls.factWithId(id),
        null,
        true,
        dispatch,
        "Tétel betöltése",
        null,
        force
      );
      commit("saveFact", fact);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createFact: async function ({ dispatch }, fact) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.fact,
        data: fact,
        cacheable: false,
        dispatch,
        flair: "Tétel létrehozása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  updateFact: async function ({ dispatch }, fact) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.factWithId(fact.id),
        data: fact,
        cacheable: false,
        dispatch,
        flair: "Tétel frissítése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  duplicateFact: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.duplicate(id),
        cacheable: false,
        dispatch,
        flair: "Tétel duplikálása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  deleteFact: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "delete",
        url: urls.factWithId(id),
        cacheable: false,
        dispatch,
        flair: "Tétel törlése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveFacts: (state, facts) => {
    state.facts = facts;
  },
  saveIncome: (state, facts) => {
    state.income = facts;
  },
  saveFact: (state, fact) => {
    state.fact = fact;
  },
};

const getters = {
  facts: (state) => {
    return state.facts;
  },
  income: (state) => {
    return state.income;
  },
  fact: (state) => {
    return state.fact;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
