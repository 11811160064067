<template>
  <div
    class="newForecastCard is-flex is-flex-direction-column"
    style="gap: 24px"
  >
    <h3 class="title">
      {{ $tf("projectPage.doneStatus.title|A projekt összefoglaló adatai") }}
    </h3>
    <div>
      <DoneWholeTable
        class="is-flex-5"
        :project-details="projectDetails"
        :project-data="projectData"
      />
    </div>
    <div class="is-flex is-justify-content-space-evenly has-gap-4">
      <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">
        <forecast-horizontal-overheaded-of-first-bar
          :title="
            $tf(
              'projectPage.doneStatus.budgetSpentChart.title|Büdzsé / költés - ezer {currency}',
              { currency: this.currencySymbol }
            )
          "
          :data="[
            {
              label: this.$tf(
                'projectPage.doneStatus.budgetSpentChart.plan.label|Terv'
              ),
              value: Math.round(projectDetails.budget / 1000),
              kind: 'ft',
              color: currentColors['forecast-primary'],
            },
            {
              label: this.$tf(
                'projectPage.doneStatus.budgetSpentChart.fact.label|Tény'
              ),
              value: Math.round(projectDetails.spent / 1000),
              kind: 'ft',
              color: currentColors['grey-darker'],
            },
          ]"
        />
      </div>
      <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">
        <forecast-horizontal-overheaded-of-first-bar
          :title="$tf('projectPage.doneStatus.mwdChart.title|Munkaidő - MWD')"
          :data="[
            {
              label: this.$tf(
                'projectPage.doneStatus.mwdChart.plan.label|Terv'
              ),
              value: projectDetails.commitment,
              kind: 'mwd',
              color: currentColors['forecast-primary'],
            },
            {
              label: this.$tf(
                'projectPage.doneStatus.mwdChart.fact.label|Tény'
              ),
              value: projectDetails.sumSpentMd,
              kind: 'mwd',
              color: currentColors['grey-darker'],
            },
          ]"
        />
      </div>
      <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">
        <forecast-horizontal-overheaded-of-first-bar
          :title="`Profit - ezer ${currencySymbol}`"
          is-overhead-good
          :data="[
            {
              label: 'Terv',
              value: Math.round(
                (projectDetails.expectedIncome - projectDetails.budget) / 1000
              ),
              kind: 'ft',
              color: currentColors['forecast-primary'],
            },
            {
              label: 'Tény',
              value: Math.round(
                Math.max(
                  projectDetails.expectedIncome - projectDetails.spent,
                  0
                ) / 1000
              ),
              kind: 'ft',
              color: currentColors['grey-darker'],
            },
          ]"
        />
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import StatusMixin from "../../../../mixins/StatusMixin";
import DoneWholeTable from "@/components/enterprise/project/module/DoneWholeTable.vue";
import ForecastHorizontalOverheadedOfFirstBar from "@/components/enterprise/project/module/forecast/ForecastHorizontalOverheadedOfFirstBar.vue";
import { mapGetters } from "vuex";
import { getCurrentColorSet } from "@/utils/util";

export default {
  name: "DoneStatusOverview",
  components: {
    ForecastHorizontalOverheadedOfFirstBar,
    DoneWholeTable,
  },
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    dueDateChartHeight() {
      const ref = this.$refs.dueDateChart;
      if (!ref) return 120;
      return ref.style.height;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-flex-basis-0 {
  flex-basis: 0;
}
</style>
