<template>
  <div class="is-flex is-flex-direction-column has-gap-4">
    <div>
      <h3 class="title">
        {{ $tf("contractEdit.base.title|Szerződés szerkesztése") }}
      </h3>
      <div class="columns mt-5">
        <div class="column">
          <b-field>
            <b-switch v-model="allMonths">Összes hónap</b-switch>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractEdit.base.changeFrom|Változás kezdete')"
            label-position="on-border"
          >
            <vuelidated-input
              :disabled="allMonths"
              type="date"
              :validation-rule="v$.contractEdit.changeFrom"
              v-model="contractEdit.changeFrom"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              icon="calendar-day"
              trap-focus
            >
            </vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractStart|Szerződés kezdete')"
            label-position="on-border"
          >
            <vuelidated-input
              type="date"
              :validation-rule="v$.contractEdit.contractStart"
              v-model="contractEdit.contractStart"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              icon="calendar-day"
              trap-focus
            >
            </vuelidated-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractExpiry|Szerződés vége')"
            label-position="on-border"
          >
            <vuelidated-input
              type="date"
              :validation-rule="v$.contractEdit.contractExpiry"
              v-model="contractEdit.contractExpiry"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              icon="calendar-day"
              trap-focus
            >
            </vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <money-input
            v-model="contractEdit.hourlyFee"
            label-position="on-border"
            :label="$tf('contractForm.base.hourlyFee|Óradíj')"
            :validation-rule="v$.contractEdit.hourlyFee"
          />
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.monthlyHours|Havi óraszám')"
            label-position="on-border"
          >
            <vuelidated-input
              :validation-rule="v$.contractEdit.monthlyHours"
              v-model="contractEdit.monthlyHours"
              type="number"
              :expanded="true"
            ></vuelidated-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button type="is-info" :expanded="true" @click="editContract"
          >Mentés</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import { ISSUE_TRACKING_SYSTEM, PROJECT_TYPES } from "@/utils/const";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minValue,
  requiredIf,
  maxValue,
} from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";
import ExternalIssueTargetingForm from "@/components/enterprise/targeting/ExternalIssueTargetingForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "OutsourceContractEdit",
  components: { VuelidatedInput, MoneyInput },
  emits: ["contract-edited"],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    contractData: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      contractEdit: {},
      contractName: "",
      PROJECT_TYPES,
      allMonths: false,
    };
  },
  validations: {
    contractEdit: {
      contractStart: {
        required,
      },
      contractExpiry: {
        required,
      },
      monthlyHours: {
        minValue: minValue(1),
        required,
      },
      hourlyFee: {
        minValue: minValue(1),
        required,
      },
      changeFrom: {
        required: requiredIf(function () {
          return !this.allMonths;
        }),
      },
    },
  },
  methods: {
    async editContract() {
      this.v$.$touch();
      const request = deepCopy(this.contractEdit);
      request.contractStart = correctWithTimezoneOffset(
        new Date(request.contractStart)
      );
      request.contractExpiry = correctWithTimezoneOffset(
        new Date(request.contractExpiry)
      );
      request.changeFrom = this.allMonths
        ? null
        : correctWithTimezoneOffset(new Date(request.changeFrom));
      await this.$store.dispatch("outsource_projects/updateContract", request);
      this.$emit("contract-edited");
    },
  },
  mounted() {
    this.contractEdit = deepCopy(this.contractData);
    this.contractEdit.contractStart = new Date(this.contractData.contractStart);
    this.contractEdit.contractExpiry = new Date(
      this.contractData.contractExpiry
    );
  },
};
</script>

<style scoped lang="scss"></style>
