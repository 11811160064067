<template>
  <div>
    <table
      aria-label="since-start"
      class="has-text-dark-blue has-font-weight-500 since-start-table"
    >
      <thead>
        <tr>
          <th></th>
          <th>
            {{ $tf("projectPage.forecastSinceStart.table.mwd|Munkaidő") }}
          </th>
          <th>
            {{
              $tf("projectPage.forecastSinceStart.budgetSpent|Büdzsé/Költés")
            }}
          </th>
          <th>{{ $tf("projectPage.forecastSinceStart.time|Átfutási idő") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="plan-row">
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.plan.tooltip|A projekt kezdetekor megadott tervhez mért állapot.'
                )
              "
              >{{
                $tf("projectPage.forecastSinceStart.table.plan|Terv")
              }}</b-tooltip
            >
          </td>
          <td>{{ workdayify(manDayPlan) }}</td>
          <td>{{ moneyify(budgetPlan) }}</td>
          <td>
            {{
              $tf("projectPage.forecastSinceStart.table.time.days|{days} nap", {
                days: numberify(daysPlan),
              })
            }}
          </td>
        </tr>
        <tr class="fact-row">
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.fact.tooltip|A projekten összesen rögzített ráfordítás, illetve a projekt kezdete óta eltelt idő.'
                )
              "
              >{{
                $tf("projectPage.forecastSinceStart.table.fact|Tény")
              }}</b-tooltip
            >
          </td>
          <td>{{ workdayify(projectDetails.sumSpentMd) }}</td>
          <td>{{ moneyify(projectDetails.sumSpentBudget) }}</td>
          <td>
            {{
              $tf("projectPage.forecastSinceStart.table.time.days|{days} nap", {
                days: numberify(projectDetails.daysSinceStart),
              })
            }}
          </td>
        </tr>
        <tr class="abs-row">
          <td class="is-vertical-align-middle">
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.planVsFact.tooltip|A projekt tervhez köthető állapota, és a tény állapot közötti eltérés'
                )
              "
              >{{
                $tf(
                  "projectPage.forecastSinceStart.table.planVsFact|Terv vs Tény"
                )
              }}</b-tooltip
            >
          </td>
          <td>
            {{ workdayify(manDayDiffAbs) }} ({{
              percentify(manDayDiffPercent)
            }})
          </td>
          <td>
            {{ moneyify(budgetDiffAbs) }} ({{ percentify(budgetDiffPercent) }})
          </td>
          <td>
            {{
              $tf(
                "projectPage.forecastSinceStart.table.daysDiffAbs|{days} nap",
                { days: numberify(daysDiffAbs) }
              )
            }}
            ({{ percentify(daysDiffPercent) }})
          </td>
        </tr>
        <tr class="full-project-plan-row">
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPlan.tooltip|A projekt kezdetekor megadott terv a projekt egészére vonatkozóan'
                )
              "
              >{{
                $tf(
                  "projectPage.forecastSinceStart.table.totalProjectPlan|Teljes projekt terv"
                )
              }}</b-tooltip
            >
          </td>
          <td class="has-font-weight-500">
            {{ workdayify(projectDetails.commitment) }}
          </td>
          <td>{{ moneyify(projectDetails.budget) }}</td>
          <td>
            {{
              $tf(
                "projectPage.forecastSinceStart.table.daysBetweenDue|{days} nap",
                { days: numberify(projectDetails.daysBetweenDue) }
              )
            }}
          </td>
        </tr>
        <tr class="full-project-percentage-row">
          <td>
            <b-tooltip
              :label="
                $tf(
                  'projectPage.forecastSinceStart.table.totalProjectPercentage.tooltip|A projekt készültsége a tényadatokat összevetve a teljes projekt tervvel, százalékban kifejezve.'
                )
              "
              >{{
                $tf(
                  "projectPage.forecastSinceStart.table.totalProjectPercentage|Teljes projekt %-ban"
                )
              }}</b-tooltip
            >
          </td>
          <td>
            {{ percentify(manDayWholePercent) }}
          </td>
          <td>
            {{ percentify(budgetWholePercent) }}
          </td>
          <td>
            {{ percentify(daysWholePercent) }}
          </td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle">
            <b-tooltip>
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastSinceStart.table.projectRiskKPI.tooltip1|A projekt tervezett, illetve tényszerűen rögzített állapotaközötti arányszám."
                      )
                    }}
                  </p>
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastSinceStart.table.projectRiskKPI.tooltip2|Amennnyiben az érték 1.00 alatt van, a projekt az elvártnál jobban teljesít, 1.00 érték felett pedig rosszabbul. Az optimális érték 1.00, illetve az alatti szám."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastSinceStart.table.projectRiskKPI|Projekt risk KPI"
                )
              }}
            </b-tooltip>
          </td>
          <td>
            <b-tag
              :icon="manDayRisk > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="manDayRisk > 1 ? 'fa' : 'far'"
              :type="getRiskColor(manDayRisk)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(manDayRisk) }}
            </b-tag>
          </td>
          <td>
            <b-tag
              :icon="budgetRisk > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="budgetRisk > 1 ? 'fa' : 'far'"
              :type="getRiskColor(budgetRisk)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(budgetRisk) }}
            </b-tag>
          </td>
          <td>
            <b-tag
              :icon="daysRisk > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="daysRisk > 1 ? 'fa' : 'far'"
              :type="getRiskColor(daysRisk)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(daysRisk) }}
            </b-tag>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  formatDate,
  moneyify,
  numberify,
  percentify,
  roundToTwoDecimals,
  workdayify,
} from "@/utils/util";
import StatusMixin from "../../../../../mixins/StatusMixin";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import { mapGetters } from "vuex";

export default {
  name: "ForecastSinceStartTable",
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      riskKpiLimits: "uiConfigStore/getRiskKpiLimits",
    }),
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },

    manDayPlan() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(
        this.projectDetails.commitment * this.projectDetails.readiness
      );
    },
    manDayDiffAbs() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(
        this.projectDetails.sumSpentMd - this.manDayPlan
      );
    },
    manDayDiffPercent() {
      if (this.notTracked) return "-";
      return this.manDayDiffAbs / this.manDayPlan;
    },
    manDayWholePercent() {
      return this.projectDetails.sumSpentMd / this.projectDetails.commitment;
    },
    manDayRisk() {
      return roundToTwoDecimals(
        this.manDayWholePercent / this.projectDetails.readiness
      );
    },

    budgetPlan() {
      if (this.notTracked) return "-";
      return this.projectDetails.budget * this.projectDetails.readiness;
    },
    budgetDiffAbs() {
      if (this.notTracked) return "-";
      return this.projectDetails.sumSpentBudget - this.budgetPlan;
    },
    budgetDiffPercent() {
      if (this.notTracked) return "-";
      return this.budgetDiffAbs / this.budgetPlan;
    },
    budgetWholePercent() {
      return this.projectDetails.sumSpentBudget / this.projectDetails.budget;
    },
    budgetRisk() {
      return roundToTwoDecimals(
        this.budgetWholePercent / this.projectDetails.readiness
      );
    },
    daysPlan() {
      return roundToTwoDecimals(
        this.projectDetails.daysBetweenDue * this.projectDetails.readiness
      );
    },
    daysDiffAbs() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(
        this.projectDetails.daysSinceStart - this.daysPlan
      );
    },
    daysDiffPercent() {
      if (this.notTracked) return "-";
      return this.daysDiffAbs / this.daysPlan;
    },
    daysWholePercent() {
      return (
        this.projectDetails.daysSinceStart / this.projectDetails.daysBetweenDue
      );
    },
    daysRisk() {
      return roundToTwoDecimals(
        this.daysWholePercent / this.projectDetails.readiness
      );
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: {
    getRiskColor(value) {
      if (value > 10) return "is-fatal";
      if (value > roundToTwoDecimals(1 + this.riskKpiLimits[1]))
        return { "is-danger": true };
      if (value > roundToTwoDecimals(1 - this.riskKpiLimits[0]))
        return { "is-warning": true };
      return "is-success";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.plan-row > td {
  background: $forecast-primary;
  color: $white !important;
}

.fact-row > td {
  background: $black;
  color: $white !important;
}

.full-project-plan-row > td {
  background: $forecast-primary;
  color: $white !important;
}

.plan-row > td:first-child {
  border-top-left-radius: 8px;
}
.plan-row > td:last-child {
  border-top-right-radius: 8px;
}

.fact-row > td:first-child {
  border-bottom-left-radius: 8px;
}
.fact-row > td:last-child {
  border-bottom-right-radius: 8px;
}

.full-project-plan-row > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.full-project-plan-row > td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.abs-row,
.full-project-percentage-row {
  border-bottom: 1px dashed $grey-lighter;
}

.rpi-tag {
  width: 130px;
}

.since-start-table th,
.since-start-table td {
  border: none !important;
  color: $custom-dark-blue;
}
</style>
