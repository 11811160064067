<template>
  <section class="section kontrolling">
    <div class="back ml-5 mb-3 has-font-weight-500">
      <RouterLink to="/projects/list">
        <div class="is-inline-flex is-align-items-center">
          <b-icon icon="chevron-left" />
          <p>{{ $tf("projectPage.backToList|Projektek") }}</p>
        </div>
      </RouterLink>
    </div>

    <div v-if="loading" style="height: 100vh">
      <div class="container">
        <p class="heading">
          {{ $tf("projectPage.loading|Adatok betöltése folyamatban...") }}
        </p>
        <b-skeleton></b-skeleton>
        <b-skeleton width="80%"></b-skeleton>
        <b-skeleton width="40%"></b-skeleton>
        <b-skeleton width="20%"></b-skeleton>
      </div>
    </div>
    <div v-else>
      <BriefData
        :project-data="projectDetails"
        :project-details="projectDetails"
        project-type="OUTSOURCE"
        @edit="bigTab = 1"
      />

      <div class="container">
        <b-tabs
          v-model="bigTab"
          type="is-toggle"
          expanded
          class="tab-without-padding"
        >
          <b-tab-item
            key="current"
            :label="$tf('projectPage.tab.overview|Áttekintés')"
            id="current"
          >
            <outsource-project-status
              :project-details="projectDetails"
              @updateYear="fetchProject(false, $event)"
            />
          </b-tab-item>
          <b-tab-item
            key="basic"
            :label="$tf('projectPage.tab.basic|Törzsadatok')"
            id="basic"
          >
            <project-edit
              :project-data="projectDetails"
              :project-details="projectDetails"
              project-type="OUTSOURCE"
              :employees="employees"
            ></project-edit>
            <ProjectCollapse id="journal">
              <template #header>
                <h3 class="title">
                  {{
                    $tf(
                      "projectPage.journal.title|Napló, események, változások"
                    )
                  }}
                  <b-tooltip
                    :auto-close="['outside', 'escape']"
                    :triggers="['click']"
                    type="is-info"
                  >
                    <template v-slot:content>
                      <div class="content tooltip">
                        <p>
                          {{
                            $tf(
                              "projectPage.journal.tooltip|Itt van lehetőség a projekt közben előforduló, a projekt lefolyását befolyásoló események dokumentálására. Futó projekt esetén csak ilyen bejegyzés keretében van lehetőség a projekt bizonyos elemein változtatni, pl.: vállalás, határidő, büdzsé, várható bevétel. A bejegyzések itt, illetve külön dashboardokon önmagukban is láthatóak."
                            )
                          }}
                        </p>
                      </div>
                    </template>
                    <portal-icon icon="info-circle" />
                  </b-tooltip>
                </h3>
              </template>
            </ProjectCollapse>
          </b-tab-item>
          <b-tab-item
            key="contracts"
            :label="$tf('projectPage.tab.contracts|Szerződések')"
            id="contracts"
          >
            <outsource-contract-tab-item
              :project-details="projectDetails"
              @refresh="fetchProject(true, selectedYear)"
            />
          </b-tab-item>
          <b-tab-item
            key="invoices"
            :label="$tf('projectPage.tab.invoices|Számlák')"
            id="invoices"
          >
            <invoice-tab-item :project-data="projectDetails" :data="[]" />
          </b-tab-item>
        </b-tabs>
      </div>
      <sync-data
        v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
        :project-details="projectDetails"
        :project-id="projectDetails.id"
        :project-type="PROJECT_TYPES.OUTSOURCE"
        @refresh="fetchProject(true, selectedYear)"
      />
    </div>
  </section>
</template>

<script>
import { moneyify } from "@/utils/util";
import { ISSUE_TRACKING_SYSTEM, PROJECT_TYPES } from "@/utils/const";
import BriefData from "@/components/enterprise/project/module/BriefData.vue";
import ProjectEdit from "@/components/enterprise/project/form/ProjectEdit.vue";
import InvoiceTabItem from "@/components/enterprise/ProjectDetails/TabItems/InvoiceTabItem.vue";
import SyncData from "@/components/enterprise/ProjectDetails/SyncData.vue";
import OutsourceProjectStatus from "@/components/enterprise/ProjectDetails/TabItems/OutsourceProjectStatus.vue";
import { mapGetters } from "vuex";
import OutsourceContractTabItem from "@/components/enterprise/ProjectDetails/TabItems/OutsourceContractTabItem.vue";
import PortalIcon from "@/components/module/icon/PortalIcon.vue";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse.vue";

export default {
  name: "OutsourceProjectDetails",
  components: {
    ProjectCollapse,
    PortalIcon,
    OutsourceContractTabItem,
    OutsourceProjectStatus,
    SyncData,
    InvoiceTabItem,
    ProjectEdit,
    BriefData,
  },
  data() {
    return {
      loading: true,
      actualStatus: 0,
      bigTab: 0,
      projectIdentifier: null,
      PROJECT_TYPES,
      selectedYear: new Date().getFullYear(),
    };
  },
  computed: {
    ISSUE_TRACKING_SYSTEM() {
      return ISSUE_TRACKING_SYSTEM;
    },
    projectDetails() {
      return this.projectDetailsGetter(this.projectIdentifier) ?? {};
    },
    ...mapGetters({
      projectDetailsGetter: "outsource_projects/projectDetails",
      employees: "employee/workers",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    moneyify,
    preloadData: async function (force) {
      await Promise.all([
        this.$store.dispatch("project_like/fetchLikedProjects"),
        this.$store.dispatch("user_indicator/fetch"),
        this.$store.dispatch("employee/fetchWorkers"),
      ]);
      this.projectIdentifier = this.$route.params.id;
      await this.fetchProject(force);
      this.setActualStatus();
      this.loading = false;
    },
    async fetchProject(force, year) {
      if (year) this.selectedYear = year;
      let params = new URLSearchParams();
      params.append("year", year ?? new Date().getFullYear());
      await this.$store
        .dispatch("outsource_projects/fetchProjectDetails", {
          projectIdentifier: this.projectIdentifier,
          force,
          requestParams: { params },
        })
        .catch((reason) => {
          if (reason.response.status === 404) this.$router.push("../projects");
        });
    },
    setActualStatus() {
      if (this.projectDetails.status === "REQUEST") this.actualStatus = 0;
      else if (this.projectDetails.status === "APPROVED") this.actualStatus = 1;
      else if (this.projectDetails.status === "ONGOING") this.actualStatus = 2;
      else if (this.projectDetails.status === "DONE") this.actualStatus = 3;
    },
  },
  async created() {
    await this.preloadData();
    if (this.projectDetails.archived) {
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$tf(
          "projectPage.archived.notification|Ez a projekt archiválva van!"
        ),
        position: "is-top",
        type: "is-danger",
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.summary-table td {
  border: none;
  vertical-align: middle;
  font-weight: 500;
  color: $custom-dark-blue;
}

.summary-table th {
  color: $custom-light-blue;
  font-weight: normal;
}

.month-type-bubble {
  border-radius: 8px;
  text-align: center;
  padding: 4px;
  width: 120px;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.bubble-green {
  background: #48c78e;
}

.bubble-red {
  background: #f14668;
}

.bubble-blue {
  background: #4e75d9;
}

.outsource-list-table {
  table-layout: fixed;
  border: none;
}

.outsource-list-table td,
.outsource-list-table th {
  border: none;
  vertical-align: middle;
}

.table-header-months-row .table-header-month-cell {
  background: $grey-light;
  color: $custom-dark-blue;
  text-align: center;
}

.table-header-months-row > td:not(:nth-child(3)),
.table-header-months-row > th:not(:nth-child(3)) {
  padding: 6px 4px;
  font-size: 0.9rem;
  width: 144px;
}

.table-header-months-row > th:nth-child(4) {
  border-radius: 8px 0 0 8px;
}

.table-header-months-row > th:nth-child(15) {
  border-radius: 0 8px 8px 0;
}

.client-section-first-row > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: center;
  font-weight: 500;
  border-right: 2px solid $grey-lighter;
}

.client-section-first-row > td:nth-child(2) {
  border-top-right-radius: 8px;
}

.client-section-second-row > td:first-child {
  border-bottom-right-radius: 8px;
}

.border-bottom-lighter-grey {
  border-bottom: 2px solid $grey-lighter;
}

.client-section-first-row > td:not(:nth-child(1)):not(:nth-child(2)),
.client-section-second-row > td:not(:nth-child(1)),
.client-section-third-row > td:not(:nth-child(1)) {
  text-align: right;
  font-weight: 500;
  color: $custom-dark-blue;
}

.client-section-second-row > td:not(:nth-child(1)) {
  padding-bottom: 0;
}

.separator-row > td {
  height: 16px;
}

.sum-cell {
  background: $custom-dark-blue !important;
  color: white;
}

.background-light-grey {
  background: $grey-light;
}

.worker-details-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 5fr;
}

.number-data-row td:not(:first-child) {
  text-align: right;
  color: $custom-dark-blue;
  font-weight: 500;
}
</style>
