<template>
  <section class="section">
    <div class="container">
      <loading-component>
        <template #loader>
          <div-with-loading
            loading-height="32px"
            loading-width="128px"
          ></div-with-loading>
        </template>
        <h1 class="title">{{ $tf("employees.title|Kollégák") }}</h1>
      </loading-component>
      <loading-component>
        <template #loader>
          <div-with-loading
            loading-height="24px"
            loading-width="256px"
          ></div-with-loading>
        </template>
        <h2 class="subtitle">
          {{
            $tf(
              "employees.subtitle|A cég munkatársai és adataik, elérhetőségeik"
            )
          }}
        </h2>
      </loading-component>
    </div>
    <div class="container">
      <loading-component>
        <template #loader
          ><div-with-loading
            loading-width="100%"
            loading-height="56px"
            class="mb-3"
          ></div-with-loading>
          <div-with-loading
            loading-width="100%"
            loading-height="32px"
            class="mb-2"
          ></div-with-loading>
          <div
            class="is-flex is-justify-content-space-around is-align-items-center mb-1"
            v-for="n in 20"
            :key="n"
          >
            <!-- <vue-skeleton-loader
              type="circle"
              animation="wave-new"
            ></vue-skeleton-loader> -->
            <div-with-loading loading-width="180px" loading-height="32px">
            </div-with-loading>
            <div>
              <div-with-loading
                loading-width="180px"
                loading-height="32px"
                class="mb-1"
              >
              </div-with-loading>
              <div-with-loading loading-width="100%" loading-height="32px">
              </div-with-loading>
            </div>
            <div>
              <div-with-loading
                loading-width="180px"
                loading-height="32px"
                class="mb-1"
              >
              </div-with-loading>
              <div-with-loading loading-width="100%" loading-height="32px">
              </div-with-loading>
            </div>
          </div>
        </template>
        <b-tabs type="is-boxed" expanded>
          <employee-list-employee-tab
            :employees="employees"
          ></employee-list-employee-tab>
          <employee-list-team-tab
            :employees="employees"
            :teams="teams"
          ></employee-list-team-tab>
        </b-tabs>
      </loading-component>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeListEmployeeTab from "../../../components/census/user/EmployeeListEmployeeTab";
import EmployeeListTeamTab from "../../../components/census/user/EmployeeListTeamTab";
import LoadingMixin from "@/mixins/LoadingMixin";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";

export default {
  name: "EmployeeList",
  components: {
    DivWithLoading,
    LoadingComponent,
    EmployeeListTeamTab,
    EmployeeListEmployeeTab,
  },
  mixins: [LoadingMixin],
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      teams: "census_team/teams",
    }),
  },
  mounted() {
    this.doStartLoading();
    this.$store.dispatch("employee/fetchColleagues");
    this.$store.dispatch("census_team/fetchTeams");
    this.doFinishLoading();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.white-link {
  color: $white;

  &:hover {
    color: $white;
  }
}

.column.is-4.avatar figure {
  margin-left: 0;
  margin-right: 0;
  float: right;
}
</style>
