<template>
  <div>
    <table
      aria-label="since-start"
      class="has-text-dark-blue has-font-weight-500 future-table"
    >
      <thead>
        <tr>
          <th></th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastFutureStatus.table.mwd|Munkaidő") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{
              $tf(
                "projectPage.forecastFutureStatus.table.budgetSpent|Büdzsé/Költés"
              )
            }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{
              $tf("projectPage.forecastFutureStatus.table.time|Átfutási idő")
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="remaining-row">
          <td>
            <b-tooltip
              position="is-right"
              :label="
                $tf(
                  'projectPage.forecastFutureStatus.table.remaining.tooltip|A projekten hátralévő erőforrások. A tervből kivont tényleges ráfordítás alapján kerül kiszámításra.'
                )
              "
              >{{
                $tf(
                  "projectPage.forecastFutureStatus.table.remaining|Hátralévő keret"
                )
              }}</b-tooltip
            >
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(remainingMd) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(remainingBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(remainingTime) }} nap</td>
        </tr>
        <tr class="remaining-plan-row">
          <td>
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastFutureStatus.table.planningForecast.tooltip|A projekt tervtartó előrejelzése szerint várható adatok. Megmutatja hogy az előrejelzés alapján a hátrelévő erőforrásokból mennyi kerül felhasználásra. A tervtartó előrejelzés a már eltelt idő és az idő alatt rögzített ráfordítás alapján számolt trend alapján jelzi az adatokat."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastFutureStatus.table.planningForecast|Tervtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(planningMd) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(planningBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(planningTime) }} nap</td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastFutureStatus.table.planVsFc.tooltip|A hátralévő időszak kerete, és az előrejelzés közötti különbség. Amennyiben az adatok megfelelőek a felület zölddel jelzi. Nem optimális adatok esetén az érték pirossal kerül megjelölésre."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastFutureStatus.table.planVsFc|Terv vs FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPlanningPercentMd > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPlanningPercentMd > 0 ? 'circle-exclamation' : 'thumbs-up'
                "
                :icon-pack="diffPlanningPercentMd > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ workdayify(diffPlanningMd) }} ({{
              percentify(diffPlanningPercentMd)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPlanningPercentBudget > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPlanningPercentBudget > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPlanningPercentBudget > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffPlanningBudget) }} ({{
              percentify(diffPlanningPercentBudget)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPlanningPercentTime > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPlanningPercentTime > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPlanningPercentTime > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffPlanningTime) }} nap ({{
              percentify(diffPlanningPercentTime)
            }})
          </td>
        </tr>
        <tr class="remaining-trend-row">
          <td>
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastFutureStatus.table.trendingForecast.tooltip|A projekt trendtartó előrejelzése szerint várható adatok. Megmutatja hogy az előrejelzés alapján a hátrelévő erőforrásokból mennyi kerül felhasználásra. A trendtartó előrejelzés a már rögzített adatok alapján számolt trend alapján jelzi az adatokat."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastFutureStatus.table.trendingForecast|Tervtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(trendingMd) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(trendingBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(trendingTime) }} nap</td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastFutureStatus.table.planVsFc.tooltip|A hátralévő időszak kerete, és az előrejelzés közötti különbség. Amennyiben az adatok megfelelőek a felület zölddel jelzi. Nem optimális adatok esetén az érték pirossal kerül megjelölésre."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastFutureStatus.table.planVsFc|Terv vs FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffTrendingPercentMd > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffTrendingPercentMd > 0 ? 'circle-exclamation' : 'thumbs-up'
                "
                :icon-pack="diffTrendingPercentMd > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ workdayify(diffTrendingMd) }} ({{
              percentify(diffTrendingPercentMd)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffTrendingPercentBudget > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffTrendingPercentBudget > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffTrendingPercentBudget > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffTrendingBudget) }} ({{
              percentify(diffTrendingPercentBudget)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffTrendingPercentTime > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffTrendingPercentTime > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffTrendingPercentTime > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffTrendingTime) }} nap ({{
              percentify(diffTrendingPercentTime)
            }})
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  formatDate,
  getCurrentColorSet,
  moneyify,
  numberify,
  percentify,
  workdayify,
} from "@/utils/util";
import StatusMixin from "../../../../../mixins/StatusMixin";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";

export default {
  name: "ForecastFutureTable",
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },

    remainingMd() {
      if (this.notTracked) return "-";
      return this.projectDetails.commitment - this.projectDetails.sumSpentMd;
    },
    planningMd() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastPlanningMd - this.projectDetails.sumSpentMd
      );
    },
    diffPlanningMd() {
      if (this.notTracked) return "-";
      return this.planningMd - this.remainingMd;
    },
    diffPlanningPercentMd() {
      if (this.notTracked) return "-";
      return this.diffPlanningMd / this.remainingMd;
    },
    trendingMd() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastTrendingMd - this.projectDetails.sumSpentMd
      );
    },
    diffTrendingMd() {
      if (this.notTracked) return "-";
      return this.trendingMd - this.remainingMd;
    },
    diffTrendingPercentMd() {
      if (this.notTracked) return "-";
      return this.diffTrendingMd / this.remainingMd;
    },

    remainingBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.budget - this.projectDetails.sumSpentBudget;
    },
    planningBudget() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastPlanningBudget -
        this.projectDetails.sumSpentBudget
      );
    },
    diffPlanningBudget() {
      if (this.notTracked) return "-";
      return this.planningBudget - this.remainingBudget;
    },
    diffPlanningPercentBudget() {
      if (this.notTracked) return "-";
      return this.diffPlanningBudget / this.remainingBudget;
    },
    trendingBudget() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastTrendingBudget -
        this.projectDetails.sumSpentBudget
      );
    },
    diffTrendingBudget() {
      if (this.notTracked) return "-";
      return this.trendingBudget - this.remainingBudget;
    },
    diffTrendingPercentBudget() {
      if (this.notTracked) return "-";
      return this.diffTrendingBudget / this.remainingBudget;
    },

    remainingTime() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.daysBetweenDue - this.projectDetails.daysSinceStart
      );
    },
    planningTime() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastPlanningTime -
        this.projectDetails.daysSinceStart
      );
    },
    diffPlanningTime() {
      if (this.notTracked) return "-";
      return this.planningTime - this.remainingTime;
    },
    diffPlanningPercentTime() {
      if (this.notTracked) return "-";
      return this.diffPlanningTime / this.remainingTime;
    },
    trendingTime() {
      if (this.notTracked) return "-";
      return (
        this.projectDetails.forecastTrendingTime -
        this.projectDetails.daysSinceStart
      );
    },
    diffTrendingTime() {
      if (this.notTracked) return "-";
      return this.trendingTime - this.remainingTime;
    },
    diffTrendingPercentTime() {
      if (this.notTracked) return "-";
      return this.diffTrendingTime / this.remainingTime;
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: {
    getRiskColor(value) {
      if (value === 1) return { "is-warning": true };
      if (value > 1) return { "is-danger": true };
      return { "is-success": true };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.remaining-row > td {
  background: $forecast-primary;
  color: $white !important;
}

.remaining-plan-row > td {
  background: $forecast-tertiary;
  color: $white !important;
}

.remaining-trend-row > td {
  background: $forecast-secondary;
  color: $white !important;
}

.remaining-trend-row > td:first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.remaining-trend-row > td:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.remaining-row > td:first-child {
  border-top-left-radius: 8px;
}
.remaining-row > td:last-child {
  border-top-right-radius: 8px;
}

.remaining-plan-row > td:first-child {
  border-bottom-left-radius: 8px;
}
.remaining-plan-row > td:last-child {
  border-bottom-right-radius: 8px;
}

.future-table th,
.future-table td {
  border: none !important;
  color: $custom-dark-blue;
}

.icon-cell {
  width: 28px;
  padding: 0 !important;
  vertical-align: middle;
}

.diff-icon {
  background: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  margin: 2px;
  height: 2rem;
}
</style>
