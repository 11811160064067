<template>
  <div class="onboarding-step-card-content">
    <h2 class="has-text-centered">
      {{
        $tf(
          "onboarding.ExternalIssueTrackerSelect.selectSystem|Válasszon, hogy milyen külső jegykezelő rendszert használ"
        )
      }}
    </h2>
    <div class="is-flex has-gap-4 mb-4">
      <b-checkbox-button
        v-model="externalIssueTrackingSystem"
        v-for="system of ISSUE_TRACKING_SYSTEM"
        :key="system.enum"
        :native-value="system.enum"
        @input="externalIssueTrackingSystem = [system.enum]"
        :value="externalIssueTrackingSystem.includes(system.enum)"
        size="is-large"
      >
        <div class="p-4">
          <span>{{ $tf(system.label) }}</span>
        </div>
      </b-checkbox-button>
    </div>
    <div class="is-flex">
      <b-button
        :disabled="isEmpty(externalIssueTrackingSystem)"
        class="onboarding-button"
        type="is-info"
        @click="saveAndNext"
      >
        {{ $tf("onboarding.externalIssueTrackerSelect.continue|Tovább") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES, ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import { isEmpty } from "lodash";

export default {
  name: "OnboardingExternalIssueTrackerSelect",
  data() {
    return {
      ONBOARDING_PAGES,
      ISSUE_TRACKING_SYSTEM,
      externalIssueTrackingSystem: [],
    };
  },
  methods: {
    isEmpty,
    async saveAndNext() {
      await this.$store.dispatch(
        "onboarding/updateExternalIssueTrackingSystem",
        this.externalIssueTrackingSystem[0]
      );
      this.$emit(
        "nextPage",
        ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SYNC_START
      );
    },
  },
};
</script>

<style></style>
