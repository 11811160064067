<template>
  <div class="modal-card" style="width: 44rem">
    <div class="container">
      <section class="modal-card-body">
        <div class="subtitle my-2">
          Szerkesztő neve: {{ journalEntry.createdBy }}
        </div>
        <div class="subtitle my-2">
          Szerkesztés ideje: {{ formatDateTime(journalEntry.created) }}
        </div>
        <div class="subtitle my-2">
          Szerkesztés összegzése:
          {{ journalEntry.summary ? journalEntry.summary : "Nincs összegzés" }}
        </div>
        <div class="subtitle mb-1">Szerkesztés leírása:</div>
        <div class="mb-2">
          {{
            journalEntry.description ? journalEntry.description : "Nincs leírás"
          }}
        </div>
        <div class="subtitle mb-1">Változtatások:</div>
        <div>
          <b-table :data="journalEntry.changes">
            <b-table-column field="target" label="Mező" v-slot="props">
              {{ targetMap.get(props.row.target) }}
            </b-table-column>
            <b-table-column label="Módosítás" v-slot="props">
              <span v-if="props.row.value !== null">{{ props.row.value }}</span>
              <span v-else-if="props.row.amount !== null">{{
                formatAmount(props.row)
              }}</span>
              <span v-else>{{ formatDate(props.row.date) }}</span>
            </b-table-column>
          </b-table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { formatDate, formatDateTime, moneyify } from "@/utils/util";

export default {
  name: "JournalCommentModal",
  props: {
    journalEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      formatDateTime,
      targetMap: new Map([
        ["NAME", "Név"],
        ["KEY", "Kulcs"],
        ["SUMMARY", "Leírás"],
        ["CLIENT", "Kliens"],
        ["LEAD", "Manager"],
        ["TYPE", "Típus"],
        ["PRICING_MODEL", "Árazás"],
        ["MANAGEMENT_TYPE", "Szervezés"],
        ["LABOR_TYPES", "Feladattípusok"],
        ["TECHNOLOGIES", "Technológiák"],
        ["SUPPORT_POTENTIAL", "Support lehetőségek"],
        ["CATEGORIES", "Kategóriák"],
        ["DEADLINE", "Befejezés"],
        ["BUDGET", "Budget"],
        ["INCOME", "Bevétel"],
        ["COMMITMENT", "Vállalás"],
        ["STATUS", "Státusz"],
        ["BILLABLE_AMOUNT", "Számlázható összeg"],
        ["PROGRESS_TYPE", "Előrehaladás típus"],
      ]),
    };
  },
  methods: {
    moneyify,
    formatAmount(change) {
      if (change.target === "COMMITMENT") {
        return change.amount > 0
          ? "+" + change.amount + " nap"
          : change.amount + " nap";
      } else if (
        change.target === "INCOME" ||
        change.target === "BUDGET" ||
        change.target === "BILLABLE_AMOUNT"
      ) {
        return change.amount > 0
          ? "+" + this.moneyify(change.amount)
          : this.moneyify(change.amount);
      }
      return change.amount;
    },
  },
};
</script>

<style lang="scss">
.arrow-icon {
  vertical-align: middle;
}
</style>
