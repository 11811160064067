<template>
  <div class="container mr-0">
    <h3 class="heading">
      {{ $tf("execDashboard.missingLogs.title|Logolás hiányok") }}
    </h3>
    <b-table-with-loading
      :data="data || []"
      hoverable
      default-sort="sum"
      default-sort-direction="desc"
      scrollable
      :loading="loading"
    >
      <b-table-column
        field="team"
        :label="$tf('execDashboard.missingLogs.table.team|Csapat')"
        v-slot="props"
        >{{ props.row.team }}</b-table-column
      >
      <b-table-column
        sortable
        field="sum.number"
        :label="
          $tf(
            'execDashboard.missingLogs.table.lastThreeMonths|Előző három hónap'
          )
        "
        v-slot="props"
      >
        <div class="is-flex is-flex-direction-column has-gap-2">
          <div>{{ props.row.sum.number }}</div>
          <div>{{ moneyify(props.row.sum.cost) }}</div>
        </div>
      </b-table-column>
      <b-table-column
        sortable
        field="currentMonth.number"
        :label="getMonth(0)"
        v-slot="props"
      >
        <div class="is-flex is-flex-direction-column has-gap-2">
          <div>{{ props.row.currentMonth.number }}</div>
          <div>{{ moneyify(props.row.currentMonth.cost) }}</div>
        </div>
      </b-table-column>
      <b-table-column
        sortable
        field="oneMonthAgo.number"
        :label="getMonth(1)"
        v-slot="props"
      >
        <div class="is-flex is-flex-direction-column has-gap-2">
          <div>{{ props.row.oneMonthAgo.number }}</div>
          <div>{{ moneyify(props.row.oneMonthAgo.cost) }}</div>
        </div>
      </b-table-column>
      <b-table-column
        sortable
        field="twoMonthsAgo.number"
        :label="getMonth(2)"
        v-slot="props"
      >
        <div class="is-flex is-flex-direction-column has-gap-2">
          <div>{{ props.row.twoMonthsAgo.number }}</div>
          <div>{{ moneyify(props.row.twoMonthsAgo.cost) }}</div>
        </div>
      </b-table-column>
    </b-table-with-loading>
  </div>
</template>

<script>
import { moneyify } from "@/utils/util";
import { mapGetters } from "vuex";
import network from "@/utils/network";
import BTableWithLoading from "@/components/loading/BTableWithLoading.vue";

export default {
  name: "ExecutiveDashboardMissingLogs",
  components: {
    BTableWithLoading,
  },
  async mounted() {
    await this.fetch();
  },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    moneyify,
    async fetch() {
      try {
        this.loading = true;
        const axios = await network.connection();
        const { data } = await axios.get("/enterprise/executive/timesheet");
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    getMonth(minus = 0) {
      const date = new Date();
      date.setMonth(date.getMonth() - minus);
      return date.toLocaleDateString("hu-HU", {
        year: "numeric",
        month: "2-digit",
      });
    },
  },
};
</script>

<style scoped></style>
