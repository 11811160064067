<template>
  <p>
    <a v-if="modelValue?.link" :href="modelValue.link" target="_blank">
      <b-icon icon-pack="fa" icon="external-link-alt"></b-icon>
    </a>
    <span v-else> - </span>
  </p>
</template>

<script>
export default {
  name: "ExternalIssueTargetingLink",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
