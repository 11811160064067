import { divideList, formatDate, moneyify, percentify } from "@/utils/util";
import { mapGetters } from "vuex";
import { PRIVILEGES, PROJECT_TABS, SHORT_MONTH_NAMES } from "@/utils/const";
import SaveableObjectContainer from "@/objects/SaveableObjectContainer";
import SaveableObject from "@/objects/SaveableObject";

function countWeekdays(startDate, endDate) {
  let count = 0;
  let curDate = startDate;
  while (curDate <= endDate) {
    let dayOfWeek = curDate.getDay();
    if (!(dayOfWeek === 6 || dayOfWeek === 0)) {
      count++;
    }
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}

const numberify = (str) => {
  if (!str) {
    return 0;
  }
  if (typeof str !== "number") {
    return parseInt(str.replaceAll(" ", ""));
  } else {
    return str;
  }
};

export default {
  props: {
    filters: {
      type: SaveableObjectContainer,
      default: () => new SaveableObjectContainer(),
    },
  },
  data() {
    return {
      formatDate,
      numberify,
      moneyify,
      divideList,
      percentify,
      SHORT_MONTH_NAMES,
    };
  },
  computed: {
    ...mapGetters({
      projectDimensions: "project_dimensions/getProjectDimensionsWithValues",
      projectColors: "project_dimensions/getProjectColors",
      loggedInId: "session/userId",
      projectRisks: "project_risk/list",
    }),

    canSeeAllProjects: function () {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT.LIST_ANY,
        PRIVILEGES.ENTERPRISE.PROJECT.ADMIN
      );
    },
  },
  methods: {
    async goToCreate(type) {
      if (type === PROJECT_TABS.SUPPORT) {
        await this.$router.push(
          `/projects/project-create${PROJECT_TABS.HASH.SUPPORT}`
        );
      } else if (type === PROJECT_TABS.OUTSOURCE) {
        await this.$router.push(
          `/projects/project-create${PROJECT_TABS.HASH.OUTSOURCE}`
        );
      } else {
        await this.$router.push(`/projects/project-create`);
      }
    },
    composeRequestParams() {
      const { page, size } = this.pagination.value;
      const sort =
        this.sort.value.field !== null
          ? `${this.sort.value.field},${this.sort.value.order}`
          : null;

      let params = new URLSearchParams();
      params.append("page", page);
      params.append("size", size);
      params.append("sort", sort);
      for (const [key, value] of Object.entries(this.filters._properties)) {
        let v;
        if (value instanceof SaveableObject) {
          if (!value.useForRequest) continue;
          v = value.value;
        } else {
          v = value;
        }
        if (v === null || v === undefined) continue;
        if (Array.isArray(v)) {
          for (const item of v) {
            params.append(key, item);
          }
        } else {
          params.append(key, v);
        }
      }

      if (this.filters.showOnlyStarred.value) {
        params.append("onlyHighlighted", "true");
      }
      if (this.filters.showOnlyMine.value) {
        params.append("leadUserId", this.loggedInId);
      }
      if (this.filters.showOnlyStarred.value) {
        params.append("onlyHighlighted", "true");
      }
      if (this.filters.showOnlyLiked.value) {
        if (this.likedProjects.length < 1) {
          params.append("projectIds", "-1");
        } else {
          for (let likedProjectId of this.likedProjects) {
            params.append("projectIds", likedProjectId);
          }
        }
      }
      if (this.filters.fromDate.value) {
        params.append("startDate", formatDate(this.filters.fromDate.value));
      }
      if (this.filters.toDate.value) {
        params.append("endDate", formatDate(this.filters.toDate.value));
      }

      if (this.filters.selectedProjectStatuses) {
        for (const status of this.filters.selectedProjectStatuses.value) {
          params.append("status", status);
        }
      }
      if (this.filters.selectedProjectTypes) {
        for (const type of this.filters.selectedProjectTypes.value) {
          params.append("type", type);
        }
      }
      if (this.filters.selectedRiskKPIs.value) {
        for (const riskKPI of this.filters.selectedRiskKPIs.value) {
          params.append("riskKPI", riskKPI);
        }
      }
      if (this.filters.dimensionFilters.value) {
        for (const dimension of this.filters.dimensionFilters.value) {
          for (const dimensionValue of dimension.values) {
            params.append("dimensionValueList", dimensionValue);
          }
        }
      }
      return { params: params };
    },
    calcProgressValue(estimate, spent) {
      if (!estimate) return NaN;
      if (!spent) return 0;
      return Math.round((spent / estimate) * 1000) / 10;
    },
    calcProgressColor(estimate, spent) {
      let percentage = this.calcProgressValue(estimate, spent);
      if (percentage < 90) {
        return "is-success";
      }
      if (percentage >= 90 && percentage < 101) {
        return "is-warning";
      }
      return "is-danger";
    },
    calcProgressColorReversed(estimate, spent) {
      let percentage = this.calcProgressValue(estimate, spent);
      if (percentage < 90) {
        return "is-danger";
      }
      if (percentage >= 90 && percentage < 100) {
        return "is-warning";
      }
      return "is-success";
    },
    timeProgress(row) {
      if (
        row.status === "CLOSED" ||
        row.status === "DONE" ||
        row.status === "DENIED" ||
        row.status === "CANCELLED"
      ) {
        return -1;
      }
      let all = countWeekdays(new Date(row.startDate), new Date(row.dueDate));
      let spent = countWeekdays(new Date(row.startDate), new Date());
      return Math.round((spent / all) * 100);
    },
    timeColor(row) {
      let percentage = this.timeProgress(row);
      if (percentage === -1) {
        return "is-dark";
      }
      if (percentage < 70) {
        return "is-success";
      }
      if (percentage >= 70 && percentage < 90) {
        return "is-warning";
      }
      return "is-danger";
    },
    resolveStatus(enu) {
      switch (enu) {
        case "REQUEST":
          return "Kérelem";
        case "CANCELLED":
          return "Visszavonva";
        case "DENIED":
          return "Elutasítva";
        case "APPROVED":
          return "Elfogadva";
        case "ONGOING":
          return "Folyamatban";
        case "DONE":
          return "Kész";
        case "CLOSED":
          return "Lezárva";
      }
    },
  },
};
