import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-JournalStore");

const urls = {
  journal: "/enterprise/journal",
  unapprovedJournal: "/enterprise/journal/unapproved",
  approve: (id) => `/enterprise/journal/${id}/approve`,
  deny: (id) => `/enterprise/journal/${id}/deny`,
};

const initState = () => {
  return {
    entries: {},
    dashEntries: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchEntries: async function ({ commit, dispatch }, { key, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.journal}/${key}`,
        null,
        true,
        dispatch,
        `${key} naplóbejegyzéseinek betöltése...`,
        null,
        force
      );
      commit("saveEntries", { entries: data, key: key });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchDash: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        `${urls.unapprovedJournal}`,
        null,
        true,
        dispatch,
        "ProjektNaplók letöltése...",
        null,
        force
      );
      commit("saveDash", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveEntry: async function ({ dispatch }, entry) {
    try {
      await this.remoteRequest(
        "put",
        urls.journal,
        entry,
        false,
        dispatch,
        "Bejegyzés létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteEntry: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.journal}/${id}`,
        null,
        false,
        dispatch,
        "Bejegyzés törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  approve: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "put",
        urls.approve(id),
        null,
        false,
        dispatch,
        "Bejegyzés elfogadása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deny: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "put",
        urls.deny(id),
        null,
        false,
        dispatch,
        "Bejegyzés elutasítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveEntries: (state, { entries, key }) => {
    delete state.entries[key];
    state.entries[key] = entries;
  },
  saveDash: (state, entries) => {
    state.dashEntries = entries;
  },
};

const getters = {
  entries: (state) => (key) => {
    return state.entries[key];
  },
  dashboard: (state) => {
    return state.dashEntries;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
